import { css, cx } from '@emotion/css';
import { ComponentType, useState } from 'react';
import { Link, matchPath, useLocation } from 'react-router-dom';

import { useLayoutT } from 'app/layout/glossary';
import { GiveawaysIcon } from 'app/layout/header/assets/giveaways-icon';
import { HelpIcon } from 'app/layout/header/assets/help-icon';
import { QuestsIcon } from 'app/layout/header/assets/quests-icon';
import { TopIcon } from 'app/layout/header/assets/top-icon';

export type IconProps = {
  active: boolean;
  current: boolean;
};

export const LayoutHeaderNavPanel = (): JsxElement => {
  type Route = [string, ComponentType<IconProps>, boolean?];
  const ROUTES: Route[] = [
    ['quests', QuestsIcon],
    ['giveaways', GiveawaysIcon],
    ['top', TopIcon],
    ['help', HelpIcon],
  ];

  const { pathname } = useLocation();

  return (
    <div className={navClass}>
      {...ROUTES.map(([code, Icon, disabled]) => (
        <Option disabled={disabled} code={code} pathname={pathname} Icon={Icon} />
      ))}
    </div>
  );
};

const navClass = css`
  display: flex;
  height: 100%;
`;

export type OptionProps = {
  code: string;
  pathname: string;
  Icon: ComponentType<IconProps>;
  disabled?: boolean;
};

const Option = ({ code, pathname, Icon, disabled }: OptionProps): JsxElement => {
  const current = !!matchPath(code, pathname);
  const includesPathname = pathname.includes(code);
  const active = current || includesPathname;

  const [hover, setHover] = useState<boolean>(false);

  const t = useLayoutT().sub('navigation');

  return (
    <Link
      to={disabled ? '#' : `/${code}`}
      className={cx(disabled ? optionBoxDisabledClass : optionBoxClass, active && currentTabClass)}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Icon active={!disabled && hover} current={active} />
      <h3 className={cx(textClass, active && activeTextClass)}>{t(code)}</h3>
    </Link>
  );
};
const activeTextClass = css`
  background-image: linear-gradient(180deg, #ffaa63 0%, #ea9a4e 100%);
  background-clip: text;
  color: transparent;
`;

const optionBoxClass = css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  cursor: pointer;
  gap: 4px;
  padding: 14px;

  &:hover h3 {
    ${activeTextClass}
  }
  svg {
    height: 18px;
  }
`;
const optionBoxDisabledClass = css`
  padding: 14px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  cursor: pointer;
  gap: 4px;
  &:hover::before {
    opacity: 1;
  }
  &::before {
    content: 'COMING SOON';
    text-align: center;
    position: absolute;
    width: 65px;
    height: 10px;
    font-size: 7px;
    top: 80%;
    left: 54%;
    transform: translate(-50%, -50%);
    opacity: 0;
    color: #988c92;
  }
  svg {
    height: 18px;
  }
`;

const currentTabClass = css`
  position: relative;
  background: #222025;
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 1px;
    background: linear-gradient(180deg, #ffaa63 0%, #ea9a4e 100%);
  }
`;

const textClass = css`
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #988c92;
`;
