import 'react-toastify/dist/ReactToastify.css';

import styled from '@emotion/styled';
import { stdBp } from 'packs/std';
import { ToastContainer } from 'react-toastify';

export const StyledToastContainer = styled(ToastContainer)`
  min-width: 320px;
  width: auto;

  ${stdBp.down('sm')} {
    right: 1em;
    left: unset;
    min-width: 70vw;
    max-width: 80vw;

    top: 120px;
  }

  .Toastify__toast {
    top: 1em;
    background: #26232d;
    border-radius: 12px;
    min-height: unset;
    padding: 0;
    margin-bottom: 38px;
    box-shadow: 0 19px 5px rgba(24, 23, 28, 40%), 0px -19px 5px rgba(24, 23, 28, 40%);

    .Toastify__progress-bar {
      background: #26232d;
      height: 2px;
    }

    .Toastify__close-button {
      display: none;
    }
  }
`;
