import { css } from '@emotion/css';
import { StdCloseDialogIcon } from 'packs/std/dialog/blocks/close-icon';
import { ReactNode } from 'react';

type StdHarmonicDialogBlockProps = {
  title: string;
  onClose(): void;
  children: ReactNode;
};

export const StdHarmonicDialogBlock = ({
  title,
  onClose,
  children,
}: StdHarmonicDialogBlockProps): JsxElement => {
  return (
    <div className={mainClass}>
      <div className={headClass}>
        <div className={titleClass}>{title}</div>
        <div onClick={onClose} className={closeClass}>
          <StdCloseDialogIcon className={closeIconClass} />
        </div>
      </div>
      <div className={bodyClass}>{children}</div>
    </div>
  );
};

const mainClass = css`
  display: flex;
  flex-direction: column;
  gap: 2px;
  border-radius: 32px;
`;

const titleClass = css`
  font-family: 'Onest';
  font-weight: 700;
  font-size: 18px;
  line-height: 140%;
`;

const closeClass = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background: #18171c;
  border-radius: 12px;
  cursor: pointer;
`;

const closeIconClass = css`
  width: 14px;
  height: 14px;
`;

const headClass = css`
  padding: 28px 24px 21px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #26232d;
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
`;

const bodyClass = css`
  padding: 24px;
  background: #26232d;
  border-bottom-left-radius: 32px;
  border-bottom-right-radius: 32px;
`;
