import { css } from '@emotion/css';
import { CaseBattleInsightStandAddBot } from 'app/case/battle/insight/stand/add-bot';
import { CaseBattleInsightStandDropList } from 'app/case/battle/insight/stand/drop';
import { CaseBattleInsightStandPlayer } from 'app/case/battle/insight/stand/player';
import { CaseBattleInsightLiveStandData } from 'app/case/battle/insight/store';
import { CaseBattleInsightOptic } from 'app/case/battle/insight/units/optic';
import { getCaseBattleInsightReel } from 'app/case/battle/insight/units/reel';
import { CaseData } from 'domain/case/definitions';
import { useState } from 'react';

type CaseBattleInsightStandProps = {
  stand?: CaseBattleInsightLiveStandData;
  player?: { id: string; name: string; image: string };
  cases: CaseData[];
  currentCase?: CaseData;
  size: number;
  withAddBot?: boolean;
};

export const CaseBattleInsightStand = ({
  currentCase,
  stand,
  player,
  cases,
  size,
  withAddBot,
}: CaseBattleInsightStandProps): JsxElement => {
  const Reel = getCaseBattleInsightReel(cases);
  const [finalResult, setFinalResult] = useState({ dropColor: null, highestColor: null });

  return (
    <div className={mainClass}>
      <CaseBattleInsightOptic finalResult={finalResult}>
        {currentCase && (
          <Reel
            caseData={currentCase}
            drop={stand!.dropping!}
            setFinalResult={setFinalResult as any}
          />
        )}
        {withAddBot && <CaseBattleInsightStandAddBot />}
      </CaseBattleInsightOptic>
      <CaseBattleInsightStandPlayer player={player} total={stand?.total} />
      <CaseBattleInsightStandDropList
        numberOfPlayers={size}
        size={cases.length}
        drops={stand?.drops ?? []}
      />
    </div>
  );
};

const mainClass = css`
  padding: 22px 0;
  flex: 1;
  background: #1e1c22;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 24px;
    width: 100%;
    background-image: linear-gradient(to bottom, #1e1c22ff, #1e1c22 95%);
  }
`;
