import { css } from '@emotion/css';
import { ReactComponent as PistolSvg } from 'app/crush/control/assets/pistol.svg';
import { ReactComponent as WalletSvg } from 'app/crush/control/assets/wallet.svg';
import { ReactComponent as WeaponSvg } from 'app/crush/control/assets/weapon.svg';
import {
  CrushControlStatsBlock,
  CrushControlStatsBlockBox,
} from 'app/crush/control/units/stat-block';
import { useCrushT } from 'app/crush/glossary';
import { stdBreakpoints } from 'packs/std';

type CrushControlStakeStatsProps = {
  size: number;
  total: number;
};

export const CrushControlStakeStats = ({ size, total }): JsxElement => {
  const t = useCrushT().sub('grab.control');

  if (size === 0) {
    return (
      <div className={mainClass}>
        <WeaponSvg className={iconClass} />
        <div className={textClass}>{t('select-skins')}</div>
      </div>
    );
  }

  return (
    <CrushControlStatsBlockBox>
      <CrushControlStatsBlock
        label={t('skins')}
        value={size}
        icon={<PistolSvg className={iconSelectClass} />}
      />
      <CrushControlStatsBlock
        label={t('total')}
        value={`$${total.toFixed(2)}`}
        icon={<WalletSvg className={iconSelectClass} />}
      />
    </CrushControlStatsBlockBox>
  );
};

const mainClass = css`
  display: flex;
  justify-content: center;
  gap: 24px;
  padding: 23px 19px 23px 45px;
  height: 107px;
  ${stdBreakpoints.between(1440, 1520)} {
    padding: 23px 19px 23px 30px;
  }
`;

const textClass = css`
  font-family: 'Commissioner';
  font-weight: 600;
  font-size: 13px;
  line-height: 130%;
  color: #988c92;
  width: 127px;

  ${stdBreakpoints.up(1920)} {
    width: 157px;
    padding-top: 9px;
    height: 60px;
    font-size: 15px;
  }
  ${stdBreakpoints.between(1440, 1520)} {
    width: 97px;
  }
`;

const iconClass = css`
  width: 145px;
  height: 45px;
  ${stdBreakpoints.between(1440, 1520)} {
    width: 130px;
  }
  ${stdBreakpoints.up(1920)} {
    width: 160px;
    height: 50px;
  }
`;

const iconSelectClass = css`
  width: 24px;
  height: 24px;
`;
