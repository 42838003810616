import { css } from '@emotion/css';
import { LeaderBoardCurrentTopData, LeaderBoardItemData } from 'app/leader-board/definitions';
import { LeaderBoardTableBody } from './body';
import { LeaderBoardTableHead } from './head';

type LeaderBoardTableProps = {
  data: LeaderBoardCurrentTopData;
};

export const LeaderBoardTable = (props: LeaderBoardTableProps): JsxElement => {
  return (
    <div className={leaderBoardTableBoxClass}>
      <LeaderBoardTableHead />
      <LeaderBoardTableBody {...props} />
    </div>
  );
};

const leaderBoardTableBoxClass = css`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;
