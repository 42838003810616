import { css } from '@emotion/css';
import { useMemo, useState } from 'react';

import { ReactComponent as CloseDialogIcon } from 'app/case/battle/creation/case-select/assets/close-icon.svg';
import { CaseBattleCreationCaseSelectCard } from 'app/case/battle/creation/case-select/card';
import { CaseBattleCreationCaseSelectDetails } from 'app/case/battle/creation/case-select/details';
import { CaseBattleCaseSelectFilters } from 'app/case/battle/creation/case-select/filters';
import { CaseBattleCreationCaseSelectStats } from 'app/case/battle/creation/case-select/stats';
import { useCaseBattleCreationStore } from 'app/case/battle/creation/store';
import { CaseData } from 'domain/case/definitions';
import { coilReq } from 'packs/libs/coil';
import { RangerIntersectionLine, RangerProvider, useRanger } from 'packs/libs/ranger';
import { createSyringe } from 'packs/libs/syringe';
import { StdTopDialog, stdBp } from 'packs/std';
import { ListCaseData } from '../case-list/case-list';
import { useCaseBattleCreationT } from '../glossary';

const [CaseBattleCreationCaseSelectDialogSocket, inject] = createSyringe();

export { CaseBattleCreationCaseSelectDialogSocket };
type CaseBattleCreationDialogProps = {
  select(data: ListCaseData): void;
};
export const openCaseBattleCreationCaseSelectDialog = ({
  select,
}: CaseBattleCreationDialogProps) => {
  const request = (query, context) =>
    coilReq({
      action: 'case.search',
      data: { ...query, ...context },
    });

  inject(function CaseBattleCreationCaseSelectDialog({ onClose }): JsxElement {
    return (
      <StdTopDialog onClose={onClose}>
        <RangerProvider size={20} request={request}>
          <Content onClose={onClose} select={select} />
        </RangerProvider>
      </StdTopDialog>
    );
  });
};
type CaseBattleCreationContentProps = {
  onClose(): void;
  select(data: ListCaseData): void;
};
const Content = ({ onClose, select }: CaseBattleCreationContentProps): JsxElement => {
  const [opened, open] = useState<CaseData>(null!);
  const t = useCaseBattleCreationT().sub('case-select');
  return (
    <div className={mainClass}>
      <div className={selectedDialogClass}>
        <div className={searchBoxClass}>
          <div className={headClass}>
            <div className={titleBoxClass}>
              <span className={titleClass}>{t('filters')}</span>
              <CaseBattleCaseSelectFilters />
            </div>
            <div onClick={onClose} className={closeClass}>
              <CloseDialogIcon />
            </div>
          </div>
        </div>
        <div className={bodyClass}>
          <div className={bodyCaseListBoxScrollClass}>
            <div className={bodyCaseListBoxClass}>
              <CaseList open={open} select={select} />
              <RangerIntersectionLine />
            </div>
          </div>
          <div className={searchFooterClass}>
            <CaseBattleCreationCaseSelectStats />
          </div>
        </div>
      </div>

      <div className={detailsBoxClass}>
        <CaseBattleCreationCaseSelectDetails data={opened} />
      </div>
    </div>
  );
};

type CaseListProps = {
  open(data: CaseData): void;
  select(data: ListCaseData): void;
};

const CaseList = ({ open, select }: CaseListProps): JsxElement => {
  const [{ data }] = useRanger();
  const [{ cases }, op] = useCaseBattleCreationStore();

  return useMemo(() => {
    const casesOp = op.cases;
    const actions = {
      open,
      addCase: casesOp.add,
      delCase: casesOp.del,
      setAmount: casesOp.setAmount,
      select,
    };
    return (
      <div className={listBoxClass}>
        {data.listMapV((item) => {
          const selected = cases.find(item.id);
          return (
            <CaseBattleCreationCaseSelectCard
              key={item.id}
              data={item}
              actions={actions}
              amount={selected ? selected.amount : undefined}
            />
          );
        })}
      </div>
    );
  }, [data, cases]);
};

const mainClass = css`
  width: 580px;
  display: flex;
  border-radius: 32px;
  gap: 12px;
  ${stdBp.up(2560)} {
    width: calc(580px * 1.3);
  }
`;
const selectedDialogClass = css`
  width: 580px;
  display: flex;
  flex-direction: column;
  border-radius: 32px;
  gap: 2px;
  ${stdBp.up(2560)} {
    width: calc(580px * 1.3);
  }
`;

const bodyCaseListBoxScrollClass = css`
  padding-right: 8px;
  height: calc(100% - 78px);
`;
const bodyCaseListBoxClass = css`
  padding: 0 10px 0 4px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 2px;
  }
`;
const listBoxClass = css`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
`;

const searchBoxClass = css`
  width: 580px;
  ${stdBp.up(2560)} {
    width: calc(580px * 1.3);
  }
`;

const detailsBoxClass = css`
  padding-left: 12px;
  width: 200px;
  ${stdBp.up(2560)} {
    width: calc(200px * 1.3);
  }
`;

const searchFooterClass = css`
  position: relative;
  padding: 9px 24px;
  background: #26232d;
  align-items: center;
  display: flex;
  justify-content: space-between;
  gap: 38px;
  border-bottom-left-radius: 32px;
  border-bottom-right-radius: 32px;
`;

const bodyClass = css`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 557px;
  background: #26232d;
  border-bottom-left-radius: 32px;
  border-bottom-right-radius: 32px;
  ${stdBp.up(2560)} {
    height: calc(557px * 1.3);
  }
`;
const titleBoxClass = css`
  display: flex;
  align-items: center;
  gap: 40px;
`;

const titleClass = css`
  font-family: 'Onest';
  font-weight: 700;
  font-size: 18px;
  line-height: 140%;
  ${stdBp.up(2560)} {
    font-size: calc(18px * 1.3);
  }
`;

const closeClass = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background: #18171c;
  border-radius: 12px;
  cursor: pointer;
  svg {
    height: 14px;
    ${stdBp.up(2560)} {
      height: calc(14px * 1.3);
      width: calc(14px * 1.3);
    }
  }
  ${stdBp.up(2560)} {
    height: calc(32px * 1.3);
    width: calc(32px * 1.3);
  }
`;

const headClass = css`
  padding: 20px 30px 14px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #26232d;
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
`;
