import { css } from '@emotion/css';
import { ComponentType, createElement, CSSProperties, Fragment, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { classified } from 'support/react/classified';
import { useSingleton } from 'support/react/use-singleton';

type Tags = [['link', { to: string }, Node[]], ['accent', void, Node[]]];
type Children = Node | Node[];
type Node = string | AnyOf<Tags>;

type ChatRichContentProps = {
  content: Node[];
};

const parse = (node: Node) => {
  if (typeof node === 'string') return node;
  const [name, props, children] = node;
  const component = TAGS.get(name);
  if (component === undefined) return 'unexpected_tag';
  return createElement(component, props as any, ...resolveChildren(children));
};

const resolveChildren = (children: Children | undefined) => {
  if (children === undefined) return [undefined];
  if (typeof children[0] === 'string') {
    // @ts-ignore
    return children.map(parse);
  } else {
    // @ts-ignore
    return [parse(children)];
  }
};

export const ChatRichContent = ({ content }: ChatRichContentProps): JsxElement => {
  return useSingleton(() => createElement(Fragment, undefined, ...content.map(parse)));
};

type RichLinkProps = {
  to: string;
  children: ReactNode;
  style?: CSSProperties;
};

const RichLink = ({ to, children, style }: RichLinkProps): JsxElement => {
  return (
    <Link to={to} style={style} className={linkClass}>
      {children}
    </Link>
  );
};

const Accent = classified.span(css`
  color: #fff;
  font-weight: 600;
`);

const TAGS = new Map<string, ComponentType<Rsa>>([
  ['link', RichLink],
  ['accent', Accent],
]);

const linkClass = css`
  color: #fff;
`;
