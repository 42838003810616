import { ReactComponent as ArrowIcon } from 'app/contest/assets/arrow.svg';
import { StdKindButton, StdKindButtonProps } from 'packs/std/inputs/kind-button';

type ContestCardArrowButtonProps = StdKindButtonProps;

export const ContestCardArrowButton = (props: ContestCardArrowButtonProps): JsxElement => {
  return (
    <StdKindButton {...props}>
      {props.children}
      <ArrowIcon />
    </StdKindButton>
  );
};
