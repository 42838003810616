class Ref<T> {
  private factory: () => T;

  constructor(factory: () => T) {
    this.factory = factory;
  }

  get v() {
    const value = this.factory();
    Object.defineProperty(this, 'v', { value });
    // @ts-ignore
    delete this.factory;
    return value;
  }
}

export function stashRef<T>(factory: () => T): Ref<T> {
  return new Ref<T>(factory);
}

export function stash<T>(func: () => T): () => T {
  const ref = new Ref(func);
  return () => ref.v;
}

export function stashCb<T extends (...args: any[]) => any>(factory: () => T): T {
  const ref = stashRef(factory);
  // @ts-ignore
  return (...args) => ref.v(...args);
}

type Descriptor<T> = TypedPropertyDescriptor<T>;

export function stash_get<T = unknown>(_target: any, key: string, descriptor: Descriptor<T>) {
  const { get } = descriptor;
  if (get === undefined) throw 'unexpected use of @stash_get';
  descriptor.get = function () {
    const value = get.apply(this);
    Object.defineProperty(this, key, { value });
    return value;
  };
}

export function stash_method<T = unknown>(
  _target: any,
  key: string,
  descriptor: Descriptor<() => T>
) {
  const { value } = descriptor;
  if (typeof value !== 'function') throw Error('unexpected use of @stashMethod');
  descriptor.value = function () {
    const result: T = value.apply(this);
    Object.defineProperty(this, key, {
      value: function () {
        return result;
      },
    });
    return result;
  };
}

type Proto<R extends object> = { [p in keyof R]: () => R[keyof R] };
type Result<T, R> = (obj: T) => T & R;

export function stashObj<T extends object, R extends object = Rsu>(
  stashes: Proto<R>
): Result<T, R> {
  const proto = {};
  for (const [prop, factory] of Object.entries(stashes)) {
    Object.defineProperty(proto, prop, {
      get() {
        // @ts-ignore
        const value = factory.apply(this);
        Object.defineProperty(this, prop, { value });
        return value;
      },
    });
  }
  return (obj) => Object.create(obj, proto);
}

export function stashProto<T>(proto: any, obj: Record<string, (this: T) => any>) {
  for (const [prop, factory] of Object.entries(obj)) {
    Object.defineProperty(proto, prop, {
      get() {
        // @ts-ignore
        const value = factory.apply(this);
        Object.defineProperty(this, prop, { value });
        return value;
      },
    });
  }
}

// export function stashObj<T extends object>() {
//   return function stashObj<R extends object>(stashes: Proto<R>): Result<T, R> {
//     const proto = {};
//     for (const [prop, factory] of Object.entries(stashes)) {
//       Object.defineProperty(proto, prop, {
//         get() {
//           // @ts-ignore
//           const value = factory.apply(this);
//           Object.defineProperty(this, prop, {value});
//           return value;
//         },
//       });
//     }
//     return obj => Object.create(obj, proto);
//   };
// }
