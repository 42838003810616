import { css } from '@emotion/css';
import { ContestCardActive } from 'app/contest/card/builds/active';
import { ContestCardCalculation } from 'app/contest/card/builds/calculation';
import { ContestCardFinished } from 'app/contest/card/builds/finished';
import { ContestCardUpcoming } from 'app/contest/card/builds/upcoming';
import { useContestT } from 'app/contest/glossary';
import { ContestData, ContestStatus } from 'domain/contest';
import { playerRequirementsCoilRequest } from 'domain/player/requirements/wrap-request';
import { StdKindButton } from 'packs/std/inputs/kind-button';
import { memo } from 'react';

type ContestInsightCardProps = {
  contest: ContestData;
  joined?: boolean;
};

export const ContestInsightCard = memo(function ContestInsightCard(
  props: ContestInsightCardProps
): JsxElement {
  const t = useContestT().sub('insight.card');
  const { contest, joined } = props;

  const composeContent = () => {
    switch (contest.status) {
      case ContestStatus.exposed:
      case ContestStatus.appeared:
        return (
          <ContestCardUpcoming contest={contest}>
            <StdKindButton
              className={buttonClass}
              disabled={joined}
              children={t(joined ? 'joined' : 'join')}
              onClick={async () => {
                await playerRequirementsCoilRequest(
                  'contest.join',
                  contest.code ? ['code', contest.code] : ['id', contest.id]
                );
              }}
            />
          </ContestCardUpcoming>
        );
      case ContestStatus.active:
        return <ContestCardActive contest={contest} />;
      case ContestStatus.calculation:
        return <ContestCardCalculation contest={contest} />;
      case ContestStatus.finished:
        return <ContestCardFinished contest={contest} />;
    }
  };

  return <div className={mainClass}>{composeContent()}</div>;
});

const mainClass = css`
  background: #1e1c22;
  border-radius: 12px;
`;

const buttonClass = css`
  width: 100%;
  display: flex;
  gap: 4px;
  align-items: center;
  justify-contetn: center;
`;

// export const ContestInsightUpcomingCard = (props: ContestInsightCardProps): JsxElement => {
//   return (
//     <ContestCardMainBox data={contest}>
//       <StdKindButton
//         onClick={async () => {
//           await coilReq({
//             action: 'contest.join',
//             data: contest.code ? ['code', contest.code] : ['id', contest.id],
//           });
//           op.setState({ ...state, joined: !joined });
//         }}
//       />
//     </ContestCardMainBox>
//   );
// };

// export const ContestInsightActiveCard = ({}: ContestInsightCardProps): JsxElement => {
//   return (
//     <ContestCardMainBox data={contest}>
//       <StdKindButton
//         onClick={async () => {
//           await coilReq({
//             action: 'contest.join',
//             data: contest.code ? ['code', contest.code] : ['id', contest.id],
//           });
//           op.setState({ ...state, joined: !joined });
//         }}
//       />
//     </ContestCardMainBox>
//   );
// };
