import { createGlossary } from 'packs/libs/intl';

import en from './langs/en.yml';
import es from './langs/es.yml';
import ru from './langs/ru.yml';

export const QuestsGlossary = createGlossary({ en, ru, es });

export const useQuestsT = QuestsGlossary.useT;
export const useQuestsSubT = QuestsGlossary.useSubT;
