import { css, cx } from '@emotion/css';
import { ContestUpon } from 'domain/contest/definitions';
import { useContestLiveT } from 'domain/contest/live/glossary';
import { ContestLiveState } from 'domain/contest/live/state';
import { useContestLiveState } from 'domain/contest/live/store';
import { useMemo } from 'react';
import { ReactComponent as CupIcon } from '../assets/small-cup.svg';

type ContestLiveGameModeBadgeProps = {
  upon: ContestUpon;
  className?: string;
};

export const ContestLiveGameModeBadge = ({
  upon,
  className,
}: ContestLiveGameModeBadgeProps): JsxElement => {
  const live = useContestLiveState();
  return useMemo(() => {
    if (!live.active || !live.contest.upon.includes(upon)) return null;
    return <BadgeItself state={live} className={className} />;
  }, [live.active]);
};

type BadgeItselfProps = {
  className?: string;
  state: ContestLiveState;
};
const BadgeItself = (props: BadgeItselfProps): JsxElement => {
  const t = useContestLiveT();

  return (
    <div className={cx(mainClass, props.className)}>
      <div className={headerClass}>
        <CupIcon />
        <p className={fullNameClass}>
          <span className={nameClass}>{t('badge.title')}:</span> {t.fork(props.state.contest.title)}
        </p>
      </div>
    </div>
  );
};

const mainClass = css`
  border-radius: 4px;
  background: rgba(38, 35, 45, 0.7);
  backdrop-filter: blur(6px);
  height: 32px;
  width: fit-content;
  padding: 7px 8px;
`;

const headerClass = css`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const fullNameClass = css`
  font-size: 13px;
  font-family: Onest;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  color: #988c92;
`;

const nameClass = css`
  color: transparent;
  text-fill-color: transparent;
  background-clip: text;
  background-image: linear-gradient(180deg, #ffaa63 0%, #ea9a4e 100%);
`;
