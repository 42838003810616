import { css, cx } from '@emotion/css';
import Slider, { Settings } from 'react-slick';

import { getInventoryOperator } from 'app/inventory';
import { UpgradeStakeState } from 'app/upgrade';
import { SkinImg } from 'domain/skin/units/image';
import { ItemData } from 'domain/item';
import { useUpgradeT } from 'app/upgrade/glossary';
import { skinNameViewOnSkin } from 'domain/skin/name-view';
import { roundCash } from 'support/etc/round-cash';
import { ReactComponent as CrossIcon } from 'app/upgrade/assets/cross-icon.svg';
import { ReactComponent as GridImage } from 'app/upgrade/assets/grid-bg-image.svg';
import { CSSProperties } from 'react';

type Props = {
  stake: UpgradeStakeState;
};

export const UpgradeStakeItemsSelected = ({ stake }: Props): JsxElement => {
  return (
    <div className={mainClass}>
      <ItemsCarousel items={stake.items} />
      <TotalBetCard total={stake.total} count={stake.items.length} />
    </div>
  );
};

type ItemsCarouselProps = {
  items: ItemData[];
};

const ItemsCarousel = ({ items }: ItemsCarouselProps): JsxElement => {
  const settings: Settings = {
    dots: false,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const deselectItem = (id: string) => {
    getInventoryOperator().deselectItem(id);
  };
  const t = useUpgradeT().sub('stake');
  const deselectAll = () => {
    getInventoryOperator().deselectAll();
  };
  return (
    <div className={sliderMainClass}>
      <Slider {...settings} className={sliderClass}>
        {items.map(({ skin, id, cost }: ItemData) => {
          return (
            <div key={id} className={sliderItemClass}>
              <div className={sliderItemBoxClass}>
                <div className={skinItemClass}>
                  <div className={skinRowClass}>
                    <div className={skinNameClass}>{skinNameViewOnSkin(skin).model}</div>
                    <div className={skinCostClass}>${roundCash(cost)}</div>
                  </div>
                  <div className={crossIconBoxClass} onClick={() => deselectItem(id)}>
                    <CrossIcon className={crossIconClass} />
                  </div>
                </div>
                <div className={deselectEverySkinClass} onClick={deselectAll}>
                  {t('deselect')}
                </div>
              </div>
              <div className={skinBoxClass}>
                <SkinImg code={skin.image} alt={skin.id} className={imageClass} />
                <GridImage className={gridBgClass} />
              </div>
              <div
                className={gradientClass}
                style={
                  {
                    '--highlighting': `rgba(${skin.color[0]}, ${skin.color[1]}, ${skin.color[2]}, 1)`,
                  } as CSSProperties
                }
              ></div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

type TotalBetCardProps = {
  total: number;
  count: number;
};
const TotalBetCard = ({ total, count }: TotalBetCardProps): JsxElement => {
  const t = useUpgradeT().sub('stake');
  return (
    <div className={totalBoxClass}>
      <div className={yourBetBoxClass}>
        <div className={yourBetClass}>{t('your-bet')}</div>
        <div className={totalBetClass}>${roundCash(total)}</div>
      </div>
      <span className={numbersOfSkinsClass}>{t('skin-count', { count })}</span>
    </div>
  );
};

const sliderItemBoxClass = css`
  display: flex;
  justify-content: space-between;
`;
const numbersOfSkinsClass = css`
  font-weight: 600;
  font-size: 10px;
  line-height: 130%;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #dfdfdf;
  text-align: center;
`;
const yourBetClass = css`
  font-weight: 600;
  font-size: 12px;
  line-height: 130%;
  color: #988c92;
`;
const yourBetBoxClass = css`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
const totalBoxClass = css`
  display: flex;
  gap: 90px;
`;
const totalBetClass = css`
  font-family: 'Onest';
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: -0.03em;
  background: linear-gradient(180deg, #ffaa63 0%, #ea9a4e 100%);
  background-clip: text;
  text-fill-color: transparent;
`;
const deselectEverySkinClass = css`
  flex-basis: 110px;
  font-weight: 600;
  font-size: 12px;
  line-height: 130%;
  color: #988c92;
  padding-left: 14px;
  cursor: pointer;
`;
const gridBgClass = css`
  position: absolute;
  bottom: -15%;
  height: 138px;
`;
const skinBoxClass = css`
  position: relative;
  display: flex;
  justify-content: center;
`;
const gradientClass = css`
  position: absolute;
  width: 100px;
  height: 90px;
  top: 22%;
  left: 36%;
  opacity: 0.3;
  filter: blur(40px);
  background: var(--highlighting);
`;

const mainClass = css`
  max-width: 356px;
  height: 100%;
`;

const sliderMainClass = css`
  position: relative;
  z-index: 2;
`;

const sliderClass = css`
  > div {
    overflow: visible;
  }

  .slick-next,
  .slick-prev {
    width: 30px;
    height: 30px;
    border-radius: 6px;
    z-index: 3;
  }

  .slick-prev {
    left: 30px;
  }

  .slick-next {
    right: 30px;
  }

  .slick-prev:before,
  .slick-next:before {
    font-size: 24px;
    line-height: 12px;
  }

  .slick-prev:before {
    content: '‹';
  }

  .slick-next:before {
    content: '›';
  }
  
  .slick-slider,
  .slick-track,
  .slick-list {
    transform: none;
  }
`;

const sliderItemClass = css`
  height: 100%;
`;

const imageClass = css`
  height: 120px;
  width: auto;
  z-index: 1;
`;

const skinItemClass = css`
  display: flex;
  gap: 6px;
  align-items: start;
  z-index: 3;
`;

const skinRowClass = css`
  display: flex;
  flex-direction: column;
`;

const skinNameClass = css`
  font-weight: 600;
  font-size: 12.5px;
  line-height: 140%;
  color: #dfdfdf;
`;

const skinCostClass = css`
  font-weight: 600;
  font-size: 12px;
  line-height: 130%;
  color: #988c92;
`;
const crossIconClass = css`
  height: 7.8px;
`;
const crossIconBoxClass = css`
  cursor: pointer;
  padding-top: 0.3px;
`;
