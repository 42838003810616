import { createElement, ImgHTMLAttributes } from 'react';

type Opts = {
  code?: string;
};
type SkinImageProps = Omit<ImgHTMLAttributes<HTMLImageElement>, 'src'> & Opts;

export const completeSkinImageUrl = (opts: Opts) => {
  // Old API gave only partial URL
  // ByMykel returns full URLs, thus making this module obsolete
  return String(`${opts.code}`);
};

export const SkinImg = ({ code, ...props }: SkinImageProps): JsxElement => {
  return createElement('img', {
    ...props,
    src: completeSkinImageUrl({ code }),
  });
};
