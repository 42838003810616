import { EffectCallback, useEffect, useState } from 'react';

import { Wire } from 'packs/libs/wire';

import { launchSignIn } from 'app/sign-in';
import { getActor } from 'domain/actor';
import { debounce } from 'lodash';
import { SubSub } from 'packs/libs/coil/utils/sub-sub';
import { UReader, UWriter } from 'packs/libs/uint-8';
import { execIfFn } from 'support/etc/exec-if-fn';
import { SimpleEventTarget } from 'support/struct/simple-event-target';
import { showCoilResponseError } from './error';

export const CoilWire = new Wire({ url: ENV_COIL_URL });

type ChannelListener = (...args: any[]) => void;

const Channels = new SubSub<string | number, UReader>();
const OnConnect = new SimpleEventTarget();
const OnDisconnect = new SimpleEventTarget();

CoilWire.onEvent = Channels.emit.bind(Channels);

let connected = false;

CoilWire.onConnect = () => {
  connected = true;
  coilSuspend();
  OnConnect.emit();
};
CoilWire.onDisconnect = () => {
  connected = false;
  OnDisconnect.emit();
};
CoilWire.connect();

export function useCoilChannel(channel: string, listener: ChannelListener) {
  useEffect(() => coilListen(channel, listener), [listener]);
}

export const coilListenR = Channels.sub;

export const coilListen = (channel: string | number, listener: ChannelListener) => {
  if (__DEBUG__) {
    return coilListenR(channel, (r) => {
      const tuple = readTupleOfPacks(r);
      lg.event('coil.event', channel, ...tuple);
      listener(...tuple);
    });
  } else {
    return coilListenR(channel, (r) => listener(...readTupleOfPacks(r)));
  }
};

const readTupleOfPacks = (r: UReader) => {
  const tuple: any[] = [];
  while (!r.complete) tuple.push(r.pack());
  return tuple;
};

export const listenCoilConnect = OnConnect.sub;

export const coilConnect = (effect: EffectCallback) => {
  let unmount;
  if (connected) unmount = effect();
  const listener = () => {
    unmount = effect();
  };
  OnConnect.add(listener);
  return () => {
    OnConnect.delete(listener);
    unmount?.();
  };
};

type CoilReqOpts = {
  action: string;
  data?: any;
  timeout?: number;
  force?: boolean;
  toast?: false; // show toast on error, default true
};

let releaseSuspense: () => void;
let suspense: Promise<void>;
export function coilRelease() {
  releaseSuspense?.();
  suspense = undefined!;
  releaseSuspense = undefined!;
}

export function coilSuspend() {
  if (suspense === undefined) {
    suspense = new Promise((resolve) => {
      releaseSuspense = resolve;
    });
  }
}

coilSuspend();

export const coilReqR = async (opts: CoilReqOpts): Promise<UReader> => {
  if (suspense !== undefined && !opts.force) await suspense;

  const answer = await CoilWire.req({
    action: opts.action,
    data: resolveData(opts.data),
    timeout: opts.timeout ?? DEFAULT_TIMEOUT,
  });

  if (answer.status !== 200) {
    const data: Rsa & { status: number } = { status: answer.status };
    if (!answer.data.complete) {
      Object.assign(data, answer.data.pack<Rsa>());
    }

    if (answer.status === 401) {
      getActor().logout();
      launchSignIn();
    } else if (opts.toast ?? true) {
      showCoilResponseError(data);
    }

    throw data;
  }

  return answer.data;
};

let coilReq = async <T = any>(opts: CoilReqOpts): Promise<T> => {
  const r = await coilReqR(opts);
  return r.complete ? undefined! : r.pack();
};

if (__DEBUG__) {
  let sourceReq = coilReq;
  coilReq = async (opts) => {
    try {
      const result = await sourceReq(opts);
      console.log('coilReq', { ...opts, result });
      return result;
    } catch (e) {
      console.log('coilReq', { ...opts, error: e });
      throw e;
    }
  };
}

export { coilReq };

const DEFAULT_TIMEOUT = 1e4;

const resolveData = (data: any) => {
  if (data === undefined) return undefined;
  const writer = new UWriter();
  writer.pack(data);
  return writer;
};

export const coilSend = (action: string, data?: any) => {
  if (data !== undefined) data = new UWriter().pack(data).finish();
  CoilWire.event(action, data);
};

export function useCoilReq<T = any>(
  opts: CoilReqOpts | (() => CoilReqOpts),
  deps: any[] = []
): T | undefined {
  const [state, setState] = useState(undefined);
  useEffect(() => {
    coilReq(execIfFn(opts)).then(setState);
  }, deps);
  return state;
}

export const coilReboot = () => CoilWire.reboot();

if (__DEV__) {
  import.meta.hot!.on(
    'vite:afterUpdate',
    debounce(() => {
      console.log('coil reboot on HMR');
      coilReboot();
    })
  );
}

if (__DEBUG__) {
  window['coil'] = {
    req: coilReq,
    send: coilSend,
    listen: coilListen,
    reboot: coilReboot,
  };
}

// https://vitejs.dev/guide/api-hmr.html
// const event = [
//   'vite:beforeUpdate',
//   'vite:afterUpdate',
//   'vite:beforeFullReload',
//   'vite:beforePrune',
//   'vite:invalidate',
//   'vite:error',
// ];
