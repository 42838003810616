export const SoundIcon = ({ active }) => {
  return active ? (
    <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.5 7.70068C13.9484 7.96004 14.25 8.4448 14.25 9.00001C14.25 9.55522 13.9484 10.04 13.5 10.2993M13.5 5.325C15.2117 5.67246 16.5 7.18577 16.5 8.99999C16.5 10.8142 15.2117 12.3275 13.5 12.675M5.64992 5.70156L8.85 3.30259C9.83885 2.56128 11.25 3.26653 11.25 4.50204V13.498C11.25 14.7335 9.83885 15.4387 8.85 14.6974L5.64992 12.2984C5.39032 12.1038 5.07463 11.9986 4.75018 11.9986H3C2.17157 11.9986 1.5 11.3274 1.5 10.4993V7.50068C1.5 6.67263 2.17157 6.00136 3 6.00136H4.75018C5.07463 6.00136 5.39032 5.89617 5.64992 5.70156Z"
        stroke="url(#paint0_linear_672_2048)"
        strokeWidth="1.125"
        strokeLinecap="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_672_2048"
          x1="9"
          y1="3"
          x2="9"
          y2="15"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFAA63" />
          <stop offset="1" stopColor="#EA9A4E" />
        </linearGradient>
      </defs>
    </svg>
  ) : (
    <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.5 7.70068C13.9484 7.96004 14.25 8.4448 14.25 9.00001C14.25 9.55522 13.9484 10.04 13.5 10.2993M13.5 5.325C15.2117 5.67246 16.5 7.18577 16.5 8.99999C16.5 10.8142 15.2117 12.3275 13.5 12.675M5.64992 5.70156L8.85 3.30259C9.83885 2.56128 11.25 3.26653 11.25 4.50204V13.498C11.25 14.7335 9.83885 15.4387 8.85 14.6974L5.64992 12.2984C5.39032 12.1038 5.07463 11.9986 4.75018 11.9986H3C2.17157 11.9986 1.5 11.3274 1.5 10.4993V7.50068C1.5 6.67263 2.17157 6.00136 3 6.00136H4.75018C5.07463 6.00136 5.39032 5.89617 5.64992 5.70156Z"
        stroke="#988C92"
        strokeWidth="1.125"
        strokeLinecap="round"
      />
    </svg>
  );
};
