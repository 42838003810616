import { AnyPromo, PromoType } from 'domain/promo/definitions';
import { css } from '@emotion/css';
import { ReactNode, useEffect, useState } from 'react';
import { StdTopDialog } from 'packs/std';
import { Duration } from 'luxon';
import { ServerTime } from 'packs/libs/coil';
import { StdCloseDialogIcon } from 'packs/std/dialog/blocks/close-icon';
import { ReactComponent as ChickTopIcon } from 'domain/promo/welcome-flow/assets/chick-top.svg';
import { ReactComponent as ChickIcon } from 'domain/promo/welcome-flow/assets/chick.svg';
import { ReactComponent as TicketTopIcon } from 'domain/promo/welcome-flow/assets/ticket.svg';
import { ReactComponent as BackgroundSkin } from 'domain/promo/welcome-flow/assets/background.svg';
import { SkinImg } from 'domain/skin/units/image';

type OnWelcomeModalProps = {
  promo: AnyPromo;
  title: ReactNode;
  subTitle: ReactNode;
  onClose: () => void;
  timerText?: ReactNode;
  actionText: ReactNode;
  bonus?: number;
};

export const OnWelcomeModal = ({
  promo,
  title,
  bonus,
  subTitle,
  onClose,
  timerText,
  actionText,
}: OnWelcomeModalProps) => {
  const composeTimer = (): ReactNode => {
    if (promo.type !== PromoType.replenish || !promo.timer) return null;
    const timerEnds = promo.timer + promo.activatedAt!;

    return (
      <div>
        <div className={timerBoxClass}>
          <ProgressBar finish={timerEnds} />
          <div className={countdownBoxClass}>
            <div>{timerText}:</div>
            <Countdown finish={timerEnds} />
          </div>
        </div>
      </div>
    );
  };

  const composeTopImage = (): JsxElement => {
    if (promo.type !== PromoType.skin) return <TicketTopIcon />;

    return <ChickTopIcon />;
  };

  const composeImage = (): JsxElement => {
    if (promo.type !== PromoType.skin)
      return (
        <div className={chickIconClass}>
          <ChickIcon />
        </div>
      );

    return (
      <div className={skinImageBoxClass}>
        <BackgroundSkin className={backgroundSkinClass} />
        <div className={skinImageClass}>
          <SkinImg code={promo.skin.image} />
        </div>
      </div>
    );
  };

  const composeSubtitle = (): ReactNode => {
    if (promo.type !== PromoType.skin)
      return (
        <div>
          <p className={subtitleClass}>
            <span className={subtitleActiveClass}>${bonus}</span> {subTitle}
          </p>
        </div>
      );

    return null;
  };

  const composePromoSection = (): JsxElement => {
    if (promo.type !== PromoType.skin) return <div className={promoCodeClass}>{promo.code}</div>;

    const skinName = promo.skin.id.split('|')[0];
    const skinSecondName = promo.skin.id.split('|')[1];

    return (
      <div className={skinNameBoxClass}>
        <p className={skinNameClass}>{skinName}</p>
        <p className={skinSecondNameClass}>{skinSecondName}</p>
      </div>
    );
  };

  return (
    <StdTopDialog onClose={onClose}>
      <div className={mainClass}>
        <div className={boxClass}>
          <div className={closeClass}>
            {composeTopImage()}
            <div onClick={onClose} className={closeIconClass}>
              <StdCloseDialogIcon />
            </div>
          </div>
          <div className={titleClass}>{title}</div>
          {composeSubtitle()}
          <div>{composeImage()}</div>
          <div>{composePromoSection()}</div>
          <div className={footerClass}>
            {composeTimer()}
            <div className={actionsClass}>
              <button onClick={onClose} className={buttonClass}>
                {actionText}
              </button>
            </div>
          </div>
        </div>
      </div>
    </StdTopDialog>
  );
};

const mainClass = css`
  background: #26232d;
  border-radius: 32px;
  width: 400px;
`;

const boxClass = css`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 28px 24px 32px 24px;
`;

const closeClass = css`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 120px;
`;

const closeIconClass = css`
  background: #18171c;
  border-radius: 12px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const footerClass = css`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const buttonClass = css`
  border: none;
  width: 352px;
  padding: 15px 0;
  background: linear-gradient(180deg, #ffaa63 0%, #ea9a4e 100%);
  box-shadow: inset 0px 4px 12px 2px #ff7324;
  border-radius: 4px;
  font-family: 'Onest';
  font-weight: 700;
  font-size: 13px;
  line-height: 140%;
  text-align: center;
  color: #2d241c;

  &:hover {
    background: #ffbe88;
    box-shadow: none;
  }
`;

const titleClass = css`
  font-family: 'Onest';
  font-weight: 700;
  font-size: 18px;
  line-height: 140%;
  text-align: center;
  color: #ffffff;
`;

const subtitleClass = css`
  font-family: 'Onest';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: rgba(152, 140, 146, 1);
`;

const subtitleActiveClass = css`
  background: linear-gradient(180deg, #ffaa63 0%, #ffaa63 0.01%, #ea9a4e 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;

const skinNameBoxClass = css`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const skinSecondNameClass = css`
  font-family: 'Commissioner';
  font-weight: 600;
  font-size: 12px;
  line-height: 140%;
  text-align: center;
  color: #988c92;
`;

const skinNameClass = css`
  font-family: 'Commissioner';
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  color: #dfdfdf;
`;

const skinImageBoxClass = css`
  position: relative;
`;

const backgroundSkinClass = css`
  position: absolute;
  width: 100%;
`;

const skinImageClass = css`
  width: 30%;
  height: 30%;
`;

const actionsClass = css`
  display: flex;
  justify-content: center;
`;

const promoCodeClass = css`
  font-family: 'Onest';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 100%;
  text-align: center;
  background: linear-gradient(180deg, #ffaa63 0%, #ffaa63 0.01%, #ea9a4e 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;

const countdownBoxClass = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Commissioner';
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #ffffff;
`;

const chickIconClass = css`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
`;

const timerBoxClass = css`
  display: flex;
  flex-direction: column;
  gap: 18px;
`;

type CountDownProps = {
  finish: number;
};

type TimeObject = {
  h: string;
  m: string;
  s: string;
};

const Countdown = ({ finish }: CountDownProps): JsxElement => {
  const initial: TimeObject = { h: '00', m: '00', s: '00' };
  const [timerObject, setTimerObject] = useState(initial);
  const UPDATE_TIME = 1000;

  useEffect(() => {
    if (!finish) {
      setTimerObject(initial);
      return;
    }

    const finishServerTime = new ServerTime(finish);

    let timer;
    const update = () => {
      const duration = finishServerTime.ms - Date.now();

      if (duration <= 0) {
        setTimerObject(initial);
        clearInterval(timer);
      } else {
        setTimerObject(getFormattedTimeObject(duration));
      }
    };

    update();
    timer = setInterval(update, UPDATE_TIME);

    return () => clearTimeout(timer);
  }, [finish]);

  return (
    <div className={mainCountdownClass}>
      <div className={timerItemClass}>{timerObject.h}</div>
      <div className={timerDividerClass}>:</div>
      <div className={timerItemClass}>{timerObject.m}</div>
      <div className={timerDividerClass}>:</div>
      <div className={lastTimerItemClass}>{timerObject.s}</div>
    </div>
  );
};

const getFormattedTimeObject = (ms: number): TimeObject => {
  const duration = Duration.fromMillis(ms)
    .shiftTo('hours', 'minutes', 'seconds', 'milliseconds')
    .toObject();

  const addPad = (val?: number) => String(val).padStart(2, '0'); // 00, 01, 02...

  return {
    h: addPad(duration.hours),
    m: addPad(duration.minutes),
    s: addPad(duration.seconds),
  };
};

const mainCountdownClass = css`
  display: flex;
  gap: 2.5px;
  align-items: center;
`;

const timerItemClass = css`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #18171c;
  border: 1.27725px solid #26232d;
  border-radius: 5.109px;
  font-family: 'Commissioner';
  font-weight: 600;
  font-size: 12px;
  line-height: 130%;
  color: #988c92;
  padding: 2px 5px;
  width: 25px;
`;

const lastTimerItemClass = css`
  ${timerItemClass};
  color: #dfdfdf;
`;

const timerDividerClass = css`
  font-family: 'Commissioner';
  font-weight: 600;
  font-size: 12.7725px;
  line-height: 130%;
  color: #dfdfdf;
  opacity: 0.3;
`;

type ProgressBarProps = {
  finish: number;
};

const ProgressBar = ({ finish }: ProgressBarProps): JsxElement => {
  const [value, seValue] = useState(50);
  const startTime = finish - Date.now();

  useEffect(() => {
    let timer;
    const update = () => {
      const currentDuration = finish - Date.now();
      const duration = (50 * currentDuration) / startTime;

      seValue(duration);
      if (duration <= 0) {
        clearInterval(timer);
      }

      return () => clearTimeout(timer);
    };

    update();
    timer = setInterval(update, 1000);
  }, []);

  return (
    <div className={backgroundProgressBarClass}>
      <div className={progressBarClass} style={{ width: `${value}%` }}></div>
    </div>
  );
};

const backgroundProgressBarClass = css`
  width: 100%;
  background: #26232d;
  border-radius: 32px;
  box-shadow: inset 0px 0px 4px 2px rgba(90, 90, 90, 0.25);
  height: 6px;
`;

const progressBarClass = css`
  background: linear-gradient(180deg, #ffaa63 0%, #ea9a4e 100%);
  border-radius: 32px;
  height: 100%;
`;
