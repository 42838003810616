import Cookies from 'js-cookie';
import { IntlGlossary } from 'packs/libs/intl/glossary';
import { INTL_SUPPORTED_LOCALES } from 'packs/libs/intl/supported-locales';
import { createContext, createElement, useContext } from 'react';
import { useForceRefresh } from 'support/react/use-force-refresh';
import { SimpleEventTarget } from 'support/struct/simple-event-target';
import { VolatileMap } from 'support/struct/volatile-map';
import detectLanguage, { COOKIE_LANG_KEY } from './detect-language';
import { IntlSub } from './operator';

export const OnLocaleChanged = new SimpleEventTarget<SupportedLocale>();
OnLocaleChanged.add((lc) => {
  Cookies.remove(COOKIE_LANG_KEY, { path: './' });
  Cookies.set(COOKIE_LANG_KEY, lc, { path: '/' });
});

export class IntlLocaleContext extends VolatileMap {
  constructor(public readonly locale: SupportedLocale) {
    super();
  }

  // @ts-ignore
  declare get: (glossary: IntlGlossary) => IntlSub;

  add(glossary: IntlGlossary) {
    this.set(glossary, new IntlSub(this.locale, glossary.dict[this.locale]));
  }

  fork<T>(dict: Record<SupportedLocale, T>): T {
    return dict[this.locale];
  }
}

const CONTEXT_STORE = new Map<SupportedLocale, IntlLocaleContext>(
  INTL_SUPPORTED_LOCALES.map((lc) => [lc, new IntlLocaleContext(lc)])
);

const defaultLang = detectLanguage();

const Context = createContext<IntlLocaleContext>(null!);
if (__DEV__) Context.displayName = 'IntlContext';
export const useIntl = () => useContext(Context);

export let globalValue: IntlLocaleContext = CONTEXT_STORE.get(defaultLang)!;

export const getIntl = () => globalValue;

let doChangeLocale: typeof changeLocale;
export const changeLocale = (locale: SupportedLocale) => doChangeLocale(locale);

export const useLocale = () => useContext(Context).locale;

export function IntlProvider({ children }: any) {
  const commit = useForceRefresh();

  doChangeLocale = (lc: SupportedLocale) => {
    globalValue = CONTEXT_STORE.get(lc)!;
    OnLocaleChanged.emit(lc);
    commit();
  };

  return createElement(Context.Provider, { value: globalValue }, children);
}

export const createGlossary = (dict: LcRecord<any>) => {
  const glossary = new IntlGlossary(dict);
  for (const context of CONTEXT_STORE.values()) context.add(glossary);
  return glossary;
};
