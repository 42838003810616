import { css } from '@emotion/css';
import { ContestBriefData } from 'domain/contest';
import { PlayerRequirementLine } from 'domain/player/requirements';

type ContestCardRequirementListProps = {
  contest: ContestBriefData;
};

export const ContestCardRequirementList = ({
  contest,
}: ContestCardRequirementListProps): JsxElement => {
  return (
    <div className={mainClass}>
      {...contest.requirements.map((item, i) => <PlayerRequirementLine data={item} />)}
    </div>
  );
};

const mainClass = css`
  display: flex;
  width: 100%;
  flex-direction: column;
`;
