import { createElement, ImgHTMLAttributes } from 'react';

type CaseImageSize = 'md';

type CaseImageProps = Omit<ImgHTMLAttributes<HTMLImageElement>, 'src' | 'id'> & {
  id: string;
  size?: CaseImageSize;
};

export const getCaseImageUrl = (id: string, size?: CaseImageSize): string =>
  `${ENV_CLOUD_MEDIA_URL}/case/${id}_${size ?? 'md'}`;

export const CaseImg = ({ id, ...props }: CaseImageProps): JsxElement => {
  return createElement('img', {
    ...props,
    src: getCaseImageUrl(id),
  });
};
