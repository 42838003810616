import { stdBreakpoints } from 'packs/std';
import { useEffect } from 'react';
import { stash_get } from 'support/etc/stash';
import { useForceRefresh } from 'support/react/use-force-refresh';
import { SimpleEventTarget } from 'support/struct/simple-event-target';

export class StdMediaQuery {
  private Sub: SimpleEventTarget;
  private match: MediaQueryList;
  private constructor(query: string) {
    this.Sub = new SimpleEventTarget();
    this.match = matchMedia(query);
    this.match.addEventListener('change', () => this.Sub.emit());
  }

  get = () => this.match.matches;

  @stash_get
  get use() {
    return () => {
      const commit = useForceRefresh();
      useEffect(() => this.Sub.sub(commit), []);
      return this.match.matches;
    };
  }

  @stash_get
  get sub() {
    return this.Sub.sub;
  }

  static create = (query: string) => new StdMediaQuery(query);
}

export const createStdMediaQuery = StdMediaQuery.create;

const simple = (rule: string) => {
  const query = createStdMediaQuery(rule);
  return [query.use, query.get];
};

const simpleLte = (bp: string) => simple(`(max-width: ${stdBreakpoints.values[bp]}px)`);
const simpleGte = (bp: string) => simple(`(min-width: ${stdBreakpoints.values[bp]}px)`);

const [useIsMobile, getIsMobile] = simpleLte('sm');
export { useIsMobile, getIsMobile };
export { useIsTablet, getIsTablet };
export { useLteLg, getLteLg };

const [useIsTablet, getIsTablet] = simpleLte('md');

const [useLteLg, getLteLg] = simpleLte('lg');

const [useIsDesktop, getIsDesktop] = simpleGte('lg');

export {  useIsDesktop , getIsDesktop };

// const [useGteLg, getGteLg] = simpleGte('lg');
// export { useGteLg as useIsLg, getGteLg as getIsLg };

// const [useIsXl, getIsXl] = simple('xl');
// export { useIsXl, getIsXl };
