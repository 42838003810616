import { chronicle } from 'packs/libs/chronicle';
import { showErrorToast } from 'packs/libs/inf';
import { useParams } from 'react-router-dom';
import { useLaunch } from 'support/react/use-launch';

export const ReplenishCallbackPage = (): JsxElement | null => {
  const { type } = useParams<{ type: 'success' | 'fail' }>();
  useLaunch(async () => {
    chronicle.replace('/');

    if (type === 'success') {
      const module = await import('app/replenish/callback/success');
      module.openReplenishCallbackSuccessDialog({ amount: null! });
      // openReplenishSuccessDialog();
    } else if (type === 'fail') {
      const module = await import('app/replenish/callback/failure');
      module.openReplenishCallbackFailureDialog();
      // openReplenishFailureDialog();
    } else {
      showErrorToast('Unknown replenish callback type');
    }
  }, []);

  return null;
};
