import { showErrorToast } from 'packs/libs/inf';

type Data = Rsa & {
  status: number;
};

export const showCoilResponseError = (data: Data) => {
  showErrorToast(<>{getResponseErrorMessage(data)}</>);
};

const getResponseErrorMessage = (data: Data) => {
  if (data.text) return data.text;
  if (data.code) return data.code;
  if (data.status >= 500) return 'Server error';
  else if (data.status >= 400) {
    if (data.status === 408) return 'Request timeout';
    return 'Bad request';
  } else return 'Something went wrong';
};
