import { createElement, ImgHTMLAttributes } from 'react';

type PlayerImageProps = Omit<ImgHTMLAttributes<HTMLImageElement>, 'src'> & {
  size?: 'medium' | 'full';
  code?: string;
};

export const PlayerImage = (props: PlayerImageProps): JsxElement => {
  return createElement('img', {
    ...props,
    src: props.code
      ? props.code.startsWith('h')
        ? props.code
        : `https://avatars.akamai.steamstatic.com/${props.code}_${props.size ?? 'medium'}.jpg`
      : undefined,
  });
};
