import { css } from '@emotion/css';
import { stdBreakpoints } from 'packs/std';
import { ReactNode } from 'react';
import { FinalResultObj } from 'app/case/battle/definitions';

type CaseBattleInsightOpticProps = {
  children: ReactNode;
  finalResult: FinalResultObj;
};
const svgOpticClass = css`
  width: 200px;
  height: 200px;

  ${stdBreakpoints.up(1900)} {
    width: 260px;
    height: 260px;
  }

  ${stdBreakpoints.up(2500)} {
    width: 310px;
    height: 310px;
  }
`;
export const CaseBattleInsightOptic = ({
  children,
  finalResult,
}: CaseBattleInsightOpticProps): JsxElement => {
  return (
    <svg
      className={svgOpticClass}
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="100" cy="100" r="100" fill="#18171C" />
      <path
        d="M187.003 100.333C187.003 111.802 184.744 123.159 180.355 133.754C175.966 144.35 169.533 153.978 161.423 162.087C153.314 170.197 143.686 176.63 133.09 181.019C122.495 185.408 111.138 187.667 99.6693 187.667C88.2005 187.667 76.844 185.408 66.2482 181.019C55.6525 176.63 46.0249 170.197 37.9153 162.087C29.8056 153.978 23.3727 144.35 18.9838 133.754C14.5949 123.159 12.3359 111.802 12.3359 100.333C12.3359 88.8645 14.5949 77.5081 18.9838 66.9123C23.3727 56.3165 29.8056 46.689 37.9153 38.5793C46.0249 30.4697 55.6525 24.0368 66.2483 19.6478C76.844 15.2589 88.2005 13 99.6693 13C111.138 13 122.495 15.2589 133.09 19.6479C143.686 24.0368 153.314 30.4697 161.423 38.5793C169.533 46.689 175.966 56.3166 180.355 66.9123C184.744 77.5081 187.003 88.8646 187.003 100.333L187.003 100.333Z"
        stroke={finalResult.highestColor === null ? '#2A2930' : finalResult.highestColor}
        strokeWidth="4"
        strokeDasharray="25 8"
      />
      <mask
        id="mask0_3497_17350"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="15"
        y="16"
        width="169"
        height="169"
      >
        <circle cx="99.5" cy="100.5" r="84.5" fill="#1E1C22" />
      </mask>
      <foreignObject id="content" x={15} y={16} width={169} height={169} className={contentClass}>
        {children}
      </foreignObject>
      <g mask="url(#mask0_3497_17350)">
        <g filter="url(#filter0_i_3497_17350)">
          <circle cx="99.5" cy="100.5" r="84.5" fill="#1E1C22" />
        </g>
        <g filter="url(#filter1_d_3497_17350)">
          <rect x="-22" y="10" width="244" height="8" fill="#1E1C22" />
        </g>
      </g>
      <line
        opacity="0.07"
        x1="51.8008"
        y1="30.8667"
        x2="51.8008"
        y2="172.946"
        stroke="url(#paint0_linear_3497_17350)"
        strokeWidth="2"
      />
      <line
        opacity="0.07"
        x1="68.043"
        y1="30.8667"
        x2="68.043"
        y2="172.946"
        stroke="url(#paint1_linear_3497_17350)"
        strokeWidth="2"
      />
      <line
        opacity="0.07"
        x1="84.2773"
        y1="30.8667"
        x2="84.2773"
        y2="172.946"
        stroke="url(#paint2_linear_3497_17350)"
        strokeWidth="2"
      />
      <line
        opacity="0.07"
        x1="100.512"
        y1="30.8667"
        x2="100.512"
        y2="172.946"
        stroke="url(#paint3_linear_3497_17350)"
        strokeWidth="2"
      />
      <line
        opacity="0.07"
        x1="116.75"
        y1="30.8667"
        x2="116.75"
        y2="172.946"
        stroke="url(#paint4_linear_3497_17350)"
        strokeWidth="2"
      />
      <line
        opacity="0.07"
        x1="132.984"
        y1="30.8667"
        x2="132.984"
        y2="172.946"
        stroke="url(#paint5_linear_3497_17350)"
        strokeWidth="2"
      />
      <line
        opacity="0.07"
        x1="149.227"
        y1="30.8667"
        x2="149.227"
        y2="172.946"
        stroke="url(#paint6_linear_3497_17350)"
        strokeWidth="2"
      />
      <line
        opacity="0.07"
        x1="34"
        y1="58.3884"
        x2="165.484"
        y2="58.3884"
        stroke="url(#paint7_linear_3497_17350)"
        strokeWidth="2"
      />
      <line
        opacity="0.07"
        x1="34"
        y1="90.8638"
        x2="165.484"
        y2="90.8638"
        stroke="url(#paint8_linear_3497_17350)"
        strokeWidth="2"
      />
      <line
        opacity="0.07"
        x1="34"
        y1="74.6257"
        x2="165.484"
        y2="74.6257"
        stroke="url(#paint9_linear_3497_17350)"
        strokeWidth="2"
      />
      <line
        opacity="0.07"
        x1="34"
        y1="107.102"
        x2="165.484"
        y2="107.102"
        stroke="url(#paint10_linear_3497_17350)"
        strokeWidth="2"
      />
      <line
        opacity="0.07"
        x1="34"
        y1="123.339"
        x2="165.484"
        y2="123.339"
        stroke="url(#paint11_linear_3497_17350)"
        strokeWidth="2"
      />
      <line
        opacity="0.07"
        x1="34"
        y1="155.814"
        x2="165.484"
        y2="155.814"
        stroke="url(#paint12_linear_3497_17350)"
        strokeWidth="2"
      />
      <line
        opacity="0.07"
        x1="34"
        y1="139.576"
        x2="165.484"
        y2="139.576"
        stroke="url(#paint13_linear_3497_17350)"
        strokeWidth="2"
      />
      <foreignObject id="content" x={15} y={16} width={169} height={169} className={contentClass}>
        {children}
      </foreignObject>
      <defs>
        <filter
          id="filter0_i_3497_17350"
          x="15"
          y="16"
          width="181"
          height="181"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="6"
            operator="erode"
            in="SourceAlpha"
            result="effect1_innerShadow_3497_17350"
          />
          <feOffset dx="12" dy="12" />
          <feGaussianBlur stdDeviation="16" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.172066 0 0 0 0 0.174098 0 0 0 0 0.220833 0 0 0 1 0"
          />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_3497_17350" />
        </filter>
        <filter
          id="filter1_d_3497_17350"
          x="-86"
          y="-54"
          width="372"
          height="136"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="32"
            operator="dilate"
            in="SourceAlpha"
            result="effect1_dropShadow_3497_17350"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="16" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.117647 0 0 0 0 0.109804 0 0 0 0 0.133333 0 0 0 1 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3497_17350" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3497_17350"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_3497_17350"
          x1="49.8028"
          y1="172.946"
          x2="49.8028"
          y2="30.8667"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#988C92" stopOpacity="0.07" />
          <stop offset="0.510417" stopColor="#988C92" stopOpacity="0.8" />
          <stop offset="1" stopColor="#988C92" stopOpacity="0.07" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3497_17350"
          x1="66.045"
          y1="172.946"
          x2="66.045"
          y2="30.8667"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#988C92" stopOpacity="0.07" />
          <stop offset="0.510417" stopColor="#988C92" stopOpacity="0.8" />
          <stop offset="1" stopColor="#988C92" stopOpacity="0.07" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_3497_17350"
          x1="82.2794"
          y1="172.946"
          x2="82.2794"
          y2="30.8667"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#988C92" stopOpacity="0.07" />
          <stop offset="0.510417" stopColor="#988C92" stopOpacity="0.8" />
          <stop offset="1" stopColor="#988C92" stopOpacity="0.07" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_3497_17350"
          x1="98.5138"
          y1="172.946"
          x2="98.5138"
          y2="30.8667"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#988C92" stopOpacity="0.07" />
          <stop offset="0.510417" stopColor="#988C92" stopOpacity="0.8" />
          <stop offset="1" stopColor="#988C92" stopOpacity="0.07" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_3497_17350"
          x1="114.752"
          y1="172.946"
          x2="114.752"
          y2="30.8667"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#988C92" stopOpacity="0.07" />
          <stop offset="0.510417" stopColor="#988C92" stopOpacity="0.8" />
          <stop offset="1" stopColor="#988C92" stopOpacity="0.07" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_3497_17350"
          x1="130.986"
          y1="172.946"
          x2="130.986"
          y2="30.8667"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#988C92" stopOpacity="0.07" />
          <stop offset="0.510417" stopColor="#988C92" stopOpacity="0.8" />
          <stop offset="1" stopColor="#988C92" stopOpacity="0.07" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_3497_17350"
          x1="147.229"
          y1="172.946"
          x2="147.229"
          y2="30.8667"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#988C92" stopOpacity="0.07" />
          <stop offset="0.510417" stopColor="#988C92" stopOpacity="0.8" />
          <stop offset="1" stopColor="#988C92" stopOpacity="0.07" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_3497_17350"
          x1="165.484"
          y1="60.3864"
          x2="34"
          y2="60.3864"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#988C92" stopOpacity="0.1" />
          <stop offset="0.510417" stopColor="#988C92" stopOpacity="0.65" />
          <stop offset="1" stopColor="#988C92" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_3497_17350"
          x1="165.484"
          y1="92.8617"
          x2="34"
          y2="92.8617"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#988C92" stopOpacity="0.1" />
          <stop offset="0.510417" stopColor="#988C92" stopOpacity="0.65" />
          <stop offset="1" stopColor="#988C92" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_3497_17350"
          x1="165.484"
          y1="76.6237"
          x2="34"
          y2="76.6237"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#988C92" stopOpacity="0.1" />
          <stop offset="0.510417" stopColor="#988C92" stopOpacity="0.65" />
          <stop offset="1" stopColor="#988C92" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_3497_17350"
          x1="165.484"
          y1="109.1"
          x2="34"
          y2="109.1"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#988C92" stopOpacity="0.1" />
          <stop offset="0.510417" stopColor="#988C92" stopOpacity="0.65" />
          <stop offset="1" stopColor="#988C92" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_3497_17350"
          x1="165.484"
          y1="125.337"
          x2="34"
          y2="125.337"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#988C92" stopOpacity="0.1" />
          <stop offset="0.510417" stopColor="#988C92" stopOpacity="0.65" />
          <stop offset="1" stopColor="#988C92" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_3497_17350"
          x1="165.484"
          y1="157.812"
          x2="34"
          y2="157.812"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#988C92" stopOpacity="0.1" />
          <stop offset="0.510417" stopColor="#988C92" stopOpacity="0.65" />
          <stop offset="1" stopColor="#988C92" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_3497_17350"
          x1="165.484"
          y1="141.574"
          x2="34"
          y2="141.574"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#988C92" stopOpacity="0.1" />
          <stop offset="0.510417" stopColor="#988C92" stopOpacity="0.65" />
          <stop offset="1" stopColor="#988C92" stopOpacity="0.1" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const contentClass = css`
  border-radius: 50%; /* set border-radius to half of the container's width and height */
  overflow: hidden; /* clip the content around the circular border */
`;
