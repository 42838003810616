import { stash_get } from 'support/etc/stash';

type Listener<T> = (value: T) => any;

export class Subscriber<T = void> extends Set<Listener<T>> {
  @stash_get
  get emit() {
    return (value: T): void => {
      for (const listener of this.values()) listener(value);
    };
  }

  @stash_get
  get sub() {
    return (listener: Listener<T>) => {
      this.add(listener);
      return () => {
        this.delete(listener);
      };
    };
  }
}

export class SubSub<K, T> extends Map<K, Subscriber<T>> {
  on(key: K, listener: Listener<T>) {
    let list = this.get(key);
    if (list === undefined) {
      this.set(key, (list = new Subscriber<T>()));
    }
    list.add(listener);
  }

  off(key: K, listener: Listener<T>) {
    this.get(key)?.delete(listener);
  }

  emit(key: K, data: T) {
    this.get(key)?.forEach((listener) => listener(data));
  }

  @stash_get
  get sub() {
    return (key: K, listener: Listener<T>) => {
      this.on(key, listener);
      return () => this.off(key, listener);
    };
  }
}
