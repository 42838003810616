import { InnerRound } from 'app/crush/store/round-state';
import { onceActorAuthenticated } from 'domain/actor';
import { kvDel, kvFind, kvSet } from 'packs/libs/db';
import { CrushOnActorBetAccepted, CrushOnAfterCrash, CrushOnAfterInit } from '../store';

const getModeKey = (mode: string) => `crush_stake:${mode}`;

type ModeState = boolean;

const store: Record<string, ModeState> = {};

CrushOnAfterInit.add(async (op) => {
  const mode = op.mode;
  // check if actor was playing before close
  const wasPlaying = await kvFind(getModeKey(mode));
  if (!wasPlaying) return;

  store[mode] = true;

  onceActorAuthenticated((actor) => {
    const bet = op.getState().bets.find(actor.id);

    if (!bet) return;

    const { prize, stake, x } = bet;

    const upd: Partial<InnerRound> = {
      stake: {
        total: stake.total,
        size: stake.skins.length + (stake.additional ?? 0),
      },
    };

    if (prize) {
      upd.won = {
        x: x!,
        item: { ...prize, cost: prize.total },
      };
    }

    op.updRound(upd);
  });
});

CrushOnActorBetAccepted.add((op) => {
  store[op.mode] = true;
  kvSet(getModeKey(op.mode), true);
});

CrushOnAfterCrash.add((op) => {
  if (store[op.mode]) return;
  kvDel(getModeKey(op.mode));
  store[op.mode] = false;
});
