import { DateTime } from 'luxon';
import { kvRepo } from 'packs/libs/db';

const [find, set, referralRepoDel] = kvRepo<{
  code: string;
  exp: string;
}>('referral');

export { referralRepoDel };

export async function referralRepoFind(): Promise<string | undefined> {
  const data = await find();
  if (!data) return;
  const { code, exp } = data;
  if (DateTime.fromISO(exp) > DateTime.utc()) return code;
  await referralRepoDel();
}

export async function referralRepoSet(code: string) {
  await set({
    code,
    exp: DateTime.utc().plus({ days: 30 }).toISO(),
  });
}
