import { css, cx } from '@emotion/css';
import { useChatState } from 'app/chat/store';
import { getChatOperator } from 'app/chat/store';
import { OpenChatRulesDialog } from 'app/chat/rules-dialog';
import { ReactComponent as CloseIcon } from 'app/chat/assets/close.svg';
import { ReactComponent as ChatIcon } from 'app/chat/assets/chat.svg';
import { useChatT } from 'app/chat/glossary';

export const ChatHeader = (): JsxElement => {
  const { collapsed } = useChatState();
  const t = useChatT().sub('header');

  return (
    <>
      {collapsed ? (
        <div
          className={mainCollapsedClass}
          onClick={() => getChatOperator().updState({ collapsed: false })}
        >
          <ChatIcon />
        </div>
      ) : (
        <div className={mainClass}>
          <div className={leftClass}>{t('chat')}</div>
          <div className={rightClass}>
            <div className={cx(borderClass, rulesClass)} onClick={OpenChatRulesDialog}>
              {t('rules')}
            </div>
            <div
              className={cx(borderClass, closeClass)}
              onClick={() => getChatOperator().updState({ collapsed: true })}
            >
              <CloseIcon />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mainCollapsedClass = css`
  background: #1e1c22;
  height: 100%;
  width: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  svg {
    height: 18px;
  }
  &:hover {
    background: #26232d;
    path {
      fill: #dfdfdf;
    }
  }
`;

const mainClass = css`
  background: #1e1c22;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  line-height: 1.5;
  padding: 15px;
  width: 279px;
`;

const leftClass = css`
  color: #988c92;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  cursor: default;
  white-space: nowrap;
`;

const borderClass = css`
  background-color: #18171c;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background: #26232d;
  }
`;

const rightClass = css`
  display: flex;
  gap: 8px;
`;

const closeClass = css`
  width: 24px;
  height: 24px;
  color: #988c92;
  cursor: pointer;
  svg {
    width: 11px;
  }
`;

const rulesClass = css`
  font-weight: 400;
  font-size: 12px;
  line-height: 134%;
  padding: 4px 10px;
  white-space: nowrap;
  color: #988c92;
  cursor: pointer;
`;
