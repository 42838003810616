import { css } from '@emotion/css';
import { ReactComponent as RulesIcon } from 'app/chat/assets/rules.svg';
import { useChatT } from 'app/chat/glossary';
import { appSyringe } from 'app/syringe';
import { StdHarmonicDialogBlock } from 'packs/std/dialog/blocks/harmonic';
import { StdTopDialog } from 'packs/std/dialog/top-dialog';

export const OpenChatRulesDialog = () => {
  appSyringe(function ChatRulesDialog({ onClose }) {
    const t = useChatT().sub('rules');
    return (
      <StdTopDialog onClose={onClose}>
        <StdHarmonicDialogBlock title={t('title')} onClose={onClose}>
          {t.obj<string[]>('list').map((children, key) => (
            <div className={listItemClass} key={key}>
              <div className={rulesIconClass}>
                <RulesIcon className={iconClass} />
              </div>
              <div>{children}</div>
            </div>
          ))}
        </StdHarmonicDialogBlock>
      </StdTopDialog>
    );
  });
};

const listItemClass = css`
  display: flex;
  gap: 9px;
  align-items: center;
  padding-bottom: 24px;
  font-family: 'Onest';
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #988c92;

  :last-child {
    padding-bottom: 0px;
  }
`;

const rulesIconClass = css`
  display: flex;
  align-items: center;
`;

const iconClass = css`
  width: 16px;
  height: 16px;
`;
