import { useContestLiveState } from 'domain/contest/live';
import { stdLoadable } from 'packs/std/loadable';
import { useMemo } from 'react';

const Main = stdLoadable(() => import('./button'));

export const LayoutSidebarContestLazy = () => {
  const state = useContestLiveState();
  return useMemo(() => {
    if (!state.alive) return null;
    return <Main />;
  }, [state.alive]);
};
