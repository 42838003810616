import { css, cx } from '@emotion/css';
import { classified } from 'support/react/classified';

const containerClass = css`
  max-width: 1557px;
  width: 100%;
`;
export const StdDesktopContainer = classified.div(containerClass);

type StdDesktopContainerBoxProps = {
  boxClassName?: string;
  className?: string;
  children?: React.ReactNode;
};
export const StdDesktopContainerBox = ({
  boxClassName,
  className,
  children,
}: StdDesktopContainerBoxProps) => {
  return (
    <div className={cx(boxClass, boxClassName)}>
      <div className={cx(containerClass, className)}>{children}</div>
    </div>
  );
};

const boxClass = css`
  width: 100%;
  display: flex;
  justify-content: center;
`;
