import { useMemo } from 'react';
import { getUpgradeOperator, useUpgradeState } from 'app/upgrade';
import { bunchCb } from 'support/etc/bunch-cb';
import { createRefEffect } from 'support/react/ref-effect';
import { UpgradeBiddingGlideGlider } from 'app/upgrade/circle/bidding/glide/glider';

type GlideBlockProps = {};

export const UpgradeBiddingGlideBlock = ({}: GlideBlockProps): JsxElement => {
  const state = useUpgradeState();
  return useMemo(() => {
    const ref = createRefEffect<Element>((el) => {
      const { chance, config } = state;
      const glider = UpgradeBiddingGlideGlider.create(config);
      if (__DEV__) {
        window['upgradeGlider'] = glider;
      }
      const op = getUpgradeOperator();
      glider.init(parseFloat(chance), el);

      const onChanceChanged = (chance: number) => {
        glider.setChance(chance);
      };

      return bunchCb([
        op.listenChanceChanged(onChanceChanged),
        () => {
          glider.clear();
        },
      ]);
    });

    return (
      <foreignObject
        transform="translate(100, 50)"
        ref={ref}
        width={800}
        height={800}
        style={{ position: 'relative' }}
      ></foreignObject>
    );
  }, [state.config]);
};
