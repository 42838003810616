import { css } from '@emotion/css';
import { debounce } from 'lodash';
import { memo, useState } from 'react';

import { ReactComponent as GifIcon } from 'app/chat/assets/gif.svg';
import { ReactComponent as PlaneIcon } from 'app/chat/assets/plane.svg';
import { ReactComponent as SmilesIcon } from 'app/chat/assets/smiles.svg';
import { ChatMessageType } from 'app/chat/definitions';
import { useChatStore } from 'app/chat/store';
import { launchSignIn } from 'app/sign-in/launch';
import { getActor } from 'domain/actor';
import { coilReq } from 'packs/libs/coil';
import { showErrorToast } from 'packs/libs/inf';
import { StdRippleDiv } from 'packs/std';
import { useChatT } from './glossary';

const MAX_LENGTH = 100;

type Obj<T = any> = {
  [x: string]: T;
};

type ChatSendProps = {
  opts?: Obj;
};

const deb = debounce((f) => f(), 1e3, { leading: true });

export const ChatSend = memo(function ChatSend({ opts }: ChatSendProps) {
  const t = useChatT().sub('send');

  const [{ slowMode }, { inputRef }] = useChatStore();

  const [cooldown, setCooldown] = useState<number | null>(null);
  const isCooldown = cooldown !== null;

  const submit = () => {
    if (getActor().anon) return launchSignIn();
    const el = inputRef.current;
    if (!el) return;

    deb(async () => {
      let value = el.value;

      if (isCooldown) {
        return showErrorToast(t('timeout', { x: cooldown }));
      }

      value = value.trim();

      if (!value) {
        return showErrorToast(t('cannot-be-empty'));
      }

      if (value.length >= MAX_LENGTH) {
        showErrorToast(t('max-len', { max: MAX_LENGTH }));
        return;
      }

      if (value === `> ${el.getAttribute('replying-to')}`) {
        return showErrorToast(t('cannot-be-empty'));
      }

      const options = { ...opts };

      if (!!el.getAttribute('replying-to')) {
        options.replyingTo = el.getAttribute('replying-to');
      }

      await coilReq({
        action: 'chat.send',
        data: [ChatMessageType.text, value, options],
      });

      inputRef.current!.value = '';
      inputRef.current!.removeAttribute('replying-to');
      inputRef.current!.focus();

      // setting cooldown
      let seconds = slowMode ? 15 : 5;

      function cooldownTick() {
        if (seconds <= 0) {
          setCooldown(null);
        } else {
          setCooldown(seconds);
          setTimeout(cooldownTick, 1e3);
        }
        seconds -= 1;
      }

      cooldownTick();
    });
  };

  return (
    <div className={mainClass}>
      <div className={boxClass}>
        <textarea
          className={areaClass}
          ref={inputRef}
          placeholder={t('placeholder')}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              submit();
            }
          }}
        />
        {isCooldown ? (
          <div className={timerClass}>
            <div>{cooldown}</div>
          </div>
        ) : (
          <StdRippleDiv className={planeBoxClass} onClick={submit}>
            <PlaneIcon />
          </StdRippleDiv>
        )}
      </div>
      <div className={footerClass}>
        <div className={maxLengthClass}>{t('description')}</div>
        <div className={smilesBox}>
          <div className={backgroundSmilesClass}>
            <SmilesIcon className={slimesIconClass} />
          </div>
          <div className={backgroundSmilesClass}>
            <GifIcon className={gifsIconClass} />
          </div>
        </div>
      </div>
    </div>
  );
});

const mainClass = css`
  background: #1e1c22;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  position: relative;
  cursor: default;
`;

const footerClass = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 4px;
`;

const smilesBox = css`
  width: 50px;
  height: 26px;
  background: #18171c;
  border-radius: 6px;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const backgroundSmilesClass = css`
  width: 22px;
  height: 22px;
  background: #1e1c22;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const slimesIconClass = css`
  width: 10px;
`;

const gifsIconClass = css`
  width: 14px;
`;

const maxLengthClass = css`
  color: #988c92;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.003em;
  cursor: default;
`;

const boxClass = css`
  align-items: center;
  border-radius: 8px;
  display: flex;
  height: 36px;
  position: relative;
  background: #18171c;
  width: 100%;
`;

const areaClass = css`
  background: transparent;
  border: none;
  height: 100%;
  outline: none;
  overflow: auto;
  padding: 9px 12px;
  resize: none;
  width: 100%;

  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  color: #988c92;

  &::placeholder {
    align-items: center;
    display: flex !important;
    height: 100%;
    margin-left: 15px;
    width: 100%;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 140%;
    color: #988c92;
  }
`;

const planeBoxClass = css`
  width: 24px;
  height: 24px;
  margin-right: 12px;
  border-radius: 50%;
  display: inline-flex;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  cursor: pointer;
  justify-content: center;

  &:hover {
    background-color: rgba(147, 147, 255, 0.1);
  }
`;

const timerClass = css`
  align-items: center;
  display: flex;
  height: 36px;
  justify-content: center;
  width: 45px;
  font-size: 12px;
  color: #988c92;
  cursor: default;
`;
