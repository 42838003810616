import { coilSend } from 'packs/libs/coil';
import { showErrorToast, showToast } from './inf';

export function format_error(error: any) {
  if (error instanceof Error)
    return {
      ...{
        class: error.constructor.name,
        name: error.name,
        message: error.message,
        stack: error.stack,
        code: 'error',
      },
      ...error,
    };

  return error;
}

export type LogData = Rsa | string;

type Meta = Rsa & {
  stack?: string;
};

// type LogLevel = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;

export enum LogLevel {
  emergency,
  alert,
  error,
  warning,
  info,
  debug1,
  debug2,
  debug3,
}

export const LogLevelCodes = [
  'emergency',
  'alert',
  'error',
  'warning',
  'info',
  'debug1',
  'debug2',
  'debug3',
];

export type Transport = (data: Rsa) => void;

export class Logger {
  private constructor(public readonly meta: Meta, private $stack?: string) {}

  public static create(meta: Rsa) {
    return new Logger(meta);
  }

  updMeta(meta: Meta): Logger {
    Object.assign(this.meta, meta);
    return this;
  }

  doStack(stack: string): Logger {
    this.$stack = this.$stack === undefined ? stack : `${this.$stack}.${stack}`;
    return this;
  }

  using<T>(callback: (logger: Logger) => T): T {
    const next = new Logger({ ...this.meta }, this.$stack);
    return callback(next);
  }

  clone() {
    return new Logger({ ...this.meta }, this.$stack);
  }

  log(lvl: LogLevel, data: LogData): void {
    if (typeof data === 'string') {
      data = { code: data };
    }
    // switch (typeof data) {
    //   case 'string':
    //     data = { code: data };
    //     break;
    //   case 'function':
    //     data = data();
    //     break;
    // }

    // if (Display in data) {
    //   // @ts-ignore
    //   data = data[Display]();
    // }
    if (this.$stack !== undefined) {
      if (data.code === undefined) {
        data.code = this.$stack;
      } else {
        data.code = `${this.$stack}.${data.code}`;
      }
    }

    switch (lvl) {
      case LogLevel.emergency:
      case LogLevel.alert:
      case LogLevel.error:
        console.error(data);
        if (__DEBUG__) {
          showErrorToast(JSON.stringify(data));
        }
        break;
      case LogLevel.warning:
        console.warn(data);
        if (__DEBUG__) {
          showToast('info', JSON.stringify(data));
        }
        break;
      case LogLevel.info:
        console.log(data);
        break;
      default:
        console.debug(data);
    }

    coilSend('l', [lvl, data]);
  }

  emergency(data: LogData): void {
    if (ENV_LOG_EMERGENCY) this.log(0, data);
  }
  alert(data: LogData): void {
    if (ENV_LOG_ALERT) this.log(1, data);
  }
  error(data: LogData): void {
    if (ENV_LOG_ERROR) this.log(2, data);
  }
  warning(data: LogData): void {
    if (ENV_LOG_WARNING) this.log(3, data);
  }
  info(data: LogData): void {
    if (ENV_LOG_INFO) this.log(4, data);
  }
  debug1(data: LogData): void {
    if (ENV_LOG_DEBUG_1) this.log(5, data);
  }
  debug2(data: LogData): void {
    if (ENV_LOG_DEBUG_2) this.log(6, data);
  }
  debug3(data: LogData): void {
    if (ENV_LOG_DEBUG_3) this.log(7, data);
  }

  dispatch(lvl: LogLevel, action: Action) {
    action().catch((e) => this.log(lvl, { code: 'dispatch_failed', error: format_error(e) }));
  }
}

type Action = () => Promise<void>;

export const dispatch = (lvl: LogLevel, action: Action) => {
  action().catch((e) => globalLogger.log(lvl, { code: 'dispatch_failed', error: format_error(e) }));
};

export const globalLogger = Logger.create({});

export const logEmergency = (data: LogData) => globalLogger.emergency(data);
