import { css } from '@emotion/css';
import { CrushSceneChickBlockUnitsAbstract } from 'app/crush/scene/chick/block/units/abstract';
import { GraphChickContext } from 'app/crush/scene/chick/block/context';

export class VideoGraphChickElement extends CrushSceneChickBlockUnitsAbstract {
  public declare node: HTMLVideoElement;

  static create(code: string) {
    const el = document.createElement('video');
    el.disablePictureInPicture = true;
    el.autoplay = true;
    el.preload = 'auto';
    el.muted = true;
    el.playsInline = true;
    el.defaultPlaybackRate = getChickPlaybackSpeed(code);

    const addSource = () => {
      let source = document.createElement('source');

      const extension = isSafari ? 'mov' : 'webm';
      const fileType = isSafari ? `video/mp4` : `video/${extension}`;
      source.src = `/assets/crush/chick/3d/${code}.${extension}`;
      source.type = fileType;

      el.appendChild(source);
    };

    addSource();
    el.classList.add(mainClass);

    const obj = new VideoGraphChickElement();
    obj.node = el;

    GraphChickContext.addChick(obj);

    el.load();
    return obj;
  }

  setBox(container: HTMLElement) {
    this.box = container;
  }

  play(from: number) {
    if (this.box === null) return null;
    this.box!.appendChild(this.node);
    this.node.currentTime = from;
    this.node.play()?.catch(() => {});
  }

  loop() {
    this.node.loop = true;
    return this;
  }
}

const isSafari: boolean =
  navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;

const getChickPlaybackSpeed = (code: string): number => {
  const preDefinedSpeeds = {
    idle: 1.5,
    planting: 1.9,
    death1: 0.85,
  };
  return preDefinedSpeeds[code] ?? 1;
};

const mainClass = css`
  pointer-events: none;
  width: 100%;
  height: 100%;
  position: absolute;
`;
