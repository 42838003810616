import { css } from '@emotion/css';
import { CrushBetCard } from 'app/crush/bets/card/card';
import { voidMemo } from 'support/react/void-memo';
import { ReactComponent as EmptyBetsListIcon } from 'app/crush/assets/empty-bets-list-icon.svg';
import { CrushStoreBetsData } from 'app/crush/store';
import { useCrushT } from 'app/crush/glossary';
import AnimateList from "app/crush/bets/animation/list";
import { createRef } from "react";

type CrushBetsListProps = { bets: CrushStoreBetsData };

export const CrushBetsList = ({ bets }: CrushBetsListProps): JsxElement => {
  return (
    <div className={mainClass}>
      {bets.size > 0 ? (
        <div className={listClass}>
          <AnimateList>
            {bets.listMapV((bet) => (
              <CrushBetCard key={bet.player.id} bet={bet} ref={createRef<HTMLDivElement>()} />
            ))}
          </AnimateList>
        </div>
      ) : (
        <EmptyList />
      )}
    </div>
  );
};

const mainClass = css`
  background: #1e1c22;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  overflow: auto;
  width: 328px;
`;
const listClass = css`
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const EmptyList = voidMemo(function EmptyList() {
  const t = useCrushT().sub('bets');
  return (
    <div className={emptyListClass}>
      <EmptyBetsListIcon />
      <p className={textClass}>{t('empty')}</p>
    </div>
  );
});

const emptyListClass = css`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 21px;

  svg {
    height: 54px;
  }
`;
const textClass = css`
  font-weight: 600;
  font-size: 12px;
  line-height: 130%;
  color: #988c92;
`;
