// import 'packs/atom/atom.main.scss';
import './global.scss';

import 'packs/std';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import 'app/layout/notify';
import 'domain/tracking/tracking';
import 'packs/etc/out-of-sync';

import { createRoot } from 'react-dom/client';

import { App } from 'app/app';
import { Bsod } from 'app/layout/bsod';
import { StyledToastContainer } from 'app/layout/toasts';
import { startup } from 'app/startup';
import { AppSyringeInjector } from 'app/syringe';
import { ActorProvider } from 'domain/actor';
import { IntlProvider } from 'packs/libs/intl';

import { ChatStoreProvider } from 'app/chat/store';
import { CookiesPolicyToast } from 'app/cookies-toast/cookies-toast';
import { CrushStoreProvider } from 'app/crush/store/store';
import InventoryStoreProvider from 'app/inventory/store';
import { QuestsStoreProvider } from 'app/quests/store';
import { UpgradeInventoryListener, UpgradeStoreProvider } from 'app/upgrade';
import { ContestLiveStoreProvider } from 'domain/contest/live';
import { PlayerBalanceProvider } from 'domain/player/balance';
import { PromoStoreProvider } from 'domain/promo';
import { ChronicleRouter } from 'packs/libs/chronicle';

import { ServiceWorkerStoreProvider } from 'packs/sw/store';

startup();

const root = createRoot(document.getElementById('root')!);

/**
 * These providers and injectors mounts only once for page
 * and can not be unmounted until page refresh
 * they must be single per page,
 * so they can have global operators, that never change
 */
root.render(
  <ServiceWorkerStoreProvider>
    <Bsod>
      <IntlProvider>
        <ChronicleRouter>
          <ActorProvider>
            <ContestLiveStoreProvider>
              <PlayerBalanceProvider>
                <PromoStoreProvider>
                  <InventoryStoreProvider>
                    <CrushStoreProvider>
                      <UpgradeStoreProvider>
                        <UpgradeInventoryListener />
                        <ChatStoreProvider>
                          <QuestsStoreProvider>
                            <CookiesPolicyToast />
                            <StyledToastContainer />
                            <App />
                            <AppSyringeInjector />
                          </QuestsStoreProvider>
                        </ChatStoreProvider>
                      </UpgradeStoreProvider>
                    </CrushStoreProvider>
                  </InventoryStoreProvider>
                </PromoStoreProvider>
              </PlayerBalanceProvider>
            </ContestLiveStoreProvider>
          </ActorProvider>
        </ChronicleRouter>
      </IntlProvider>
    </Bsod>
  </ServiceWorkerStoreProvider>
);
