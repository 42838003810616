import { css } from '@emotion/css';
import { CaseBattleInsightHeader } from 'app/case/battle/insight/header/header';
import { CaseBattleInsightDialogSocket } from 'app/case/battle/insight/results/dialog';
import { CaseBattleInsightSoundGroup } from 'app/case/battle/insight/sounds';
import { CaseBattleInsightStandList } from 'app/case/battle/insight/stand/list';
import { CaseBattleInsightStoreProvider } from 'app/case/battle/insight/store';
import { CaseBattleInsightActionBlock } from 'app/case/battle/insight/units/action-block';
import { LayoutFooter } from 'app/layout/footer/footer';
import { useEnableSoundGroup } from 'packs/libs/sound';
import { stdBp } from 'packs/std';
import { useParams } from 'react-router-dom';
import { linearClamp } from 'support/polished/linear-clamp';

export const CaseBattleInsightPage = (): JsxElement => {
  useEnableSoundGroup(CaseBattleInsightSoundGroup);
  const { id } = useParams<{ id: string }>();

  return (
    <div className={mainClass}>
      <CaseBattleInsightStoreProvider id={id!}>
        <CaseBattleInsightDialogSocket />
        <div className={containerClass}>
          <div className={contentClass}>
            <CaseBattleInsightHeader />
            <CaseBattleInsightActionBlock />
            <CaseBattleInsightStandList />
          </div>
          <LayoutFooter />
        </div>
      </CaseBattleInsightStoreProvider>
    </div>
  );
};

const mainClass = css`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
`;
const containerClass = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 22px;
  flex: 1;
  max-width: 1926px;
  padding: 2px 2px 0 2px;
  ${stdBp.up(1920)} {
    padding-top: ${linearClamp(1920, 2, 2560, 64)};
  }
`;
const contentClass = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2px;
  flex: 1;
`;
