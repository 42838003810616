import { DateTime } from 'luxon';
import { CoilWire } from 'packs/libs/coil/coil';
import sleep from 'support/etc/sleep';
import { SubCell } from 'support/react/sub-cell';

let globalDelta = 0;
const PingCell = new SubCell<number | null>(null);

CoilWire.onPing = (ping, diff) => {
  globalDelta = diff;
  PingCell.set(ping);
};

export const getOutOfSyncDelta = () => globalDelta;

export function serverNow(): number {
  return Date.now() - globalDelta;
}

export async function serverSleepUntil(ms: number): Promise<void> {
  while (true) {
    const diff = ms - serverNow();
    if (diff > 500) await sleep(500);
    else if (diff > 0) await sleep(diff);
    else return;
  }
}

export class ServerTime {
  private val: number;

  constructor(val: number) {
    this.val = val;
  }

  static fromIso(iso: string) {
    return new ServerTime(DateTime.fromISO(iso).toMillis());
  }

  get ms(): number {
    return this.val - globalDelta;
  }

  static now(): number {
    return Date.now() - globalDelta;
  }
}

export const usePing = PingCell.useValue;
