import { css, cx } from '@emotion/css';
import { CSSProperties, useState } from 'react';

import { LiveBidData } from 'app/upgrade/store';
import { PlayerImage } from 'domain/player/image/image';
import { skinNameView } from 'domain/skin/name-view';
import { SkinImg } from 'domain/skin/units/image';
import { voidMemo } from 'support/react/void-memo';
import { PlayerLinkedImage } from 'domain/player/image/linked-image';
import { RippleLink } from 'packs/std/effects/ripple-link';

type BidProps = { bid: LiveBidData };

export const UpgradeLiveBidsBid = voidMemo(function UpgradePageLiveBidsBid({ bid }: BidProps) {
  const [hover, setHover] = useState<boolean>(false);
  const name = skinNameView(bid.skin.id);

  return (
    <div
      className={mainClass}
      onMouseLeave={() => setTimeout(() => setHover(false), 50)}
      onMouseEnter={() => setTimeout(() => setHover(true), 50)}
      style={
        {
          '--skin-color': `${bid.skin.color[0]}, ${bid.skin.color[1]}, ${bid.skin.color[2]}`,
        } as CSSProperties
      }
    >
      <div className={boxClass}>
        {hover ? (
          <RippleLink to={bid.player.id ? `/player/${bid.player.id}` : '#'}>
            <div className={hoverWrapperClass}>
              <div className={dataWrapperClass}>
                <p className={dataTextClass}>${bid.stake.toFixed(1)}</p>
                <PercentBadge percent={bid.chance} big />
                <p className={prizeClass}>${bid.prize.toFixed(1)}</p>
              </div>
              <div className={userBoxClass}>
                <PlayerImage code={bid.player.image} className={userPictureClass} />
                <p className={userNameClass}>{bid.player.name}</p>
              </div>
            </div>
          </RippleLink>
        ) : (
          <div className={wrapperClass}>
            <SkinImg code={bid.skin.image} alt={'img'} className={pictureClass} />
            <div>
              <PercentBadge percent={bid.chance} />
              <div className={skinTitleClass}>
                {name.brand}
                <span className={skinSubtitleClass}>{name.model}</span>
              </div>
              <p className={levelTitleClass}>{name.level?.full}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
});

const badgeBoxClass = css`
  padding: 0 2px 1px;
  width: fit-content;
  border-radius: 2px;
  font-weight: 600;
  font-size: 9px;
  line-height: 130%;
`;
const blueBadge = css`
  ${badgeBoxClass};
  color: rgba(94, 118, 221, 1);
  background: rgba(94, 118, 221, 0.2);
`;
const whiteBadge = css`
  ${badgeBoxClass};
  color: rgba(223, 223, 223, 1);
  background: rgba(223, 223, 223, 0.2);
`;
const redBadge = css`
  ${badgeBoxClass};
  color: rgba(255, 90, 90, 1);
  background: rgba(255, 90, 90, 0.2);
`;
const goldBadgeBackgroundClass = css`
  ${badgeBoxClass};
  background: linear-gradient(180deg, rgba(255, 170, 99, 0.2) 0%, rgba(234, 154, 78, 0.2) 100%);
`;

const colors = new Map<number, string>([
  [50, blueBadge],
  [30, whiteBadge],
  [10, redBadge],
  [0, goldBadgeBackgroundClass],
]);

const getColor = (x: number): string => {
  for (const [threshold, color] of colors) {
    if (x >= threshold) return color;
  }
  throw Error('invalid_x');
};

type PercentBadgeProps = { percent: number; big?: boolean };

const PercentBadge = ({ percent, big }: PercentBadgeProps): JsxElement => {
  const badgeClass = getColor(percent);

  const textClass = percent >= 10 ? smallText : goldBadgeTextClass;
  return (
    <div className={badgeClass}>
      <div className={cx(textClass, big && bigBadgeTextClass)}>{percent.toFixed(0)}%</div>
    </div>
  );
};
const mainClass = css`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(
    135deg,
    rgba(0, 0, 0, 0) 45.02%,
    rgba(var(--skin-color), 1) 100%
  );
  width: 144px;
  height: 57px;
  border-radius: 8px;

  &:hover {
    border: 1px solid rgb(var(--skin-color));
  }
`;
const boxClass = css`
  height: calc(100% - 1px);
  width: calc(100% - 1px);
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(180deg, #1f1d23 0%, rgba(30, 28, 34, 0.6) 100%);
  border-radius: 8px;

  &:hover {
    height: 100%;
    width: 100%;
  }
`;
const wrapperClass = css`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 11px;
  cursor: pointer;
`;
const bigBadgeTextClass = css`
  font-size: 12px;
`;

const dataTextClass = css`
  font-family: 'Onest';
  font-weight: 700;
  font-size: 11px;
  line-height: 130%;
  letter-spacing: -0.003em;
  color: #988c92;
  padding-top: 3px;
`;
const prizeClass = css`
  ${dataTextClass};
  color: #dfdfdf;
`;
const dataWrapperClass = css`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
`;
const hoverWrapperClass = css`
  display: flex;
  flex-direction: column;
  gap: 8px;
  cursor: pointer;
  padding-left: 15px;
`;
const userPictureClass = css`
  width: 12px;
  border-radius: 4px;
`;
const userNameClass = css`
  width: 100px;
  font-weight: 400;
  font-size: 11px;
  line-height: 130%;
  letter-spacing: -0.003em;
  color: #dfdfdf;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const userBoxClass = css`
  display: flex;
  gap: 4px;
  align-items: center;
`;

const smallText = css`
  font-weight: 600;
  font-size: 9px;
  line-height: 130%;
`;
const goldBadgeTextClass = css`
  ${smallText};
  background: linear-gradient(180deg, #ffaa63 0%, #ea9a4e 100%);
  background-clip: text;
  text-fill-color: transparent;
`;
const levelTitleClass = css`
  font-weight: 400;
  font-size: 10px;
  line-height: 130%;
  letter-spacing: -0.003em;
  color: #988c92;
  max-width: 68px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const skinSubtitleClass = css`
  font-weight: 600;
  line-height: 130%;
  color: #dfdfdf;
`;
const skinTitleClass = css`
  font-weight: 400;
  font-size: 10px;
  line-height: 130%;
  display: flex;
  gap: 2px;
  align-items: center;
  color: rgb(var(--skin-color));
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 68px;
`;

const pictureClass = css`
  width: 52px;
`;
