import { ComponentType, ReactNode, useRef } from 'react';
import { css, cx } from '@emotion/css';
import TouchRipple from '@mui/material/ButtonBase/TouchRipple';

type Attrs = {
  className?: string;
  children?: ReactNode;
  onMouseDown?(e);
  onMouseUp?(e);
  onTouchStart?(e);
  onTouchEnd?(e);
  onMouseLeave?(e);
  onDragLeave?(e);
};

export function foldRipple<P extends Attrs>(Component: ComponentType<P>): ComponentType<P> {
  // export function foldRipple<T extends HTMLAttributes<HTMLElement>>(type) {
  return function RippleFold(props: P) {
    const rippleRef = useRef<any>(null);

    return (
      <Component
        {...props}
        className={cx(mainClass, props.className)}
        onMouseDown={(e) => {
          rippleRef.current!.start(e);
          props.onMouseDown?.(e);
        }}
        onMouseUp={(e) => {
          rippleRef.current!.stop(e);
          props.onMouseUp?.(e);
        }}
        onTouchStart={(e) => {
          rippleRef.current!.start(e);
          props.onTouchStart?.(e);
        }}
        onTouchEnd={(e) => {
          rippleRef.current!.stop(e);
          props.onTouchEnd?.(e);
        }}
        onMouseLeave={(e) => {
          rippleRef.current!.stop(e);
          props.onMouseLeave?.(e);
        }}
        onDragLeave={(e) => {
          rippleRef.current!.stop(e);
          props.onDragLeave?.(e);
        }}
      >
        {props.children}
        <TouchRipple className={rippleClass} ref={rippleRef} center={false} />
      </Component>
    );
  };
}

const mainClass = css`
  position: relative;
`;

const rippleClass = css`
  color: var(--dark-blue-100);
`;
