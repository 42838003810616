import { css } from '@emotion/css';
import { skinColorMemoize } from 'domain/skin/utils/color-memoize';

export const returnGradientFrame = skinColorMemoize((color: number[]) => {
  const mkRP = (rgb: number[], percent: string | number) =>
    `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, 1) ${percent}%`;

  const bgLine = ['rgba(0, 0, 0, 0) 5%', mkRP(color, 90), mkRP(color, 100)];

  const toGradient = (line) => `linear-gradient(155deg, ${line.join(', ')});`;

  const mainClass = css`
    padding: 0 1px 1px 0;
    background-image: ${toGradient(bgLine)};
    width: 136px;
    height: 140px;
  `;

  const boxClass = css`
    width: 135px;
    height: 139px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(
      180deg,
      #1f1d23 0%,
      rgba(30, 28, 34, 0.6) 50%,
      rgba(30, 28, 34, 0.6) 100%
    );
  `;

  return function GradientFrame({ children }: Rcp) {
    return (
      <div className={mainClass}>
        <div className={boxClass}>{children}</div>
      </div>
    );
  };
});
