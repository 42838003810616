import { getIntl, useIntl } from 'packs/libs/intl/intl';
import { IntlSub } from 'packs/libs/intl/operator';

export class IntlGlossary {
  constructor(public readonly dict: LcRecord<any>) {}

  useT = () => useIntl().get(this);
  useSubT = (prefix: string) => useIntl().get(this).sub(prefix);

  ref(code: string, data?: Rsa) {
    return (op: IntlSub) => op(code, data);
  }

  getT(): IntlSub {
    return getIntl().get(this);
  }

  t(code: string, data?: Rsa) {
    return this.getT()(code, data);
  }
}
