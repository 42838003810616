import { ActorOperator } from 'domain/actor';
import { PlayerRole } from 'domain/player/definitions';

export const ChatRoleText = {
  [PlayerRole.admin]: 'ADMIN',
  [PlayerRole.blogger]: 'BLOGGER',
  [PlayerRole.vip]: 'VIP',
  [PlayerRole.moderator]: 'MODER',
  [PlayerRole.support]: 'SUPPORT',
};

export enum ChatMessageType {
  text = 't',
  shared = 's',
  system = 'sys',
}

export const CHAT_MAX_MESSAGES = 30;

export const CHAT_COLLAPSE_BREAKPOINT = 1560;

export type ChatActor = PlayerRole | string | undefined;

export type ChatMessageData = {
  id: string;
  sender: {
    id: string;
    name: string;
    role: PlayerRole;
    image: string;
    level: number;
  };
  content: any;
  type: ChatMessageType;
  at: string; // iso
};

export type ChatPinnedMessageData = ChatMessageData & {
  until: string;
};

const CHAT_MOD_ROLES = new Set([PlayerRole.admin, PlayerRole.moderator, PlayerRole.support]);

export function checkCanActorModerateChat(actor: ActorOperator): boolean {
  return actor.signed && CHAT_MOD_ROLES.has(actor.role);
}

export type ChatMessageContentProps = {
  message: ChatMessageData;
};
