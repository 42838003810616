import { utmRepoFind } from 'domain/etc/utm';
import { referralRepoFind } from 'domain/referral/repo';
import { getGoogleAnalyticsClientId } from 'domain/tracking/google-analytics'; 
import { launch } from 'support/react/use-launch';

export const launchSignIn = () => {
  launch(async () => {
    const payload: Rsa = {
      pwa: location.href,
      utm: await utmRepoFind(),
      ref: await referralRepoFind(),
      cid: await getGoogleAnalyticsClientId(),
    };

    window.location.href = `${ENV_API_URL}/player.auth?q=${encodeURIComponent(
      JSON.stringify({ ...payload })
    )}`;
  });
};
