import { css } from '@emotion/css';
import { useContestT } from 'app/contest/glossary';
import { ReactComponent as CalculationIcon } from 'app/contest/assets/calculation-icon.svg';

export const ContestCardStatusCalculation = (): JsxElement => {
  const t = useContestT().sub('card');

  return (
    <div className={mainClass}>
      <div className={titleClass}>{t('calculation')}</div>
      <CalculationIcon />
    </div>
  );
};

const mainClass = css`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
  width: 100%;
`;

const titleClass = css`
  font-family: 'Commissioner';
  font-weight: 500;
  font-size: 13px;
  line-height: 140%;
  color: #dfdfdf;
`;
