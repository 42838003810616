import { css } from '@emotion/css';

import { CaseBattleMonitorHeader } from 'app/case/battle/monitor/header';
import { CaseBattleMonitorList } from 'app/case/battle/monitor/list';
import { CaseBattleMonitorSoundGroup } from 'app/case/battle/monitor/sounds';
import { CaseBattleMonitorStoreProvider } from 'app/case/battle/monitor/store';
import { LayoutFooter } from 'app/layout/footer/footer';
import { useEnableSoundGroup } from 'packs/libs/sound';
import { stdBreakpoints } from 'packs/std';
import { linearClamp } from 'support/polished/linear-clamp';

export const CaseBattleMonitorPage = (): JsxElement => {
  useEnableSoundGroup(CaseBattleMonitorSoundGroup);

  return (
    <CaseBattleMonitorStoreProvider>
      <div className={containerClass}>
        <CaseBattleMonitorHeader />
        <div className={mainClass}>
          <div className={boxClass}>
            <CaseBattleMonitorList />
          </div>
          <LayoutFooter />
        </div>
      </div>
    </CaseBattleMonitorStoreProvider>
  );
};

const boxClass = css`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 22px;
`;
const mainClass = css`
  max-width: 1548px;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 22px;
  justify-content: space-between;
`;
const containerClass = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 22px 22px 0 22px;
  gap: 22px;
  width: 100%;
  height: 100%;

  ${stdBreakpoints.up(1920)} {
    padding-top: ${linearClamp(1920, 22, 2560, 64)};
  }
`;
