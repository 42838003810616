import { css } from '@emotion/css';
import { ReactNode } from 'react';

type ContestCardAttributeProps = {
  title: ReactNode;
  children: ReactNode;
};

export const ContestCardAttribute = ({
  title,
  children,
}: ContestCardAttributeProps): JsxElement => {
  return (
    <div className={mainClass}>
      <div className={titleClass}>{title}</div>
      <div className={valueClass}>{children}</div>
    </div>
  );
};

const mainClass = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const titleClass = css`
  font-family: 'Commissioner';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 140%;
  color: #dfdfdf;
  padding-left: 3px;
  white-space: nowrap;
`;

const valueClass = css`
  display: flex;
  gap: 2.5px;
  align-items: center;
`;
