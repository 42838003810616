import { crushCompareBets } from 'app/crush/store/utils/compare-bets';
import { fastInsert } from 'app/crush/store/utils/fast-insert';
import { fastInsertMany } from 'app/crush/store/utils/fast-insert-many';
import { getInventoryOperator } from 'app/inventory';
import { getContestLiveState } from 'domain/contest/live';
import { CrushBetStatus, CrushMode, CrushModeCode, CrushRoundStage } from 'domain/crush';
import makeGrow, { GrowNum } from 'domain/crush/growth';
import { coilListenR, serverSleepUntil } from 'packs/libs/coil';
import { infError } from 'packs/libs/inf';
import { UReader } from 'packs/libs/uint-8';
import { getIsMobile } from 'packs/std';
import { createContext, useContext, useEffect } from 'react';
import { round2DP } from 'support/etc/round-decimal-places';
import { useForceRefresh } from 'support/react/use-force-refresh';
import { useSingleton } from 'support/react/use-singleton';
import { HashMap } from 'support/struct/hash-map';
import { IHashMap } from 'support/struct/i-hash-map';
import { SimpleEventTarget } from 'support/struct/simple-event-target';
import {
  CrushBetData,
  CrushStoreBetsData,
  CrushStoreRound,
  CrushStoreScope,
  CrushStoreState,
} from './definitions';
import { InnerRound, crushStoreRoundMakeProtoFactory } from './round-state';

type CrushOperator = {
  getMode(): CrushMode;
  setMode(mode: CrushMode);
  scope(mode: CrushMode): ScopeOperator;
};

type ScopeOperator = {
  readonly mode: CrushMode;
  getState(): CrushStoreScope;
  updRound(fn: Partial<CrushStoreRound>): void;
  isCurrent(): boolean;
  // mutState(fn: ImeSpec<CrushStoreScope>): void
  getX(): GrowNum | undefined;
};

const CrushContext = createContext<CrushStoreState>(null!);
if (__DEV__) CrushContext.displayName = 'CrushStateContext';
const ScopeContext = createContext<CrushStoreScope>(null!);
if (__DEV__) ScopeContext.displayName = 'CrushScopeContext';
const RoundContext = createContext<CrushStoreRound>(null!);
if (__DEV__) RoundContext.displayName = 'CrushRoundContext';
const BetsContext = createContext<CrushStoreBetsData>(null!);
if (__DEV__) BetsContext.displayName = 'CrushBetsContext';
// const ProsContext = makeNullContext<CrushStorePros>('CrushPros');
const ModeContext = createContext<CrushMode>(null!);
if (__DEV__) ModeContext.displayName = 'CrushModeContext';

export const useCrushState = () => useContext(CrushContext);
export const useCrushMode = () => useContext(ModeContext);
export const useCrushScopeState = () => useContext(ScopeContext);
export const useCrushScopeRound = () => useContext(RoundContext);
export const useCrushScopeBets = () => useContext(BetsContext);
// export const useCrushScopePros = () => useContext(ProsContext);

let globalOperator: CrushOperator;
let scopeOperators = new Map<CrushMode, ScopeOperator>();

export const getCrushOperator = () => globalOperator;
export const getCrushScopeOperator = () => scopeOperators.get(globalOperator.getMode());

export const getCrushMode = () => getCrushOperator().getMode();

// Crush Events
export const CrushOnAfterInit = new SimpleEventTarget<ScopeOperator>();
export const CrushOnAfterNewRound = new SimpleEventTarget<ScopeOperator>();
export const CrushOnActorBetAccepted = new SimpleEventTarget<ScopeOperator>();
export const CrushOnActorWon = new SimpleEventTarget<ScopeOperator>();
export const CrushOnAfterCrash = new SimpleEventTarget<ScopeOperator>();

type Round = InnerRound;

export enum ModeEvents {
  init,
  addBet,
  grabBet,
  wonBets,
  crash,
  flash,
  stats,
  prospective,
  graz,
  state,
}

export function CrushStoreProvider(props: Rcp) {
  const commit = useForceRefresh();
  // const refresh = useForceRefresh();
  // const commit = () => {
  //   console.log('crush state', store.getState());
  //   refresh();
  // };

  const store = useSingleton(() => {
    const coilEngines: Map<CrushMode, Map<ModeEvents, (r: UReader) => any>> = new Map();

    type BetsMap = CrushStoreBetsData;
    type Scope = CrushStoreScope;

    let CurrentMode = CrushMode.normal;
    // @ts-ignore
    let Scopes: Record<CrushMode, Scope> = {};

    Object.values(CrushMode).forEach((mode) => {
      const mkRound = crushStoreRoundMakeProtoFactory(mode);

      const setScope = (state: Scope) => {
        scope = state;
        Scopes = { ...Scopes, [mode]: { ...Scopes[mode], ...state } };
      };
      const updScope = (state: Partial<Scope>) => {
        setScope({ ...scope, ...state });
      };
      const setRound = (state: Round) => {
        updScope({
          round: mkRound(state),
        });
      };
      // const setRound = (state: Round) => updScope({
      //   round: crushStoreMkRound(state),
      // });
      const updRound = (state: Partial<Round>) => {
        setRound({ ...scope.round, ...state });
      };
      const applyBets = (fn: (map: BetsMap) => BetsMap) => {
        updScope({ bets: fn(scope.bets) });
      };

      let scope: Scope;
      setScope({
        bets: IHashMap.empty(),
        recent: [],
        round: mkRound({}),
      });

      const scopeOp = {
        mode,
        getState: () => scope,
        updRound,
        getX: () => scope.round.growth!.getX(),
        isCurrent: () => globalOperator.getMode() === mode,
      };

      // const wrapCommit
      scopeOperators.set(mode, scopeOp);

      coilEngines.set(
        mode,
        new Map<ModeEvents, (r: UReader) => any>([
          [
            ModeEvents.state,
            async (r) => {
              const data = r.pack();
              for (const tuple of data.bets) {
                const stake = tuple[1].stake;
                stake.total = round2DP(stake.total);
              }
              if (__DEBUG__) lg.event('crush.state', mode, data);
              updScope({
                bets: IHashMap.fromEntries(data.bets),
                recent: data.recent,
                round: mkRound(data.round),
                pros: undefined,
              });

              CrushOnAfterInit.emit(scopeOp);

              if (scope.round.stage === CrushRoundStage.countdown) {
                commit();
                await serverSleepUntil(scope.round.clashAt);
                updRound({ stage: CrushRoundStage.growth });
                // @ts-ignore
                if (scope.round.stage === CrushRoundStage.crash) return;
              }

              if (scope.round.stage === CrushRoundStage.growth) {
                const growth = makeGrow();
                updRound({
                  stage: CrushRoundStage.growth,
                  growth,
                });
                commit();
                growth.start(scope.round.opts!.grow);
              } else {
                const growth = makeGrow();
                growth.setFinal(scope.round.x!);
                updRound({ growth });
                commit();
              }
            },
          ],
          [
            ModeEvents.init,
            async (r) => {
              const opts = r.pack();
              if (__DEBUG__) lg.event('crush.init', mode, opts);
              const growth = makeGrow();

              updScope({
                pros: undefined,
                bets: IHashMap.empty(),
                round: mkRound({
                  opts,
                  growth,
                  stage: CrushRoundStage.countdown,
                }),
              });

              commit();

              CrushOnAfterNewRound.emit(scopeOp);

              await serverSleepUntil(opts.grow.at);
              if (scope.round.stage !== CrushRoundStage.crash) {
                const growth = makeGrow();
                updRound({ growth, stage: CrushRoundStage.growth });
                commit();
                growth.start(opts.grow);
              }
            },
          ],

          [
            ModeEvents.addBet,
            (r) => {
              const bet: CrushBetData = {
                player: { id: r.id(), name: r.string(), image: r.id() },
                stake: {
                  total: round2DP(r.float()),
                  ...getStakeSkinsFromNewBet(r),
                },
                status: CrushBetStatus.pending,
              };
              if (__DEBUG__) lg.event('crush.addBet', mode, bet);
              applyBets((bets) => {
                return IHashMap.fromEntries(
                  fastInsert((a, b) => b.stake.total - a.stake.total, bet, bets.values()).map(
                    (item) => [item.player.id, item]
                  )
                );
              });
              commit();
            },
          ],

          [
            ModeEvents.grabBet,
            (r) => {
              const id = r.string();
              const x = r.float();
              const prize = r.pack();
              applyBets((bets) =>
                bets.applyOne(id, (bet) => ({
                  ...bet,
                  x,
                  prize,
                  status: CrushBetStatus.won,
                }))
              );
              commit();
            },
          ],

          [
            ModeEvents.wonBets,
            (r) => {
              applyBets((iBets) => {
                const added: CrushBetData[] = [];

                const bets = iBets.copySource();

                while (!r.complete) {
                  const id = r.id();
                  const prev = bets.get(id);
                  if (prev !== undefined) {
                    bets.delete(id);
                    added.push({
                      ...prev,
                      x: round2DP(r.float()),
                      prize: {
                        total: r.float(),
                        image: r.string(),
                        color: coloR(r),
                      },
                      status: CrushBetStatus.won,
                    });
                  }
                }

                const resultSource = new HashMap<string, CrushBetData>();
                const resultList = fastInsertMany(crushCompareBets, added, bets.values());
                for (const bet of resultList) {
                  resultSource.set(bet.player.id, bet);
                }

                return IHashMap.fromHashMap(resultSource);
              });
              commit();
            },
          ],

          [
            ModeEvents.crash,
            (r) => {
              // [id, x, ids]: [string, number, string[]]
              const x = round2DP(r.float());
              if (__DEBUG__) lg.event('crush.crash', mode, x);
              const round = scope.round;
              const growth = round.growth;

              if (growth) {
                growth.setFinal(x);
              }

              applyBets((bets) =>
                bets.apply((map) => {
                  for (const bet of map.values()) {
                    if (bet.status === CrushBetStatus.pending) {
                      map.set(bet.player.id, {
                        ...bet,
                        x,
                        status: CrushBetStatus.crashed,
                      });
                    }
                  }
                })
              );

              const id = scope.round.opts!.id;

              updScope({
                recent: [{ x, id }, ...scope.recent.slice(0, 29)],
              });

              updRound({
                x,
                stage: CrushRoundStage.crash,
                crashAt: Date.now(),
                resolved: true, // FIXME: resolve
              });

              commit();

              CrushOnAfterCrash.emit(scopeOp);
            },
          ],

          [
            ModeEvents.graz,
            (r) => {
              const inContest = r.bool();
              const data = r.pack();
              if (__DEBUG__) lg.event('crush.graz', mode, data);
              updRound({ won: data, resolved: true });
              commit();

              CrushOnActorWon.emit(scopeOp);
              if (getIsMobile()) require('../Graz').openGrazDialog(data);

              if (inContest === getContestLiveState().active)
                getInventoryOperator().addItem(data.item);
            },
          ],

          [
            ModeEvents.prospective,
            (r) => {
              const data: any = [];
              while (!r.complete) {
                data.push({
                  x: r.float(),
                  skin: skinUReadBasic(r),
                });
              }
              if (__DEBUG__) lg.event('crush.prospective', mode, data);
              updScope({ pros: data });
              commit();
            },
          ],

          [
            ModeEvents.flash,
            () => {
              if (!document.hidden) infError('crush.broke');

              updScope({
                round: mkRound({}),
                pros: undefined,
              });
              commit();
            },
          ],
        ])
      );
    });

    // globalOperator = magicGet(prop => operators[prop]);
    globalOperator = {
      scope(mode: CrushMode): ScopeOperator {
        return scopeOperators.get(mode)!;
      },
      getMode(): CrushMode {
        return CurrentMode;
      },
      setMode(mode: CrushMode) {
        CurrentMode = mode;
        commit();
      },
    };

    const CodeMode = new Map([
      [CrushModeCode.normal, CrushMode.normal],
      [CrushModeCode.extreme, CrushMode.extreme],
    ]);

    return {
      getState: () => Scopes,
      getMode: () => CurrentMode,
      effect: () =>
        coilListenR('c', (r) => {
          coilEngines.get(CodeMode.get(r.uint8())!)!.get(r.uint8())!(r);
        }),
    };
  });

  useEffect(store.effect, []);

  const scopes = store.getState();
  const mode = store.getMode();
  const scope = scopes[mode];

  return (
    <CrushContext.Provider value={{ mode, scopes }}>
      <ScopeContext.Provider value={scope}>
        <BetsContext.Provider value={scope.bets}>
          {/*<ProsContext.Provider value={scope.pros}>*/}
          <RoundContext.Provider value={scope.round}>
            <ModeContext.Provider value={mode}>{props.children}</ModeContext.Provider>
          </RoundContext.Provider>
          {/*</ProsContext.Provider>*/}
        </BetsContext.Provider>
      </ScopeContext.Provider>
    </CrushContext.Provider>
  );
}

const skinUReadBasic = (r: UReader) => {
  return {
    id: r.string(),
    image: r.string(),
    color: [r.uint8(), r.uint8(), r.uint8()],
  };
};

const coloR = (r: UReader) => [r.uint8(), r.uint8(), r.uint8()] as [number, number, number];

const getStakeSkinsFromNewBet = (r: UReader) => {
  const skins: any[] = [];
  for (let i = 0; i < 3; i++) {
    skins.push({
      image: r.string(),
      color: coloR(r),
    });
    if (r.complete) return { skins };
  }
  return { skins, additional: r.uint16() };
};
