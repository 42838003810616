import { css } from '@emotion/css';
import { useContestT } from '../glossary';
import { ReactComponent as PistolIcon } from 'app/contest/insight/assets/pistol.svg';
import { ReactComponent as WalletIcon } from 'app/contest/insight/assets/wallet.svg';
import { ReactComponent as WinnersIcon } from 'app/contest/insight/assets/winners.svg';
import { ReactComponent as CrushIcon } from 'app/contest/assets/crush-icon.svg';
import { ReactComponent as CaseBattleIcon } from 'app/contest/assets/case-battle-icon.svg';
import { ReactComponent as UpgradeIcon } from 'app/contest/assets/upgrade-icon.svg';
import { ReactNode } from 'react';
import { ContestData } from 'domain/contest';
import { PlayerRequirementLine } from 'domain/player/requirements';

type ContestInsightAboutProps = {
  data: ContestData;
};

export const ContestInsightAbout = ({ data }: ContestInsightAboutProps): JsxElement => {
  const t = useContestT().sub('insight');
  const { skins, prize, cost, requirements } = data;

  return (
    <div className={mainClass}>
      <div className={boxClass}>
        <div className={statsClass}>
          <StatsItem title={t('skins')} icon={<PistolIcon />}>
            {skins.count} {t('skins-for')} ${skins.price}
          </StatsItem>
          <StatsItem title={t('winners')} icon={<WinnersIcon />}>
            {prize.length}
          </StatsItem>
          <StatsItem title={t('contribution')} icon={<WalletIcon />}>
            ${cost ?? 0}
          </StatsItem>
        </div>
        {requirements.length > 0 && (
          <div className={footerBoxClass}>
            <div className={reqBoxClass}>
              <div className={reqClass}>
                <div className={reqTextClass}>
                  {...requirements.map((item) => <PlayerRequirementLine data={item} />)}
                </div>
              </div>
            </div>
            {/* <div className={regimesBoxClass}>
            <div className={regimesContentClass}>
              <div className={regimesClass}>
                <p className={regimesTextClass}>{t('regimes')}</p>
                <CrushIcon />
                <UpgradeIcon />
                <CaseBattleIcon />
              </div>
            </div>
          </div> */}
          </div>
        )}
      </div>
    </div>
  );
};

type StatsItemProps = {
  title: string;
  children: ReactNode;
  icon: ReactNode;
};

const StatsItem = ({ title, children, icon }: StatsItemProps): JsxElement => {
  return (
    <div className={itemMainClass}>
      <div className={itemBoxClass}>
        <div className={iconBoxClass}>{icon}</div>
        <div className={statsBoxClass}>
          <p className={statsValueClass}>{children}</p>
          <p className={statsTitleClass}>{title}</p>
        </div>
      </div>
    </div>
  );
};

const mainClass = css`
  background: #1e1c22;
  border-radius: 12px;
  width: 100%;
`;

const boxClass = css`
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  height: 100%;
`;

const statsClass = css`
  display: flex;
  gap: 18px;
`;

const footerBoxClass = css`
  display: flex;
  gap: 17px;
  align-items: center;
  flex-wrap: wrap;
`;

const reqBoxClass = css`
  background: linear-gradient(170deg, rgba(38, 35, 45, 0) 30%, rgba(242, 160, 86, 1) 100%);
  border-radius: 8px;
  min-height: 40px;
  height: 100%;
  min-width: 420px;
`;
const reqClass = css`
  background: linear-gradient(170deg, rgba(30, 28, 34, 70%) 0%, rgba(30, 28, 34, 75%) 100%);
  border-radius: 8px;
  height: calc(100% - 1px);
  width: calc(100% - 1px);
`;

const reqTextClass = css`
  padding: 12px 10px;
  display: flex;
  flex-direction: column;
`;

const regimesBoxClass = css`
  background: linear-gradient(170deg, rgba(38, 35, 45, 0) 30%, rgba(255, 57, 130, 1) 100%);
  border-radius: 8px;
  height: 40px;
`;

const regimesContentClass = css`
  background: linear-gradient(170deg, rgba(30, 28, 34, 70%) 0%, rgba(30, 28, 34, 75%) 100%);
  border-radius: 8px;
  height: calc(100% - 1px);
  width: calc(100% - 1px);
`;

const regimesClass = css`
  padding: 11px 10px 11px 12px;
  display: flex;
  align-items: center;
  gap: 12px;
`;

const regimesTextClass = css`
  font-family: 'Commissioner';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 130%;
  color: #988c92;
`;

const itemMainClass = css`
  background: #18171c;
  border-radius: 8px;
`;

const itemBoxClass = css`
  padding: 14px 14px 15px;
  display: flex;
  align-items: center;
  gap: 9px;
`;

const iconBoxClass = css`
  width: 26px;
  height: 26px;
  background: #18171c;
  border-radius: 6px;
  border: 1px solid rgba(255, 170, 99, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const statsBoxClass = css`
  display: flex;
  flex-direction: column;
`;

const statsValueClass = css`
  font-family: 'Onest';
  font-weight: 700;
  font-size: 13px;
  line-height: 120%;
  letter-spacing: -0.03em;
  color: #dfdfdf;
`;

const statsTitleClass = css`
  font-family: 'Commissioner';
  font-weight: 400;
  font-size: 11px;
  line-height: 130%;
  letter-spacing: -0.003em;
  color: #988c92;
`;
