import { css } from '@emotion/css';
import { appSyringe } from 'app/syringe';
import { PromoType, ReplPromo, ReplPromoKind } from 'domain/promo/definitions';
import { OnWelcomeModal } from 'domain/promo/welcome-flow/dialog';
import { usePromoT } from 'domain/promo/glossary';

if (__DEV__) {
  window['openWelcomeFlowPreReplenishDialog'] = (opts) => {
    const isPercent = true;
    // const hasTimer = Math.random() < 0.5;
    // const wasReplaced = Math.random() < 0.5;
    const hasTimer = !!opts.timer;

    openWelcomeFlowPreReplenishDialog({
      data: {
        replaced: opts.replaced,
        chosen: {
          // @ts-ignore
          // isWelcome: !!opts.welcome,
          code: 'CrushKYE8-VKXK',
          // startsAt: '2022-08-25T21:25:54.000000Z',
          expiresAt: null!,
          activatedAt: Date.now() - 1000 * 60 * 5,
          minDep: null!,
          timer: hasTimer ? 86400000 : undefined,
          type: PromoType.replenish,
          kind: isPercent ? ReplPromoKind.percent : ReplPromoKind.amount,
          value: isPercent ? 0.9 : 88.11,
        },
      },
    });
  };
}

type openWelcomeFlowPreReplenishDialogProps = {
  data: {
    chosen: ReplPromo;
    replaced: boolean;
  };
  onClose?(): void;
};

const MAX_PERCENTAGE = 100;

export const openWelcomeFlowPreReplenishDialog = ({
  data,
  onClose,
}: openWelcomeFlowPreReplenishDialogProps) => {
  const { chosen: promo, replaced } = data;
  const openWithPrefix = (prefix: string) => {
    appSyringe((props) => {
      const t = usePromoT().sub('welcome-flow');

      const doSubmit = () => {
        onClose?.();
        props.onClose();
      };

      const bonus = roundDecimal(promo.value * MAX_PERCENTAGE, 2);

      return (
        <OnWelcomeModal
          promo={promo}
          bonus={bonus}
          title={t('title')}
          subTitle={t('subtitle')}
          onClose={doSubmit}
          timerText={t('timer')}
          actionText={t('button')}
        />
      );
    });
  };

  // @ts-ignore
  const isWelcome = promo.isWelcome;
  if (replaced) {
    openWithPrefix('replaced');
  } else if (isWelcome) {
    openWithPrefix('welcome');
  } else {
    onClose?.();
  }
};

const roundDecimal = (number: number, signs = 2): number => {
  if (Number.isInteger(number)) return number;

  return +number.toFixed(signs);
};

const hlClass = css`
  color: var(--c-orange);
  text-transform: uppercase;
`;
