import { cx } from '@emotion/css';
import { ComponentType, createElement } from 'react';

type ClassFactory<T extends keyof JSX.IntrinsicElements, P extends Rsu = Rsa> = (
  props: JSX.IntrinsicElements[T] & P
) => string | undefined;

type ClassifiedComponent<
  T extends keyof JSX.IntrinsicElements,
  P extends Rsu = Rsa
> = ComponentType<JSX.IntrinsicElements[T] & P>;

export type Classified = {
  <P extends Rsu = Rsa, T = any>(
    Component: ComponentType<T>,
    cnf: string | ((props: P & T) => string)
  ): ComponentType<T & P>;
} & {
  [T in keyof JSX.IntrinsicElements]: <P extends Rsu = Rsa>(
    cnf: string | ClassFactory<T, P>
  ) => ClassifiedComponent<T, P>;
};

function createClassified(): Classified {
  const makeClassNameFactoryFromString = (name: string) => (props) => cx(name, props.className);

  function resolveComponent(Component, factory) {
    const classNameFactory =
      typeof factory === 'string' ? makeClassNameFactoryFromString(factory) : factory;

    return (props) => {
      return createElement(Component, { ...props, className: classNameFactory(props) });
    };
  }

  // @ts-ignore
  return new Proxy(resolveComponent, {
    get(target, tag: string): any {
      return (factory) => {
        const classNameFactory =
          typeof factory === 'string' ? makeClassNameFactoryFromString(factory) : factory;

        return (props) => {
          return createElement(tag, { ...props, className: classNameFactory(props) });
        };
      };
    },
  });
}

export const classified = createClassified();
