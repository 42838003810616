import { css, cx } from '@emotion/css';
import { useInventoryState } from 'app/inventory';
import { UpgradeFlowStage, getUpgradeOperator, useUpgradeState } from 'app/upgrade';
import { useUpgradeT } from 'app/upgrade/glossary';
import { InstantTooltip } from 'packs/single/instant-tooltip';
import { stdBreakpoints } from 'packs/std';
import { useState } from 'react';
import { round2DP } from 'support/etc/round-decimal-places';

export const UpgradeControl = (): JsxElement => {
  const t = useUpgradeT().sub('control');

  const [processing, setProcessing] = useState(false);
  const { hasItems } = useInventoryState();
  const { chance, targetPrice, config, stake, stage } = useUpgradeState();
  const { wheel } = getUpgradeOperator();

  if (!config || !stake) {
    return <div className={loadingClass}>Loading...</div>;
  }

  const chanceTextError = t('chance-max-value', {
    percent: config.rtp,
  });

  const minimumChance = 0.01;
  const isShowChanceTextError = +chance < minimumChance || +chance > config.rtp;

  const doUpgrade = async () => {
    const offset = round2DP(wheel!.chance.getOffset() / 3.6);
    setProcessing(true);
    try {
      await getUpgradeOperator().doUpgrade(offset);
    } finally {
      setProcessing(false);
    }
  };

  const disabled =
    stage === UpgradeFlowStage.resulting ||
    stage === UpgradeFlowStage.spinning ||
    stake.total === 0 ||
    !hasItems;

  return (
    <div className={mainClass}>
      <div className={contentClass}>
        <div className={chanceClass}>
          <p className={titleClass}>{t('chance')}</p>
          <div className={contentChanceClass}>
            <div className={chanceInputBoxClass}>
              <InstantTooltip
                className={tooltipClass}
                open={isShowChanceTextError}
                title={chanceTextError}
                arrow
                placement="top"
              >
                <input
                  disabled={disabled}
                  className={cx(chanceInputClass, disabled && disabledColorClass)}
                  value={chance ?? minimumChance}
                  onChange={(e) => getUpgradeOperator().setChance(e.target.value)}
                />
              </InstantTooltip>
              <span className={cx(chanceActiveClass, disabled && disabledColorClass)}>%</span>
            </div>
            <div className={sliderBoxClass}>
              <input
                disabled={disabled}
                className={cx(sliderClass, disabled && disabledSliderClass)}
                value={+chance}
                min={+chance <= 1 ? minimumChance : 1}
                max={config.maxChance}
                type="range"
                onChange={(e) => getUpgradeOperator().setChance(e.target.value.toString())}
                style={{
                  backgroundSize: `${+chance + +chance * 0.25}% 100%`,
                }}
              />
            </div>
            <MakeStrips num={28} />
          </div>
        </div>
        <div className={priceSkinClass}>
          <p className={cx(titleClass, disabled && disabledColorClass)}>{t('price')}</p>
          <div className={priceBoxClass}>
            <span className={cx(textActiveClass, disabled && disabledPriceClass)}>$</span>
            <input
              disabled={disabled}
              className={cx(inputPriceClass, disabled && disabledColorClass)}
              value={targetPrice}
              onChange={(e) => getUpgradeOperator().setTargetPrice(e.target.value)}
            />
          </div>
        </div>
      </div>
      <button
        disabled={processing || disabled || isShowChanceTextError}
        onClick={doUpgrade}
        className={buttonClass}
      >
        {t('upgrade')}
      </button>
    </div>
  );
};

type MakeStripsProps = {
  num: number;
};

const MakeStrips = ({ num }: MakeStripsProps): JsxElement => {
  const stripNumberArray = [...Array(num).keys()];

  return (
    <div className={stripsBoxClass}>
      {stripNumberArray.map((id) => (
        <span key={id} className={stripClass}></span>
      ))}
    </div>
  );
};

const stripsBoxClass = css`
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding-top: 6px;
`;

const stripClass = css`
  width: 1px;
  height: 4px;
  background: rgba(123, 139, 172, 0.2);
  border-radius: 12px;
  position: relative;
`;

const mainClass = css`
  width: 50%;
  background: #1e1c22;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  padding: 31px 32px 24px;
  display: flex;
  flex-direction: column;
  gap: 23px;

  ${stdBreakpoints.up(1920)} {
    padding-top: 49px;
    max-width: 640px;
    gap: 36px;
  }
`;

const contentClass = css`
  display: flex;
  gap: 20px;
`;

const chanceClass = css`
  display: flex;
  flex-direction: column;
  gap: 11px;
  width: 80%;
`;

const contentChanceClass = css`
  background: #18171c;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  height: 37px;
`;

const chanceInputBoxClass = css`
  background: #18171c;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  height: 37px;
  padding: 8px 16px;
  font-weight: 500;
  width: 100%;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: left;

  ${stdBreakpoints.up(1920)} {
    height: 42px;
  }
`;

const chanceInputClass = css`
  background: #18171c;
  font-weight: 500;
  font-size: 14.5px;
  line-height: 140%;
  width: 100%;
  color: #dfdfdf;
  border: none;
  outline: none;
`;

const chanceActiveClass = css`
  font-weight: 500;
  font-size: 14.5px;
  line-height: 140%;
  color: #dfdfdf;
`;

const sliderBoxClass = css`
  background: #1e1c22;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
`;

const sliderClass = css`
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  height: 6px;
  width: 100%;
  -webkit-appearance: none;
  background: #2a2930;
  background-image: linear-gradient(180deg, #ffaa63 0%, #ea9a4e 100%);
  background-repeat: no-repeat;
  cursor: pointer;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 12px;
    width: 7px;
    border-radius: 128px;
    background: #ffffff;
    transition: background 0.3s ease-in-out;
  }

  & ::-moz-range-thumb {
    -webkit-appearance: none;
    height: 12px;
    width: 7px;
    border-radius: 128px;
    background: #ffffff;
    transition: background 0.3s ease-in-out;
    position: absolute;
  }

  & ::-ms-thumb {
    -webkit-appearance: none;
    height: 12px;
    width: 7px;
    border-radius: 128px;
    background: #ffffff;
    transition: background 0.3s ease-in-out;
  }

  &::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }

  &::-moz-range-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }

  &::-ms-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }
`;

const priceSkinClass = css`
  display: flex;
  flex-direction: column;
  gap: 11px;
  width: 100%;
`;

const titleClass = css`
  font-weight: 500;
  font-size: 14.5px;
  line-height: 140%;
  color: #dfdfdf;
`;

const priceBoxClass = css`
  font-weight: 500;
  font-size: 14.5px;
  line-height: 140%;
  color: #dfdfdf;
  width: 100%;
  height: 46px;
  background: #18171c;
  border-radius: 7px;
  display: flex;
  align-items: center;
  text-align: center;

  ${stdBreakpoints.up(1920)} {
    height: 52px;
  }
`;

const inputPriceClass = css`
  font-size: 14.5px;
  line-height: 140%;
  color: #dfdfdf;
  background: #18171c;
  border: none;
  outline: none;
`;

const textActiveClass = css`
  font-family: 'Onest';
  font-weight: 700;
  line-height: 140%;
  background: linear-gradient(180deg, #ffaa63 0%, #ffaa63 0.01%, #ea9a4e 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  padding-left: 10px;
  padding-right: 4px;
`;

const buttonClass = css`
  background: linear-gradient(180deg, #ffaa63 0%, #ffaa63 0.01%, #ea9a4e 100%);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 52px;
  border: none;
  cursor: pointer;

  font-family: 'Onest';
  font-weight: 700;
  font-size: 14.5px;
  line-height: 140%;
  color: #18171c;

  &:hover {
    background: #ffbe88;
  }

  &:disabled {
    cursor: not-allowed;
    color: #18171c;
    background: #988c92;
    opacity: 0.3;
  }
`;

const disabledColorClass = css`
  color: #988c92;
`;

const disabledPriceClass = css`
  background: linear-gradient(180deg, #988c92 0%, #988c92 0.01%, #988c92 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;

const disabledSliderClass = css`
  background-image: linear-gradient(
    180deg,
    rgba(152, 140, 146, 0.3) 0%,
    rgba(152, 140, 146, 0.3) 100%
  );
  cursor: default;

  &::-webkit-slider-thumb {
    background: #988c92;
  }

  & ::-moz-range-thumb {
    background: #988c92;
  }

  & ::-ms-thumb {
    background: #988c92;
  }
`;

const loadingClass = css`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

const tooltipClass = css`
  & .tooltip {
    align-items: center;
    display: flex;
    margin: 10px;
  }
`;
