import { css } from '@emotion/css';
import { ReactComponent as WatchIcon } from 'app/contest/assets/watch.svg';
import { useContestT } from 'app/contest/glossary';
import { ContestBriefData } from 'domain/contest';

type ContestCardDurationProps = {
  contest: ContestBriefData;
};

export const ContestCardDuration = ({ contest }: ContestCardDurationProps): JsxElement => {
  const t = useContestT().sub('card');

  return (
    <div className={durationBoxClass}>
      <p className={titleTextClass}>{t('duration')}</p>
      <div className={durationClass}>
        <WatchIcon />
        {convertMillisecondsToTime(contest.duration)}
      </div>
    </div>
  );
};

const convertMillisecondsToTime = (ms: number): string => {
  const date = new Date(ms);

  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();

  const checkTime = (num: number) => (num < 10 ? '0' + num : num);

  const time = checkTime(hours) + ':' + checkTime(minutes);

  return time;
};

const titleTextClass = css`
  font-family: 'Commissioner';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 140%;
  color: #dfdfdf;
`;

const durationBoxClass = css`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 6px;
  width: 100%;
`;

const durationClass = css`
  font-family: 'Onest';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 130%;
  display: flex;
  gap: 4px;
  align-items: center;
  letter-spacing: -0.03em;
  color: #dfdfdf;
`;
