import { css } from '@emotion/css';
import { PlayerLinkedImage } from 'domain/player/image/linked-image';
import { SkinImg } from 'domain/skin/units/image';
import { useLeaderBoardT } from 'app/leader-board/glossary';
import { LeaderBoardItemData } from 'app/leader-board/definitions';
import { FunctionComponent } from 'react';
import { stdBp } from 'packs/std';
import { skinNameViewOnSkin } from 'domain/skin/name-view';

type LeaderBoardTop3LeaderCardProps = {
  leader: LeaderBoardItemData;
  icon: string;
  background: string;
  BackgroundIcon: FunctionComponent<React.SVGProps<SVGSVGElement>>;
};

export const LeaderBoardTop3LeaderCard = ({
  leader,
  icon,
  background,
  BackgroundIcon,
}: LeaderBoardTop3LeaderCardProps): JsxElement => {
  const t = useLeaderBoardT().sub('top3');

  const { profit, games, player, prize } = leader;
  const { skin, cost } = prize!;
  const skinName = skinNameViewOnSkin(skin);
  const skinColor = `rgb(${skin.color[0]}, ${skin.color[1]}, ${skin.color[2]})`;
  const borderLeftColor = `1px solid ${skinColor}`;
  return (
    <div className={mainClass} style={{ backgroundImage: background }}>
      <BackgroundIcon className={backgroundClass} />
      <div className={boxClass}>
        <div className={contentClass}>
          <div className={headerClass}>
            <PlayerLinkedImage player={player} className={avatarClass} />
            <div className={nameBoxClass}>
              <p className={nameClass}>{player?.name}</p>
              <img src={icon} width="15px" />
            </div>
          </div>
          <div className={playerInfoClass}>
            <div className={playerInfoBoxClass}>
              <p className={playerInfoTitleClass}>{t('profit')}</p>
              <p className={profitClass}>${profit}</p>
            </div>
            <div className={playerInfoBoxClass}>
              <p className={playerInfoTitleClass}>{t('games')}</p>
              <p className={gamesClass}>{games}</p>
            </div>
          </div>
          <div className={footerClass}>
            <div className={footerBoxClass} style={{ borderLeft: borderLeftColor }}>
              <SkinImg code={skin.image} className={skinImageClass} />
              <div className={skinInfoBoxClass}>
                <p className={costClass} style={{ color: skinColor }}>
                  ${cost}
                </p>
                <div className={skinNameBoxClass}>
                  <p>{skinName.model}</p>
                  <p className={skinNameClass}>{skinName.brand}</p>
                  {/* <p className={skinLastNameClass}>{skinName.level?.full}</p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mainClass = css`
  border-radius: 8px;
  width: 100%;
  min-width: 212px;
  max-width: 512px;
  min-height: 100%;
  background-image: linear-gradient(135deg, rgba(38, 35, 45, 0) 0%, rgba(212, 94, 57, 1) 100%);
  position: relative;
  ${stdBp.down(1440)} {
    max-width: 100%;
  }
`;

const backgroundClass = css`
  position: absolute;
  top: 14px;
  width: 100%;
  display: flex;
  align-items: center;
`;

const boxClass = css`
  padding: 18px 8px 8px 8px;
  border-radius: 8px;
  width: calc(100% - 1px);
  height: calc(100% - 1px);
  background: rgba(28, 27, 32, 85%);
`;

const contentClass = css`
  display: flex;
  flex-direction: column;
  gap: 18px;
  align-items: center;
  justify-content: center;
`;

const headerClass = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
`;

const avatarClass = css`
  height: 48px;
  width: 48px;
  border-radius: 12px;
`;

const nameBoxClass = css`
  display: flex;
  gap: 4px;
  align-items: center;
`;

const nameClass = css`
  font-family: 'Onest';
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #ffffff;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const playerInfoClass = css`
  display: flex;
  gap: 39px;
`;

const playerInfoBoxClass = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  font-family: 'Onest';
`;

const playerInfoTitleClass = css`
  font-weight: 600;
  font-size: 12px;
  line-height: 130%;
  letter-spacing: -0.03em;
  color: #ffffff;
`;

const profitClass = css`
  font-weight: 600;
  font-size: 12px;
  line-height: 130%;
  letter-spacing: -0.03em;
  background: linear-gradient(180deg, #ffaa63 0%, #ea9a4e 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;

const gamesClass = css`
  font-weight: 600;
  font-size: 12px;
  line-height: 130%;
  letter-spacing: -0.03em;
  color: #988c92;
`;

const footerClass = css`
  background: rgba(24, 23, 28, 0.5);
  border-radius: 8px;
  width: 100%;
  overflow: hidden;
`;

const footerBoxClass = css`
  padding: 5px 6px 8px 6px;
  display: flex;
  align-items: center;
  gap: 9px;
  height: 78px;
`;

const skinBoxClass = css`
  display: flex;
  gap: 1px;
  flex-direction: column;
`;

const skinImageClass = css`
  width: 115px;
  height: 100%;
  padding-top: 6px;
  object-fit: cover;
  ${stdBp.up(1650)} {
    margin: 0 20px;
  }
`;

const costClass = css`
  font-family: 'Onest';
  font-weight: 700;
  font-size: 14px;
  width: max-content;
  padding: 6px 12px;
  background: #18171c;
  border-radius: 7px;
  text-align: center;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const skinNameBoxClass = css`
  ${skinBoxClass}
  padding: 2px 0;
  font-family: 'Commissioner';
  font-weight: 400;
  font-size: 12px;
  color: #dfdfdf;
`;
const skinInfoBoxClass = css`
  ${skinBoxClass}
  font-family: 'Commissioner';
  font-weight: 400;
  font-size: 12px;
  color: #dfdfdf;
`;

const skinNameClass = css`
  font-weight: 600;
  font-size: 11px;
`;

const skinLastNameClass = css`
  font-size: 10px;
  letter-spacing: -0.003em;
  color: #988c92;
`;
