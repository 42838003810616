import { css } from '@emotion/css';
import { ReactNode } from 'react';

type CrushControlStatsBlockProps = {
  label: ReactNode;
  value: ReactNode;
  icon: ReactNode;
};

export const CrushControlStatsBlock = ({
  label,
  value,
  icon,
}: CrushControlStatsBlockProps): JsxElement => {
  return (
    <div className={mainClass}>
      {icon}
      <div className={secondClass}>
        <div className={valueClass}>{value}</div>
        <div className={labelClass}>{label}</div>
      </div>
    </div>
  );
};

type CrushControlStatsBlockBoxProps = {
  children: ReactNode;
};

export const CrushControlStatsBlockBox = ({
  children,
}: CrushControlStatsBlockBoxProps): JsxElement => {
  return <div className={boxClass}>{children}</div>;
};

const boxClass = css`
  display: flex;
  gap: 8px;
  padding: 19px 22px;
  height: 107px;
`;

const mainClass = css`
  font-size: 14px;
  border-radius: 8px;
  display: flex;
  gap: 10px;
  background: #18171c;
  padding: 18px;
  flex: 1;
  align-items: center;
`;

const secondClass = css`
  display: flex;
  flex-direction: column;
`;

const valueClass = css`
  font-family: 'Onest';
  font-weight: 700;
  font-size: 14px;
  line-height: 120%;
  color: #dfdfdf;
  white-space: nowrap;
`;

const labelClass = css`
  font-size: 12px;
  line-height: 130%;
  display: flex;
  align-items: center;
  color: #988c92;
  white-space: nowrap;
`;
