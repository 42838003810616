export enum WithdrawalStage {
  pending = 'pending', // Заявка зарегистрирована, начата работа по валидации заявки. `Начальное состояние`
  checked = 'checked', // Заявка проверена, на текущий момент есть скины на бирже с ценой ниже или равной указанной
  pending_confirm = 'pending_confirm', // Ожидаем подтверждения заявки администрацией
  confirmed = 'confirmed', // Заявка подтверждена, ожидает отправки вендору
  processing = 'processing', // Запрос отправлен вендор, ожидаем
  pending_player = 'pending_player', // Скин куплен, ожидаем прием скина игроком
  completed = 'completed', // Вывод скина прошел успешно. `Завершающее состояние`
  failed = 'failed', // Заявка отклонена, причина указана в error. `Завершающее состояние`
}
