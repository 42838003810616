import { css } from '@emotion/css';
import Popover, { PopoverOrigin } from '@mui/material/Popover';
import { InventoryItemData } from 'app/inventory/definitions';
import { useInventoryT } from 'app/inventory/glossary';
import { getInventoryOperator } from 'app/inventory/store';
import { appSyringe } from 'app/syringe';
import { SimplePopoverPaperProps } from 'packs/single/simple-popover';
import { StdKindButton } from 'packs/std/inputs/kind-button';

// https://mui.com/material-ui/react-popover/#anchor-playground
const defaultAnchorOrigin: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'center',
};
const defaultTransformOrigin: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'center',
};

export const getTradeUri = (tradeId: number) => `https://steamcommunity.com/tradeoffer/${tradeId}`;

const UNEXPECTED_PROBLEM_CODE = 'unexpected';

export const openInventoryItemLockTooltip = (el: HTMLDivElement, data: InventoryItemData) => {
  const { lock } = data;
  appSyringe(function InventoryItemLockTooltip({ onClose }) {
    const t = useInventoryT().sub('lock');
    if (lock === undefined) return null;

    const getText = () => {
      if (lock.code === 'wd.failed') {
        const problem = t.sub('problems').tOrNull(lock.problem ?? UNEXPECTED_PROBLEM_CODE, lock);
        if (problem === null) return t.sub('problems').tOrNull(UNEXPECTED_PROBLEM_CODE, lock);
        return problem;
      } else {
        return t.sub('codes')(lock.code);
      }
    };

    const getActions = () => {
      const { code } = lock;
      if (code === 'wd.failed') {
        return [
          <StdKindButton
            kind="grey"
            children={t('unlock')}
            onClick={async () => {
              await getInventoryOperator().unlockItem(data.id);
              onClose();
            }}
          />,
          <StdKindButton
            children={t('try-again')}
            onClick={async () => {
              await getInventoryOperator().withdrawFailedById(data.id);
              onClose();
            }}
          />,
        ];
      }

      if (code === 'wd.pending_player') {
        return [
          <a href={`${getTradeUri(lock.tradeId)}`} target="_blank" rel="noreferrer">
            <StdKindButton children={t('go-trade')} onClick={onClose} />
          </a>,
        ];
      }
    };

    const actions = getActions();

    return (
      <Popover
        open
        onClose={onClose}
        anchorEl={el}
        anchorOrigin={defaultAnchorOrigin}
        transformOrigin={defaultTransformOrigin}
        // @ts-ignore
        PaperProps={SimplePopoverPaperProps}
      >
        <div className={mainClass}>
          {getText()}
          {actions !== undefined && <div className={actionsClass}>{...actions}</div>}
        </div>
      </Popover>
    );
  });
};

const actionsClass = css`
  display: flex;
  justify-content: center;
  gap: 8px;
`;

const mainClass = css`
  background: #26232d;
  border-radius: 8px;
  padding: 12px;
  max-width: 247px;
  text-align: center;
  overflow-wrap: break-word;
  font-family: 'Onest';
  font-weight: 700;
  font-size: 13px;
  line-height: 140%;
  color: #dfdfdf;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
