import { css } from '@emotion/css';
import { ContestCardCountdown } from 'app/contest/card/units/countdown';
import { ContestCardDuration } from 'app/contest/card/units/duration';
import { ContestCardJoined } from 'app/contest/card/units/joined';
import { ContestCardMainBox } from 'app/contest/card/units/main-box';
import { ContestCardPrize } from 'app/contest/card/units/prize';
import { useContestT } from 'app/contest/glossary';
import { ContestBriefData } from 'domain/contest';
import { ReactNode } from 'react';

type ContestCardUpcomingProps = {
  children?: ReactNode;
  contest: ContestBriefData;
};

export const ContestCardUpcoming = ({
  contest,
  children,
}: ContestCardUpcomingProps): JsxElement => {
  const t = useContestT().sub('card');

  return (
    <ContestCardMainBox data={contest}>
      <div className={mainClass}>
        <div className={actionClass}>
          <ContestCardCountdown
            title={t('countdown.start')}
            finish={contest.start}
          />
          <ContestCardPrize contest={contest} />
        </div>
        <div className={actionClass}>
          <ContestCardJoined contest={contest} />
          <ContestCardDuration contest={contest} />
        </div>
        {children}
      </div>
    </ContestCardMainBox>
  );
};

const mainClass = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;
`;

const actionClass = css`
  width: 100%;
  gap: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const buttonClass = css`
  width: 122px;
`;
