import { stash_get } from 'support/etc/stash';

class SkinNameView {
  // M4A1-S | Master Piece (Minimal Wear)
  constructor(public readonly id: string) {}

  @stash_get
  // M4A1-S
  get model(): string {
    return this.id.split('|')[0].trim();
  }

  @stash_get
  // Master Piece
  get brand(): string {
    const tmp = this.id.split('|')[1];
    if (!tmp) return this.id;
    return tmp.replace(/\(.*\)/, '').trim();
  }

  @stash_get
  get quality(): string {
    return '';
  }

  @stash_get
  // M4A1-S | Master Piece
  get std() {
    return this.id.replace(/\(.*\)/, '').trim();
  }

  @stash_get
  // Factory New / Minimal Wear
  get level() {
    for (const [full, mini] of SKIN_LEVEL_ENTRIES) {
      if (this.id.includes(full)) return { full, mini };
    }
  }

  // Minimal Wear
  // get wearRating() {
  //   throw 'not-implemented'
  // }
}

const sym = Symbol();

export const skinNameViewOnSkin = (skin: { id: string }): SkinNameView => {
  return (skin[sym] ??= new SkinNameView(skin.id));
};

export const skinNameView = (id: string) => new SkinNameView(id);

export const SKIN_LEVEL_ENTRIES = [
  ['Factory New', 'FN'],
  ['Minimal Wear', 'MW'],
  ['Field-Tested', 'FT'],
  ['Well-Worn', 'WW'],
  ['Battle-Scarred', 'BS'],
];
