import { css } from '@emotion/css';
import { Grow, GrowNum } from 'domain/crush/growth';

import { createRefEffect } from 'support/react/ref-effect';
import { useSingleton } from 'support/react/use-singleton';

type CrushSceneXCounterProps = {
  growth: Grow;
};

export const CrushSceneXCounter = ({ growth }: CrushSceneXCounterProps): JsxElement => {
  return useSingleton(() => {
    const ref = createRefEffect((el) => {
      // FIXME: This is a hack to prevent undefined error
      if (!growth) return;
      // if (!growth) debugger;
      const update = (x: GrowNum) => {
        el.innerText = `x${x.dp2.toFixed(2)}`;
        if (x.final) el.classList.add(crashedClass);
      };
      update(growth.getX());
      return growth.subGrow(update);
    });

    return <div className={mainClass} ref={ref} />;
  });
};

const mainClass = css`
  font-weight: 600;
  font-size: 36px;
  line-height: 130%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #dfdfdf;
  text-shadow: 0px 4px 18px rgba(223, 223, 223, 0.6);
  z-index: 1;
`;

const crashedClass = css`
  color: #ff5a5a !important;
  text-shadow: 0px 4px 18px rgba(255, 90, 90, 0.6) !important;
`;
