type ContestCardStatusIconProps = {
  color: string;
};

export const ContestCardStatusIcon = ({ color }: ContestCardStatusIconProps): JsxElement => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect opacity="0.1" width="16" height="16" rx="8" fill={color} />
      <rect opacity="0.1" x="2" y="2" width="12" height="12" rx="6" fill={color} />
      <g filter="url(#filter0_d_530_6272)">
        <rect x="6" y="6" width="4" height="4" rx="2" fill={color} />
      </g>
      <defs>
        <filter
          id="filter0_d_530_6272"
          x="1"
          y="1"
          width="14"
          height="14"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="1"
            operator="dilate"
            in="SourceAlpha"
            result="effect1_dropShadow_530_6272"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.596078 0 0 0 0 0.54902 0 0 0 0 0.572549 0 0 0 1 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_530_6272" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_530_6272"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
