import { css } from '@emotion/css';
import { useMemo } from 'react';

import { UpgradeStakeItemsSelected } from 'app/upgrade/stake/items-selected';
import { UpgradeNoItemsSelected } from 'app/upgrade/stake/no-items-selected';
import { useUpgradeState } from 'app/upgrade';
import { stdBreakpoints } from 'packs/std';

type Props = {
  locked?: boolean;
};

export const UpgradeStake = ({ locked }: Props): JsxElement => {
  const state = useUpgradeState();

  return useMemo(() => {
    return (
      <div className={mainClass}>
        {state.stake?.items?.length !== 0 ? (
          <UpgradeStakeItemsSelected stake={state.stake} />
        ) : (
          <UpgradeNoItemsSelected />
        )}
        {locked && <div className={blurClass}></div>}
      </div>
    );
  }, [state.stake, locked]);
};

const mainClass = css`
  height: 100%;
  position: relative;
  padding: 12px;
  padding-right: 4px;
  ${stdBreakpoints.up(1695)} {
    transform: scaleX(0.9);
  }
  ${stdBreakpoints.up(1820)} {
    transform: scaleX(0.8);
  }
  ${stdBreakpoints.up(2320)} {
    transform: scaleX(0.78);
  }
`;
const blurClass = css`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: radial-gradient(
    31.32% 49.76% at 41.23% 28.78%,
    rgba(24, 23, 28, 0.4) 0%,
    rgba(24, 23, 28, 0.8) 100%
  );
  backdrop-filter: blur(4px);
  z-index: 20;
  pointer-events: none;
`;
