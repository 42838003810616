import { splashAlert } from 'app/chat/splash';
import { formatError } from 'support/etc/format-error';
import { collectBrowserData } from 'domain/tracking/tracking';

export const chickHandleError = (e) => {
  console.error(e);
  splashAlert({
    code: 'bsod.graph-chick',
    error: formatError(e),
    ...collectBrowserData(),
  });
};
