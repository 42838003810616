import { css, cx } from '@emotion/css';
import { createElement } from 'react';
import { StdButton, StdButtonProps } from './button';

export type StdKindButtonProps = StdButtonProps & {
  kind?: keyof typeof KINDS;
  size?: keyof typeof SIZES;
  type?: 'submit';
};

export const StdKindButton = ({ className, kind, size, ...props }: StdKindButtonProps) => {
  return createElement(StdButton, {
    ...props,
    className: cx(mainClass, KIND_MAP.get(kind), SIZE_MAP.get(size), className),
  });
};

const mainClass = css`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  border: 0;
  color: #fff;

  font-family: 'Onest';

  &:disabled {
    cursor: not-allowed;
    color: #2d241c;
    background: #988c92 !important;
    opacity: 0.3 !important;
    box-shadow: none !important;
  }

  &.cursor-default {
    cursor: default;
  }
`;

const SIZES = {
  // TODO: change 'sm' styles to those in figma
  sm: css`
    padding: 6px 11px;
    border-radius: 2px;

    font-weight: 600;
    font-size: 11px;
  `,
  md: css`
    padding: 12px 21px;
    border-radius: 4px;

    font-weight: 700;
    font-size: 13px;
  `,
};

const KINDS = {
  orange: css`
    color: #2d241c;
    background: linear-gradient(180deg, #ffaa63 0%, #ea9a4e 100%);
    box-shadow: inset 0px 4px 12px 2px #ff7324;

    &.selected,
    &:hover:not(:disabled) {
      background: #ffbe88;
    }
  `,

  grey: css`
    background: #2f3341;

    &.selected,
    &:hover:not(:disabled) {
      background: #40465a;
    }
  `,

  blue: css`
    background: linear-gradient(90deg, #4b69ff 0%, #1d4eff 100%);

    &.selected,
    &:hover:not(:disabled) {
      background: linear-gradient(90deg, #425ad5 0%, #163fd2 100%);
    }
  `,

  green: css`
    background: #50ae30;
  `,

  'green-hollow': css`
    background: transparent;
    color: #50ae30;
    border: 1px solid #50ae30;
  `,
};

const KIND_MAP = new Map<string | undefined, string>(Object.entries(KINDS));
KIND_MAP.set(undefined, KIND_MAP.get('orange')!);

const SIZE_MAP = new Map<string | undefined, string>(Object.entries(SIZES));
SIZE_MAP.set(undefined, SIZE_MAP.get('md')!);
