export const CasesIcon = () => {
  return (
    <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect opacity="0.2" width="14" height="14" rx="4" fill="#696155" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.88203 3.4723L2.10765 4.46937C1.85254 4.79785 2.08663 5.27606 2.50254 5.27606H5.25021C5.40754 5.27606 5.55569 5.20201 5.65012 5.07618L6.99884 3.27899L8.39676 5.08239C8.49147 5.20456 8.63736 5.27606 8.79194 5.27606H11.4975C11.9135 5.27606 12.1476 4.79763 11.8922 4.46919L11.1171 3.47212C11.0224 3.35027 10.8767 3.27899 10.7224 3.27899H6.99884H3.27692C3.1225 3.27899 2.97674 3.35034 2.88203 3.4723ZM5.06222 6.1598H3.00003V9.11427C3.00003 10.0017 3.6716 10.721 4.50003 10.721H9.50003C10.3285 10.721 11 10.0017 11 9.11427V6.1598H8.94474C8.35829 6.1598 7.81082 5.84514 7.48657 5.3217L7.00348 4.54187L6.52039 5.3217C6.19614 5.84514 5.64867 6.1598 5.06222 6.1598Z"
        fill="url(#paint0_linear_1_14)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1_14"
          x1="7.00001"
          y1="3.27899"
          x2="7.00001"
          y2="10.721"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFAA63" />
          <stop offset="1" stopColor="#EA9A4E" />
        </linearGradient>
      </defs>
    </svg>
  );
};
