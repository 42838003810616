import { css } from '@emotion/css';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';

export const InstantTooltip = (props: TooltipProps) => {
  const { children, ...propsOther } = props;
  return (
    <Tooltip
      {...propsOther}
      classes={{
        popper: props.className,
        arrow: arrowClass,
        tooltip: tooltipClass,
      }}
    >
      {children}
    </Tooltip>
  );
};

const arrowClass = css`
  color: var(--dark-blue-200);
`;

const tooltipClass = css`
  background: var(--dark-blue-200);
  border-radius: 6px;
  font-size: 12px;
  padding: 10px;
`;
