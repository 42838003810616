import { css, cx } from '@emotion/css';

export const crushRecentCrateStaticFrame = (color: string) => {
  const { r, g, b } = hexToRgb(color)!;
  const colorClass = css`
    color: ${color === '#4C4D53' ? "#988C92": color};
    background: rgba(${r}, ${g}, ${b}, 0.15);
    &:hover{
      background: ${color};
      color: #DFDFDF;
    }
  `;

  return function StaticFrame({ children }: Rcp) {
    return <div className={cx(mainClass, colorClass)}>{children}</div>;
  };
};

function hexToRgb(hex: string) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

const mainClass = css`
  align-items: center;
  background: rgba(76, 77, 83, 0.15);
  border-radius: 6px;
  display: inline-flex;
  flex-shrink: 0;
  height: 100%;
  justify-content: center;
  padding: 0 8px;
  width: 100%;
`;
