if (__DEBUG__) {
  const tools = window['jet_tools'];
  if (tools) {
  }

  const lg = (...args: any[]) => console.log(...args);
  const event = (name: string, ...args) => {
    lg('EVENT', name, ...args);
  };

  window['log_event'] = event;

  Object.assign(lg, {
    event,
  });

  window['lg'] = lg;
}

export {};
