import { globalLogger, LogData, LogLevel } from 'packs/libs/logger';

const level = (lvl: LogLevel) => (log: LogData) => globalLogger.log(lvl, log);

export const splashEmergency = level(LogLevel.emergency);
export const splashAlert = level(LogLevel.alert);
export const splashError = level(LogLevel.error);
export const splashWarning = level(LogLevel.warning);
export const splashInfo = level(LogLevel.error);
export const splashDebug1 = level(LogLevel.debug1);
export const splashDebug2 = level(LogLevel.debug1);
export const splashDebug3 = level(LogLevel.debug1);
