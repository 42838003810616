import { css, cx } from '@emotion/css';
import { ReactComponent as BotAvatar } from 'app/case/battle/assets/bot-avatar.svg';
import { ReactComponent as SwordIcon } from 'app/case/battle/assets/sword.svg';
import { ReactComponent as UserIcon } from 'app/case/battle/assets/user.svg';
import { CaseBattlePlayerRole, CaseBattleStage } from 'app/case/battle/definitions';
import { ReactComponent as FillBoxIcon } from 'app/case/battle/monitor/card/icons/fill-box.svg';
import { CaseBattleMonitorBattleState } from 'app/case/battle/monitor/definitions';
import { useCaseBattleMonitorT } from 'app/case/battle/monitor/glossary';
import { CaseBattleMonitorEnterBattleSound } from 'app/case/battle/monitor/sounds';
import { Actor } from 'domain/actor';
import { getCaseBattleActorRole } from 'domain/case/battle/utils/get-actor-role';
import { CaseData } from 'domain/case/definitions';
import { CaseImg } from 'domain/case/units/image';
import { PlayerImage } from 'domain/player/image/image';
import { memoize } from 'lodash';
import { StdButton, stdBreakpoints } from 'packs/std';
import { CSSProperties, ReactNode, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { roundCash } from 'support/etc/round-cash';
import { ReactComponent as ContestBadgeIcon } from '../assets/contest-badge.svg';
import { openCaseBattleMonitorAllCaseDialog } from '../dialog/dialog';
import { ReactComponent as BoxIcon } from './icons/box.svg';
import { ReactComponent as WatchIcon } from './icons/watch.svg';

const CARD_WIDTH = 122;
const CARD_GAP = 2;
const CARD_TOTAL_WIDTH = CARD_WIDTH + CARD_GAP;

type CaseBattleMonitorCardProps = {
  data: CaseBattleMonitorBattleState;
  actor: Actor;
};

export const CaseBattleMonitorCard = ({ data, actor }: CaseBattleMonitorCardProps): JsxElement => {
  const { stage, id, members, cases, size, cost, currentRound, contestId } = data;
  const t = useCaseBattleMonitorT().sub('card');

  const scrollRef = useRef<HTMLDivElement | null>(null);
  const frameRef = useRef<HTMLDivElement | null>(null);

  const role = getCaseBattleActorRole(data, actor);

  const composeActions = () => {
    const link = `/case-battles/${id}`;
    if (stage === CaseBattleStage.waiting) {
      if (role === CaseBattlePlayerRole.observer) {
        return (
          <LinkButton
            type="a"
            to={link}
            children={t('enter', { amount: cost.toFixed(1) })}
            icon={<SwordIcon />}
            onClick={() => CaseBattleMonitorEnterBattleSound.replay()}
          />
        );
      }
      return <LinkButton type="b" to={link} children={t('watch')} icon={<WatchIcon />} />;
    }
    const watchButton = (
      <LinkButton type="b" to={link} children={t('watch')} icon={<WatchIcon />} />
    );
    if (stage === CaseBattleStage.live) {
      return (
        <>
          {watchButton}
          <div className={accentBoxClass}>
            <BoxIcon className={boxIconSvgClass} />
            <span className={accentClass}>{data.currentRound}</span>/{cases.length} {t('opened')}
          </div>
        </>
      );
    }
    return watchButton;
  };

  const players = members.map((data) =>
    data.bot ? (
      <BotAvatar className={playerAvatarClass} />
    ) : (
      <PlayerImage key={data.id} className={playerAvatarClass} code={data.image} />
    )
  );
  for (let i = members.length; i < size; i++) players.push(emptyPlayer);

  useEffect(() => {
    // initial for frame
    const { width: clientWidth } = scrollRef.current?.getBoundingClientRect()!;
    const cardsInView = Math.ceil(clientWidth / CARD_TOTAL_WIDTH);
    const centerCard = Math.ceil(cardsInView / 2);

    if (currentRound! >= centerCard) {
      const offset = (centerCard - 1) * CARD_TOTAL_WIDTH;
      animateLeft(frameRef.current, offset, 200);
    }
  }, []);

  useEffect(() => {
    if (!currentRound) return;

    const { width: clientWidth } = scrollRef.current?.getBoundingClientRect()!;
    const cardsInView = Math.ceil(clientWidth / CARD_TOTAL_WIDTH);
    const centerCard = Math.ceil(cardsInView / 2);

    if (currentRound <= centerCard) {
      // move frame
      const offset = (currentRound - 1) * CARD_TOTAL_WIDTH;
      animateLeft(frameRef.current, offset, 200);
    } else {
      // move stipe
      const offset = (currentRound - centerCard) * CARD_TOTAL_WIDTH;
      scrollRef.current?.scroll({
        left: offset,
        behavior: 'smooth',
      });

      // move frame + stripe
      const scrollTillEnd =
        scrollRef.current?.scrollWidth! - (scrollRef.current?.scrollLeft! + clientWidth);
      if (scrollTillEnd <= CARD_TOTAL_WIDTH) {
        const offset = frameRef.current?.offsetLeft! + CARD_TOTAL_WIDTH - scrollTillEnd;
        animateLeft(frameRef.current, offset, 200);
      }
    }
  }, [currentRound]);

  return (
    <div className={mainClass}>
      <div className={stageBoxClass(String(stage))}>
        <div className={statusClass(String(stage))}>{t.sub('stage')(String(stage))}</div>
      </div>

      <div className={infoClass}>
        <div className={casesInfoClass}>
          <span className={casePriceClass}>${roundCash(cost)}</span>
          <span className={caseTotalClass}>
            <span className={accentClass}>{cases.length}</span> {t('cases-label')}
          </span>
        </div>
        <div className={userTotalClass}>
          <UserIcon className={userIconClass} />
          <p>
            <span>{members.length}</span>/{size}
          </p>
          {!!contestId && (
            <Link to={`/contests/${contestId}`}>
              <ContestBadgeIcon />
            </Link>
          )}
        </div>
        <div className={playersClass}>{...players}</div>
      </div>
      <div className={casesBoxClass}>
        {!!currentRound && <div className={currentRoundFrameClass} ref={frameRef}></div>}
        <div className={casesClass} ref={scrollRef}>
          {...cases.map((caseData) => <CaseCard data={caseData} />)}
        </div>
        <div
          className={openAllCaseButtonClass}
          onClick={() => openCaseBattleMonitorAllCaseDialog(data)}
        >
          <div className={allCaseButtonTextClass}>{t('all-case')}</div>
          <FillBoxIcon className={svgAllCaseClass} />
        </div>
      </div>

      <div className={actionsBoxClass}>{composeActions()}</div>
    </div>
  );
};

const actionsBoxClass = css`
  min-width: 209px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

type LinkButtonProps = {
  to: string;
  children: ReactNode;
  icon: ReactNode;
  type: 'a' | 'b';
  onClick?: () => void;
};

const LinkButton = ({ to, children, icon, type, onClick }: LinkButtonProps): JsxElement => {
  return (
    <Link to={to}>
      <StdButton className={BUTTON_TYPES[type]} onClick={onClick}>
        {icon}
        {children}
      </StdButton>
    </Link>
  );
};

type CaseCardProps = {
  data: CaseData;
};
const CaseCard = ({ data }: CaseCardProps): JsxElement => {
  const { id, name, color } = data;

  return (
    <div
      className={cx(caseCardClass)}
      style={{ '--l-color': color && color.join(', ') } as CSSProperties}
    >
      <CaseImg id={id} alt={name} className={slotItemClass} />
      <span className={slotItemNameClass}>{name}</span>
    </div>
  );
};

const mainClass = css`
  width: 100%;
  height: 144px;
  display: flex;
  align-items: center;
  background: #1e1c22;
  border-radius: 12px;
  overflow: hidden;
`;

const colorStage = ['#988C92', '#0FAC7F', '#FF5A5A'];
const backgroundStage = [
  'rgba(117, 108, 113, 0.3) 40%, rgba(152, 140, 146, 0.4) 97%',
  'rgba(34, 108, 87, 0.3) 40%, rgba(15, 172, 127, 0.5) 97%',
  'rgba(97, 36, 36, 0.5) 40%, rgba(255, 90, 90, 0.5) 97%',
];

const statusClass = memoize(
  (stage) => css`
    text-align: center;
    font-family: 'Commissioner';
    font-weight: 700;
    font-size: 10px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: ${colorStage[stage]};
    transform: rotate(-90deg);
    ${stdBreakpoints.up(1920)} {
      font-size: 12px;
    }
  `
);
const stageBoxClass = memoize(
  (stage) => css`
    display: flex;
    width: 18px;
    height: 100%;
    align-items: center;
    justify-content: center;
    background-image: radial-gradient(circle, ${backgroundStage[stage]});
  `
);

const infoClass = css`
  min-width: 190px;
  padding: 18px 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const casesInfoClass = css`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const userTotalClass = css`
  font-family: 'Onest';
  font-weight: 700;
  font-size: 11px;
  line-height: 140%;
  color: #988c92;

  display: flex;
  align-items: center;
  gap: 4px;

  span {
    color: #dfdfdf;
    text-align: center;
    align-items: center;
    ${stdBreakpoints.up(1920)} {
      font-size: 15px;
    }
  }

  ${stdBreakpoints.up(1920)} {
    font-size: 13px;
  }
`;

const userIconClass = css`
  width: auto;
  height: 14px;
`;

const playersClass = css`
  display: flex;
  gap: 7px;
`;

const playerAvatarClass = css`
  border-radius: 6px;
  width: 32px;
  height: 32px;
`;
const emptyAvatarClass = css`
  ${playerAvatarClass};
  background: #18171c;
`;
const openAllCaseButtonClass = css`
  width: 24px;
  height: 126px;
  border-radius: 0 11px 11px 0;
  background: #18171c;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 4px;
  position: relative;
`;
const allCaseButtonTextClass = css`
  min-width: 80px;
  font-weight: 700;
  font-size: 10px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #988c92;
  text-align: center;
  transform: rotate(-90deg);
  padding-left: 10px;
`;
const svgAllCaseClass = css`
  position: absolute;
  bottom: 10px;
`;
const casesBoxClass = css`
  position: relative;
  max-width: calc(100% - 190px - 209px - 18px);
  height: 126px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const currentRoundFrameClass = css`
  position: absolute;
  width: ${CARD_WIDTH}px;
  height: ${CARD_WIDTH}px;
  border: 2px solid #feeb8f;
  border-radius: 11px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 7px solid #feeb8f;
    left: 50%;
    transform: translateX(-50%);
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 7px solid #feeb8f;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const casesClass = css`
  overflow: hidden;
  max-width: 1107px;
  height: 100%;
  width: 100%;
  gap: 2px;
  background: #18171c;
  border-radius: 11px 0 0 11px;
  display: flex;
  padding: 2px;
  align-items: center;
  flex: 1;

  &::-webkit-scrollbar {
    display: none;
  }

  & {
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
`;

const casePriceClass = css`
  font-family: 'Onest';
  font-weight: 700;
  font-size: 13px;
  line-height: 140%;
  background: linear-gradient(180deg, #ffaa63 0%, #ea9a4e 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  &:after {
    content: '';
    border-right: 1px solid #2a2930;
    padding-left: 8px;
  }
  ${stdBreakpoints.up(1920)} {
    font-size: 16px;
  }
`;

const caseTotalClass = css`
  font-family: 'Onest';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 140%;
  color: #988c92;
  ${stdBreakpoints.up(1920)} {
    font-size: 16px;
  }
`;

const accentBoxClass = css`
  padding-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #dfdfdf;
  font-family: 'Onest';
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  color: #dfdfdf;
`;
const accentClass = css`
  color: #fff;
  padding-left: 5px;
`;

const baseButtonClass = css`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  gap: 5px;

  width: 161px;
  height: 34px;

  border-radius: 4px;
  border: 0;
  color: #2d241c;

  font-family: 'Onest';
  font-weight: 700;
  font-size: 12px;

  margin: 0 5px;

  &:hover {
    box-shadow: none;
  }

  svg {
    width: auto;
    height: 16px;
  }
`;

const caseCardClass = css`
  display: flex;
  flex-direction: column;
  width: ${CARD_WIDTH}px;
  min-width: ${CARD_WIDTH}px;
  height: ${CARD_WIDTH}px;
  justify-content: center;
  align-items: center;

  background: linear-gradient(180deg, rgb(0, 0, 0, 0) 0%, rgb(var(--l-color), 0.1) 100%);
  box-sizing: border-box;

  padding: 8px 13px 11px 8px;
  border-radius: 11px;
`;

const slotItemClass = css`
  width: 97px;
  height: 94px;
`;
const boxIconSvgClass = css`
  width: 14px;
  height: 14px;
`;

const slotItemNameClass = css`
  text-align: center;
  font-family: 'Commissioner';
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  color: #dfdfdf;
  padding-bottom: 8px;
`;

const BUTTON_TYPES = {
  a: css`
    ${baseButtonClass};

    background: linear-gradient(180deg, #ffaa63 0%, #ea9a4e 100%);
    box-shadow: inset 0 4px 12px 2px #ff7324;

    &:hover {
      background: #ffbe88;
    }
  `,
  b: css`
    ${baseButtonClass};
    color: #988c92;

    background: #26232d;

    &:hover {
      background: #4e4a56;
    }
  `,
};

const emptyPlayer = <div className={emptyAvatarClass} />;

const animateLeft = (element, targetPosition, duration) => {
  const startPosition = element.offsetLeft;
  const distance = targetPosition - startPosition;
  const startTime = performance.now();

  function updatePosition(currentTime) {
    const elapsedTime = currentTime - startTime;
    const progress = Math.min(elapsedTime / duration, 1);
    const newPosition = startPosition + distance * progress;

    element.style.left = newPosition + 'px';

    if (progress < 1) {
      requestAnimationFrame(updatePosition);
    }
  }

  requestAnimationFrame(updatePosition);
};
