export enum UpgradeFlowStage {
  /**
   * Waiting for config from server
   * The game is not prepared
   */
  loading,
  /**
   * Player is picking skins from inventory and choosing chance
   */
  planning,
  /**
   * The bid was accepted, animation is running
   */
  spinning,
  /**
   * Player is seeing the result of his bid
   */
  resulting,
}

export const UPGRADE_SPINNING_ANIMATION_DURATION = 1e4; // 10s
export const UPGRADE_SPINNING_ANIMATION_TURNS_COUNT = 8; // 10s
export const UPGRADE_LOST_SCENE_DURATION = 2e3; // 2s

export type UpgradeConfigData = {
  enabled?: boolean;
  rtp: number; // 90
  maxChance: number; // 90
  streak: {
    rtp: number;
    chance: number;
    steps: number;
  };
};
