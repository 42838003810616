import { mut, MutSpec } from 'support/etc/immutate';
import { HashMap } from 'support/struct/hash-map';

export class IHashMap<K, V> {
  private constructor(private readonly source: HashMap<K, V>) {}

  static by<K, V>(indexBy: (item: V) => K, list: V[]): IHashMap<K, V> {
    return IHashMap.fromEntries<K, V>(list.map((value) => [indexBy(value), value]));
  }

  static fromHashMap<K, V>(map: HashMap<K, V>) {
    return new IHashMap<K, V>(map);
  }

  static fromEntries<K, V>(entries?: readonly (readonly [K, V])[]) {
    return new IHashMap<K, V>(new HashMap<K, V>(entries));
  }

  listEntries(): [K, V][] {
    return [...this.entries()];
  }

  prepend(key: K, value: V) {
    const next = new HashMap<K, V>();
    next.set(key, value);
    for (const [key, value] of this.source.entries()) {
      next.set(key, value);
    }
    return new IHashMap<K, V>(new HashMap<K, V>(next));
  }

  listValues(): V[] {
    return [...this.values()];
  }

  clone() {
    return new IHashMap<K, V>(this.source);
  }

  copySource() {
    return new Map(this.source);
  }

  concat(add: IHashMap<K, V>): IHashMap<K, V> {
    return this.apply((map) => {
      for (const [k, v] of add.entries()) map.set(k, v);
    });
  }

  entries() {
    return this.source.entries();
  }

  static empty<K, V>() {
    return IHashMap.fromEntries<K, V>();
  }

  find(key: K): V | undefined {
    return this.source.find(key);
  }

  // @ts-ignore
  set(key: K, val: V): IHashMap<K, V> {
    return new IHashMap<K, V>(new HashMap<K, V>(this.source).set(key, val));
  }

  get(key: K): V {
    return this.source.get(key);
  }

  has(key: K) {
    return this.source.has(key);
  }

  apply(cb: (map: HashMap<K, V>) => void) {
    const next = new HashMap(this.source);
    cb(next);
    return new IHashMap<K, V>(next);
  }

  mutOne(key: K, spec: MutSpec<V>) {
    return this.applyOne(key, mut(spec));
    // return this.applyOne(key, (value) => ime(value, spec));
  }

  applyOne(key: K, cb: (val: V) => V) {
    const value = this.find(key);
    if (value === undefined) return this;
    return this.set(key, cb(value));
  }

  /** @deprecated */
  map<T>(fn: (value: V, key: K) => T): IHashMap<K, T> {
    const map = new HashMap<K, T>();
    for (const [key, value] of this.source.entries()) {
      map.set(key, fn(value, key));
    }
    return new IHashMap(map);
  }

  listMapV<T>(fn: (val: V) => T): T[] {
    const result: T[] = [];
    for (const value of this.values()) result.push(fn(value));
    return result;
  }

  values() {
    return this.source.values();
  }

  keys() {
    return this.source.keys();
  }

  del(key: K) {
    const next = new HashMap<K, V>(this.source);
    next.delete(key);
    return new IHashMap<K, V>(next);
  }

  delMany(keys: K[]) {
    return this.apply((map) => {
      for (const id of keys) {
        map.delete(id);
      }
    });
  }

  get size() {
    return this.source.size;
  }

  /** @deprecated */
  get length() {
    return this.source.size;
  }
}
