import { LayoutDesktop } from 'app/layout/desktop';
import { coilSend } from 'packs/libs/coil';
import { OnLocaleChanged } from 'packs/libs/intl';
import { useIsDesktop } from 'packs/std';
import { MobilePlaceholder } from './mobile/placeholder';

document.documentElement.setAttribute('data-useragent', navigator.userAgent);

OnLocaleChanged.add((lc) => coilSend('lc', lc));

export const App = (): JsxElement => {
  const isDesktop = useIsDesktop();

  return isDesktop ? <LayoutDesktop /> : <MobilePlaceholder />;
};
