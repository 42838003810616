/** Universal Notifications Listener */
import { coilListen } from 'packs/libs/coil';
import { showToast } from 'packs/libs/inf';
import { getIntl } from 'packs/libs/intl';

type UniversalMessage = {
  type: 'success' | 'error';
  content:
    | {
        // just a plain text
        type: 'simple';
        text: string;
      }
    | {
        // fork with all locales
        type: 'locales';
        locales: {
          en: string;
          ru: string;
          es: string;
        };
      }
    | {
        // code to the translation in the frontend dictionary
        type: 'code';
        code: string;
        data: Rsu;
      };
};

coilListen('notify', ({ type, content }: UniversalMessage) => {
  switch (content.type) {
    case 'simple':
      showToast(type, content.text);
      break;
    case 'locales':
      showToast(type, getIntl().fork(content.locales));
      break;
    // case 'code':
    //   showToast(type, getIntl()(content.code, content.data));
    //   break;
    default:
      throw Error('not-implemented');
  }
});
