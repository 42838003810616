import { encode } from 'msgpackr';
import { Writer } from 'protobufjs';
import { stdIdToUint8Array } from 'support/conversion/std-id-uint8array';
import { actionStringToUint8Array } from 'support/conversion/action-string-uint8array';


export class UWriter extends Writer { }

UWriter.prototype.pack = function (value) {
  return this.bytes(encode(value));
};
UWriter.prototype.concat = function (writer) {
  this.len += writer.len;
  // @ts-ignore
  this.tail.next = writer.head.next;
  this.tail = writer.tail;
  return this;
};

UWriter.prototype.id = function (id) {
  return this.bytes(stdIdToUint8Array(id));
};

const write_buf = (val, buf, pos) => {
  buf.set(val, pos); // also works for plain array values
}

// TODO 0 len
UWriter.prototype.raw = function (value) {
  // const len = value.length >>> 0;
  return this._push(write_buf, value.length, value);
}

UWriter.prototype.action = function (action) {
  return this.bytes(actionStringToUint8Array(action));
};

UWriter.prototype.clone = function (writer) {
  throw Error('not_implemented')
};

UWriter.prototype.uint16 = Writer.prototype.uint32;
UWriter.prototype.int16 = Writer.prototype.int32;
UWriter.prototype.uint8 = Writer.prototype.uint32;
UWriter.prototype.int8 = Writer.prototype.int32;
