import { css } from '@emotion/css';

export const CrushBetsCardBetGradientFrame = (color: number[], isColorGreen?: boolean) => {
  const mkRP = (alpha: number) => (rgb: number[], percent: string | number) =>
    `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${alpha}) ${percent}%`;

  const fgRp = mkRP(1);
  const bgRp = mkRP(0.2);

  const bgLine = ['rgba(30, 28, 34, 0) 0%', bgRp(color, 100)];
  const fgLine = ['rgba(0, 0, 0, 0) 45.02%', fgRp(color, 100)];

  const toGradient = (line) => `linear-gradient(135deg, ${line.join(', ')});`;

  const mainClass = css`
    position: relative;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 292px;
    background-image: ${toGradient(fgLine)};
    &:hover {
      background-image: linear-gradient(
        135deg,
        rgba(0, 0, 0, 0) 45.02%,
        rgba(242, 160, 86, 1) 100%
      );

      > div {
        background-image: linear-gradient(
          135deg,
          rgba(30, 28, 34, 0) 0%,
          rgba(242, 160, 86, 0.2) 100%
        );
        :before {
          background: none;
        }
      }
    }
  `;

  const boxClass = css`
    height: calc(100% - 2px);
    width: calc(100% - 2px);
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(28, 27, 32, 75%);
    background-image: ${toGradient(bgLine)};
    padding: 0 16px;
  `;
  const lineClass = css`
    ${boxClass}
    &:before {
      width: 1px;
      height: 75%;
      position: absolute;
      border-radius: 2px;
      content: '';
      left: 0px;
      background: #0fac7f;
    }
  `;

  return function GradientFrame({ children }: Rcp) {
    return (
      <div className={mainClass}>
        <div className={isColorGreen ? lineClass : boxClass}>{children}</div>
      </div>
    );
  };
};
