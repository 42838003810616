import { useEffect, useRef } from 'react';

export const usePrevious = (value: JSX.Element) => {
  const prevChildrenRef = useRef<JSX.Element>();

  useEffect(() => {
    prevChildrenRef.current = value;
  }, [value]);

  return prevChildrenRef.current;
};
