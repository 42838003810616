import { css } from '@emotion/css';

import { ChatMessageContentProps } from 'app/chat/definitions';
import { ChatRichContent } from 'app/chat/content/rich';

export function ChatSystemContent(props: ChatMessageContentProps) {
  return (
    <div className={mainClass}>
      <ChatRichContent content={props.message.content} />
    </div>
  );
}

const mainClass = css`
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  color: #988c92;
`;
