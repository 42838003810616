import React, { useState } from 'react';
import { css, cx } from '@emotion/css';

import { ReactComponent as CloseIcon } from 'app/sign-in/assets/close-icon.svg';
import { ReactComponent as LogoIcon } from 'app/sign-in/assets/logo-icon.svg';
import { ReactComponent as GrayLogoIcon } from 'app/sign-in/assets/gray-logo-icon.svg';
import { ReactComponent as SteamIcon } from 'app/sign-in/assets/steam-icon.svg';
import { ReactComponent as CheckBoxFalseIcon } from 'app/sign-in/assets/check-box-false-icon.svg';
import { ReactComponent as CheckBoxTrueIcon } from 'app/sign-in/assets/check-box-true-icon.svg';
import { launchSignIn } from 'app/sign-in/launch';
import { appSyringe } from 'app/syringe';
import { StdTopDialog } from 'packs/std';
import { useSignInT } from 'app/sign-in/glossary';

const CheckAmount = 4;

export function openSignInDialog() {
  appSyringe(function LayoutSignInDialog({ onClose }) {
    const [checkAll, setCheckAll] = useState<Map<number, boolean>>();

    const t = useSignInT();
    const onChange = (key: number) => {
      const map = new Map(checkAll);
      !map.has(key) ? map.set(key, true) : map.delete(key);
      if (key >= 0 && key <= 1 && map.has(0) && map.has(1)) {
        map.set(2, true);
        map.set(3, true);
      }
      setCheckAll(map);
    };

    return (
      <StdTopDialog onClose={onClose}>
        <div className={mainClass}>
          <div className={headerClass}>
            <div className={closeBoxClass}>
              <div onClick={onClose} className={closeClass}>
                <CloseIcon />
              </div>
            </div>
            <LogoIcon className={logoIconClass}/>
            <h3 className={titleClass}>{t('title')}</h3>
            <p className={subTitleClass}>{t('subtitle')}</p>
          </div>
          <div className={bodyClass}>
            <CheckBox data={t('check.0')} onChange={() => onChange(0)} checked={checkAll?.has(0)} />
            <CheckBox
              data={t('check.1', {
                terms: (children) => (
                  <a href="/terms-of-services" target="_blank">
                    {children}
                  </a>
                ),
                privacy: (children) => (
                  <a href="/privacy-policy" target="_blank">
                    {children}
                  </a>
                ),
              })}
              onChange={() => onChange(1)}
              checked={checkAll?.has(1)}
            />
            <CheckBox data={t('check.2')} onChange={() => onChange(2)} checked={checkAll?.has(2)} />
            <CheckBox
              className={outlinedTextClass}
              data={t('check.3')}
              onChange={() => onChange(3)}
              checked={checkAll?.has(3)}
            />
          </div>
          <div className={footerClass}>
            <button
              disabled={(checkAll?.size ?? 0) < CheckAmount}
              className={buttonClass}
              onClick={launchSignIn}
            >
              <SteamIcon />
              {t('login')}
            </button>
            <GrayLogoIcon className={grayLogoIconClass}/>
          </div>
        </div>
      </StdTopDialog>
    );
  });
}

type CheckBox = {
  data: string;
  checked?: boolean;
  onChange: () => void;
  className?: string;
};
const CheckBox = ({ data, onChange, checked, className }: CheckBox) => {
  return (
    <div className={checked ? activeTextClass : cx(textClass, className)}>
      <div className={iconBoxClass} onClick={onChange}>
        {checked ? (
          <CheckBoxTrueIcon className={iconClass} />
        ) : (
          <CheckBoxFalseIcon className={iconClass} />
        )}
      </div>
      <span>{data}</span>
    </div>
  );
};

const iconBoxClass = css`
  padding-top: 3px;
`;
const outlinedTextClass = css`
  -webkit-text-stroke: 0.3px black;
  text-shadow: 0 0 9px #000000;
`;
const footerClass = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  width: 100%;
`;
const iconClass = css`
  height: 18px;
`;
const textClass = css`
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #988c92;
  display: flex;
  gap: 12px;

  a {
    text-decoration: underline;
    color: #dfdfdf;
  }
`;
const activeTextClass = css`
  ${textClass};
  color: #ffaa63;

  a {
    color: #ffaa63;
  }
`;

const mainClass = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #26232d;
  border-radius: 32px;
  gap: 32px;
  width: 496px;
  padding: 24px;
  padding-top: 28px;
  position: relative;
`;
const headerClass = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const closeBoxClass = css`
  position: absolute;
  right: 24px;
  top: 28px;
`;
const bodyClass = css`
  display: flex;
  align-items: start;
  flex-direction: column;
  gap: 24px;
`;

const titleClass = css`
  padding-top: 28px;
  font-family: 'Onest';
  font-size: 18px;
  font-weight: 700;
  line-height: 140%;
  text-align: center;
`;
const subTitleClass = css`
  padding-top: 12px;
  color: #988c92;
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
`;

const buttonClass = css`
  height: 48px;
  border-radius: 4px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border: none;
  color: #2d241c;
  background: linear-gradient(180deg, #ffaa63 0%, #ea9a4e 100%);
  box-shadow: inset 0px 4px 12px 2px #FF7324;
  font-family: 'Onest';
  font-size: 13px;
  font-weight: 700;
  line-height: 140%;
  text-align: center;
  cursor: pointer;
  &:hover {
    background: #ffbe88;
    box-shadow: none;
  }
  svg{
    height: 18px;
  }
`;
const closeClass = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background: #18171c;
  border-radius: 12px;
  cursor: pointer;
  svg{
    width: 32px;
  }
`;
const logoIconClass = css`
  height: 48px;
`;
const grayLogoIconClass = css`
  height: 25px;
`