import Cookie from 'js-cookie';
import { INTL_SUPPORTED_LOCALES } from './supported-locales';

export const COOKIE_LANG_KEY = 'lc';

export default function detectLanguage(ctx?: any): SupportedLocale {
  const isServer = !!ctx;

  function tryLang(lc: string): string | undefined {
    if (INTL_SUPPORTED_LOCALES.includes(lc as any)) return lc;
    lc = lc.slice(0, 2);
    if (INTL_SUPPORTED_LOCALES.includes(lc as any)) return lc;
  }

  const methods: (() => string | undefined)[] = [];

  isServer &&
    methods.push(() => {
      const firstPartOfPAth = ctx.req.url.split('/')[1];

      return tryLang(firstPartOfPAth);
    });
  methods.push(() => Cookie.get(COOKIE_LANG_KEY));
  if (isServer) {
    methods.push(() => {
      const parts = ctx.req.headers['accept-language']?.split(',') ?? [];
      for (const part of parts) {
        const lg = tryLang(part);
        if (lg !== undefined) return lg;
      }
    });
  } else {
    methods.push(
      () => {
        if (!navigator.languages) return;
        for (const lc of navigator.languages) {
          const lg = tryLang(lc);
          if (lg !== undefined) return lg;
        }
      },
      () => {
        if (INTL_SUPPORTED_LOCALES.includes(navigator.language as any)) {
          return tryLang(navigator.language);
        }
      }
    );
  }

  methods.push(() => 'ru');

  let lg;
  for (const method of methods) {
    const result = method();
    if (result) {
      lg = result;
      break;
    }
  }
  Cookie.set(COOKIE_LANG_KEY, lg, { path: '/' });
  return lg;
}
