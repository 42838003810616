import { css, cx } from '@emotion/css';
import { PlayerImage } from 'domain/player/image/image';
import { RippleLink } from 'packs/std/effects/ripple-link';
import { CSSProperties } from 'react';
import { objToMap } from 'support/etc/obj-to-map';

type PlayerLinkedImageProps = {
  player: { id: string; image: string };
  className?: string;
};

export const PlayerLinkedImage = ({
  player: { id, image },
  className,
}: PlayerLinkedImageProps): JsxElement => {
  return (
    <RippleLink className={mainClass} to={id ? `/player/${id}` : '#'}>
      <div className={boxClass}>
        <PlayerImage className={cx(imageClass, className)} code={image} size="medium" />
      </div>
    </RippleLink>
  );
};

const mainClass = css``;

const boxClass = css``;

const imageClass = css``;
