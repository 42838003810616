import { css } from '@emotion/css';
import { completeSkinImageUrl } from 'domain/skin/units/image';
import { UpgradeUnitsWheel, UpgradeUnitsWheelTheme } from 'app/upgrade/units/wheel';
import { useUpgradeState } from 'app/upgrade';
import { skinNameViewOnSkin } from 'domain/skin/name-view';
import { stdBreakpoints } from 'packs/std';

export const UpgradeResultWon = (): JsxElement => {
  const { item } = useUpgradeState().result!;
  const view = skinNameViewOnSkin(item.skin);
  return (
    <div className={mainClass}>
      <UpgradeUnitsWheel theme={UpgradeUnitsWheelTheme.success}>
        <image
          href={completeSkinImageUrl({ code: item.skin.image })}
          x={100}
          y={-70}
          height={800}
          width={800}
        />

        <foreignObject x={200} y={610} width={600} height={100} style={{ textAlign: 'center' }}>
          <span className={skinNameClass}>
            {view.brand}
            {view.model}
          </span>
        </foreignObject>
        <foreignObject x={200} y={685} width={600} height={100} style={{ textAlign: 'center' }}>
          <span className={skinLevelClass}>{view.level?.full}</span>
        </foreignObject>
        <foreignObject x={200} y={765} width={600} height={100} style={{ textAlign: 'center' }}>
          <span className={skinPriceClass}>${item.cost}</span>
        </foreignObject>
      </UpgradeUnitsWheel>
    </div>
  );
};

const mainClass = css`
  align-items: flex-end;
  ${stdBreakpoints.up(1695)} {
    transform: scaleX(0.9);
  }
  ${stdBreakpoints.up(1820)} {
    transform: scaleX(0.85);
  }
  ${stdBreakpoints.up(1920)} {
    transform: scaleX(0.78);
  }
`;

const skinNameClass = css`
  font-weight: 700;
  font-size: 50px;
  line-height: 140%;
  color: #0fac7f;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const skinLevelClass = css`
  font-weight: 700;
  font-size: 45px;
  line-height: 140%;
  color: #0fac7f;
`;

const skinPriceClass = css`
  font-weight: 700;
  font-size: 70px;
  line-height: 130%;
  color: #dfdfdf;
`;
