import { getActor, startupSignIn } from 'domain/actor';
// import { checkUtmParams } from 'domain/promo/utm';
import { DateTime } from 'luxon';
import { getQue, queReplace } from 'packs/libs/que';

import { startupMeta } from 'domain/etc/meta/client';
import { startupUtm } from 'domain/etc/utm';
import { startupPromo } from 'domain/promo';
import { initializeGA } from 'domain/tracking/google-analytics';
import { getOutOfSyncDelta } from 'packs/libs/coil';
import 'packs/libs/coil/tools';
import { toast } from 'react-toastify';
import { addScript } from 'support/html/add-script';

/**
 * This function executes when user enters the page.
 */
export function startup() {
  if (ENV_GRAVITEC_KEY) {
    addScript(`https://cdn.gravitec.net/storage/${ENV_GRAVITEC_KEY}/client.js`);
  }

  initializeGA();
  // checkUtmParams();
  const initialQuery = getQue();
  startupUtm(initialQuery);
  startupSignIn(initialQuery);
  startupMeta();
  startupPromo(initialQuery);
  queReplace({});

  if (__DEBUG__) {
    const el = document.createElement('div');
    el.style.top = '8px';
    el.style.left = '50%';
    el.style.position = 'absolute';
    el.style.zIndex = '999';
    el.style.cursor = 'pointer';
    el.innerText = 'copy debug';
    el.onclick = () => {
      const data = {
        time: DateTime.utc().toSQL({ includeZone: false, includeOffset: false }),
        ms: Date.now(),
        pid: getActor().payload?.id,
        oos: getOutOfSyncDelta(),
      };
      import('js-yaml').then(({ dump }) => {
        navigator.clipboard.writeText(dump(data));
        toast('копід', { position: 'bottom-right', autoClose: 500 });
      });
    };
    document.body.appendChild(el);
  }
}
