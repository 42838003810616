import { css } from '@emotion/css';
import { StdTopDialog } from 'packs/std';
import { appSyringe } from 'app/syringe';
import { CrushProvablyFairLeftDialog } from 'app/crush/provably-fair/componets/left-dialog';
import { CrushProvablyFairRightDialog } from 'app/crush/provably-fair/componets/right-dialog';
import { useCrushT } from '../glossary';
import { ReactComponent as CopyIcon } from 'app/crush/provably-fair/assets/copy.svg';
import { ReactComponent as InfoIcon } from 'app/crush/provably-fair/assets/info.svg';
import { ReactComponent as ArrowIcon } from 'app/crush/provably-fair/assets/black-arrow.svg';
import { showSuccessToast } from 'packs/libs/inf';
import { CrushProvablyFairBetFrame } from 'app/crush/provably-fair/componets/bet-frame';
import { openCrushProvablyFairHowItWorkDialog } from './how-it-work-dialog';
import { CrushRoundRecord } from '../store';
import { format } from 'date-fns';

type OpenCrushProvablyFairRoundCheckDialogProps = {
  roundCrash: CrushRoundRecord;
};

export const openCrushProvablyFairRoundCheckDialog = ({
  roundCrash,
}: OpenCrushProvablyFairRoundCheckDialogProps) => {
  appSyringe(function CrushProvablyFairHowItWorkDialog({ onClose }) {
    const t = useCrushT().sub('how-it-work');
    const { id, x, hash, time, salt, index, config } = roundCrash;

    return (
      <StdTopDialog onClose={onClose}>
        <div className={mainClass}>
          <CrushProvablyFairLeftDialog onClose={onClose} title={t('title')} id={id}>
            <div className={listClass}>
              <div className={listItemBoxClass}>
                <div className={listItemClass}>
                  <p className={titleClass}>{t('cef')}</p>
                  <CrushProvablyFairBetFrame x={x} />
                </div>
              </div>
              <ListItem title={t('hash')} value={hash} />
              <ListItem title={t('salt')} value={salt} />
              <ListItem title={t('microtime')} value={time} />
              <ListItem title={t('index')} value={index} />
              <ListItem title={t('date')} value={format(new Date(time), 'dd.MM.yyyy')} />
            </div>
          </CrushProvablyFairLeftDialog>
          <CrushProvablyFairRightDialog
            title={t('title-right')}
            className={rightClass}
            titleClassName={titleRightClass}
          >
            <div className={rightBoxClass}>
              <p className={rightTextClass}>
                {t('about-text')}{' '}
                <a
                  className={linkClass}
                  href="https://emn178.github.io/online-tools/sha256.html"
                  target="_blank"
                >
                  {t('generator-hash')}
                </a>
              </p>
              <p className={rightTextClass}>{t('check')}</p>
              <div className={footerClass}>
                <div className={footerBoxClass}>
                  <div>
                    <InfoIcon className={iconClass} />
                  </div>
                  <p className={footerTextClass}>{t('info')}</p>
                </div>
                <button
                  className={buttonClass}
                  onClick={() => {
                    onClose();
                    openCrushProvablyFairHowItWorkDialog();
                  }}
                >
                  {t('button')}
                  <ArrowIcon />
                </button>
              </div>
            </div>
          </CrushProvablyFairRightDialog>
        </div>
      </StdTopDialog>
    );
  });
};

type ListItemProps = {
  title: string;
  value: string | number;
};

const ListItem = ({ title, value }: ListItemProps): JsxElement => {
  const t = useCrushT().sub('how-it-work');

  const copyToClipboard = async () => {
    await navigator.clipboard.writeText(value.toString());
    showSuccessToast(t('copied'));
  };

  return (
    <div className={itemBoxClass}>
      <div className={itemContentClass}>
        <div className={titleBoxClass}>
          <p className={titleClass}>{title}</p>
          <CopyIcon className={iconBoxClass} onClick={copyToClipboard} />
        </div>
        <p className={nameClass}>{value}</p>
      </div>
    </div>
  );
};

const mainClass = css`
  display: flex;
  gap: 18px;
`;

const listClass = css`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 0 8px;
`;

const listItemBoxClass = css`
  background: #18171c;
  border-radius: 12px;
  width: 421px;
  height: 64px;
  display: flex;
  align-items: center;
`;

const listItemClass = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  width: 100%;
`;

const titleClass = css`
  font-family: 'Commissioner';
  font-weight: 600;
  font-size: 12px;
  line-height: 130%;
  color: #988c92;
`;

const itemBoxClass = css`
  background: #18171c;
  border-radius: 12px;
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 421px;
`;

const itemContentClass = css`
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 18px 21px 20px 24px;
`;

const titleBoxClass = css`
  display: flex;
  gap: 4px;
  align-items: center;
`;

const nameClass = css`
  font-family: 'Commissioner';
  font-weight: 600;
  font-size: 12px;
  line-height: 130%;
  color: #dfdfdf;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 380px;
`;

const titleRightClass = css`
  font-family: 'Onest';
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #ffffff;
  padding-bottom: 16px;
`;

const rightBoxClass = css`
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding-right: 10px;
`;

const rightTextClass = css`
  font-family: 'Onest';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #988c92;
`;

const linkClass = css`
  background: linear-gradient(180deg, #ffaa63 0%, #ffaa63 0.01%, #ea9a4e 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  border-bottom: 1px solid rgba(234, 154, 78, 0.15);
  padding-bottom: 2px;
`;

const iconBoxClass = css`
  cursor: pointer;

  &:active {
    path {
      fill: url(#GradientColor);
    }
  }
`;

const footerClass = css`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const footerBoxClass = css`
  display: flex;
  gap: 11px;
`;

const iconClass = css`
  width: 24px;
  height: 24px;
`;

const footerTextClass = css`
  font-family: 'Commissioner';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  color: #988c92;
`;

const rightClass = css`
  max-width: 295px;
  min-width: 295px;
  max-height: 482px;
`;

const buttonClass = css`
  background: linear-gradient(180deg, #ffaa63 0%, #ea9a4e 100%);
  box-shadow: inset 0px 4px 12px 2px #ff7324;
  border-radius: 4px;
  font-family: 'Onest';
  font-style: normal;
  width: 224px;
  height: 38px;
  font-weight: 700;
  font-size: 13px;
  line-height: 140%;
  display: flex;
  align-items: center;
  text-align: center;
  gap: 4px;
  color: #2d241c;
  border: none;
  padding: 0 16px;
  cursor: pointer;

  &:hover:not(:disabled) {
    background: #ffbe88;
  }
`;
