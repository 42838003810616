import { css } from '@emotion/css';
import { checkCanActorModerateChat } from 'app/chat/definitions';
import { ChatHistory } from 'app/chat/history';
import { ChatMessage } from 'app/chat/message';
import { ChatSend } from 'app/chat/send';
import { useChatState } from 'app/chat/store';
import { useActor } from 'domain/actor';
import { stdLoadable } from 'packs/std/loadable';

const ModMessage = stdLoadable(() => import('app/chat/mod/message'));

export const ChatBody = (): JsxElement => {
  const { collapsed } = useChatState();
  const actor = useActor();

  if (collapsed) return null;

  const Message = checkCanActorModerateChat(actor) ? ModMessage : ChatMessage;

  return (
    <div className={boxClass}>
      <ChatHistory Message={Message} />
      <div className={chatClass}>
        <ChatSend />
      </div>
    </div>
  );
};

const boxClass = css`
  background: #1c1b20;
  margin-top: 2px;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 279px;
`;

const chatClass = css`
  width: 100%;
`;
