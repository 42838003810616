import { css } from '@emotion/css';
import { CrushSceneChickAnimationBlock } from 'app/crush/scene/chick/animation/block';
import { GraphChicken } from "app/crush/scene/chick/block";

export const CrushSceneChick = () => {
  return (
    <div className={mainClass}>
      <GraphChicken />
    </div>
  );
};

const mainClass = css`
  position: absolute;
  left: 0;
  bottom: 10px;
  right: 0;
  display: flex;
  justify-content: center;
`;
