import { execIfFn } from 'support/etc/exec-if-fn';
import { useForceRefresh } from 'support/react/use-force-refresh';
import { useSingleton } from 'support/react/use-singleton';

/**
 * Basic Store represents fundamental use of states
 * it consists of [State, Operator] where:
 * State - immutable data that triggers rerender
 * Operator - some operations towards the state.
 *
 * Operator never changes from its creation
 */

type OperatorFactory<State, Operator> = (
  setState: (state: State) => void,
  getState: () => State
) => Operator;

export function useBasicStore<State, Operator>(
  init: () => [State, OperatorFactory<State, Operator>]
) {
  const refresh = useForceRefresh();
  type Store = { value: [State, Operator] };
  return useSingleton<Store>(() => {
    const getState = () => store.value[0];
    const setState = (state) => {
      if (state !== getState()) {
        store.value = [state, operator];
        refresh();
      }
    };

    const [state, factory] = init() as any;
    const operator = factory(setState, getState);

    const store: Store = { value: [state, operator] };

    return store;
  }).value;
}
