import { css } from '@emotion/css';
import { useSingleton } from 'support/react/use-singleton';
import { UpgradeWheelOperator } from 'app/upgrade/units/wheel/wheel';
import { UPGRADE_WHEEL_CUBIC_BEZIER_TUPLE } from 'app/upgrade/units/wheel/definitions';
import { stash_get } from 'support/etc/stash';

const ARROW_ID = 'uw-arrow';
const TAIL_ID = 'uw-tail';

const SPIN_DELAY = 600;

export class UpgradeWheelSpinOperator {
  private declare enabled?: boolean;

  constructor(private readonly main: UpgradeWheelOperator) {}

  private enable() {
    this.arrowEl.setAttribute('display', 'inherit');
    this.tailEl.setAttribute('display', 'inherit');
    this.enabled = true;
  }

  spin(turns: number, duration: number) {
    this.enable();
    return new Promise((resolve, reject) => {
      try {
        const options: KeyframeAnimationOptions = {
          duration: duration - SPIN_DELAY,
          easing: `cubic-bezier(${UPGRADE_WHEEL_CUBIC_BEZIER_TUPLE.join(', ')})`,
          fill: 'forwards',
          endDelay: SPIN_DELAY,
        };

        const anima = this.arrowEl.animate(
          {
            transform: `rotate(${turns}turn)`,
          },
          options
        );

        this.tailEl.animate(
          {
            transform: `rotate(${turns}turn)`,
          },
          options
        );

        anima.addEventListener('cancel', reject);
        anima.addEventListener('finish', resolve);
      } catch (e) {
        reject(e);
      }
    });
  }

  @stash_get
  private get tailEl() {
    // https://codepen.io/daleyjem/pen/OyLepO
    return this.main.findById<SVGCircleElement>(TAIL_ID);
  }

  @stash_get
  private get arrowEl() {
    return this.main.findById<SVGPathElement>(ARROW_ID);
  }
}

type UpgradeWheelSpinProps = {
  streak?: boolean;
};

export const UpgradeUnitsWheelSpin = (props: UpgradeWheelSpinProps): JsxElement => {
  return useSingleton(() => {
    const arrow = (
      <g transform="translate(490, 80)">
        <g
          filter="url(#filter0_d_3602_17714)"
          id={ARROW_ID}
          display="none"
          style={{ transformOrigin: '10px 420px' }}
        >
          <path
            d="M8.51672 424.364C6.36422 423.33 5 421.185 5 418.835L5 24.2972C5 13.6396 13.8142 5.00001 24.6871 5.00001V5.00001C36.0754 5.00001 45.0881 14.4429 44.3305 25.581L17.553 419.244C17.2557 423.615 12.5293 426.293 8.51672 424.364V424.364Z"
            fill="url(#paint0_linear_3602_17714)"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_3602_17714"
            x="0"
            y="0"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="2.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.988235 0 0 0 0 0.596078 0 0 0 0 0.203922 0 0 0 0.5 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_3602_17714"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_3602_17714"
              result="shape"
            />
          </filter>
          <linearGradient
            id="paint0_linear_3602_17714"
            x1="5"
            y1="215"
            x2="44.375"
            y2="215"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFAA63" />
            <stop offset="0.0001" stopColor="#FFAA63" />
            <stop offset="1" stopColor="#EA9A4E" />
          </linearGradient>
        </defs>
      </g>
    );

    const arrowTail = (
      <foreignObject
        display="none"
        id={TAIL_ID}
        width="1000"
        height="1000"
        clipPath="url(#uw_arrow_tail_clip)"
        style={{ transformOrigin: '500px 500px' }}
      >
        <div className={arrowTailClass} />
      </foreignObject>
    );

    return (
      <>
        {arrow}
        {arrowTail}
        <clipPath id="uw_arrow_tail_clip">
          <circle cx={500} cy={500} r={420} />
        </clipPath>
      </>
    );
  });
};

const arrowTailClass = css`
  width: 100%;
  height: 100%;
  background: conic-gradient(transparent, rgba(234, 154, 78, 0.5));
  opacity: 0.3;
`;
