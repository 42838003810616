import { css } from '@emotion/css';
import { PlayerRequirementData } from 'domain/player/requirements/definitions';
import { PlayerRequirementsGlossary } from 'domain/player/requirements/glossary';
import { memoize } from 'lodash';
import { useIntl } from 'packs/libs/intl';
import { useMemo } from 'react';
import { stashCb } from 'support/etc/stash';

const DAY_IN_MILLISECONDS = 24 * 60 * 60 * 1000;

const getElements = stashCb(() => {
  const common = {
    b: (children) => <span className={accentClass}>{children}</span>,
    c: (children) => <span>{Math.floor(+children / DAY_IN_MILLISECONDS)}</span>,
  };
  const rules = {
    nickname: {
      a: (children) => (
        <a className={linkClass} target="_blank" href="https://steamcommunity.com/id/me/edit/info">
          {children}
        </a>
      ),
    },
  };

  return memoize((rule: string) => {
    return { ...common, ...(rules[rule] ?? {}) };
  });
});

type PlayerRequirementLineProps = {
  data: PlayerRequirementData;
};

export function PlayerRequirementLine(props: PlayerRequirementLineProps) {
  const t = useIntl().get(PlayerRequirementsGlossary);

  return useMemo(() => {
    const [rule, data] = props.data;

    const options = Object.create(getElements(rule));
    if (typeof data === 'object') Object.assign(options, data);
    else options.opt = data;

    return <p className={mainClass}>{t(`rules.${rule}`, options)}</p>;
  }, [t]);
}

const mainClass = css`
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  color: #988c92;

  @media (min-width: 1024px) and (max-width: 1100px) {
    margin-left: 0;
  }
`;

const accentClass = css`
  color: #fff;
`;

const linkClass = css`
  color: #ffaa63;
`;
