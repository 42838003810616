import { css, cx } from '@emotion/css';
import { useState } from 'react';

import { LayoutHeaderAccountPanelMenuPopup } from 'app/layout/header/account-panel/menu-popup';
import { ReactComponent as VectorIcon } from 'app/layout/header/assets/vector-icon.svg';
import { ActorOperator } from 'domain/actor';
import { PlayerImage } from 'domain/player/image/image';
import { SimplePopover } from 'packs/single/simple-popover';

type LayoutHeaderAccountPanelPlayerAccountProps = {
  actor: ActorOperator;
};

export const LayoutHeaderAccountPanelPlayerAccount = ({
  actor,
}: LayoutHeaderAccountPanelPlayerAccountProps): JsxElement => {
  const [open, setOpen] = useState<boolean>(false);

  const player = actor.getPayload();

  return (
    <div className={userBoxClass} onClick={() => setOpen(!open)}>
      <SimplePopover
        open={open}
        onClose={() => setOpen(false)}
        className={userClass}
        popup={<LayoutHeaderAccountPanelMenuPopup />}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <PlayerImage code={player.image} className={imgClass} size="medium" />
        <VectorIcon className={cx(open && vectorIconClass)} />
      </SimplePopover>
    </div>
  );
};

const vectorIconClass = css`
  path {
    fill: url(#paint0_linear_218_2644);
  }
`;

const userBoxClass = css`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    width: 10px;
  }

  &:hover {
    path {
      fill: url(#paint0_linear_218_2644);
    }
  }
`;
const userClass = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const imgClass = css`
  background: #000;
  margin-right: 7px;
  height: 38px;

  border-radius: 4px;
`;
