import { css } from '@emotion/css';
import { ReactComponent as BillSvg } from 'app/crush/control/assets/bill.svg';
import { ReactComponent as RateSvg } from 'app/crush/control/assets/rate.svg';
import { CrushControlStatsBlock } from 'app/crush/control/units/stat-block';
import { useCrushT } from 'app/crush/glossary';
import { CrushStoreRound, useCrushScopeRound } from 'app/crush/store';
import { GrowNum } from 'domain/crush/growth';
import { useMemo } from 'react';
import { createRefEffect } from 'support/react/ref-effect';
import { useSingleton } from 'support/react/use-singleton';

export const CrushControlPlayingRoundStats = (): JsxElement => {
  const t = useCrushT().sub('grab.control');
  const round = useCrushScopeRound();

  const refs = useSingleton(() => {
    const { growth } = round;
    if (!growth) throw Error('????');

    const prize = createRefEffect((el) => {
      const totalStake = round.stake!.total;
      const update = (x: GrowNum) => {
        el.innerText = x.mul(totalStake).toFixed(2);
      };
      update(growth.getX());
      return growth.subGrow(update);
    });

    const x = createRefEffect((el) => {
      const update = (x: GrowNum) => {
        el.innerText = `x${x.dp2.toFixed(2)}`;
      };
      update(growth.getX());
      return growth.subGrow(update);
    });

    return { prize, x };
  });

  return useMemo(() => {
    return (
      <div className={mainClass}>
        <CrushControlStatsBlock
          label={t('bet')}
          value={
            <>
              $<span ref={refs.prize} />
            </>
          }
          icon={<BillSvg className={iconSelectClass} />}
        />
        <CrushControlStatsBlock
          icon={<RateSvg className={iconSelectClass} />}
          label={t('rate')}
          value={<span ref={refs.x} className={getXClass(round)} />}
        />
      </div>
    );
  }, [round.won, round.resolved]);
};

const getXClass = (round: CrushStoreRound) => {
  if (round.won) return winClass;
  if (round.resolved) return crashedClass;
};

const crashedClass = css`
  color: #ff5a5a;
  text-shadow: 0px 4px 18px rgba(255, 90, 90, 0.6);
`;
const winClass = css`
  color: #14bc8c;
  text-shadow: 0px 4px 18px rgba(90, 255, 186, 0.6);
`;

const mainClass = css`
  display: flex;
  justify-content: space-between;
  padding: 18px 24px;
  gap: 8px;
  height: 107px;
`;

const iconSelectClass = css`
  width: 26px;
  height: 26px;
`;
