import { css, cx } from '@emotion/css';
import { ComponentType, ReactNode, useState } from 'react';

import { useLayoutT } from 'app/layout/glossary';
import { IconProps } from 'app/layout/header/nav-panel/nav-panel';
import { Link, matchPath, useLocation } from 'react-router-dom';
import { CompetitionsIcon } from './assets/competitions-icon';
import { CrashIcon } from './assets/crash-icon';
import { UpgradeIcon } from './assets/upgrade-icon';
import { CaseBattlesIcon } from './assets/case-battles-icon';

type Route = {
  to?: string;
  code: string;
  Icon: ComponentType<IconProps>;
  disabled?: boolean;
};
const ROUTES: Route[] = [
  { code: 'crush', to: '', Icon: CrashIcon },
  { code: 'upgrade', Icon: UpgradeIcon },
  { code: 'contests', Icon: CompetitionsIcon },
  { code: 'case-battles', Icon: CaseBattlesIcon },
];

export const LayoutSidebarNavPanel = (): JsxElement => {
  const { pathname } = useLocation();
  const t = useLayoutT().sub('side');

  return (
    <div className={sideNavClass}>
      {...ROUTES.map(({ code, to, Icon, disabled }) => {
        to ??= `/${code}`;
        const current = !!matchPath(to, pathname);
        const includesPathname = pathname.includes(code);
        return (
          <Option
            to={to}
            disabled={disabled!}
            active={current || includesPathname}
            title={t(code)}
            Icon={Icon}
          />
        );
      })}
    </div>
  );
};

type OptionProps = {
  active: boolean;
  to: string;
  title: ReactNode;
  Icon: any;
  disabled: boolean;
};

const Option = ({ to, title, active, Icon, disabled }: OptionProps): JsxElement => {
  const [hover, setHover] = useState<boolean>(false);

  return (
    <div className={cx(optionBoxClass, disabled && optionDisabledBoxClass)}>
      <Link
        to={disabled ? '#' : to}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <Icon active={!disabled && hover} current={active} />
      </Link>
      {hover && !active && !disabled && <p className={textClass}>{title}</p>}
    </div>
  );
};

const optionBoxClass = css`
  position: relative;
  cursor: pointer;
  width: 36px;
`;
const optionDisabledBoxClass = css`
  ${optionBoxClass}
  &:hover::before {
    opacity: 1;
  }
  &::before {
    content: 'COMING SOON';
    text-align: center;
    position: absolute;
    color: #988c92;
    width: 65px;
    height: 10px;
    font-size: 7px;
    top: 100%;
    left: 52%;
    transform: translate(-50%, -50%);
    opacity: 0;
  }
`;
const textClass = css`
  position: absolute;
  white-space: nowrap;
  left: 130%;
  z-index: 1;
  bottom: 0;
  background: #18171c;
  height: 36px;
  display: flex;
  align-items: center;
  padding: 0 9px;
  border-radius: 4px;
  font-size: 12px;
  &:before {
    content: '';
    position: absolute;
    right: 100%;
    border-top: 7px solid transparent;
    border-right: 7px solid #18171c;
    border-bottom: 7px solid transparent;
  }
`;

const sideNavClass = css`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
