export const CaseBattlesIcon = ({ active, current }): JsxElement => {
  return !active && !current ? (
    <svg viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.0501 19.406L16.5841 22.942L15.1711 24.356L16.5861 25.771L15.1721 27.185L12.6971 24.71L9.8681 27.539L8.4541 26.125L11.2831 23.295L8.8081 20.821L10.2221 19.407L11.6361 20.82L13.0501 19.406ZM9.0001 9L12.5461 9.003L24.3631 20.821L25.7781 19.407L27.1921 20.821L24.7181 23.296L27.5461 26.125L26.1321 27.539L23.3031 24.71L20.8281 27.185L19.4141 25.771L20.8281 24.356L9.0031 12.531L9.0001 9ZM23.4571 9L27.0001 9.003L27.0021 12.526L22.9491 16.578L19.4131 13.043L23.4571 9Z"
        fill="#988C92"
      />
    </svg>
  ) : current ? (
    <svg viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_i_2996_15402)">
        <rect width="36" height="36" rx="9" fill="url(#paint0_linear_2996_15402)" />
      </g>
      <rect
        x="0.75"
        y="0.75"
        width="34.5"
        height="34.5"
        rx="8.25"
        stroke="url(#paint1_linear_2996_15402)"
        strokeWidth="1.5"
      />
      <path
        d="M13.0501 19.406L16.5841 22.942L15.1711 24.356L16.5861 25.771L15.1721 27.185L12.6971 24.71L9.8681 27.539L8.4541 26.125L11.2831 23.295L8.8081 20.821L10.2221 19.407L11.6361 20.82L13.0501 19.406ZM9.0001 9L12.5461 9.003L24.3631 20.821L25.7781 19.407L27.1921 20.821L24.7181 23.296L27.5461 26.125L26.1321 27.539L23.3031 24.71L20.8281 27.185L19.4141 25.771L20.8281 24.356L9.0031 12.531L9.0001 9ZM23.4571 9L27.0001 9.003L27.0021 12.526L22.9491 16.578L19.4131 13.043L23.4571 9Z"
        fill="#2D241C"
      />
      <defs>
        <filter
          id="filter0_i_2996_15402"
          x="0"
          y="0"
          width="36"
          height="39"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="1.5"
            operator="erode"
            in="SourceAlpha"
            result="effect1_innerShadow_2996_15402"
          />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="4.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 0.450667 0 0 0 0 0.141667 0 0 0 1 0"
          />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2996_15402" />
        </filter>
        <linearGradient
          id="paint0_linear_2996_15402"
          x1="18"
          y1="0"
          x2="18"
          y2="36"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFAA63" />
          <stop offset="0.0001" stopColor="#FFAA63" />
          <stop offset="1" stopColor="#EA9A4E" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2996_15402"
          x1="18"
          y1="0"
          x2="18"
          y2="36"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9457" />
          <stop offset="1" stopColor="#FFBA69" />
        </linearGradient>
      </defs>
    </svg>
  ) : (
    <svg viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.0501 19.406L16.5841 22.942L15.1711 24.356L16.5861 25.771L15.1721 27.185L12.6971 24.71L9.8681 27.539L8.4541 26.125L11.2831 23.295L8.8081 20.821L10.2221 19.407L11.6361 20.82L13.0501 19.406ZM9.0001 9L12.5461 9.003L24.3631 20.821L25.7781 19.407L27.1921 20.821L24.7181 23.296L27.5461 26.125L26.1321 27.539L23.3031 24.71L20.8281 27.185L19.4141 25.771L20.8281 24.356L9.0031 12.531L9.0001 9ZM23.4571 9L27.0001 9.003L27.0021 12.526L22.9491 16.578L19.4131 13.043L23.4571 9Z"
        fill="url(#paint0_linear_101_40)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_101_40"
          x1="18.0001"
          y1="9"
          x2="18.0001"
          y2="27.539"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFAA63" />
          <stop offset="0.0001" stopColor="#FFAA63" />
          <stop offset="1" stopColor="#EA9A4E" />
        </linearGradient>
      </defs>
    </svg>
  );
};
