import { css } from '@emotion/css';
import { CaseBattlePlayerRole, CaseBattleStage } from 'app/case/battle/definitions';
import { ReactComponent as EnterIcon } from 'app/case/battle/insight/assets/enter-icon.svg';
import { useCaseBattleInsightT } from 'app/case/battle/insight/glossary';
import { CaseBattleInsightEnterBattleSound } from 'app/case/battle/insight/sounds';
import { useCaseBattleInsightBaseState } from 'app/case/battle/insight/store';
import { coilReq } from 'packs/libs/coil';
import { StdButton } from 'packs/std';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import sleep from 'support/etc/sleep';
import { ReactComponent as ContestBadgeIcon } from 'app/case/battle/insight/assets/contest-badge.svg';

export const CaseBattleInsightActionBlock = (): JsxElement => {
  return (
    <div className={mainClass}>
      <div className={contestBoxClass}>
        <ContestBlock />
      </div>
      <ActionButton />
    </div>
  );
};

export const ContestBlock = (): JsxElement => {
  const state = useCaseBattleInsightBaseState();

  if (!state.contestId) return null;

  return (
    <Link to={`/contests/${state.contestId}`}>
      <ContestBadgeIcon />
    </Link>
  );
};

const ActionButton = (): JsxElement => {
  const [processing, setProcessing] = useState<boolean>(false);
  const state = useCaseBattleInsightBaseState();
  const t = useCaseBattleInsightT();
  if (state.stage !== CaseBattleStage.waiting) return null;

  if (state.role === CaseBattlePlayerRole.observer) {
    return (
      <>
        <StdButton
          className={buttonClass}
          onClick={async () => {
            await coilReq({
              action: 'case.battle.join',
              data: { battleId: state.id },
            });
            CaseBattleInsightEnterBattleSound.replay();
          }}
        >
          <EnterIcon className={enterIconClass} />
          {t('enter', { cash: state.cost.toFixed(2) })}
        </StdButton>
      </>
    );
  }

  if (state.role === CaseBattlePlayerRole.author) {
    return (
      <>
        <StdButton
          className={buttonClass}
          disabled={processing}
          onClick={async () => {
            setProcessing(true);
            try {
              await coilReq({
                action: 'case.battle.start',
                data: { battleId: state.id, addBots: true },
              });
            } finally {
              await sleep(1000);
              setProcessing(false);
            }
          }}
        >
          {t('start-with-bots')}
        </StdButton>
      </>
    );
  }

  return null;
};

const enterIconClass = css`
  height: 18px;
`;
const buttonClass = css`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 63px;
  gap: 5px;
  background: linear-gradient(180deg, #ffaa63 0%, #ffaa63 0.01%, #ea9a4e 100%);
  border-radius: 4px;
  font-weight: 700;
  font-size: 13px;
  line-height: 140%;
  color: #18171c;

  &:hover {
    background: #ffbe88;
  }
`;
const mainClass = css`
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, #1f1d23 0%, rgba(30, 28, 34, 0.6) 100%);
  padding: 11px 0 12px 0;
  gap: 6px;
  position: relative;
`;

const contestBoxClass = css`
  position: absolute;
  left: 0;
  top: 0;
  padding: 12px 0 0 12px;
`;
