import { css, cx } from '@emotion/css';
import { ContestCardUponIconList } from 'app/contest/card/units/upon-icon';
import { ContestBriefData } from 'domain/contest';
import { ContestImg } from 'domain/contest/units/image';
import { useIntl } from 'packs/libs/intl';
import { ReactNode } from 'react';

type ContestCardMainBoxProps = {
  data: ContestBriefData;
  children: ReactNode;
  className?: string;
};

export const ContestCardMainBox = ({
  data,
  children,
  className,
}: ContestCardMainBoxProps): JsxElement => {
  const t = useIntl();

  return (
    <div className={cx(mainClass, className)}>
      <div className={nameClass}>{t.fork(data.title)}</div>
      <div className={uponListBoxClass}>
        <ContestCardUponIconList upon={data.upon} />
      </div>
      <ContestImg code={data.id} className={contestImageClass} />
      {children}
    </div>
  );
};

const uponListBoxClass = css`
  position: absolute;
  top: 48px;
  right: 18px;
`;

const mainClass = css`
  position: relative;
  width: 310px;
  border-radius: 12px;
  padding: 0 17px 19px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 18px;
`;

const nameClass = css`
  min-width: 81px;
  max-width: 95%;
  height: 29px;
  background: #2a2930;
  box-shadow: inset 0px -4px 12px rgba(152, 140, 146, 0.3);
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  font-family: 'Onest';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 140%;
  padding: 5px 12px 6px;
  color: #dfdfdf;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
`;

const contestImageClass = css`
  width: 147px;
  height: 147px;
`;
