import { createSoundGroup } from 'packs/libs/sound';
import { CrushOnActorWon, CrushOnAfterCrash, CrushOnAfterNewRound } from '../store';

import ROUND_BIG_WIN_URL from 'app/crush/assets/sounds/big-win.mp3';
import ROUND_CRASH_MAX_CEF_URL from 'app/crush/assets/sounds/round-crash-max-cef.mp3';
import ROUND_CRASH_URL from 'app/crush/assets/sounds/round-crash.mp3';
import ROUND_START_URL from 'app/crush/assets/sounds/round-start.mp3';

export const CrushSoundGroup = createSoundGroup();
const createSound = (url: string) => CrushSoundGroup.createSound(url);
const RoundStartedSound = createSound(ROUND_START_URL);
const RoundCrashedSound = createSound(ROUND_CRASH_URL);
const RoundCrashedMaxCefSound = createSound(ROUND_CRASH_MAX_CEF_URL);
const BigWinSound = createSound(ROUND_BIG_WIN_URL);

CrushOnAfterNewRound.add((op) => {
  if (op.isCurrent()) {
    RoundStartedSound.replay();
  }
});

CrushOnAfterCrash.add((op) => {
  if (op.isCurrent()) {
    const state = op.getState().round;
    if (state.opts!.grow.max === state.x) {
      RoundCrashedMaxCefSound.replay();
    } else {
      RoundCrashedSound.replay();
    }
  }
});

CrushOnActorWon.add((op) => {
  if (op.getState().round.won!.x >= 10) {
    BigWinSound.replay();
  }
});
