import { useCrushT } from 'app/crush/glossary';
import {
  CrushOnActorBetAccepted,
  getCrushOperator,
  getCrushScopeOperator,
  useCrushScopeRound,
} from 'app/crush/store';
import { getInventoryOperator, useInventoryState } from 'app/inventory';
import { CrushMode, CrushModeCode, CrushRoundStage } from 'domain/crush';
import { coilReq } from 'packs/libs/coil';
import { showInfSuccess } from 'packs/libs/inf';
import { createContext, memo, useContext, useMemo } from 'react';
import { round2DP } from 'support/etc/round-decimal-places';
import { Swiss } from 'support/react/swiss';
import { useForceRefresh } from 'support/react/use-force-refresh';
import { useSingleton } from 'support/react/use-singleton';
import { HashMap } from 'support/struct/hash-map';
import { IHashMap } from 'support/struct/i-hash-map';

export type InternalState = {
  presets: number[];
  value: string;
  invalid?: boolean;
  processing?: boolean;
  inputVal?: string;
  canCommitBet?: boolean;
  historyInput?: string;
  autoStopEnabled: boolean;
  mode: CrushMode;
};

export type XPanelState = InternalState & {
  autoStopActive: boolean;
  canCommitBet: boolean;
  locked: boolean;
};

type XPanelOperator = {
  // setState: (state: XPanelState) => void;
  // updState: (state: Partial<XPanelState>) => void;
  commitBet(): void;
  toggleAutoStop(): void;
  onValueChange(value: string): void;
  setPreset(value: number): void;
  getMinValue(): number;
  getMaxValue(): number;
};

export type XPanelModeStore = [[InternalState], XPanelOperator];

export const XPanelContext = createContext<[XPanelState, XPanelOperator]>(null!);

type XPanelStoreType = {
  children: React.ReactNode;
};

export enum XPanelPresetType {
  std,
  cut,
}
type Presets = IHashMap<XPanelPresetType, number[]>;

export const X_MIN = 1.1;
export const X_MIN_EXTREME = 4; // TODO: Probably should be taken from the game config

const PRESETS = new Map([
  [
    CrushMode.normal,
    IHashMap.fromEntries([
      [XPanelPresetType.std, [1.2, 1.5, 2, 3, 4, 5]],
      [XPanelPresetType.cut, [1.5, 3, 5]],
    ]),
  ],
  [
    CrushMode.extreme,
    IHashMap.fromEntries([
      [XPanelPresetType.std, [X_MIN_EXTREME, 5, 10, 15, 20]],
      [XPanelPresetType.cut, [X_MIN_EXTREME, 5, 10]],
    ]),
  ],
]);

export const XPanelProvider = memo<XPanelStoreType>(({ children }) => {
  const t = useCrushT().sub('grab');
  const commit = useForceRefresh();
  const modes = useSingleton(() => {
    const store = new HashMap<CrushMode, XPanelModeStore>();

    Object.values(CrushMode).forEach((mode) => {
      let _state: [InternalState] = [
        {
          autoStopEnabled: false,
          presets: PRESETS.get(mode)!.get(XPanelPresetType.std),
          value: '',
          mode,
        },
      ];

      const setState = (state: XPanelState) => {
        _state[0] = state;
        commit();
      };
      const getState = () => _state[0];
      const { updState } = new Swiss(setState, getState);

      const setProcessing = (processing: boolean) => updState({ processing });
      const commitBet: any = async () => {
        try {
          setProcessing(true);
          const items = getInventoryOperator().getSelectedItems();
          const ids = items.map((i) => i.id);
          const data: Rsa = { items: ids, mode: CrushModeCode[mode] };
          const state = getState();
          if (state.autoStopEnabled) {
            data.auto = parseFloat(state.value!);
          }
          await coilReq({
            action: 'crush.bet',
            data,
          });
          showInfSuccess(t('toast'));
          const scopeOp = getCrushScopeOperator();
          const total = round2DP(items.reduce((acc, i) => acc + i.cost, 0));
          scopeOp!.updRound({ stake: { total, size: items.length } });
          getInventoryOperator().excludeItems(ids);
          CrushOnActorBetAccepted.emit(scopeOp!);
        } finally {
          setProcessing(false);
        }
      };

      const toggleAutoStop = () => {
        updState({ autoStopEnabled: !getState().autoStopEnabled });
      };

      const isInvalid = (val: string) => {
        const x = parseFloat(val);
        if (val === '') return false;
        if (isNaN(x)) return true;
        if (x < getMinValue()) return true;
        if (x > getMaxValue()) return true;
        return false;
      };

      const MIN_VALUE = mode === CrushMode.normal ? X_MIN : X_MIN_EXTREME;
      const getMinValue = () => MIN_VALUE;
      const getMaxValue = () =>
        getCrushOperator().scope(mode).getState().round.opts?.grow.max ?? 999;

      const setValue = (value: string, invalid: boolean) => {
        const autoStopEnabled = value === '' ? false : !invalid;
        updState({ value, invalid, autoStopEnabled });
      };

      const setPreset = (value: number) => {
        console.log('setPreset', value, mode);
        setValue(String(value), false);
      };

      const onValueChange = (value: string) => {
        setValue(value, isInvalid(value));
      };

      const operator = {
        commitBet,
        toggleAutoStop,
        onValueChange,
        setPreset,
        getMinValue,
        getMaxValue,
      };

      store.set(mode, [_state, operator] as XPanelModeStore);
    });

    return store;
  });

  const { mode, isPlaying, stage } = useCrushScopeRound();
  const [[state], operator] = modes.find(mode)!;

  const { processing, invalid } = state;

  const { selectedTotal } = useInventoryState();

  const canCommitBet =
    stage === CrushRoundStage.countdown && // user can only bet during countdown stage
    !processing && // bet is currently processing on the server side
    !isPlaying &&
    !invalid &&
    selectedTotal !== 0; // has selected items in the inventory

  const value = useMemo((): any => {
    return [
      {
        ...state,
        canCommitBet,
        autoStopActive: state.autoStopEnabled && !state.invalid,
        locked: isPlaying,
      },
      operator,
    ];
  }, [state, canCommitBet, isPlaying]);

  return <XPanelContext.Provider value={value}>{children}</XPanelContext.Provider>;
});

export const useXPanelState = () => useContext(XPanelContext);
