import { css, cx } from '@emotion/css';
import { useContestT } from 'app/contest/glossary';
import { openContestInsightGameHistoryDialog } from 'app/contest/insight//game-history/dialog';
import { ReactComponent as ButtonIcon } from 'app/contest/insight/assets/button-icon.svg';
import {
  CONTEST_UPCOMING_STATUSES,
  ContestData,
  ContestTopListItem
} from 'domain/contest';
import { PlayerImage } from 'domain/player/image/image';
import { IntlSub } from 'packs/libs/intl/operator';
import { stdBp } from 'packs/std';

type ContestsInsightTableBodyProps = {
  contest: ContestData;
  top: ContestTopListItem[];
};

export const ContestsInsightTopTableBody = ({
  top,
  contest,
}: ContestsInsightTableBodyProps): JsxElement => {
  const prizeIt = contest.prize.values();
  const t = useContestT().sub('insight.table');

  const showHistory = !CONTEST_UPCOMING_STATUSES.has(contest.status);

  return (
    <div className={containerClass}>
      {top.map((item, i) => {
        return (
          <Row
            key={item.player.id}
            contest={contest}
            position={i + 1}
            item={item}
            prize={prizeIt.next().value}
            showHistory={showHistory}
            t={t}
          />
        );
      })}
    </div>
  );
};

type RowProps = {
  prize: string;
  position: number;
  contest: ContestData;
  item: ContestTopListItem;
  showHistory: boolean;
  t: IntlSub;
};

const Row = ({ t, contest, prize, position, item, showHistory }: RowProps): JsxElement => {
  const { player, profit, stake } = item;

  const strip = '—';
  const cashOrStrip = (value: number | string | undefined): string => {
    return value ? '$' + value : strip;
  };

  return (
    <div className={mainClass}>
      <div className={cx(boxClass, prize && boxPaddingWithButton)}>
        <span className={titleClass}>{position ?? strip}</span>
        <span className={cx(titleClass, player.name && nameClass)}>
          {player ? <PlayerImageBlock name={player.name} code={player.image} /> : strip}
        </span>
        <span className={titleClass}>{cashOrStrip(stake)}</span>
        <span className={titleClass}>{cashOrStrip(profit)}</span>
        <span className={cx(titleClass, prize && prizeClass)}>{cashOrStrip(prize)}</span>
        <span className={titleClass}>
          {showHistory ? (
            <button
              className={buttonClass}
              onClick={() => openContestInsightGameHistoryDialog(contest, item, prize)}
            >
              <ButtonIcon />
              <span>{t('button')}</span>
            </button>
          ) : (
            strip
          )}
        </span>
      </div>
    </div>
  );
};

type PlayerImageBlockProps = {
  code: string;
  name: string;
};

const PlayerImageBlock = ({ code, name }: PlayerImageBlockProps): JsxElement => {
  return (
    <div className={nameClass}>
      <PlayerImage code={code} className={imageClass} />
      <span className={imageBlockNameClass}>{name}</span>
    </div>
  );
};

const containerClass = css`
  width: 100%;
  max-height: 419px;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${stdBp.up(1980)} {
    max-height: 740px;
  }

  ::-webkit-scrollbar {
    width: 0;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: transparent;
  }
`;

const mainClass = css`
  width: 100%;
  background: #1e1c22;
  border-radius: 12px;
`;

const boxClass = css`
  padding: 15px 10px 14px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const boxPaddingWithButton = css`
  padding: 10px 10px 9px 24px;
`;

const titleClass = css`
  width: calc(100% / 6);
  overflow: hidden;
  font-family: 'Onest';
  font-weight: 700;
  font-size: 13px;
  line-height: 140%;
  color: #988c92;
`;

const buttonClass = css`
  background: #26232d;
  border-radius: 4px;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  gap: 4px;
  border: none;
  cursor: pointer;
  font-family: 'Onest';
  font-weight: 700;
  font-size: 13px;
  line-height: 140%;
  color: #988c92;

  &:hover {
    background: #dfdfdf;
    color: #18171c;

    svg {
      path {
        fill: #18171c;
      }
    }
  }

  ${stdBp.down(1440)} {
    padding: 8px 4px;
  }
`;

const nameClass = css`
  color: #dfdfdf;
  display: flex;
  gap: 8px;
  align-items: center;
  overflow: hidden;
`;

const imageBlockNameClass = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 10px;
`;

const prizeClass = css`
  color: #0fac7f;
`;

const activePlayerClass = css`
  font-family: 'Onest';
  font-weight: 700;
  font-size: 13px;
  line-height: 140%;
  background: linear-gradient(180deg, #ffaa63 0%, #ea9a4e 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;

const imageClass = css`
  width: 24px;
  height: 24px;
  border-radius: 4.5px;
`;
