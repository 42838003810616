export const ReplenishIcon = () => {
  return (
    <svg  viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 6V18M18 12L6 12"
        stroke="#2D241C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
