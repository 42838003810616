import { coilListen } from 'packs/libs/coil';
import { ReactNode, createContext, useEffect } from 'react';
import { useBasicSwissStore } from 'support/react/swiss';
import { launch } from 'support/react/use-launch';
import { SimpleEventTarget } from 'support/struct/simple-event-target';

const Context = createContext<State>(null!);

type Operator = {};

let globalOperator: Operator;

type State = {
  status?: ServiceWorkerState;
};

type ServiceWorkerStoreProviderProps = {
  children: ReactNode;
};

export const ServiceWorkerStoreProvider = ({
  children,
}: ServiceWorkerStoreProviderProps): JsxElement => {
  const [state, effect] = useBasicSwissStore(() => [
    {} as State,
    (swiss) => {
      const { updState } = swiss;

      let reg: ServiceWorkerRegistration | null = null;

      globalOperator = {};

      const effect = () => {
        if (ENV_SW_ENABLED) {
          const sw = navigator.serviceWorker;
          if (!sw) return;
          const disposal = new SimpleEventTarget();

          launch(async () => {
            const reg = await sw.register(__DEV__ ? '/dev-sw.js?dev-sw' : '/sw.js', {
              scope: '/',
              type: 'module',
            });

            reg.addEventListener('updatefound', () => {
              const next = reg.installing;
              console.log('updatefound', next);
              if (!next) return;

              next.addEventListener('statechange', () => {
                console.log('onUpdate', reg);
                if (next.state === 'installing') {
                  updState({ status: next.state });
                }

                if (next.state === 'activated') {
                  // window.location.reload();
                  updState({ status: next.state });
                }
              });
            });

            disposal.add(
              coilListen('sw', () => {
                reg?.update();
              })
            );
          });

          return () => disposal.emit();
        } else {
          if ('serviceWorker' in navigator) {
            navigator.serviceWorker.ready
              .then((registration) => {
                registration.unregister();
              })
              .catch((error) => {
                console.error(error.message);
              });
          }
        }
      };

      return effect;
    },
  ]);

  useEffect(effect, []);

  return <Context.Provider value={state}>{children}</Context.Provider>;
};
