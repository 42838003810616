export type DoubleLinkedList<V> = List<V>;
export type DoubleLinkedPoint<V> = Point<V>;

class List<V> {
  private length: number;
  public head: Point<V>;
  public tail: Point<V>;

  static fromList<V>(points: V[]) {
    const self = new List<V>();
    if (points.length === 0) throw Error('list of point is empty');
    self.length = points.length;
    const last = points[points.length - 1];
    self.tail = new Point(last);
    self.head = points
      .slice(0, -1)
      .reverse()
      .reduce((next, value) => {
        const point = new Point(value);
        point.next = next;
        next.prev = point;
        return point;
      }, self.tail);
    return self;
  }

  get(order: number) {
    let i = 0;
    let point: Point<V> | any = this.head;
    while (++i < order) {
      point = point.next;
    }
    return point;
  }

  *iter(): Iterable<Point<V>> {
    let current = this.head;
    while (current) {
      yield current;
      current = current.next!;
    }
  }

  *iterRev(): Iterable<Point<V>> {
    let current = this.tail;
    while (current) {
      yield current;
      current = current.prev!;
    }
  }
}

class Point<V> {
  prev?: Point<V>;
  val: V;
  next?: Point<V>;

  constructor(value: V) {
    this.val = value;
  }

  // right(turns: number) {
  //   let i = 0;
  //   let cur: DoubleLinkedPoint<V> | any = this;
  //   while (++i <= turns) {
  //     cur = cur.next?.value
  //     if (cur === undefined) return cur;
  //   }
  //   return cur;
  // }
  //
  // left(turns: number) {
  //   let i = 0;
  //   let point: DoubleLinkedPoint<V> | any = this;
  //   while (++i <= turns) {
  //     point = point.prev
  //     if (point === undefined) return point;
  //   }
  //   return point;
  // }
  //
  // head() {
  //   let point: DoubleLinkedPoint<V> | any = this;
  //   while (point.prev) {
  //     point = point.prev
  //   }
  //   return point;
  // }
  //
  // tail() {
  //   let point: DoubleLinkedPoint<V> | any = this;
  //   while (point.next) {
  //     point = point.next
  //   }
  //   return point;
  // }
}

export function doubleLinkedList<V = unknown>(points: V[]): List<V> {
  return List.fromList(points);
}
