export const fastInsert = <T>(cmp: (a: T, b: T) => number, added: T, iter: IterableIterator<T>) => {
  const result: T[] = [];
  while (true) {
    const it = iter.next();
    if (it.done) {
      result.push(added);
      return result;
    }
    if (cmp(added, it.value) <= 0) {
      result.push(added);
      result.push(it.value);
      break;
    } else {
      result.push(it.value);
    }
  }
  result.push(...iter);
  return result;
};
