import { css } from '@emotion/css';
import { ReactComponent as MobileIcon } from 'app/mobile/assets/mobile.svg';
import { ReactComponent as ChickIcon } from 'app/mobile/assets/chick.svg';

export const MobilePlaceholder = (): JsxElement => {
  return (
    <div className={mainClass}>
      <div>
        <div className={topClass}>
          <span>4</span>
          <ChickIcon />
          <span>4</span>
        </div>
        <div className={textClass}>
          <h4>UNDER CONSTRUCTION:</h4>
          <p>mobile version will be available soon</p>
        </div>
      </div>
      <MobileIcon />
      <div className={footerClass}>
        <p>You can find us on screens</p>
        <p>
          with a resolution of <span className={activeClass}>1280px</span> or higher
        </p>
      </div>
    </div>
  );
};

const mainClass = css`
  background: linear-gradient(
    180deg,
    #18171c 0%,
    rgba(24, 23, 28, 0.92) 33.33%,
    rgba(24, 23, 28, 0) 48.44%,
    rgba(24, 23, 28, 0.6) 74.59%,
    #18171c 100%
  );
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 25px;
`;

const topClass = css`
  display: flex;
  justify-content: center;
  align-items: center;

  > span {
    color: #ffaa63;
    text-align: center;
    font-size: 180px;
    font-family: Onest;
    font-weight: 700;
    line-height: 100%;
  }
`;

const textClass = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > h4 {
    color: #ffaa63;
    font-size: 50px;
    font-family: Onest;
    font-weight: 800;
    line-height: 100%;
  }

  > p {
    color: #ffaa63;
    font-size: 27px;
    font-family: Onest;
    font-weight: 800;
    line-height: 90%;
  }
`;

const footerClass = css`
  color: #ffaa63;
  text-align: center;
  font-size: 25px;
  font-family: Onest;
  font-weight: 800;
  line-height: 120%;
`;

const activeClass = css`
  color: rgba(228, 60, 60, 0.9);
`;
