import { css } from '@emotion/css';
import { ComponentType, ReactNode, useState } from 'react';

import { CrushBetData } from 'app/crush/store/definitions';
import { SkinImg } from 'domain/skin/units/image';
import { SimplePopover } from 'packs/single/simple-popover';
import { CrushBetsCardSkinGradientFrame as skinGrad } from 'app/crush/bets/card/skin-gradient';
import { CrushBetStatus } from 'domain/crush';
import { CrushBetsCardBetGradientFrame as grad } from 'app/crush/bets/card/bet-gradient';
import { CrushBetsCardStaticFrame as stat } from 'app/crush/bets/card/static';

type CrushBetsCardStakePopProps = {
  bet: CrushBetData;
  children: ReactNode;
};

const STATUS_FRAMES = new Map<CrushBetStatus, ComponentType<Rcp>>([
  [CrushBetStatus.won, grad([15, 172, 127], true)],
  [CrushBetStatus.crashed, grad([255, 57, 130])],
  [CrushBetStatus.pending, stat('#18171C')],
]);
const GOLD_FRAME = grad([242, 160, 86]);

export const CrushBetsCardDetailsPop = ({
  bet,
  children,
}: CrushBetsCardStakePopProps): JsxElement => {
  const [open, setOpen] = useState<boolean>(false);
  const Frame = open ? GOLD_FRAME : STATUS_FRAMES.get(bet.status)!;

  return (
    <div onClick={() => setOpen(!open)}>
      <SimplePopover
        popup={<Popup bet={bet} />}
        open={open}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Frame>{children}</Frame>
      </SimplePopover>
    </div>
  );
};

type PopupProps = { bet: CrushBetData };

const Popup = ({ bet }: PopupProps): JsxElement => {
  const skins = bet.stake.skins;
  return (
    <div className={popoverBoxClass}>
      <p className={nameClass}>{bet.player.name}</p>
      <div className={picturesClass}>
        <>
          {bet.prize ? (
            <FrameWithSkinImg color={bet.prize.color} image={bet.prize.image} />
          ) : (
            skins &&
            skins.map(({ image, color }) => (
              <FrameWithSkinImg key={image} color={color} image={image} />
            ))
          )}
        </>
        {bet.stake.additional && !bet.prize && (
          <p className={additionalClass}>+{bet.stake.additional}</p>
        )}
      </div>
    </div>
  );
};
type FrameWithSkinImgProps = {
  color: RgbTuple;
  image: string;
};
const FrameWithSkinImg = ({ image, color }: FrameWithSkinImgProps): JsxElement => {
  const Frame = skinGrad(color);
  return (
    <Frame>
      <SkinImg code={image} alt={'img'} className={pictureClass} />
    </Frame>
  );
};

const popoverBoxClass = css`
  width: 243px;
  height: 101px;
  background: #26232d;
  border-radius: 8px;
  padding: 12px 18px 15px 12px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 0 8px 3px rgba(24, 23, 28, 1);

  &:before {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    bottom: -4px;
    left: 11.5%;
    transform: rotate(45deg);

    background: #26232d;
  }
`;

const picturesClass = css`
  display: flex;
  gap: 6.5px;
  align-items: center;
`;
const pictureClass = css`
  height: 35px;
  width: auto;
`;
const nameClass = css`
  font-family: 'Onest';
  font-weight: 700;
  font-size: 13px;
  line-height: 140%;
  color: #dfdfdf;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const additionalClass = css`
  font-family: 'Onest';
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #dfdfdf;
`;
