export const CompetitionsIcon = ({ active, current }) => {
  return !active && !current ? (
    <svg viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 10V11.9207C21.5477 11.02 19.8346 10.5 18 10.5C16.1654 10.5 14.4523 11.02 13 11.9207V10C13 8.89543 13.8954 8 15 8H21C22.1046 8 23 8.89543 23 10ZM26 20C26 24.4183 22.4183 28 18 28C13.5817 28 10 24.4183 10 20C10 15.5817 13.5817 12 18 12C22.4183 12 26 15.5817 26 20ZM18.7869 16.5086L19.3432 17.6805C19.471 17.9497 19.718 18.1364 20.0038 18.1796L21.2477 18.3675C21.9675 18.4762 22.2549 19.3958 21.7341 19.9236L20.834 20.8358C20.6272 21.0454 20.5328 21.3474 20.5816 21.6434L20.7941 22.9314C20.917 23.6767 20.1646 24.2451 19.5209 23.8932L18.4083 23.285C18.1527 23.1453 17.8473 23.1453 17.5917 23.285L16.4791 23.8932C15.8354 24.2451 15.083 23.6767 15.2059 22.9314L15.4184 21.6434C15.4672 21.3474 15.3728 21.0454 15.166 20.8358L14.266 19.9236C13.7451 19.3958 14.0325 18.4762 14.7523 18.3675L15.9961 18.1796C16.282 18.1364 16.529 17.9497 16.6568 17.6805L17.2131 16.5086C17.535 15.8305 18.465 15.8305 18.7869 16.5086Z"
        fill="#988C92"
      />
    </svg>
  ) : current ? (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_i_24_12260)">
        <rect width="36" height="36" rx="9" fill="url(#paint0_linear_24_12260)" />
      </g>
      <rect
        x="0.75"
        y="0.75"
        width="34.5"
        height="34.5"
        rx="8.25"
        stroke="url(#paint1_linear_24_12260)"
        strokeWidth="1.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 10V11.9207C21.5477 11.02 19.8346 10.5 18 10.5C16.1654 10.5 14.4523 11.02 13 11.9207V10C13 8.89543 13.8954 8 15 8H21C22.1046 8 23 8.89543 23 10ZM26 20C26 24.4183 22.4183 28 18 28C13.5817 28 10 24.4183 10 20C10 15.5817 13.5817 12 18 12C22.4183 12 26 15.5817 26 20ZM18.7869 16.5086L19.3432 17.6805C19.471 17.9497 19.718 18.1364 20.0038 18.1796L21.2477 18.3675C21.9675 18.4762 22.2549 19.3958 21.7341 19.9236L20.834 20.8358C20.6272 21.0454 20.5328 21.3474 20.5816 21.6434L20.7941 22.9314C20.917 23.6767 20.1646 24.2451 19.5209 23.8932L18.4083 23.285C18.1527 23.1453 17.8473 23.1453 17.5917 23.285L16.4791 23.8932C15.8354 24.2451 15.083 23.6767 15.2059 22.9314L15.4184 21.6434C15.4672 21.3474 15.3728 21.0454 15.166 20.8358L14.266 19.9236C13.7451 19.3958 14.0325 18.4762 14.7523 18.3675L15.9961 18.1796C16.282 18.1364 16.529 17.9497 16.6568 17.6805L17.2131 16.5086C17.535 15.8305 18.465 15.8305 18.7869 16.5086Z"
        fill="#2D241C"
      />
      <defs>
        <filter
          id="filter0_i_24_12260"
          x="0"
          y="0"
          width="36"
          height="39"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="1.5"
            operator="erode"
            in="SourceAlpha"
            result="effect1_innerShadow_24_12260"
          />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="4.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 0.450667 0 0 0 0 0.141667 0 0 0 1 0"
          />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_24_12260" />
        </filter>
        <linearGradient
          id="paint0_linear_24_12260"
          x1="18"
          y1="0"
          x2="18"
          y2="36"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFAA63" />
          <stop offset="1" stopColor="#EA9A4E" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_24_12260"
          x1="18"
          y1="0"
          x2="18"
          y2="36"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9457" />
          <stop offset="1" stopColor="#FFBA69" />
        </linearGradient>
      </defs>
    </svg>
  ) : (
    <svg viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 10V11.9207C21.5477 11.02 19.8346 10.5 18 10.5C16.1654 10.5 14.4523 11.02 13 11.9207V10C13 8.89543 13.8954 8 15 8H21C22.1046 8 23 8.89543 23 10ZM26 20C26 24.4183 22.4183 28 18 28C13.5817 28 10 24.4183 10 20C10 15.5817 13.5817 12 18 12C22.4183 12 26 15.5817 26 20ZM18.7869 16.5086L19.3432 17.6805C19.471 17.9497 19.718 18.1364 20.0038 18.1796L21.2477 18.3675C21.9675 18.4762 22.2549 19.3958 21.7341 19.9236L20.834 20.8358C20.6272 21.0454 20.5328 21.3474 20.5816 21.6434L20.7941 22.9314C20.917 23.6767 20.1646 24.2451 19.5209 23.8932L18.4083 23.285C18.1527 23.1453 17.8473 23.1453 17.5917 23.285L16.4791 23.8932C15.8354 24.2451 15.083 23.6767 15.2059 22.9314L15.4184 21.6434C15.4672 21.3474 15.3728 21.0454 15.166 20.8358L14.266 19.9236C13.7451 19.3958 14.0325 18.4762 14.7523 18.3675L15.9961 18.1796C16.282 18.1364 16.529 17.9497 16.6568 17.6805L17.2131 16.5086C17.535 15.8305 18.465 15.8305 18.7869 16.5086Z"
        fill="url(#paint0_linear_1_16)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1_16"
          x1="18"
          y1="8"
          x2="18"
          y2="28"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFAA63" />
          <stop offset="1" stopColor="#EA9A4E" />
        </linearGradient>
      </defs>
    </svg>
  );
};
