import createBreakpoints from '@mui/system/createTheme/createBreakpoints';

const BREAKPOINTS = {
  xs: 0,
  sm: 576,
  md: 960,
  lg: 1280,
  xl: 1680,
  xxl: 1921,
  xxxl: 2500,
};

type StdBreakpoints = {
  keys: BpKey[];
  values: Record<BpKey, number>;
  up: (key: BpKey | number) => string;
  down: (key: BpKey | number) => string;
  between: (start: BpKey | number, end: BpKey | number) => string;
  only: (key: BpKey) => string;
  width: (key: BpKey) => number;
};
type BpKey = keyof typeof BREAKPOINTS;

const createBp = (param: string): StdBreakpoints => {
  const vals = new Map<any, any>(Object.entries(BREAKPOINTS));
  const unit = 'px';
  const steep = 0.5;

  const between = (start, end) => {
    return `@media (min-${param}:${vals.get(start) ?? start}${unit}) and (max-width:${
      (vals.get(end) ?? end) - steep
    }${unit})`;
  };

  // @ts-ignore
  return {
    // keys: [...vals.keys()],
    values: BREAKPOINTS,
    up(key) {
      return `@media (min-${param}:${vals.get(key) ?? key}${unit})`;
    },
    down(key: BpKey | number) {
      return `@media (max-${param}:${(vals.get(key) ?? key) - steep}${unit})`;
    },
    between,
  };
};

// @ts-ignore
export const stdBp = Object.assign(createBreakpoints(createBp('width')), {
  // @ts-ignore
  height: createBreakpoints(createBp('height')),
});
export { stdBp as stdBreakpoints };
