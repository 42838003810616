import { css } from '@emotion/css';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as LeftBackgroundImage } from 'app/cookies-toast/assets/left-background-image.svg';
import { ReactComponent as LogoIcon } from 'app/cookies-toast/assets/logo-icon.svg';
import { ReactComponent as RightBackgroundImage } from 'app/cookies-toast/assets/right-background-image.svg';
import { useLayoutT } from 'app/layout/glossary';
import { useActor } from 'domain/actor';
import { chronicle } from 'packs/libs/chronicle';
import { kvRepo } from 'packs/libs/db';
import { toast } from 'react-toastify';

let currentToastId;

export const CookiesPolicyToast = (): JsxElement => {
  const actor = useActor();

  useEffect(() => {
    if (!actor.resolved) return;
    (async () => {
      const [repoFind, repoSet] = kvRepo<string[]>('cookies-policy-accepted');

      const acceptedActors = (await repoFind()) ?? [];

      const actorShouldBeAccepted = actor.anon ? 'anon' : actor.id;

      if (!acceptedActors.includes(actorShouldBeAccepted)) {
        const previousToastId = currentToastId;
        if (previousToastId) toast.dismiss(previousToastId);

        const onAccept = () => {
          (async () => {
            await repoSet([...acceptedActors, actorShouldBeAccepted]);
            toast.dismiss(currentToastId);
            currentToastId = null;
            chronicle.push('');
          })();
        };

        currentToastId = toast(<Toast onAccept={onAccept} />, {
          position: toast.POSITION.BOTTOM_CENTER,

          bodyClassName: toastClass,
          hideProgressBar: true,
          autoClose: false,
          closeOnClick: false,
          draggable: false,
        });
      }
    })();
  }, [actor]);

  return null;
};

type ToastProps = {
  onAccept(): void;
};

function Toast({ onAccept }: ToastProps) {
  const t = useLayoutT().sub('cookies-popup');

  return (
    <div className={mainClass}>
      <LeftBackgroundImage className={leftBackgroundImageClass} />
      <div className={boxClass}>
        <LogoIcon className={logoClass} />
        <Link to="/privacy-policy" className={titleClass}>
          {t('cookies')}
        </Link>
      </div>
      <button onClick={onAccept} className={buttonClass}>
        {t('ok')}
      </button>
      <RightBackgroundImage className={rightBackgroundImage} />
    </div>
  );
}
const mainClass = css`
  display: flex;
  overflow: hidden;
  justify-content: space-between;
  align-items: center;
  width: 591px;
  height: 62px;
  border-radius: 12px;
  padding-right: 10px;
  padding-left: 18px;
  background: #26232d;
`;
const leftBackgroundImageClass = css`
  position: absolute;
  left: 0;
  height: 100%;
`;
const rightBackgroundImage = css`
  position: absolute;
  right: 0;
  height: 100%;
`;
const boxClass = css`
  display: flex;
  align-items: center;
  gap: 16px;
  z-index: 1;
`;
const logoClass = css`
  width: 36px;
`;
const titleClass = css`
  font-family: 'Onest';
  font-weight: 700;
  font-size: 14px;
  line-height: 140%;
  color: #ffffff;
`;
const buttonClass = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 141px;
  height: 42px;
  background: linear-gradient(180deg, #ffaa63 0%, #ffaa63 0.01%, #ea9a4e 100%);
  box-shadow: inset 0px 4px 12px 2px #ff7324;
  border-radius: 4px;
  font-family: 'Onest';
  font-weight: 700;
  font-size: 13px;
  line-height: 140%;
  color: #2d241c;
  border: 0;
  z-index: 1;
  cursor: pointer;
  &:hover {
    background: #ffbe88;
    box-shadow: none;
  }
`;
const toastClass = css`
  background: transparent;
  padding: 0;
  margin: 0;
  height: fit-content;
`;
