import { css } from '@emotion/css';
import { useInventoryT } from 'app/inventory/glossary';
import { openInventoryBuySkinsDialog } from '../buy-skins';

export const InventoryEmptyBuySkinsButton = () => {
  const t = useInventoryT().sub('buttons');

  return (
    <button onClick={openInventoryBuySkinsDialog} className={mainClass}>
      {t('buy-skins')}
    </button>
  );
};

const mainClass = css`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  width: 35%;
  padding: 12px 0;
  margin-bottom: 18px;

  background: linear-gradient(180deg, #ffaa63 0%, #ea9a4e 100%);
  box-shadow: inset 0 4px 12px 2px #ff7324;
  border-radius: 4px;
  border: 0;
  color: #2d241c;

  font-family: 'Onest';
  font-weight: 700;
  font-size: 13px;

  &:hover {
    background: #ffbe88;
    box-shadow: none;
  }
`;
