import { css } from '@emotion/css';
import { CrushBets } from 'app/crush/bets/bets';
import { CrushControl } from 'app/crush/control/control';
import { CrushFloor } from 'app/crush/floor/floor';
import { CrushScene } from 'app/crush/scene/scene';
import { CrushSoundGroup } from 'app/crush/store/listeners/sounds';
import { Inventory } from 'app/inventory/inventory';
import { LayoutFooter } from 'app/layout/footer/footer';
import { useActor } from 'domain/actor';
import { useEnableSoundGroup } from 'packs/libs/sound';
import { CrushPlaceholder } from './placeholder/placeholder';
import { stdBp } from 'packs/std';
import { linearClamp } from 'support/polished/linear-clamp';

export const CrushPage = (): JsxElement => {
  useEnableSoundGroup(CrushSoundGroup);
  const actor = useActor();
  const isAnon = actor.anon;

  return (
    <div className={mainClass}>
      <div className={boxClass}>
        <div className={yBoxClass}>
          <CrushBets />
          <div className={centralClass}>
            <div className={sceneBoxClass}>
              <CrushScene />
              <CrushFloor />
            </div>
            {isAnon ? (
              <div className={lowBlockAnonClass}>
                <CrushPlaceholder />
              </div>
            ) : (
              <div className={lowBlockSignedClass}>
                <Inventory className={inventoryClass} />
                <CrushControl />
              </div>
            )}
          </div>
        </div>
        <LayoutFooter />
      </div>
    </div>
  );
};

const mainClass = css`
  display: flex;
  justify-content: center;
  flex: 1;
  overflow: hidden;
  padding: 22px 22px 0;
`;

const boxClass = css`
  max-width: 1874px;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 22px;
  overflow: hidden;
`;

const yBoxClass = css`
  overflow: hidden;
  display: flex;
  gap: 22px;
  flex: 1;
  ${stdBp.up(1920)} {
    padding: ${linearClamp(1920, 0, 2560, 42)} 0;
  }
`;

const sceneBoxClass = css`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const centralClass = css`
  flex: 1;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  height: 100%;
  position: relative;
`;

const lowBlockClass = css`
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
  min-height: 270px;
`;

const inventoryClass = css`
  flex: 1;
`;

const lowBlockAnonClass = css`
  ${lowBlockClass};
`;

const lowBlockSignedClass = css`
  ${lowBlockClass};
  display: flex;
  gap: 2px;
`;
