import { createAppDesktopRoutes, LayoutDesktopRoute } from 'app/routes.desktop';
import { createElement } from 'react';
import { Route, RouteProps, Routes } from 'react-router-dom';

const create = (routes: LayoutDesktopRoute[]) => {
  return routes.map(({ index, path, elem, sub }) => {
    const props: RouteProps = {
      index,
      path,
    };
    if (elem) props.element = elem;
    let children: JsxElement[] = [];
    if (sub) children = create(sub);
    return createElement(Route, props, ...children);
  });
};

export const LayoutJunction = () => {
  return <Routes>{...create(createAppDesktopRoutes())}</Routes>;
};
