import { CaseBattleCreationPage } from 'app/case/battle/creation/page';
import { CaseBattleInsightPage } from 'app/case/battle/insight/page';
import { CaseBattleMonitorPage } from 'app/case/battle/monitor/page';
import { ContestJunction } from 'app/contest/junction';
import { ContestMonitorPreset } from 'app/contest/monitor';
import { CrushPage } from 'app/crush/page';
import { GiveawaysPage } from 'app/giveaways/lazy';
import { ProfilePage } from 'app/profile/lazy';
import { ProfileReferralPage } from 'app/profile/referral/lazy';
import { QuestsPage } from 'app/quests/lazy';
import { ReferralInvitePage } from 'app/referral/invite/page';
import { ReplenishCallbackPage } from 'app/replenish/callback';

import { UpgradePage } from 'app/upgrade/page';
import { LeaderBoardPage } from './leader-board';

import { SiteHelpPage } from 'app/static-pages/help/lazy';
import { PrivacyPolicyPage, TermsOfServicesPage } from 'app/static-pages/terms/lazy';

console.log(`:preset(${Object.values(ContestMonitorPreset).join('|')})?`);

export type LayoutDesktopRoute = {
  path?: string;
  elem?: JsxElement;
  sub?: LayoutDesktopRoute[];
  index?: true;
};

export const createAppDesktopRoutes = () => {
  const routes: LayoutDesktopRoute[] = [
    { path: '/', elem: <CrushPage /> },
    { path: '/top', elem: <LeaderBoardPage /> },
    {
      path: '/case-battles',
      sub: [
        { path: 'create', elem: <CaseBattleCreationPage /> },
        { index: true, elem: <CaseBattleMonitorPage /> },
        { path: ':id', elem: <CaseBattleInsightPage /> },
      ],
    },
    {
      path: '/contests/:a?/:b?',
      elem: <ContestJunction />,
    },
    { path: '/giveaways', elem: <GiveawaysPage /> },
    { path: '/upgrade', elem: <UpgradePage /> },
    { path: '/quests/:tab?', elem: <QuestsPage /> },
    { path: '/player/:id', elem: <ProfilePage /> },
    { path: '/player/:id/referral', elem: <ProfileReferralPage /> },
    { path: '/payment/:type', elem: <ReplenishCallbackPage /> },
    { path: '/ref/:code', elem: <ReferralInvitePage /> },

    // STATIC ROUTES
    { path: '/privacy-policy', elem: <PrivacyPolicyPage /> },
    { path: '/terms-of-services', elem: <TermsOfServicesPage /> },
    {
      path: '/help/:category?',
      elem: <SiteHelpPage />,
    },
  ];

  return routes;
};
