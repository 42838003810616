import { css } from '@emotion/css';
import { useLayoutT } from 'app/layout/glossary';
import { linearClamp } from 'support/polished/linear-clamp';
import { ReplenishIcon } from '../assets/replenish-icon';
import { launchOpenReplenishDialog } from 'domain/replenish/launch-replenish-dialog';

export const LayoutHeaderAccountPanelReplenishButton = (): JsxElement => {
  const t = useLayoutT();
  return (
    <button className={replenishClass} onClick={() => launchOpenReplenishDialog()}>
      <ReplenishIcon />
      {t('replenish')}
    </button>
  );
};

const replenishClass = css`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 119px;
  height: 38px;
  background: linear-gradient(180deg, #ffaa63 0%, #ea9a4e 100%);
  box-shadow: inset 0 4px 12px 2px #ff7324;
  border-radius: ${linearClamp(1920, 4, 3440, 7)};
  border: 0;
  color: #2d241c;
  font-family: 'Onest';
  font-weight: 700;
  font-size: 13px;

  &:hover {
    background: #ffbe88;
    box-shadow: none;
  }
  svg {
    height: 24px;
  }
`;
