import { css } from '@emotion/css';
import { CrushBetsList } from 'app/crush/bets/list';
import { CrushBetsStats } from 'app/crush/bets/stats';
import { useCrushScopeBets } from 'app/crush/store/store';

export const CrushBets = (): JsxElement => {
  const bets = useCrushScopeBets();
  return (
    <div className={mainClass}>
      <CrushBetsStats bets={bets} />
      <CrushBetsList bets={bets} />
    </div>
  );
};

const mainClass = css`
  width: 328px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 2px;
  cursor: pointer;
`;
