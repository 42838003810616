import { css } from '@emotion/css';

import { LayoutHeaderAccountPanelBalance } from 'app/layout/header/account-panel/balance';
import { LayoutHeaderAccountPanelPlayerAccount } from 'app/layout/header/account-panel/player-account';
import { LayoutHeaderAccountPanelReplenishButton } from './replenish-button';

import { LayoutHeaderAccountPanelContestMode } from 'app/layout/header/account-panel/contest-mode';
import { SignInButton } from 'app/sign-in';
import { useActor } from 'domain/actor';
import { useContestLiveState } from 'domain/contest/live';

export const LayoutHeaderAccountPanel = (): JsxElement => {
  const actor = useActor();

  if (!actor.resolved) return null!;
  if (actor.anon)
    return (
      <div className={mainClass}>
        <SignInButton className={signInButton} />
      </div>
    );

  return (
    <div className={mainClass}>
      <ReplenishOrContest />
      <LayoutHeaderAccountPanelPlayerAccount actor={actor} />
    </div>
  );
};

const ReplenishOrContest = (): JsxElement => {
  const live = useContestLiveState();

  return live.active ? (
    <>
      <LayoutHeaderAccountPanelContestMode />
      <LayoutHeaderAccountPanelBalance />
    </>
  ) : (
    <>
      <LayoutHeaderAccountPanelBalance />
      <LayoutHeaderAccountPanelReplenishButton />
    </>
  );
};

const mainClass = css`
  display: flex;
  align-items: center;
  gap: 17px;
  height: 100%;
`;

const signInButton = css`
  height: 38px;
  border-radius: 4px;
  font-weight: 700;
  font-size: 13px;
`;
