import { useEffect } from 'react';
import { ExtraSyncCell } from 'support/react/extra-sync-cell';

const VolumeCell = new ExtraSyncCell<number>({
  code: 'sound-vol',
  value: 60,
  onChange(value) {
    if (value !== undefined) {
      VolumeCell.localSet(value);
    }
  },
});

export function useSoundVolumeState(): [number, (volume: number) => void] {
  return [VolumeCell.use(), VolumeCell.set];
}

VolumeCell.add((on) => {
  if (!on) GlobalSoundGroup.stop();
});

export const useEnableSoundGroup = (group: SoundGroup) => {
  useEffect(() => {
    group.enable();
    return () => {
      group.disable();
    };
  }, []);
};

class SoundGroup {
  private subgroups = new Set<SoundGroup>();
  private sounds = new Set<Sound>();

  createSound(url: string): Sound {
    const sound = new Sound(new Audio(url));
    this.sounds.add(sound);
    return sound;
  }

  createSubgroup(): SoundGroup {
    const sub = new SoundGroup();
    this.subgroups.add(sub);
    return sub;
  }

  enable() {
    this.eachSound((s) => s.enable());
  }

  disable() {
    this.eachSound((s) => s.disable());
  }

  stop() {
    this.eachSound((s) => s.stop());
  }

  private eachSound(cb: (sound: Sound) => void) {
    for (const sound of this.sounds) cb(sound);
    for (const group of this.subgroups) group.eachSound(cb);
  }
}

export class Sound {
  private disabled: boolean = true;

  constructor(private audio: HTMLAudioElement) {}

  enable() {
    this.disabled = false;
  }

  disable() {
    this.disabled = true;
    if (this.playing) {
      this.audio.pause();
    }
  }

  replay() {
    if (this.disabled) return;
    const volume = VolumeCell.get();
    if (!volume) return;
    this.audio.volume = volume / 100;
    this.audio.currentTime = 0;
    this.audio.play().catch(console.error);
  }

  stop() {
    this.audio.pause();
  }

  get playing() {
    return this.audio.duration > 0 && !this.audio.paused;
  }
}

export const GlobalSoundGroup = new SoundGroup();

export const createSoundGroup = GlobalSoundGroup.createSubgroup.bind(
  GlobalSoundGroup
) as SoundGroup['createSubgroup'];
export const createSound = GlobalSoundGroup.createSound.bind(
  GlobalSoundGroup
) as SoundGroup['createSound'];
