// Browser Broadcast Channel Simplified

import { EventEmitter } from 'events';
import { stash, stashRef } from 'support/etc/stash';

type Listener = (data) => void;
type Event = string;

const channelRef = stashRef(() => new BroadcastChannel('bbc'));

const emitterRef = stashRef(() => {
  const ev = new EventEmitter();
  channelRef.v.onmessage = (msg) => {
    const [event, data] = msg.data;
    ev.emit(event, data);
  };
  return ev;
});

export const bbcSupports = stash(
  async () =>
    window.BroadcastChannel && (await navigator.serviceWorker?.getRegistration()) !== undefined
);

export function bbcOnce(event: string, listener: Listener) {
  emitterRef.v.once(event, listener);
}

export function bbcOn(event: string, listener) {
  emitterRef.v.on(event, listener);
}

export function bbcEmit(event: Event, data: any) {
  channelRef.v.postMessage([event, data]);
}
