import { GraphChickStageOpFactory } from 'app/crush/scene/chick/block/definitions';
import { makeGraphChickElement } from 'app/crush/scene/chick/block/units/element';
import { chickTimeline, ChickTimelinePoint } from 'app/crush/scene/chick/block/timeline';
import { serverNow } from 'packs/libs/coil';

const DEATH_DURATION = 13e3;

export const ChickStageCrash: GraphChickStageOpFactory = (c) => {
  let dispose: () => void;
  const chickDeath = makeGraphChickElement('death1');

  return () => {
    const planner = chickTimeline([
      DEATH_DURATION,
      (p: ChickTimelinePoint) => chickDeath.subPlay(p.passed / 1000),
    ]);

    return {
      start({ crashAt }) {
        dispose = planner(!crashAt ? DEATH_DURATION : DEATH_DURATION + crashAt - serverNow());
      },
      close() {
        dispose && setTimeout(dispose, 200);
      },
    };
  };
};
