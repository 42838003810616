import Decimal from 'decimal.js';

type T = Decimal.Value;

export const upgradeCalcChance = (rtp: T, source: T, target: T) => {
  if (!target || target === '0') return rtp.toString();
  return new Decimal(rtp).mul(source).div(target).toDP(2).toString();
};

export const upgradeCalcTarget = (rtp: T, source: T, chance: T) => {
  if (!chance || chance === '0') return '0';
  return new Decimal(rtp).mul(source).div(chance).toDP(2).toString();
};
