import { css } from '@emotion/css';
import { ReactComponent as PlusIcon } from 'app/case/battle/insight/assets/big-plus.svg';
import { useCaseBattleInsightOperator } from 'app/case/battle/insight/store';
import { voidMemo } from 'support/react/void-memo';
import { useCaseBattleInsightT } from '../glossary';

export const CaseBattleInsightStandAddBot = voidMemo(
  function CaseBattleInsightStandAddBot(): JsxElement {
    const t = useCaseBattleInsightT();

    const op = useCaseBattleInsightOperator();
    return (
      <div className={mainClass} onClick={op.addBot}>
        <div className={boxClass}>
          <PlusIcon className={iconClass} />
        </div>
        <p className={textClass}>{t('add-bots')}</p>
      </div>
    );
  }
);

const mainClass = css`
  display: flex;
  flex-direction: column;
  gap: 13px;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

const iconClass = css`
  width: 34px;
  height: 34px;
`;

const boxClass = css`
  font-family: 'Onest';
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 76px;
  height: 76px;
  background: #18171c;
`;

const textClass = css`
  font-family: 'Commissioner';
  font-weight: 600;
  font-size: 12px;
  line-height: 130%;
  text-align: center;
  color: #988c92;
`;
