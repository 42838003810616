import { debounce } from 'lodash';
import { bbcEmit, bbcOn, bbcSupports } from 'packs/libs/bbc';
import { kvFind, kvSet } from 'packs/libs/db';
import { useEffect } from 'react';
import { stash_get } from 'support/etc/stash';
import { useForceRefresh } from 'support/react/use-force-refresh';

type Opts<T> = {
  debounce?: number;
  code: string;
  value: T;
  onChange?(value: any): void;
};

type Listener<T> = (value: T) => void;
export class ExtraSyncCell<T = any> extends Set<Listener<T>> {
  private declare value: T;
  private declare readonly debounce: number;
  private declare readonly code: string;
  private declare readonly onChange?: (value: any) => void;

  constructor({ code, value, onChange, debounce = 200 }: Opts<T>) {
    super();

    this.code = code;
    this.value = value;
    this.debounce = debounce;
    this.onChange =
      onChange ??
      ((value) => {
        if (this.value !== value) {
          this.localSet(value);
        }
      });

    kvFind<T>(code).then(this.onChange);

    bbcSupports().then((supports) => {
      if (__DEBUG__) console.log('broadcasting active', supports);
      if (supports) {
        bbcOn(this.channel, this.onChange);
      }
    });
  }

  set = (value: T) => {
    this.localSet(value);
    this.save(value);
  };

  get = () => this.value;

  use = () => {
    const commit = useForceRefresh();
    useEffect(() => this.sub(commit), []);
    return this.value;
  };

  sub = (listener: Listener<T>) => {
    this.add(listener);
    return () => {
      this.delete(listener);
    };
  };

  @stash_get
  private get save() {
    return debounce((value) => {
      kvSet(this.code, value);
      bbcSupports().then((supports) => {
        if (supports) {
          bbcEmit(this.channel, value);
        }
      });
    }, this.debounce);
  }

  localSet = (value: T) => {
    this.value = value;
    for (const listener of this.values()) listener(value);
  };

  private get channel() {
    return `${this.code}:changed`;
  }
}
