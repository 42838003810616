import { serverNow } from 'packs/libs/coil';
import { GraphChickStageOpFactory } from 'app/crush/scene/chick/block/definitions';
import { makeGraphChickElement } from 'app/crush/scene/chick/block/units/element';
import { chickTimeline, ChickTimelinePoint } from 'app/crush/scene/chick/block/timeline';
import { CrushSceneChickBlockUnitsAbstract } from 'app/crush/scene/chick/block/units/abstract';
import { VideoGraphChickElement } from 'app/crush/scene/chick/block/units/video-element';

const PLANTING_DURATION = 5600;
const TIMEOUT = 200;

export const Countdown: GraphChickStageOpFactory = () => {
  const chickIdle: VideoGraphChickElement = makeGraphChickElement('idle').loop();
  const chickPlanting: CrushSceneChickBlockUnitsAbstract = makeGraphChickElement('planting');

  let dispose: () => void;

  return () => {
    const planner = chickTimeline(
      [3e4, (p: ChickTimelinePoint) => chickIdle.subPlay(0)],
      [PLANTING_DURATION, (p: ChickTimelinePoint) => chickPlanting.subPlay(p.passed / 1000)]
    );

    return {
      start({ clashAt }) {
        dispose = planner(clashAt - serverNow() + TIMEOUT);
      },
      close() {
        dispose && setTimeout(dispose, TIMEOUT);
      },
    };
  };
};
