import { css } from '@emotion/css';
import { CrushPlaceholder } from 'app/crush/placeholder/placeholder';

import { Inventory } from 'app/inventory/inventory';
import { LayoutFooter } from 'app/layout/footer/footer';
import { UpgradeControl } from 'app/upgrade/control/control';
import { UpgradeFlow } from 'app/upgrade/flow';
import { UpgradeLiveBids } from 'app/upgrade/live-bids/live-bids';
import { useActor } from 'domain/actor';
import { ContestUpon } from 'domain/contest';
import { ContestLiveGameModeBadge } from 'domain/contest/live/units/game-mode-badge';
import { stdBp, stdBreakpoints } from 'packs/std';

export function UpgradePage(): JsxElement {
  const actor = useActor();
  const isAnon = actor.anon;
  return (
    <div className={containerClass}>
      <UpgradeLiveBids />
      <div className={mainClass}>
        <div className={boxClass}>
          <UpgradeFlow />
          <div className={contestBoxClass}>
            <ContestLiveGameModeBadge upon={ContestUpon.upgrade} className={contestNameClass} />
          </div>
          {isAnon ? (
            <div className={lowBlockClass}>
              <CrushPlaceholder />
            </div>
          ) : (
            <div className={panelClass}>
              <Inventory className={inventoryClass} />
              <UpgradeControl />
            </div>
          )}
        </div>
      </div>
      <div className={footerClass}>
        <LayoutFooter />
      </div>
    </div>
  );
}

const containerClass = css`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2px;
  gap: 21px;
`;
const boxClass = css`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 22px;
  position: relative;
`;
const mainClass = css`
  max-width: 1548px;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  ${stdBp.down(1940)} {
    padding: 0 19px;
  }
`;

const panelClass = css`
  display: flex;
  border-radius: 12px;
  overflow: hidden;
  width: 100%;
  gap: 2px;
`;

const inventoryClass = css`
  background: #1e1c22;
  flex: 1;
  ${stdBreakpoints.up(1920)} {
    height: 270px;
  }
`;
const lowBlockClass = css`
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
  min-height: 270px;
`;
const footerClass = css`
  max-width: 1548px;
  width: 100%;
  ${stdBp.down(1940)} {
    padding: 0 19px;
  }
`;

const contestBoxClass = css`
  position: absolute;
  top: 290px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;

  ${stdBreakpoints.up(1440)} {
    top: 388px;
  }
`;

const contestNameClass = css`
  background: #18171c;
`;
