type ElementAttributes = {
  [key: string]: string | boolean | number | undefined;
};

type Child = string | Node;

type Children = Child[] | Child | null | undefined;

const attrMap = new Map<string, string>([['className', 'class']]);

export function createNativeElement(
  tagName: string,
  attributes?: ElementAttributes,
  ...children: Children[]
): HTMLElement {
  const element = document.createElement(tagName);
  if (attributes) {
    for (let [name, value] of Object.entries(attributes)) {
      if (value == null || value === false) continue;
      name = attrMap.get(name) ?? name;
      if (value === true) {
        element.setAttribute(name, '');
      } else {
        element.setAttribute(name, String(value));
      }
    }
  }

  if (children.length > 0) {
    // Add children to the element
    const appendChild = (child: Child) => {
      if (child instanceof Node) {
        element.appendChild(child);
      } else {
        const textNode = document.createTextNode(child);
        element.appendChild(textNode);
      }
    };
    for (const child of children) {
      if (Array.isArray(child)) {
        child.forEach(appendChild);
      } else {
        appendChild(child!);
      }
    }
  }

  return element;
}
