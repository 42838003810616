import { createContext, createElement, useContext, useEffect } from 'react';

import { ActorOperator } from 'domain/actor/operator';
import { coilListenR, listenCoilConnect } from 'packs/libs/coil';
import { showErrorToast } from 'packs/libs/inf';
import { useForceRefresh } from 'support/react/use-force-refresh';
import { useSingleton } from 'support/react/use-singleton';
export type Actor = ActorOperator;

let globalOperator: ActorOperator;

export const getActor = () => globalOperator;

const Context = createContext<ActorOperator>(null!);
if (__DEV__) Context.displayName = 'ActorContext';
export const useActor = () => useContext(Context);

export const ActorProvider = ({ children }: Rcp) => {
  const commit = useForceRefresh();
  const connect = useSingleton(() => {
    let state = { resolved: false };

    const getState = () => state;
    const setState = (next) => {
      state = next;
      if (__DEV__) {
        console.trace('actor state', next);
      }
      globalOperator = new ActorOperator(setState, getState);
      commit();
    };
    globalOperator = new ActorOperator(setState, getState);

    return () =>
      listenCoilConnect(() => {
        globalOperator.connect();

        return coilListenR('actor', (r) => {
          if (r.id() === 'kick-out') {
            showErrorToast(
              'You have been kicked out by the administration. Please contact support.'
            );
            getActor().logout();
          }
        });
      });
  });

  useEffect(connect, []);

  return createElement(Context.Provider, { value: globalOperator }, children);
};

/**
 * fire event to clear user credentials
 * @deprecated
 */
export function actorClear() {
  getActor().logout();
}
