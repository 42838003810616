import { css, cx } from '@emotion/css';
import { ReactComponent as ComboDots } from 'app/inventory/assets/combo-dots.svg';
import { useInventoryT } from 'app/inventory/glossary';
import { InventoryHeaderCheckbox } from 'app/inventory/header/checkbox';
import { getInventoryOperator, useInventoryState } from 'app/inventory/store';
import { stdBp, stdBreakpoints } from 'packs/std';
import { roundCash } from 'support/etc/round-cash';

export const InventoryHeader = () => {
  const t = useInventoryT().sub('header');
  const { count, selectedCount, total, hasSelectedItems } = useInventoryState();
  const { selectAll, deselectAll, getItems } = getInventoryOperator();
  
  const getLockedItems = () => [...getItems().values()].filter((i) => i.lock);
  const countWithoutLockedItems = count - getLockedItems().length;
  const totalCash = total ? `$${roundCash(total)}` : null;
  const isSelectedAll = selectedCount === countWithoutLockedItems && countWithoutLockedItems > 0;

  return (
    <div className={headerClass}>
      <div className={titleClass}>
        <ComboDots className={comboDotsClass} /> {t('inventory')}
        <span className={totalCashClass}>{totalCash}</span>
      </div>
      <button className={unselectClass} disabled={!hasSelectedItems} onClick={deselectAll}>
        {hasSelectedItems && t('unselect-all')}
      </button>
      <div className={cx(selectAllClass, isSelectedAll && selectAllActiveClass)}>
        <InventoryHeaderCheckbox active={isSelectedAll} onClick={() => selectAll(!isSelectedAll)} />
        <span className={cx(selectAllTextClass, hasSelectedItems && wrapClass)}>
          {t('select-all')}
        </span>
      </div>
    </div>
  );
};

const headerClass = css`
  padding: 19px 18px 20px 15px;
  display: grid;
  max-width: 345px;
  grid-template-columns: 1fr 2fr 1fr;
  grid-gap: 6px;
  line-height: 100%;

  ${stdBreakpoints.between(1280, 1440)} {
    min-width: 219px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 1px;
  }
  ${stdBreakpoints.up(1440)} {
    min-width: 402px;
  }
`;

const titleClass = css`
  font-family: 'Commissioner';
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: 9px;
  color: #dfdfdf;

  ${stdBreakpoints.between(1280, 1440)} {
    grid-area: 1 / 1 / 2 / 3;
  }
`;

const comboDotsClass = css`
  width: 12px;
  height: 12px;
`;

const totalCashClass = css`
  font-family: 'Onest';
  font-weight: 500;
  font-size: 14px;
`;

const unselectClass = css`
  background: none;
  white-space: nowrap;
  border: none;
  cursor: pointer;
  font-family: 'Commissioner';
  font-weight: 600;
  font-size: 12px;
  color: #988c92;
  text-align: left;

  &:disabled {
    cursor: default;
  }
`;

const selectAllClass = css`
  font-family: 'Commissioner';
  font-weight: 600;
  font-size: 12px;
  display: flex;
  align-items: center;
  color: #988c92;
  gap: 6px;
  justify-content: flex-end;
  ${stdBreakpoints.between(1280, 1440)} {
    grid-area: 2 / 2 / 3 / 3;
  }
`;

const selectAllActiveClass = css`
  background: linear-gradient(180deg, #ffaa63 0%, #ea9a4e 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;
const selectAllTextClass = css`
  width: fit-content;
  white-space: nowrap;
`;
const wrapClass = css`
  ${stdBp.down(1620)} {
    white-space: normal;
  }
`;
