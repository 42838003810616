import { css } from '@emotion/css';

import { PlayerImage } from 'domain/player/image/image';
import { ReactComponent as BotIcon } from 'app/case/battle/insight/assets/bot-icon.svg';
import { ReactComponent as BotAvatar } from 'app/case/battle/assets/bot-avatar.svg';
import { useCaseBattleInsightT } from 'app/case/battle/insight/glossary';

type CaseBattleInsightStandPlayerProps = {
  player?: { id: string; name: string; image: string; bot?: boolean };
  total?: number;
};

export const CaseBattleInsightStandPlayer = ({
  player,
  total,
}: CaseBattleInsightStandPlayerProps): JsxElement => {
  const t = useCaseBattleInsightT().sub('bot');
  if (player === undefined)
    return (
      <div className={mainClass}>
        <Skeleton />
      </div>
    );

  return (
    <div className={mainClass}>
      <div className={playerImageBoxClass}>
        {player.bot ? (
          <>
            <BotAvatar className={playerImageClass} />
            <div className={botSignClass}>{t('sign')}</div>
          </>
        ) : (
          <PlayerImage code={player.image} className={playerImageClass} />
        )}
      </div>
      <div>
        <div className={nameBoxClass}>
          {player.bot ? (
            <div className={nameBoxClass}>
              <div className={playerNameClass}>{t('name')}</div>
              <BotIcon className={botIconClass} />
            </div>
          ) : (
            <div className={playerNameClass}>{player.name}</div>
          )}
        </div>
        <div className={totalClass}>${total ? total.toFixed(2) : '0.00'}</div>
      </div>
    </div>
  );
};

const Skeleton = (): JsxElement => {
  return (
    <svg
      className={skeletonClass}
      viewBox="0 0 108 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="34" height="34" rx="6" fill="#18171C" />
      <rect x="42" y="2" width="66" height="12" rx="6" fill="#18171C" />
      <rect x="42" y="19" width="45" height="12" rx="6" fill="#18171C" />
    </svg>
  );
};

const mainClass = css`
  display: flex;
  gap: 9px;
`;
const skeletonClass = css`
  width: 94px;
  height: 36px;
`;
const playerImageBoxClass = css`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const botSignClass = css`
  font-weight: 700;
  font-size: 8px;
  line-height: 130%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  color: #dfdfdf;
  position: absolute;
  width: 23px;
  height: 10px;
  bottom: 0;
  right: 2px;
  justify-content: center;
  background: #2d241c;
  border-radius: 3px;
  padding: 0 3px 2px;
`;
const playerImageClass = css`
  width: 32px;
  height: 32px;
  border-radius: 5.4px;
`;
const nameBoxClass = css`
  display: flex;
  gap: 3px;
  align-items: center;
`;
const playerNameClass = css`
  font-weight: 700;
  font-size: 13px;
  line-height: 140%;
  color: #dfdfdf;
  max-width: 88px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const totalClass = css`
  font-family: 'Onest';
  font-weight: 700;
  font-size: 13px;
  line-height: 140%;
  background: linear-gradient(180deg, #ffaa63 0%, #ea9a4e 100%);
  background-clip: text;
  text-fill-color: transparent;
`;

const botIconClass = css`
  height: 13px;
  path {
    fill: #0fac7f;
  }
`;
