import { css } from '@emotion/css';

import { useUpgradeT } from 'app/upgrade/glossary';
import { ReactComponent as LeftChickenImage } from 'app/upgrade/assets/left-chicken.svg';
import { getUpgradeOperator, useUpgradeState } from 'app/upgrade/store';
import { getInventoryOperator } from 'app/inventory';

type UpgradeLeftChickenBlockProps = {
  speech: string;
};

const UpgradeUnitsLeftChicken = ({ speech }: UpgradeLeftChickenBlockProps): JsxElement => {
  const t = useUpgradeT().sub('stake');

  return (
    <div className={mainClass}>
      <LeftChickenImage className={imageClass} />
      <div className={titleClass}>{t(speech)}</div>
      {speech.includes('won') && <Buttons />}
      {speech.includes('lost') && <Cashback />}
    </div>
  );
};

const Buttons = (): JsxElement => {
  const t = useUpgradeT().sub('stake.won');
  const state = useUpgradeState();
  const { item } = state.result!;
  const resetAction = () => {
    getUpgradeOperator().reset();
    getInventoryOperator().toggleItem(item.id);
  };

  const sellAction = () => {
    getUpgradeOperator().sellPrize();
  };

  return (
    <div className={buttonsWrapperClass}>
      <button className={newUpgradeButtonClass} onClick={resetAction}>
        {t('new-upgrade')}
      </button>
      <button className={sellButtonClass} onClick={sellAction}>
        {t('sell', { cash: item.cost })}
      </button>
    </div>
  );
};

const Cashback = (): JsxElement => {
  const t = useUpgradeT().sub('stake.lost');

  return <div className={cashBackClass}>{t('cashback')}</div>;
};

const cashBackClass = css`
  font-weight: 700;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  color: #988c92;
`;
const buttonsWrapperClass = css`
  display: flex;
  gap: 12px;
`;
const sellButtonClass = css`
  width: 139px;
  height: 38px;
  background: #26232d;
  border-radius: 4px;
  font-family: 'Onest';
  font-weight: 700;
  font-size: 13px;
  line-height: 140%;
  text-align: center;
  color: #988c92;
  border: 0;
  cursor: pointer;
`;
const newUpgradeButtonClass = css`
  cursor: pointer;
  border: 0;
  width: 130px;
  height: 38px;
  background: linear-gradient(180deg, #ffaa63 0%, #ea9a4e 100%);
  border-radius: 4px;
  font-family: 'Onest';
  font-weight: 700;
  font-size: 13px;
  line-height: 140%;
  text-align: center;
  color: #2d241c;
`;
const mainClass = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 14px;
  width: 356px;
  z-index: 10;
`;
const imageClass = css`
  height: 138px;
`;
const titleClass = css`
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  background: linear-gradient(180deg, #ffaa63 0%, #ea9a4e 100%);
  background-clip: text;
  text-fill-color: transparent;
`;

const dumb = (code: string) => () => <UpgradeUnitsLeftChicken speech={`${code}.speech`} />;

export const UpgradeUnitsLeftChickenUpgrading = dumb('upgrading');
export const UpgradeUnitsLeftChickenWon = dumb('won');
export const UpgradeUnitsLeftChickenLost = dumb('lost');
