import {
  CASE_BATTLE_RARE_CHANCE,
  CASE_BATTLE_ROUND_REEL_DURATION,
  CASE_BATTLE_ROUND_DURATION as ROUND_DURATION,
} from 'app/case/battle/definitions';
import { openCaseBattleInsightResultsDialog } from 'app/case/battle/insight/results/dialog';
import {
  CaseBattleInsightBigWinSound,
  CaseBattleInsightCaseReelRollingSound,
} from 'app/case/battle/insight/sounds';
import {
  CaseBattleInsightInternalStore,
  CaseBattleInsightLiveStandData as StandData,
} from 'app/case/battle/insight/store/definitions';
import { CaseData, CaseSlotData } from 'domain/case/definitions';
import { chronicle } from 'packs/libs/chronicle';
import { coilReq, serverNow } from 'packs/libs/coil';
import { getMostIndex } from 'support/etc/get-most';
import { MutSpec, mut } from 'support/etc/immutate';
import { round2DP } from 'support/etc/round-decimal-places';
import sleep from 'support/etc/sleep';
import { launch } from 'support/react/use-launch';

const AFTER_DROP_DURATION = ROUND_DURATION - CASE_BATTLE_ROUND_REEL_DURATION;

export class CaseBattleInsightLiveStore {
  declare currentProcess?: LiveProcess;

  constructor(private _op: CaseBattleInsightInternalStore) {}

  async run(passCompletedRounds: boolean) {
    if (this.currentProcess !== undefined) this.currentProcess.kill();
    this.currentProcess = new LiveProcess(this._op);
    this.currentProcess.run(passCompletedRounds);
  }

  unmount() {
    this.currentProcess?.kill();
  }
}

class LiveProcess {
  private declare killed: boolean;
  constructor(private op: CaseBattleInsightInternalStore) {}

  kill() {
    this.killed = true;
  }

  async run(passCompletedRounds: boolean) {
    const { base, live, commit } = this.op;

    type Stand = StandData;
    const battle = this.op.base.getState();
    const { cases, startedAt, results, cost } = battle;
    let totalRounds = cases.length;

    function* iterateDrops() {
      const chanceIt = results!.values();
      for (const caseData of cases) {
        const openedCase = new OpenedCase(caseData);
        yield [
          caseData,
          battle.members.map(() => {
            const chance = chanceIt.next().value;
            return openedCase.pickEntry(chance);
          }),
        ] as const;
      }
    }

    let currentCase: any;
    let stands: Stand[] = battle.members.map((player) => ({ player, total: 0, drops: [] }));
    let mutStands = (fn: MutSpec<Stand[]>) => {
      stands = mut(stands, fn);
    };
    // seat, spot, stand
    let currentDrop: any[];
    const dropsIt = new StrictIterator(iterateDrops());
    const pushCurrentDrop = () => {
      mutStands((draft) => {
        const previousDropIt = currentDrop.values();
        for (const stand of draft) {
          const drop = previousDropIt.next().value;
          stand.total += round2DP(drop.cost);
          stand.drops.push(drop);
        }
      });
    };

    const progress = () => {
      mutStands((draft) => {
        currentDrop = [];
        const dropResult = dropsIt.nextValue();
        currentCase = dropResult[0];
        const currentDropIt = new StrictIterator(dropResult[1].values());

        for (const stand of draft) {
          const [index, slot] = currentDropIt.nextValue();
          currentDrop.push(slot);
          stand.dropping = { slotIndex: index };
        }

        let highestCost;
        const index = getMostIndex((a, b) => a.cost - b.cost, currentDrop);
        draft[index].dropping!.highest = true;
      });
    };

    // CaseBattleInsightCaseRollSound.replay();
    //   CaseBattleInsightEndOfCaseRollSound.replay();

    let currentRound = 1;
    if (passCompletedRounds) {
      const now = serverNow();
      let roundFinishAt = startedAt + ROUND_DURATION;
      while (now > roundFinishAt) {
        ++currentRound;
        if (currentRound === totalRounds) break;
        roundFinishAt += ROUND_DURATION;
        progress();
      }
    }

    while (currentRound <= totalRounds) {
      progress();

      live.setState({
        round: currentRound,
        currentCase,
        stands,
      });
      commit();

      CaseBattleInsightCaseReelRollingSound.replay();

      await sleep(CASE_BATTLE_ROUND_REEL_DURATION);
      if (this.killed) return;

      if (currentDrop!) {
        pushCurrentDrop();
        live.updState({ stands });
        commit();
        playBigWinSoundIfLowChanceAny(currentDrop);
      }

      await sleep(AFTER_DROP_DURATION);
      if (this.killed) return;

      ++currentRound;
    }

    live.setState({
      round: totalRounds,
      stands,
    });
    commit();

    // await sleep(CASE_BATTLE_CALCULATION_DURATION);

    const winnerIndex = getMostIndex((a, b) => a.total - b.total, stands);

    mutStands((draft) => {
      const [[winner], others] = divide(draft, (_, i) => i === winnerIndex);
      for (const stand of others) {
        const drops = stand.drops;
        stand.drops = [];
        winner.drops.push(...drops);
      }
    });

    live.setState({
      round: totalRounds,
      stands,
    });

    commit();

    const totalSum = stands.reduce((sum, stand) => sum + stand.total, 0);
    const [[winner], others] = divide(base.getState().members, (_, i) => i === winnerIndex);

    openCaseBattleInsightResultsDialog({
      winner,
      others,
      total: totalSum,
      cost,
      battleId: base.getState().id,
      duplicate() {
        launch(async () => {
          const { cases, size } = base.getState();
          const id = await coilReq({
            action: 'case.battle.create',
            data: { size, cases: casesToIdAmount(cases) },
          });
          chronicle.push(`/case-battles/${id}`);
        });
      },
    });
  }
}

const playBigWinSoundIfLowChanceAny = (slots: CaseSlotData[]) => {
  for (const slot of slots) {
    if (slot.chance <= CASE_BATTLE_RARE_CHANCE) {
      CaseBattleInsightBigWinSound.replay();
      return;
    }
  }
};

class OpenedCase {
  constructor(private data: CaseData) {}

  pickEntry(chance: number) {
    let current = chance * 100;
    for (const [index, slot] of this.data.slots.entries()) {
      if (current <= slot.chance) return [index, slot] as const;
      current -= slot.chance;
    }
    throw Error('invalid_chance');
  }
}

class StrictIterator<T> {
  constructor(private iterator: Iterator<T>) {
    this.iterator = iterator;
  }

  nextValue() {
    const { value, done } = this.iterator.next();
    if (done) throw new Error('iterator is done');
    return value as T;
  }
}

const divide = <T>(list: T[], fn: (v: T, i: number) => boolean) => {
  const result: T[][] = [[], []];
  for (const [i, v] of list.entries()) {
    result[fn(v, i) ? 0 : 1].push(v);
  }
  return result;
};

const casesToIdAmount = (cases: CaseData[]): { id: string; amount: number }[] => {
  const map = new Map<string, number>();
  for (const caseData of cases) {
    const amount = map.get(caseData.id) || 0;
    map.set(caseData.id, amount + 1);
  }
  return [...map.entries()].map(([id, amount]) => ({ id, amount }));
};
