import { css } from '@emotion/css';
import { CrushControlCrashResult } from 'app/crush/control/crash-result';
import { CrushControlGrabButton } from 'app/crush/control/grab-button';
import { CrushControlStartButton } from 'app/crush/control/start-button';
import { CrushControlPlayingRoundStats } from 'app/crush/control/units/playing-round-stats';
import { CrushControlSelectedSkinsStats } from 'app/crush/control/units/selected-skins-stats';
import { CrushControlStakeStats } from 'app/crush/control/units/stake-stats';
import { XPanelProvider } from 'app/crush/control/x-panel/store';
import { CrushControlXPanel } from 'app/crush/control/x-panel/x-panel';
import { useCrushScopeRound } from 'app/crush/store/store';
import { CrushRoundStage } from 'domain/crush';
import { useMemo } from 'react';

export const CrushControl = (): JsxElement => {
  const round = useCrushScopeRound();

  return useMemo(() => {
    const stage = round.stage;

    const composeContent = () => {
      if (stage === undefined) return null;

      if (round.stake) {
        if (stage === CrushRoundStage.countdown) {
          return (
            <div className={stageClass}>
              <CrushControlStakeStats {...round.stake} />
              <CrushControlXPanel />
              <CrushControlStartButton />
            </div>
          );
        }

        if (stage === CrushRoundStage.growth && !round.won) {
          return (
            <div className={stageClass}>
              <CrushControlPlayingRoundStats />
              <CrushControlXPanel />
              <CrushControlGrabButton />
            </div>
          );
        }

        return (
          <div className={stageClass}>
            <CrushControlPlayingRoundStats />
            <CrushControlXPanel />
            <CrushControlCrashResult />
          </div>
        );
      }

      return (
        <div className={stageClass}>
          <CrushControlSelectedSkinsStats />
          <CrushControlXPanel />
          <CrushControlStartButton />
        </div>
      );
    };

    return (
      <XPanelProvider>
        <div className={mainClass}>{composeContent()}</div>
      </XPanelProvider>
    );
  }, [round.stage, round.stake, round.won]);
};

const mainClass = css`
  width: 433px;
  background: #1e1c22;
`;

const stageClass = css`
  display: flex;
  flex-direction: column;
`;
