import { throttle } from 'lodash';
import { InfToast } from 'packs/libs/inf/toast';
import { createSound } from 'packs/libs/sound';
import { createElement, ReactNode } from 'react';
import { toast, TypeOptions } from 'react-toastify';
import NOTIFICATION_SOUND_URL from './notification.mp3';

const NotificationSound = createSound(NOTIFICATION_SOUND_URL);
NotificationSound.enable();
const play = throttle(() => {
  NotificationSound.replay();
}, 1e3);

const sendToast = (node: ReactNode) => {
  play();
  toast(node, {
    position: 'top-right',
  });
};

export type ToastType = 'success' | 'error' | 'info';

export function showSuccessToast(children: ReactNode) {
  showToast('success', children);
}

export function showErrorToast(children: ReactNode) {
  showToast('error', children);
}

export function showToast(type: TypeOptions, children: ReactNode) {
  sendToast(createElement(InfToast, { type, children }));
}

export const toastSuccess = (children: ReactNode) => {
  sendToast(createElement(InfToast, { type: 'success', children }));
};

export function infTransNote(type: TypeOptions, code: string, data?: Rsa) {
  sendToast(
    createElement(function TransToast() {
      return (
        <InfToast type={type}>
          <pre>
            DO NOT USE ME!!
            {Error().stack}
          </pre>
        </InfToast>
      );
    })
  );
}

export const showInfSuccess = (node: ReactNode) => {
  showToast('success', node);
};

export function infSuccess(code = 'success', data?: Rsa) {
  infTransNote('success', code, data);
}

export function infError(code = 'error', data?: Rsa) {
  infTransNote('error', code, data);
}

export function infInfo(code = 'info', data?: Rsa) {
  infTransNote('info', code, data);
}

// export default extendFn(infNote, {
//   success: infSuccess,
//   error: infError,
// });

if (__DEV__) {
  window['info_trans_toast'] = (code: string, opts?: Rsa) => {
    infInfo(code, opts);
  };
}
