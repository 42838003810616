type RefEffectCallback = void | (() => void);
export type RefEffect<T> = (el: T) => RefEffectCallback;
export const createRefEffect = <T = HTMLDivElement>(factory: (el: T) => RefEffectCallback) => {
  let callback: RefEffectCallback;
  return (el: T | null) => {
    if (el === null) {
      if (callback) callback();
    } else {
      callback = factory(el);
    }
  };
};
