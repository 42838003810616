import { ContestCardCountdown } from 'app/contest/card/units/countdown';
import { ContestCardDoubleColumnRow } from 'app/contest/card/units/double-column-row';
import { ContestCardMainBox } from 'app/contest/card/units/main-box';
import { ContestCardPrize } from 'app/contest/card/units/prize';
import { ContestBriefData } from 'domain/contest';
import { ReactNode } from 'react';
import { ContestCardStatusCalculation } from 'app/contest/card/units/status-calculation';

type ContestCardActiveProps = {
  contest: ContestBriefData;
  children?: ReactNode;
};

export const ContestCardCalculation = ({
  contest,
  children,
}: ContestCardActiveProps): JsxElement => {
  return (
    <ContestCardMainBox data={contest}>
      <ContestCardDoubleColumnRow>
        <ContestCardStatusCalculation />
        <ContestCardPrize contest={contest} />
      </ContestCardDoubleColumnRow>
      {children}
    </ContestCardMainBox>
  );
};
