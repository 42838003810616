import React, { createElement, Fragment, useEffect, useState } from 'react';

export type SyringeInjectorProps = {
  onClose(): void;
};

export type SyringeInjector = React.ComponentType<SyringeInjectorProps>;

type Inject = (injector: SyringeInjector) => void;

const initialState = () => new Map();

export function createSyringe(): [React.ComponentType, Inject] {
  let i = 0;
  let beforeMount: SyringeInjector[] = [];
  let doInject: Inject = beforeMount.push.bind(beforeMount);

  const Syringe = () => {
    const [items, setItems] = useState<Map<number, React.ReactNode>>(initialState);

    useEffect(() => {
      function set(key: number, val: React.ReactNode) {
        setItems((items) => new Map(items).set(key, val));
      }

      function del(key: number) {
        setItems((items) => {
          const next = new Map(items);
          next.delete(key);
          return next;
        });
      }

      doInject = (Injector: SyringeInjector) => {
        const key = ++i;

        // if yarn start -> hot reloading support
        if (__DEV__) {
          set(
            key,
            createElement(
              function Dev() {
                return createElement(Injector, {
                  onClose() {
                    del(key);
                  },
                });
              },
              { key }
            )
          );
        } else {
          set(
            key,
            createElement(Injector, {
              key,
              onClose() {
                del(key);
              },
            })
          );
        }
      };

      for (const Injector of beforeMount) doInject(Injector);
      beforeMount = [];
    }, []);

    return createElement(Fragment, {
      children: [...items.values()],
    });
  };

  return [Syringe, (injector) => doInject(injector)];
}
