import { useCrushScopeRound } from 'app/crush/store/store';
import { CrushRoundStage } from 'domain/crush';
import { useEffect, useState } from 'react';
import { CrushSceneBackgroundAnimation } from './animation';

export const CrushSceneBackground = (): JsxElement => {
  const [playX2, setPlayX2] = useState<boolean>(false);

  const { growth, stage } = useCrushScopeRound();

  const stop = stage === CrushRoundStage.countdown || stage === CrushRoundStage.crash;

  useEffect(() => {
    growth?.subGrow((x) => {
      const n = x?.num;
      if (Number(n) > 2) {
        setPlayX2(true);
      } else setPlayX2(false);
    });
  }, [growth]);

  return <CrushSceneBackgroundAnimation stop={stop} playX2={playX2} />;
};
