import { css, keyframes } from '@emotion/css';
import { createNativeElement as el } from 'support/html/native-jsx';

export const launchCaseBattleInsightReelCostAnimation = (box: HTMLDivElement, cost: number) => {
  // FIXME: find out the actual bug with multiple cost elements
  // https://app.clickup.com/t/85zt2z5pq
  const prev = box.querySelector(':scope > span');
  if (prev) prev.remove();

  box.appendChild(el('span', { className: costClass }, `$${cost}`));
};

const slideIn = keyframes`
   from {
    bottom: -30px;
    opacity: 0;
  }

  to {
    bottom: 15px;
    opacity: 1;
  }
`;

const costClass = css`
  padding: 8px 10px;
  text-align: center;
  font-weight: 700;
  font-size: 15px;
  line-height: 130%;
  color: #dfdfdf;

  background: radial-gradient(ellipse, rgba(100, 100, 106, 0.4) 0%, rgba(2, 0, 31, 0) 60%);

  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -30px;
  opacity: 0;
  animation: ${slideIn} 0.3s forwards;
`;
