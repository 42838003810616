import { GrowNum } from 'domain/crush/growth';
import { GraphChickStageOpFactory } from 'app/crush/scene/chick/block/definitions';
import {
  GraphChickElement,
  makeGraphChickElement,
} from 'app/crush/scene/chick/block/units/element';

export const Growth: GraphChickStageOpFactory = (c) => {
  const chickRun1 = makeGraphChickElement('run1').loop();
  const chickRun2 = makeGraphChickElement('run2').loop();

  return (mode) => {
    type Run = {
      start(x: GrowNum): void;
      update(x: GrowNum): void;
      close(): void;
    };

    function createRun(chick: GraphChickElement, factory: (chick: GraphChickElement) => Run): Run {
      const { start, update, close } = factory(chick);
      return {
        start(x) {
          start(x);
          chick.play(0);
        },
        update,
        close() {
          close();
          chick.stop();
        },
      };
    }

    const run1 = createRun(chickRun1, (chick) => {
      return {
        start() {},
        update() {},
        close() {},
      };
    });

    const run2 = createRun(chickRun2, (chick) => {
      return {
        start() {},
        update() {},
        close() {},
      };
    });

    let currentRun: Run;
    let unsubGrow: () => void;

    return {
      start({ growth }) {
        const getRun = (x: GrowNum): Run => {
          const n = x.num;

          if (n < 2) return run1;
          return run2;
        };

        unsubGrow = growth!.subGrow((x) => {
          const run = getRun(x!);

          if (currentRun !== run) {
            currentRun?.close();
            currentRun = run;
            currentRun.start(x!);
          } else {
            currentRun.update(x!);
          }
        });
      },
      close() {
        currentRun?.close();
        currentRun = null!;
        unsubGrow?.();
      },
    };
  };
};
