import { coilSend } from 'packs/libs/coil';
import { SimpleEventTarget } from 'support/struct/simple-event-target';

const PERIOD = 5000;
const SIGMA = 200;

const milliseconds = PERIOD + SIGMA;

const sub = new SimpleEventTarget<number>();

export const subOnLag = (listener: (ms: number) => void) => sub.sub(listener);

let last = Date.now();

function tick() {
  const now = Date.now();
  if (last + milliseconds < now) {
    const delta = now - PERIOD - last;
    sub.emit(delta);
  }
  last = now;
}

setInterval(tick, PERIOD);

subOnLag(() => {
  if (__DEBUG__) console.log('lag detected');
  if (!document.hidden) coilSend('sync');
});
