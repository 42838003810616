import { css } from '@emotion/css';
import { LeaderBoardHallOfFameItemData } from 'app/leader-board/definitions';
import { useLeaderBoardT } from '../glossary';
import { ReactComponent as UnionIcon } from './assets/union.svg';
import { LeaderboardHallOfFameList } from './players';

type LeaderBoardHallOfFameProps = { list: LeaderBoardHallOfFameItemData[] };

export const LeaderBoardHallOfFame = ({ list }: LeaderBoardHallOfFameProps): JsxElement => {
  return (
    <div className={hallOfFameBoxClass}>
      <LeaderboardHallOfFameHead />
      <LeaderboardHallOfFameList list={list} />
    </div>
  );
};

export const LeaderboardHallOfFameHead = (): JsxElement => {
  const t = useLeaderBoardT().sub('hall-of-fame');
  return (
    <div className={hallOfFameHeadBoxClass}>
      <UnionIcon />
      <span className={hallOfFameHeadClass}>{t('title')}</span>
    </div>
  );
};

const hallOfFameBoxClass = css`
  min-width: 309px;
  height: 100%;
  width: 100%;
  padding: 12px 12px 0 12px;
  border-radius: 12px;
  background-color: #1e1c22;
  max-height: 923px;
`;
const hallOfFameHeadClass = css`
  padding-left: 8px;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #dfdfdf;
`;
const hallOfFameHeadBoxClass = css`
  padding: 12px 12px 18px 12px;
  display: flex;
  align-items: center;
`;
