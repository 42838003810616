import { css } from '@emotion/css';

import { CaseBattleMonitorCard } from 'app/case/battle/monitor/card/card';
import { useCaseBattleMonitorState } from 'app/case/battle/monitor/store';
import { useActor } from 'domain/actor';
import { StdDesktopContainer } from 'packs/std';

export const CaseBattleMonitorList = (): JsxElement => {
  const { loading, battles } = useCaseBattleMonitorState();
  const actor = useActor();
  if (loading) return null;

  return (
    <div className={mainClass}>
      {battles.listMapV((battle) => {
        return <CaseBattleMonitorCard key={battle.id} actor={actor} data={battle} />;
      })}
    </div>
  );
};

const mainClass = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  flex: 1;
`;
