import { css, keyframes } from '@emotion/css';
import { ReactComponent as FirstClouds } from 'app/crush/assets/first-clouds.svg';
import { stdBreakpoints } from 'packs/std';

export const CrushSceneClouds = (): JsxElement => {
  return (
    <div className={mainClass}>
      <FirstClouds className={cloudsClass} />
      <FirstClouds className={cloudsAnimationClass} />
    </div>
  );
};

const mainClass = css`
  overflow: hidden;
  padding-top: 44px;
  height: 100%;
  width: 100%;
  ${stdBreakpoints.up(1920)} {
    padding-top: 88px;
  }
`;

const runAnimationClouds = keyframes`
  0%{
    transform: translateX(0%);
  }

  100%{
    transform: translateX(-200%);
  }
`;

const cloudsAnimationClass = css`
  height: 120px;
  position: absolute;
  left: 100%;
  animation: ${runAnimationClouds} 60s linear infinite;
  padding: 0 15px;
  ${stdBreakpoints.up(1920)} {
    height: 144px;
  }
`;

const cloudsClass = css`
  ${cloudsAnimationClass}
  animation-delay: 30s;
`;
