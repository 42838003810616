const ReferralIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.52461 2.45356C8.12812 1.18215 9.87187 1.18215 10.4754 2.45356L11.5184 4.65088C11.7581 5.15576 12.2213 5.5057 12.7572 5.58666L15.0895 5.93902C16.439 6.1429 16.9779 7.86716 16.0013 8.85681L14.3137 10.5672C13.9259 10.9602 13.749 11.5264 13.8405 12.0813L14.2389 14.4964C14.4694 15.8938 13.0587 16.9595 11.8517 16.2997L9.76562 15.1595C9.28631 14.8975 8.71369 14.8975 8.23438 15.1595L6.14832 16.2997C4.94129 16.9595 3.53057 15.8938 3.76109 14.4964L4.15949 12.0813C4.25103 11.5264 4.07408 10.9602 3.68631 10.5672L1.99866 8.85681C1.02215 7.86716 1.561 6.1429 2.9105 5.93902L5.24278 5.58666C5.77867 5.5057 6.24192 5.15576 6.48158 4.65088L7.52461 2.45356Z"
        fill="#988C92"
      />
    </svg>
  );
};

export { ReferralIcon };
