import { css } from '@emotion/css';

import { useLayoutT } from 'app/layout/glossary';
import { coilListenR } from 'packs/libs/coil';
import { Field, Message } from 'protobufjs';
import { linearClamp } from 'support/polished/linear-clamp';
import { SubCell } from 'support/react/sub-cell';
import { CasesIcon } from '../assets/cases-icon';
import { OnlineIcon } from '../assets/online-icon';
import { UpgradesIcon } from '../assets/upgrades-icon';

type LidStatsData = {
  online?: number;
  upgrades?: number;
  cases?: number;
};

class LidStatsMessage extends Message {
  @Field.d(1, 'uint32', 'optional')
  public declare online: number;
  @Field.d(2, 'uint32', 'optional')
  public declare upgrades: number;
  @Field.d(3, 'uint32', 'optional')
  public declare cases: number;
}

const Cell = new SubCell<LidStatsData>({});
coilListenR('ls', (r) => {
  const next = LidStatsMessage.decode(r.bytes());
  if (__DEBUG__) lg.event('lid-stats', next);
  Cell.set({ ...Cell.get(), ...next });
});

export const LayoutStatsPanel = (): JsxElement => {
  const t = useLayoutT().sub('stats');
  const data = Cell.useValue()!;
  return (
    <div className={statsBoxClass}>
      <div className={statClass}>
        <OnlineIcon />
        <p className={textClass}>
          {data.online} {t('online')}
        </p>
      </div>
      <div className={statClass}>
        <UpgradesIcon />
        <p className={textClass}>
          {data.upgrades} {t('upgrades')}
        </p>
      </div>
      <div className={statClass}>
        <CasesIcon />
        <p className={textClass}>
          {data.cases} {t('cases')}
        </p>
      </div>
    </div>
  );
};

const statsBoxClass = css`
  display: flex;
  align-items: center;
  gap: 25px;
`;

const statClass = css`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  svg {
    height: 14px;
  }
`;

const textClass = css`
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  color: #696155;
`;
