import { css } from '@emotion/css';
import { ContestCardStatusIcon } from 'app/contest/card/units/status-icon';
import { useContestT } from 'app/contest/glossary';
import { format } from 'date-fns';
import { ContestBriefData } from 'domain/contest';

type ContestCardStatusFinishedProps = {
  contest: ContestBriefData;
};

export const ContestCardStatusFinished = ({
  contest,
}: ContestCardStatusFinishedProps): JsxElement => {
  const t = useContestT().sub('card');
  const finished = contest.start + contest.duration;

  return (
    <div className={mainClass}>
      <div className={titleClass}>{t('status')}</div>
      <div className={statusBoxClass}>
        <ContestCardStatusIcon color="#988C92" />
        {t('finished-at', {
          date: format(finished, 'dd.MM.yyyy'),
          time: format(finished, 'HH:mm'),
        })}
      </div>
    </div>
  );
};

const mainClass = css`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
  width: 100%;
`;

const titleClass = css`
  font-family: 'Commissioner';
  font-weight: 500;
  font-size: 13px;
  line-height: 140%;
  color: #dfdfdf;
`;

const statusBoxClass = css`
  display: flex;
  align-items: center;
  gap: 4px;
  font-family: 'Onest';
  font-weight: 700;
  font-size: 13px;
  line-height: 140%;
  color: #ffffff;
`;
