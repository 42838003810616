import { css } from '@emotion/css';
import { LeaderBoardHallOfFameItemData } from 'app/leader-board/definitions';
import { format } from 'date-fns';
import { PlayerImage } from 'domain/player/image/image';
import { SkinImg } from 'domain/skin/units/image';
import { returnGradientFrame } from '../skin-border';

type HallOfFamePlayersProps = { list: LeaderBoardHallOfFameItemData[] };

export const LeaderboardHallOfFameList = ({ list }: HallOfFamePlayersProps): JsxElement => {
  return (
    <div className={hallOfFameListBoxClass}>
      {list
        ? list.map((item: LeaderBoardHallOfFameItemData) => <Player item={item} key={item.at} />)
        : null}
    </div>
  );
};

export const Player = ({ item }): JsxElement => {
  //formating date
  const formattedDate = format(new Date(item.at), 'dd.MM.yyyy');

  const Frame = returnGradientFrame(item.prize.skin.color);
  return (
    <div className={playerBoxClass}>
      <div className={playerInfoBoxClass}>
        <div className={playerUserInfoBoxClass}>
          <PlayerImage className={playerAvatarClass} code={item.player.image} />
          <div className={playerUserInfoClass}>
            <span className={playerUserNameClass}>{item.player.name}</span>
            <span className={playerDateClass}>{formattedDate}</span>
          </div>
        </div>
        <div className={playerCostClass}>
          <span className={playerCostColorClass}>${item.prize.cost}</span>
        </div>
      </div>
      <Frame>
        <SkinImg className={skinImageClass} code={item.prize.skin.image} alt={item.prize.skin.id} />
      </Frame>
    </div>
  );
};

const hallOfFameListBoxClass = css`
  padding-bottom: 8px;
  height: calc(100% - 22px);
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  overflow: scroll;

  ::-webkit-scrollbar {
    width: 0;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: transparent;
  }
`;
const playerBoxClass = css`
  padding-right: 4px;
  width: 100%;
  height: 53px;
  background: #18171c;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const playerInfoBoxClass = css`
  width: 65%;
  padding: 9px 4px 9px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const playerUserInfoBoxClass = css`
  display: flex;
  align-items: center;
`;
const playerAvatarClass = css`
  border-radius: 4.5px;
  width: 24px;
  height: 24px;
`;
const playerUserInfoClass = css`
  width: 115px;
  display: flex;
  flex-direction: column;
  padding-left: 8px;
`;
const playerUserNameClass = css`
  font-family: 'Onest';
  font-weight: 700;
  font-size: 13px;
  line-height: 140%;
  color: #dfdfdf;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
`;
const playerDateClass = css`
  font-family: 'Onest';
  font-weight: 400;
  font-size: 12px;
  color: #988c92;
`;
const playerCostClass = css`
  width: 50px;
  padding-left: 5px;
  font-family: 'Onest';
  font-weight: 700;
  font-size: 14px;
  color: transparent;
  display: flex;
  justify-content: flex-start;
`;
const playerCostColorClass = css`
  background: linear-gradient(180deg, #ffaa63 0%, #ffaa63 0.01%, #ea9a4e 100%);
  background-clip: text;
`;
const playerSkinBackgroundClass = css`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 1px 1px 0px;
  box-sizing: border-box;
  width: clamp(72px, 72px, 74px);
  height: clamp(43px, 44px, 45px);
  background: linear-gradient(
    135deg,
    rgba(30, 28, 34, 0) 0%,
    rgba(191, 89, 255, 0.2) 50%,
    rgba(191, 89, 255, 0.8) 100%
  );
  border-radius: 8px;
`;
const playerSkinBorderClass = css`
  width: clamp(71px, 72px, 73px);
  height: clamp(42px, 43px, 44px);
  border-radius: 8px;
  background: rgba(28, 27, 32, 75%);
  display: flex;
  align-items: center;
  justify-content: center;
`;
const skinImageClass = css`
  width: 54.62px;
  height: 35.12px;
`;
