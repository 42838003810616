// - `pending` - Заявка зарегистрирована, начата работа по валидации заявки. `Начальное состояние`
// - `checked` - Заявка проверена, на текущий момент есть скины на бирже с ценой ниже или равной указанной
// - `pending_confirm` - Ожидаем подтверждения заявки администрацией
// - `confirmed` - Заявка подтверждена, ожидает отправки вендору
// - `processing` - Запрос отправлен вендор, ожидаем
// - `pending_player` - Скин куплен, ожидаем прием скина игроком
// - `completed` - Вывод скина прошел успешно. `Завершающее состояние`
// - `failed` - Заявка отклонена, причина указана в error. `Завершающее состояние`

import { css } from '@emotion/css';
import { InventoryItemData } from 'app/inventory/definitions';
import { openInventoryItemLockTooltip } from 'app/inventory/skins/lock/tooltip';
import { ItemLockCode } from 'domain/item';
import { ReactNode, useMemo } from 'react';
import { stashCb } from 'support/etc/stash';

import { ReactComponent as WaitingPlayerIcon } from 'app/inventory/skins/lock/icons/waiting-player.svg';
import { ReactComponent as CompletedIcon } from 'app/inventory/skins/lock/icons/completed.svg';
import { ReactComponent as ConfirmedIcon } from 'app/inventory/skins/lock/icons/confirmed.svg';
import { ReactComponent as FailedIcon } from 'app/inventory/skins/lock/icons/failed.svg';
import { ReactComponent as PendingIcon } from 'app/inventory/skins/lock/icons/pending.svg';
import { ReactComponent as ProcessingIcon } from 'app/inventory/skins/lock/icons/processing.svg';

const getIcon = stashCb(() => {
  const ICONS = new Map<ItemLockCode, ReactNode>([
    ['wd.pending', <PendingIcon />],
    ['wd.checked', <PendingIcon />],
    ['wd.pending_confirm', <PendingIcon />],
    ['wd.confirmed', <ConfirmedIcon />],
    ['wd.processing', <ProcessingIcon />],
    ['wd.pending_player', <WaitingPlayerIcon />],
    ['wd.completed', <CompletedIcon />],
    ['wd.failed', <FailedIcon />],
  ]);

  return ICONS.get.bind(ICONS);
});

type InventoryItemLockProps = {
  data: InventoryItemData;
};

export const InventoryItemLock = ({ data }: InventoryItemLockProps): JsxElement => {
  return useMemo(() => {
    const { lock } = data;
    if (!lock) throw new Error('Lock is not defined');

    return (
      <div className={mainClass}>
        <div
          className={iconBoxClass}
          onClick={(el) => {
            openInventoryItemLockTooltip(el.target as HTMLDivElement, data);
          }}
        >
          {getIcon(lock.code)}
        </div>
      </div>
    );
  }, [data.lock]);
};

const mainClass = css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(1px);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const iconBoxClass = css`
  cursor: pointer;
  height: 36px;
  width: 36px;
`;
