import { css, cx } from '@emotion/css';
import { createElement } from 'react';

type SwitchProps = {
  checked?: boolean;
  onChange(checked: boolean);
};
export const StdSwitchToggle = ({ checked, onChange }: SwitchProps) =>
  createElement('div', {
    className: mainClass,
    onClick: () => onChange(!checked),
    children: createElement('div', { className: cx(knobClass, checked && checkedKnobClass) }),
  });

const mainClass = css`
  position: relative;
  background: #2e2b35;
  border-radius: 16px;
  cursor: pointer;
  display: inline-block;
  height: 12px;
  width: 35px;
`;

const knobClass = css`
  position: absolute;
  top: -25%;
  background: #968c92;
  border-radius: 50%;
  height: 18px;
  transition-duration: 0.2s;
  width: 18px;
`;

const checkedKnobClass = css`
  transform: translateX(23px);
  background: linear-gradient(180deg, #ffaa63 0%, #ffaa63 0.01%, #ea9a4e 100%);
`;
