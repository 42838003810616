import { PlayerRole } from 'domain/player/definitions';
import { getPlayerStatsOperator } from 'domain/player/stats';
import { coilReboot, coilRelease, coilReq, coilSend } from 'packs/libs/coil';
import { kvRepo } from 'packs/libs/db';
import { showErrorToast } from 'packs/libs/inf';
import { stash_get } from 'support/etc/stash';
import { Switz } from 'support/react/switz';
import { launch } from 'support/react/use-launch';

type Payload = Rsa & {
  id: string;
  name: string;
  avatar: string;
};

type ActorState = {
  payload?: Payload;
  token?: string;
  resolved: boolean;
};

let signInParams:
  | undefined // not yet resolved
  | null // already resolved
  | { token: string }; // should be resolved

export const startupSignIn = (params: Rsa) => {
  if (params.sign_in === undefined) {
    signInParams = null;
  } else {
    const { sign_in } = params;
    signInParams = { token: sign_in };
  }
};

const [dbFind, dbSet, dbDel] = kvRepo<string>('auth');

export class ActorOperator extends Switz<ActorState> {
  // is jwt token presence already defined
  get resolved() {
    return this.getState().resolved;
  }

  get payload() {
    return this.getState().payload;
  }

  public get id(): string {
    return this.getPayload().id;
  }

  @stash_get
  // user is not resolved yet or not authorized
  public get anon(): boolean {
    return this.getState().payload === undefined;
  }

  // actor has resolved and authenticated in coil
  @stash_get
  public get signed(): boolean {
    return !this.anon;
  }

  logout() {
    launch(async () => {
      this.clear();
      coilReboot();
    });
  }

  public getPayload(): Rsa {
    const payload = this.getState().payload;
    if (payload === undefined) {
      throw new Error('unauthorized');
    }

    return payload;
  }

  public get role(): PlayerRole {
    return this.getPayload().role;
  }

  public isAdmin(): boolean {
    return this.role === PlayerRole.admin;
  }

  async connect() {
    if (signInParams !== null) {
      await dbSet(signInParams!.token);
      this.resolveToken(signInParams!.token);
      signInParams = null;
    } else {
      this.resolveToken(await dbFind());
    }
  }

  public async setCredentials(data: Rsa) {
    if (data.token) {
      const { token } = data;
      await dbSet(token);
      await this.resolveToken(token);
      // await referralResolveCallback(referral);
      // await utmRepoDel();
      getPlayerStatsOperator().setState(data.stats);
    } else {
      showErrorToast(data.problem);
    }
  }

  private async resolveToken(token?: string) {
    await this.asyncMutState(async (draft) => {
      try {
        if (token) {
          const data = await coilReq({
            force: true,
            action: 'auth',
            data: token,
          });
          draft.payload = data;
          draft.token = token;
          // getAccountOperator().setState(data);
        } else {
          coilSend('auth.anon');
        }
      } catch (e) {
        await dbDel();
      } finally {
        console.log('actor resolved');
        draft.resolved = true;
        coilRelease();
      }
    });
  }

  private async clear() {
    await dbDel();
    this.setState({
      resolved: true,
      payload: undefined,
    });
  }

  async findToken() {
    return this.getState().token;
  }
}
