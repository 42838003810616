import { css } from '@emotion/css';
import { CASE_BATTLE_RARE_CHANCE, FinalResultObj } from 'app/case/battle/definitions';
import { stashCb } from 'support/etc/stash';
import { createNativeElement as el } from 'support/html/native-jsx';
import { Dispatch } from 'react';
import { SkinData } from 'domain/skin';

export const launchCaseBattleInsightReelStatusAnimation = (
  box: HTMLDivElement,
  highestDrop: boolean | void,
  chance: number,
  skin: SkinData,
  setFinalResult: Dispatch<FinalResultObj>
) => {
  const elem = el('div', {
    className: animationClass,
  });

  const backgroundColor =
    chance <= CASE_BATTLE_RARE_CHANCE ? '#f5bf03' : highestDrop ? '#03ff00' : '#FF5A5A';
  elem.style.background = backgroundColor;

  const animation = animate(elem);
  setFinalResult({
    dropColor: `rgb(${skin.color?.join(', ')})`,
    highestColor: backgroundColor,
  });
  animation.onfinish = () => {
    setFinalResult({ dropColor: null, highestColor: null });
  };

  box.prepend(elem);
};

const animate = stashCb(() => {
  const step = (transform: number, opacity: number) => ({
    transform: `scale(${transform})`,
    opacity,
  });
  const frames = [step(0, 0), step(0.9, 0.3), step(0.7, 0.2), step(0.9, 0.3), step(0, 0)];
  return (elem: HTMLElement) => {
    return elem.animate(frames, {
      duration: 1800,
      // https://cubic-bezier.com/#.3,.8,.8,.3
      easing: 'cubic-bezier(.3,.8,.8,.3)',
    });
  };
});

const animationClass = css`
  position: absolute;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  transform: scale(0.7);
  opacity: 0.2;
`;
