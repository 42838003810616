import { css } from '@emotion/css';
import { CrushMode, CrushRoundStage } from 'domain/crush';
import { useEffect } from 'react';
import { stashCb } from 'support/etc/stash';
import { Countdown } from 'app/crush/scene/chick/block/stage/countdown';
import { Growth } from 'app/crush/scene/chick/block/stage/growth';
import { CrushStoreRound, useCrushScopeRound } from 'app/crush/store';
import { useSingleton } from 'support/react/use-singleton';
import {
  GraphChickStageOp,
  GraphChickStageOpFactory,
} from 'app/crush/scene/chick/block/definitions';
import { ChickStageCrash } from 'app/crush/scene/chick/block/stage/crash';
import { GraphChickContext } from 'app/crush/scene/chick/block/context';
import { chickHandleError } from 'app/crush/scene/chick/block/error';
import { stdBreakpoints } from 'packs/std';

const getStageOp = stashCb(() => {
  const StageOperators: [CrushRoundStage, GraphChickStageOpFactory][] = [
    [CrushRoundStage.countdown, Countdown],
    [CrushRoundStage.growth, Growth],
    [CrushRoundStage.crash, ChickStageCrash],
  ];

  type Compiled = Map<CrushRoundStage, Map<CrushMode, GraphChickStageOp>>;

  const compiled: Compiled = new Map(
    StageOperators.map(([stage, factory]) => {
      const fromMode = factory(GraphChickContext);

      return [
        stage,
        new Map(
          Object.values(CrushMode).map((mode) => {
            return [mode, fromMode(mode)];
          })
        ),
      ];
    })
  );

  return (stage: CrushRoundStage, mode: CrushMode) => {
    return compiled.get(stage)!.get(mode);
  };
});

export function GraphChicken() {
  const round = useCrushScopeRound();

  const singleton = useSingleton(() => {
    const initRef = (el: HTMLDivElement) => {
      GraphChickContext.setBox(el);
    };

    let currentOp: GraphChickStageOp;

    const update = (state: CrushStoreRound) => {
      try {
        if (state.stage === undefined) {
          // currentOp?.close();
        } else {
          const op = getStageOp(state.stage, state.mode);
          currentOp?.close();
          currentOp = op!;
          op?.start(state);
        }
      } catch (e) {
        chickHandleError(e);
      }
    };

    const lifecycle = () => {
      return () => {
        try {
          currentOp?.close();
        } catch (e) {
          chickHandleError(e);
        }
      };
    };

    const children = <div ref={initRef} className={mainClass} />;

    return { update, lifecycle, children };
  });

  useEffect(() => {
    singleton.update(round);
  }, [round.stage]);

  useEffect(singleton.lifecycle, []);

  return singleton.children;
}

const mainClass = css`
  position: relative;
  width: 350px;
  height: 270px;
  overflow: visible;

  ${stdBreakpoints.up(1920)} {
    width: 500px;
    height: 300px;
  }
`;
