import { css } from '@emotion/css';
import { LayoutFooter } from 'app/layout/footer/footer';
import {
  LeaderBoardCurrentTopData,
  LeaderBoardHallOfFameItemData,
} from 'app/leader-board/definitions';
import { LeaderBoardHallOfFame } from 'app/leader-board/hall-of-fame/hall-of-fame';
import { LeaderBoardTable } from 'app/leader-board/table';
import { LeaderBoardTop3 } from 'app/leader-board/top-3/top-3';
import { useActor } from 'domain/actor';
import { coilConnect, coilListen, coilReq, coilSend } from 'packs/libs/coil';
import { createStdMediaQuery, stdBp } from 'packs/std';
import { useEffect } from 'react';
import { bunchCb } from 'support/etc/bunch-cb';
import { linearClamp } from 'support/polished/linear-clamp';
import { useSwissState } from 'support/react/swiss';
import { launch } from 'support/react/use-launch';

const SupportViewportMediaQuery = createStdMediaQuery('(min-width: 1400px)');

type ResponseData = {
  current: null | LeaderBoardCurrentTopData;
  history: LeaderBoardHallOfFameItemData[];
};

export const LeaderBoardPage = (): JsxElement => {
  // const period = useParams<{ period?: LeaderBoardPeriod }>().period ?? LeaderBoardPeriod.day;
  const [data, op] = useSwissState<ResponseData | null>(null);

  const actor = useActor();

  useEffect(
    () =>
      coilConnect(() => {
        launch(async () => {
          op.setState(
            await coilReq({
              action: 'top.enter',
            })
          );
        });

        const listeners = {
          top: (current) => {
            op.updState({ current });
          },
          hof: (data) => {
            op.updState({ history: data });
          },
        };

        return bunchCb([
          () => coilSend('top.leave'),
          coilListen('leaderboard', ([event, data]) => {
            listeners[event]!(data);
          }),
        ]);
      }),
    [actor.anon]
  );

  if (data === null) return null;

  const { current, history } = data;

  const composeCurrent = () => {
    if (current === null) return null;
    return (
      <>
        <LeaderBoardTop3 data={current} />
        <LeaderBoardTable data={current} />
      </>
    );
  };

  return (
    <div className={containerClass}>
      <div className={mainClass}>
        <div className={boxClass}>
          <div className={currentTopBoxClass}>{composeCurrent()}</div>
          <div className={hallOfFameBoxClass}>
            <LeaderBoardHallOfFame list={history} />
          </div>
        </div>
        <LayoutFooter />
      </div>
    </div>
  );
};

const containerClass = css`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  width: 100%;
  height: 100%;
`;
const mainClass = css`
  max-width: 1548px;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 22px 22px 0;
  ${stdBp.up(1920)} {
    padding-top: ${linearClamp(1920, 22, 2560, 64)};
  }
`;

const currentTopBoxClass = css`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 21px;
  max-width: 1176px;
  min-width: 720px;
  width: 100%;
  height: 100%;
  ${stdBp.down(1440)} {
    max-width: 100%;
  }
`;

const hallOfFameBoxClass = css`
  ${stdBp.down(1440)} {
    display: none;
  }
`;
const boxClass = css`
  max-height: 100%;
  flex: 1;
  display: flex;
  justify-content: space-between;
  gap: 22px;
  overflow: hidden;
`;
