import { createElement, ImgHTMLAttributes } from 'react';

type ContestImageSize = 'md';

type CaseImageProps = Omit<ImgHTMLAttributes<HTMLImageElement>, 'src' | 'id'> & {
  code: string;
  size?: ContestImageSize;
};

export const ContestImg = ({ code, size, ...props }: CaseImageProps): JsxElement => {
  return createElement('img', {
    ...props,
    src: `${ENV_CLOUD_MEDIA_URL}/contest/${code}_${size ?? 'md'}`,
  });
};
