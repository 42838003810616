import { createSoundGroup } from 'packs/libs/sound';

export const CasePlaySoundGroup = createSoundGroup();
CasePlaySoundGroup.enable();

const createSound = (url: string) => CasePlaySoundGroup.createSound(url);

import CONTEST_FINISHED_SOUND_URL from './assets/finished.mp3';
export const ContestStartedSound = createSound(CONTEST_STARTED_SOUND_URL);

import CONTEST_STARTED_SOUND_URL from './assets/started.mp3';
export const ContestFinishedSound = createSound(CONTEST_FINISHED_SOUND_URL);
