import { createBrowserHistory } from 'history';
import { createElement, useLayoutEffect, useState } from 'react';
import { Router } from 'react-router-dom';

export const chronicle = createBrowserHistory();

export const ChronicleRouter = (props: Rcp): JsxElement => {
  let [state, setState] = useState({
    action: chronicle.action,
    location: chronicle.location,
  });
  useLayoutEffect(() => chronicle.listen(setState), []);
  return createElement(Router, {
    children: props.children,
    location: state.location,
    navigationType: state.action,
    navigator: chronicle,
  });
};
