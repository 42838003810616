import { css } from '@emotion/css';
import { openCaseBattleCreationCaseSelectDialog } from 'app/case/battle/creation/case-select/dialog';
import { useCaseBattleCreationStore } from 'app/case/battle/creation/store';
import { CaseData } from 'domain/case/definitions';
import { StdKindButton } from 'packs/std/inputs/kind-button';
import { useMemo } from 'react';
import { useCaseBattleCreationT } from '../glossary';
import { ReactComponent as DotIcon } from './assets/apps-circle.svg';
import { ReactComponent as PlusIcon } from './assets/plus.svg';
import { Card } from './card';
import { CaseBattleCaseSelectDetails } from './case-details';
import { stdBp } from 'packs/std';

export type ListCaseData = {
  amount: number;
  case: CaseData;
};
type CaseBattleCreationListProps = {
  selected: ListCaseData;
  select(data: ListCaseData): void;
};
export const CaseBattleCreationCaseList = ({
  select,
  selected,
}: CaseBattleCreationListProps): JsxElement => {
  const [{ cases }] = useCaseBattleCreationStore();

  const t = useCaseBattleCreationT().sub('case-list');
  return (
    <div className={mainClass}>
      <div className={listBoxClass}>
        {cases.size != 0 ? (
          <List select={select} selected={selected} />
        ) : (
          <div className={emptySelectBoxClass}>
            <div className={emptySelectIconClass}>
              <DotIcon className={dotIconClass} />
            </div>
            <p className={emptySelectTextClass}>{t('title')}</p>
            <StdKindButton
              className={buttonSelectClass}
              kind="orange"
              children={t('add')}
              onClick={() => openCaseBattleCreationCaseSelectDialog({ select })}
            />
          </div>
        )}
      </div>
      <div className={caseDetailsBoxClass}>
        {selected && <CaseBattleCaseSelectDetails data={selected.case} />}
      </div>
    </div>
  );
};

const List = ({ select, selected }: CaseBattleCreationListProps): JsxElement => {
  const [{ cases }, op] = useCaseBattleCreationStore();
  const t = useCaseBattleCreationT().sub('case-list');
  return useMemo(() => {
    const casesOp = op.cases;
    const actions = {
      delCase: casesOp.del,
      setAmount: casesOp.setAmount,
    };
    return (
      <div className={listBoxCasesClass}>
        {cases.listMapV((data) => {
          return <Card key={data.case.id} data={data} select={select} actions={actions} />;
        })}
        <div
          onClick={() => openCaseBattleCreationCaseSelectDialog({ select })}
          className={cardAddCaseClass}
        >
          <div className={addCaseButtonClass}>
            <PlusIcon className={svgBigPlusClass} />
          </div>
          <div className={cardAddcaseTextClass}>{t('add')}</div>
        </div>
      </div>
    );
  }, [cases, selected]);
};

const mainClass = css`
  max-width: 1182px;
  height: 100%;
  width: calc(100% - 364px);
  display: flex;
  flex-direction: column;
  gap: clamp(2px, calc(0.13157894736842105 * 1vw - 0.5263157894736842px), 4px);
`;
const listBoxClass = css`
  background: #1e1c22;
  height: calc(100% - 191px);
  max-height: 697px;
  display: flex;
  overflow: auto;
  align-items: flex-start;
  justify-content: center;
`;
const listBoxCasesClass = css`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 1px;
  padding: 4px;
  ${stdBp.up(2560)} {
    gap: 3px;
  }
`;
const caseDetailsBoxClass = css`
  background: #1e1c22;
  height: 191px;
`;
const buttonSelectClass = css`
  width: 229px;
  height: 42px;
  font-size: 13px;
  border-radius: 4px;
`;
const dotIconClass = css`
  width: 64px;
  height: 64px;
`;
const emptySelectBoxClass = css`
  padding-top: calc(76px - 24px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  ${stdBp.up(1920)} {
    padding-top: 160px;
  }
`;
const emptySelectIconClass = css`
  padding: 24px;
`;
const emptySelectTextClass = css`
  padding-bottom: 24px;
  font-weight: 600;
  font-size: 12px;
  line-height: 130%;
  color: #988c92;
`;
const cardAddCaseClass = css`
  background: #18171c;
  border-radius: 12px;
  width: 145px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  ${stdBp.up(2560)} {
    height: calc(145px * 1.3);
    width: calc(147px * 1.3);
  }
`;
const addCaseButtonClass = css`
  width: 78px;
  height: 78px;
  background: #1e1c22;
  backdrop-filter: blur(27.5px);
  border-radius: 50%;
  border: 5px solid rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  ${stdBp.up(2560)} {
    height: calc(78px * 1.3);
    width: calc(78px * 1.3);
  }
`;
const svgBigPlusClass = css`
  width: 35px;
  height: 35px;
  ${stdBp.up(2560)} {
    height: calc(35px * 1.3);
    width: calc(35px * 1.3);
  }
`;
const cardAddcaseTextClass = css`
  font-weight: 600;
  font-size: 13px;
  line-height: 130%;
  color: #988c92;
  padding-top: 10px;
  ${stdBp.up(2560)} {
    font-size: calc(13px * 1.3);
  }
`;
