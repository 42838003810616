import { css, keyframes } from '@emotion/css';
import { CSSProperties, useEffect, useState } from 'react';
import { CrushBetData, useCrushScopeBets, useCrushScopeRound } from '../store';
import { PlayerImage } from 'domain/player/image/image';
import { CrushBetStatus, CrushRoundStage } from 'domain/crush';

export const CrushSceneWinners = (): JsxElement => {
  const bets = useCrushScopeBets();
  const round = useCrushScopeRound();
  const [positions, setPositions] = useState<{ [key: string]: { y: number; delay: number } }>({});
  const [animationDelay, setAnimationDelay] = useState(0);
  const [currentPositionIndex, setCurrentPositionIndex] = useState(0);

  useEffect(() => {
    setAnimationDelay(0);
  }, [bets]);

  if (round.stage !== CrushRoundStage.growth) return null;

  const updatePosition = (betId: string) => {
    const positionsY = [36, 72, 108];
    const currentY = positionsY[currentPositionIndex];

    setCurrentPositionIndex((prevIndex) => (prevIndex + 1) % positionsY.length);

    setPositions((prevPositions) => ({
      ...prevPositions,
      [betId]: { y: currentY, delay: animationDelay * 0.3 },
    }));

    setAnimationDelay((prevDelay) => prevDelay + 1);
  };

  return (
    <div className={mainClass}>
      {bets.listMapV((bet) => {
        if (bet.status !== CrushBetStatus.won) return null;

        return (
          <Winner
            key={bet.player.id}
            bet={bet}
            updatePosition={updatePosition}
            position={positions[bet.player.id]}
          />
        );
      })}
    </div>
  );
};

type WinnerProps = {
  bet: CrushBetData;
  position?: { y: number; delay: number };
  updatePosition: (betId: string) => void;
};

const Winner = ({ bet, position, updatePosition }: WinnerProps): JsxElement => {
  useEffect(() => {
    if (!position) {
      updatePosition(bet.player.id);
    }
  }, [bet.player.id, position, updatePosition]);

  const { player, prize } = bet;

  return (
    <div
      className={containerClass}
      style={
        {
          top: position?.y,
          animationDelay: `${position?.delay}s`,
        } as CSSProperties
      }
    >
      <div className={winnerBoxClass}>
        <PlayerImage className={imageClass} code={player.image} />
        <p className={nameClass}>{player.name}</p>
        <p className={winClass}>+${prize?.total.toFixed(2)}</p>
      </div>
    </div>
  );
};

const mainClass = css`
  width: 250px;
  height: 150px;
  position: relative;
  display: flex;
  justify-content: flex-start;
`;

const winnerAnimation = keyframes`
    0% {
        opacity: 0;
        transform: translateX(0%);
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: translateX(-300%);
    }
`;

const containerClass = css`
  position: absolute;
  width: 200px;
  height: 26px;
  animation: ${winnerAnimation} 4s cubic-bezier(1, 0.09, 0.91, 0.62);
  animation-iteration-count: 1;
  opacity: 0;
  cursor: default;
`;

const winnerBoxClass = css`
  width: fit-content;
  height: 26px;
  border-radius: 4px;
  padding: 5px 4px;
  background: rgba(38, 35, 45, 0.7);
  backdrop-filter: blur(6px);
  display: flex;
  align-items: center;
  gap: 2px;
`;

const imageClass = css`
  border-radius: 4px;
  background: blue;
  width: 18px;
  height: 18px;
`;

const nameClass = css`
  font-family: 'Onest';
  font-weight: 500;
  font-size: 10px;
  line-height: 13px;
  letter-spacing: -0.4px;
  padding-left: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const winClass = css`
  font-family: 'Onest';
  font-weight: 500;
  font-size: 10px;
  line-height: 130%;
  background: linear-gradient(180deg, #ffaa63 0%, #ffaa63 0.01%, #ea9a4e 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;
