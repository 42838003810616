import { css } from '@emotion/css';
import { skinColorMemoize } from 'domain/skin/utils/color-memoize';

export const CrushBetsCardSkinGradientFrame = skinColorMemoize((color: number[]) => {
  const mkRP = (alpha: number) => (rgb: number[], percent: string | number) =>
    `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${alpha}) ${percent}%`;

  const fgRp = mkRP(1);
  const bgRp = mkRP(0.2);

  const bgLine = ['rgba(30, 28, 34, 0) 0%', bgRp(color, 100)];
  const fgLine = ['rgba(0, 0, 0, 0) 45.02%', fgRp(color, 100)];

  const toGradient = (line) => `linear-gradient(135deg, ${line.join(', ')});`;

  const mainClass = css`
    position: relative;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    width: 59px;
    background-image: ${toGradient(fgLine)};
  `;

  const boxClass = css`
    height: calc(100% - 2px);
    width: calc(100% - 2px);
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(38, 35, 45,75%);
    background-image: ${toGradient(bgLine)};
  `;

  return function GradientFrame({ children }: Rcp) {
    return (
      <div className={mainClass}>
        <div className={boxClass}>{children}</div>
      </div>
    );
  };
});
