import { css } from '@emotion/css';
import { useCrushT } from 'app/crush/glossary';
import { useCrushScopeRound } from 'app/crush/store';

export const CrushControlCrashResult = (): JsxElement => {
  const round = useCrushScopeRound();

  const t = useCrushT().sub('grab.result');

  if (round.won) {
    return (
      <div className={wonBoxClass}>
        <div className={wonTitleClass}>
          {t('won.title', { amount: round.won!.item.cost.toFixed(2) })}
        </div>
        <div className={captionClass}>{t('won.caption')}</div>
      </div>
    );
  } else {
    return (
      <div className={lostBoxClass}>
        <div className={lostTitleClass}>{t('lost.title')}</div>
        <div className={captionClass}>{t('lost.caption')}</div>
      </div>
    );
  }
};

const wonBoxClass = css`
  background: #18171c;
  border-radius: 4px;
  padding: 8px;
  margin: 6px 24px;
  min-height: 42px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const lostBoxClass = css`
  ${wonBoxClass};
  background: rgba(255, 90, 90, 0.1);
`;

const wonTitleClass = css`
  font-weight: 700;
  font-size: 13px;
  font-family: 'Onest';
  color: #0fac7f;
`;

const lostTitleClass = css`
  ${wonTitleClass};
  color: #ff5a5a;
`;

const captionClass = css`
  font-weight: 600;
  font-size: 10px;
  line-height: 130%;
  color: #988c92;
`;
