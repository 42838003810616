import { showErrorToast } from 'packs/libs/inf';
import { UReader, UWriter } from 'packs/libs/uint-8';
import sleep from 'support/etc/sleep';

type WireOpts = {
  url: string;
  onEvent(channel: string | number, data: UReader);
  onConnected();
};

const PING_INTERVAL = 3e4;

const NORMAL_REBOOT_CLOSE_CODE = 1000;
const NORMAL_REBOOT_CLOSE_REASON = 'Rebooting connection';

export type WireReqOpts = {
  action: string;
  timeout: number;
  data?: UWriter;
};

const emptyReader = new UReader(new Uint8Array());
export class WireAnswer {
  constructor(public readonly status: number, public readonly data: UReader) {}
}

let connectionCount = 0;

export class Wire {
  private declare ws: WebSocket;
  public readonly serverTime?: number;

  onEvent: (channel: string | number, data: UReader) => void;
  onConnect: () => void;
  onDisconnect: () => void;
  onPing: (ping: number, diffWithServerTime: number) => void;

  private suspense?: Promise<void>;
  private release?: () => void;

  private genReqId: () => number;

  constructor(private opts: any) {
    this.reset();
  }

  private requestStack: Map<number, any>;

  private declare pingTimer: any;

  async req(opts: WireReqOpts): Promise<WireAnswer> {
    return new Promise<WireAnswer>(async (resolve) => {
      if (this.suspense) await this.suspense;
      const reqId = this.genReqId();
      const writer = new UWriter();
      writer.uint8(PRIME_QUESTION);
      writer.uint32(reqId);
      writer.action(opts.action);
      if (opts.data) writer.concat(opts.data);
      this.ws.send(writer.finish());

      this.requestStack.set(reqId, {
        resolve,
        timeout: setTimeout(() => {
          resolve(new WireAnswer(408, emptyReader));
        }, opts.timeout),
      });
    });
  }

  event(action: string, data?: Uint8Array) {
    const w = new UWriter().uint8(PRIME_EVENT).action(action);
    if (data !== undefined) w.raw(data);
    if (this.suspense) {
      this.suspense.then(() => {
        this.ws.send(w.finish());
      });
    } else {
      this.ws.send(w.finish());
    }
  }

  connect() {
    if (__DEBUG__) {
      if (this.ws) {
        showErrorToast('trying to connect to ws while already connected');
      }
    }

    const ws = (this.ws = new WebSocket(this.opts.url));
    ws.binaryType = 'arraybuffer';

    ws.onclose = (ev) => {
      if (ev.code !== NORMAL_REBOOT_CLOSE_CODE) {
        this.disconnect();
        sleep(3e3).then(() => {
          this.connect();
        });
      }
    };
    ws.onerror = (ev) => {
      console.error('wire websocket error', ev);
      if (__DEBUG__) {
        showErrorToast('wire websocket error');
      }
    };
    ws.onopen = () => {
      this.release!();
      this.onConnect();
      doPingAndPlanNext();
      if (++connectionCount === 1) {
        setTimeout(doPing, 3e3);
      }
      // const writer = new WireWriter();
      // writer.int32(PRIME_CONNECT);
      // ws.send(writer.finish());
    };
    ws.onmessage = (ev) => {
      const reader = new UReader(new Uint8Array(ev.data));
      const prime = reader.uint8();

      if (prime === PRIME_EVENT) {
        const e = reader.action();
        if (__DEBUG__) console.log('wire_event', e);
        this.onEvent(e, reader);
        // this.onEvent(reader.id(), reader);
      }

      if (prime === PRIME_PONG) {
        const end = Date.now();
        const serverTime = reader.uint64();
        const ping = end - current_ping_start;
        const diff = end - ping / 2 - serverTime;
        this.onPing(ping, diff);
      }

      if (prime === PRIME_ANSWER) {
        const reqId = reader.uint32();
        const request = this.requestStack.get(reqId);
        if (request === undefined) return console.debug('wire_unexpected_answer');

        const status = reader.uint16();

        request.resolve(new WireAnswer(status, reader));
      }
    };

    let current_ping_start;
    const doPing = () => {
      current_ping_start = Date.now();
      this.ws.send(ping_message);
    };

    const doPingAndPlanNext = () => {
      doPing();
      this.pingTimer = setTimeout(doPingAndPlanNext, PING_INTERVAL);
    };
  }

  private reset() {
    let i = -1;
    if (this.ws) {
      this.ws.onmessage = null;
      this.ws = undefined!;
    }
    this.genReqId = () => ++i;
    this.suspense = new Promise((resolve) => {
      this.release = resolve;
    });
    this.requestStack = new Map();
    clearTimeout(this.pingTimer);
  }

  private disconnect() {
    this.reset();
    this.onDisconnect();
  }

  reboot() {
    console.trace('rebooting wire');
    this.ws.close(NORMAL_REBOOT_CLOSE_CODE, NORMAL_REBOOT_CLOSE_REASON);
    this.disconnect();
    this.connect();
  }
}

const PRIME_EVENT = 1;
const PRIME_QUESTION = 2;
const PRIME_ANSWER = 3;
const PRIME_PING = 4;
const PRIME_PONG = 5;

const ping_message = new UWriter().uint8(PRIME_PING).finish();
