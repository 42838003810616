type StdCloseDialogIconProps = {
  className?: string;
};

export const StdCloseDialogIcon = ({ className }: StdCloseDialogIconProps): JsxElement => {
  return (
    <svg
      className={className}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.724434 13.2759C0.974471 13.5259 1.31355 13.6663 1.6671 13.6663C2.02065 13.6663 2.35973 13.5259 2.60977 13.2759L7.00043 8.88528L11.3911 13.2759C11.6426 13.5188 11.9794 13.6532 12.329 13.6502C12.6786 13.6471 13.013 13.5069 13.2602 13.2597C13.5074 13.0125 13.6476 12.6781 13.6507 12.3285C13.6537 11.9789 13.5193 11.6421 13.2764 11.3906L8.88577 6.99995L13.2764 2.60928C13.4038 2.48628 13.5054 2.33916 13.5752 2.17648C13.6451 2.01381 13.6819 1.83885 13.6834 1.66181C13.685 1.48477 13.6512 1.3092 13.5842 1.14534C13.5172 0.981478 13.4182 0.832609 13.293 0.707419C13.1678 0.582228 13.0189 0.483224 12.855 0.416183C12.6912 0.349142 12.5156 0.315406 12.3386 0.316945C12.1615 0.318483 11.9866 0.355265 11.8239 0.425144C11.6612 0.495022 11.5141 0.596599 11.3911 0.723946L7.00043 5.11461L2.60977 0.723946C2.48677 0.596599 2.33964 0.495022 2.17697 0.425144C2.0143 0.355265 1.83934 0.318483 1.6623 0.316945C1.48526 0.315406 1.30969 0.349142 1.14583 0.416183C0.981967 0.483224 0.833097 0.582228 0.707907 0.707419C0.582717 0.832609 0.483712 0.981478 0.416671 1.14534C0.34963 1.3092 0.315895 1.48477 0.317433 1.66181C0.318972 1.83885 0.355753 2.01381 0.425632 2.17648C0.495511 2.33916 0.597087 2.48628 0.724434 2.60928L5.1151 6.99995L0.724434 11.3906C0.474473 11.6406 0.334052 11.9797 0.334052 12.3333C0.334052 12.6868 0.474473 13.0259 0.724434 13.2759V13.2759Z"
        fill="#988C92"
      />
    </svg>
  );
};
