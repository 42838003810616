import { getInventoryOperator, useInventoryState } from 'app/inventory';
import { UpgradeConfigData, UpgradeFlowStage } from 'app/upgrade/definitions';
import { UpgradeWheelOperator } from 'app/upgrade/units/wheel';
import { upgradeCalcChance, upgradeCalcTarget } from 'app/upgrade/utils/calc';
import { getContestLiveState } from 'domain/contest/live';
import { ItemData } from 'domain/item';
import { SkinData } from 'domain/skin';
import { coilListen, coilReq } from 'packs/libs/coil';
import { EffectCallback, createContext, createElement, useContext, useEffect } from 'react';
import { useBasicSwissStore } from 'support/react/swiss';
import { Subscriber } from 'support/struct/subscriber';

const DEFAULT_CHANCE = '20';

export type UpgradeStakeState = {
  items: ItemData[];
  total: number;
};

type UpgradeState = {
  stage: UpgradeFlowStage;
  config: UpgradeConfigData;
  // grade: PlayerGrade;
  // canWithdraw: boolean;
  // onWelcomeFlow: boolean;
  // playedOnce: boolean;
  stake: UpgradeStakeState;

  chance: string;
  offset: number;
  targetPrice: string;

  result?: {
    contestId?: string;
    dropped: number;
    won: boolean;
    item: ItemData;
    remains: number;
  };
};

export type LiveBidData = {
  id: number;
  player: {
    id: string;
    name: string;
    image: string;
  };
  stake: number;
  chance: number;
  prize: number;
  skin: SkinData;
  streak: number;
  added?: boolean; // added in live
};

const Context = createContext<UpgradeState>(null!);
if (__DEV__) Context.displayName = 'UpgradeContext';

export const useUpgradeState = () => useContext(Context);

type UpgradeOperator = {
  getState(): UpgradeState;
  getConfig(): UpgradeConfigData;
  doUpgrade(offset: number): Promise<void>;
  setChance(value: string): void;
  setTargetPrice(value: string): void;
  discardItem(id: string): void;
  discardAllItems(): void;
  onSpinningFinished(): void;
  // continueStreak(): void;
  reset(): void;
  sellPrize(): void;
  listenChanceChanged(cb: (chance: number) => void): () => void;
  wheel: null | UpgradeWheelOperator;
};

let globalOperator: UpgradeOperator;
let onInventorySelectedItemsChanged: () => void;

export const getUpgradeOperator = () => globalOperator;

export function UpgradeStoreProvider({ children }: Rcp) {
  const [value, effect] = useBasicSwissStore<UpgradeState, EffectCallback>(() => [
    {
      stage: UpgradeFlowStage.loading,
      config: null!,
      chance: null!,
      offset: 0,
      targetPrice: null!,
      stake: null!,
    },
    (op) => {
      const { getState, setState, updState } = op;

      const doUpgrade = async (offset: number) => {
        updState({ offset });
        const itemsIds = getInventoryOperator().getSelectedItemsIds();

        await doUpgradeWithItemsIds(itemsIds, offset);
      };

      const doUpgradeWithItemsIds = async (itemsIds: string[], offset: number) => {
        const chance = getState().chance;
        const contestId = getContestLiveState().getIdIfActive();
        const result = await coilReq({
          action: 'upgrade.do',
          data: { itemsIds, chance: parseFloat(chance), offset },
        });

        updState({
          stage: UpgradeFlowStage.spinning,
          result: { contestId, ...result },
        });

        getInventoryOperator().excludeItems(itemsIds);
      };

      const ChanceChangedSub = new Subscriber<number>();

      const getSourcePrice = () => getInventoryOperator().getState().selectedTotal;
      const getRtp = () => getState().config.rtp;

      const getStakeFromInventory = () => {
        const state = getInventoryOperator().getState();
        return { items: state.selectedItems, total: state.selectedTotal };
      };

      const setChance = (value: string) => {
        ChanceChangedSub.emit(parseFloat(value));
        updState({
          chance: value,
          targetPrice: upgradeCalcTarget(getRtp(), getSourcePrice(), value),
        });
      };

      const setTargetPrice = (value: string) => {
        const chance = upgradeCalcChance(getRtp(), getSourcePrice(), value);
        ChanceChangedSub.emit(parseFloat(chance));
        updState({
          chance,
          targetPrice: value,
        });
      };

      onInventorySelectedItemsChanged = () => {
        const { stage, config, chance } = getState();
        if (stage !== UpgradeFlowStage.planning) return;
        const stake = getStakeFromInventory();
        updState({
          stake,
          targetPrice: upgradeCalcTarget(config.rtp, stake.total, chance),
        });
      };

      const discardItem = (id: string) => getInventoryOperator().deselectItem(id);
      const discardAllItems = () => getInventoryOperator().deselectAll();

      const onSpinningFinished = () => {
        updState({ stage: UpgradeFlowStage.resulting });
        const { item, won, contestId } = getState().result!;
        if (won && contestId === getContestLiveState().getIdIfActive()) {
          getInventoryOperator().addItem(item);
        }
      };

      // const continueStreak = () => {
      //   doUpgradeWithItemsIds([getState().result.item.id]);
      // };

      const reset = () => {
        const stake = getStakeFromInventory();
        updState({
          stage: UpgradeFlowStage.planning,
          chance: DEFAULT_CHANCE,
          targetPrice: upgradeCalcTarget(getState().config.rtp, stake.total, DEFAULT_CHANCE),
          stake,
        });
      };

      const sellPrize = () => {
        getInventoryOperator().exchangeByIds([getState().result!.item.id]);
        reset();
      };

      const getConfig = () => getState().config;

      globalOperator = {
        getState,
        getConfig,
        doUpgrade,
        setChance,
        setTargetPrice,
        discardItem,
        discardAllItems,
        onSpinningFinished,
        // continueStreak,
        reset,
        sellPrize,
        listenChanceChanged: ChanceChangedSub.sub,
        wheel: null,
      };

      if (__DEV__) {
        window['upgradeOp'] = globalOperator;
        const sandbox: Rsa = {};

        type PlaySpinOpts = {
          chance: number;
          dropped: number;
        };
        sandbox.playSpin = ({ chance, dropped }: PlaySpinOpts) => {
          updState({
            stage: UpgradeFlowStage.spinning,
            chance: chance.toString(),
            result: {
              dropped,
              won: dropped < chance,
              item: null!,
              remains: 0,
            },
          });
        };

        window['upgradeSandbox'] = sandbox;
      }

      return () =>
        coilListen('upgrade', (config: UpgradeConfigData) => {
          const stake = getStakeFromInventory();
          setState({
            stage: UpgradeFlowStage.planning,
            config,
            chance: DEFAULT_CHANCE,
            targetPrice: upgradeCalcTarget(config.rtp, stake.total, DEFAULT_CHANCE),
            stake,
            offset: 0,
          });
        });
    },
  ]);

  useEffect(effect, []);

  return createElement(Context.Provider, { value }, children);
}

export const UpgradeInventoryListener = (): JsxElement => {
  const state = useInventoryState();
  useEffect(onInventorySelectedItemsChanged, [state.selectedItems]);
  return null!;
};
