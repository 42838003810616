import { css } from '@emotion/css';
import { ReactNode } from 'react';
import { useCaseBattleMonitorT } from '../glossary';
import { ReactComponent as RoundIcon } from './assets/round.svg';
import { ReactComponent as WalletIcon } from './assets/wallet.svg';

type CaseBattleMonitorAllCaseStatsProps = {
  rounds: number;
  total: number;
};

export const CaseBattleMonitorAllCaseStats = ({
  rounds,
  total,
}: CaseBattleMonitorAllCaseStatsProps): JsxElement => {
  const t = useCaseBattleMonitorT().sub('all-cases');
  return (
    <div className={statsBoxClass}>
      <Card icon={<RoundIcon />} label={t('round')} children={rounds} iconClass={roundIconClass} />
      <Card icon={<WalletIcon />} label={t('price')} iconClass={walletIconClass}>
        <span>${total.toFixed(2)}</span>
      </Card>
    </div>
  );
};

type CardProps = {
  icon: ReactNode;
  label: ReactNode;
  children: ReactNode;
  iconClass: string;
};

const Card = ({ icon, label, children, iconClass }: CardProps): JsxElement => {
  return (
    <div className={cardBoxClass}>
      <div className={iconClass}>{icon}</div>
      <div>
        <div className={cardValueClass}>{children}</div>
        <div className={cardLabelClass}>{label}</div>
      </div>
    </div>
  );
};

const cardBoxClass = css`
  display: flex;
  align-items: center;
  background: #18171c;
  border-radius: 8px;
  gap: 12px;
  padding: 10px;
`;
const statsBoxClass = css`
  display: flex;
  align-items: center;
  gap: 12px;
`;
const cardLabelClass = css`
  font-weight: 400;
  font-size: 11px;
  letter-spacing: -0.003em;
  color: #988c92;
`;
const cardValueClass = css`
  font-family: 'Onest';
  font-weight: 500;
  font-size: 13px;
  letter-spacing: -0.03em;
  color: #dfdfdf;
`;
const roundIconClass = css`
  svg {
    height: 16px;
  }
`;
const walletIconClass = css`
  svg {
    height: 20px;
  }
`;
