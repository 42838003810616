import { css } from '@emotion/css';
import { getFrameByX } from 'app/crush/recent/recent';

type CrushProvablyFairBetFrameProps = {
  x: number;
};

export const CrushProvablyFairBetFrame = ({ x }: CrushProvablyFairBetFrameProps): JsxElement => {
  const Frame = getFrameByX(x)!;

  return (
    <div>
      <Frame>
        <div className={boxClass}>{x.toString().length <= 3 ? x : x.toFixed(2)}x</div>
      </Frame>
    </div>
  );
};

const boxClass = css`
  padding: 4px 1px;
  font-family: 'Commissioner';
  font-style: normal;
  line-height: 130%;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  letter-spacing: -0.03em;
  border-radius: 6px;
  cursor: pointer;
`;
