import { createSoundGroup } from 'packs/libs/sound';

export const CaseBattleInsightSoundGroup = createSoundGroup();
CaseBattleInsightSoundGroup.disable();

const createSound = (url: string) => CaseBattleInsightSoundGroup.createSound(url);

import BATTLE_END_LOSER_URL from 'app/case/battle/assets/sounds/battle-end-loser.mp3';
export const CaseBattleInsightBattleEndLoserSound = createSound(BATTLE_END_LOSER_URL);

import BATTLE_END_WATCHER_URL from 'app/case/battle/assets/sounds/battle-end-watcher.mp3';
export const CaseBattleInsightBattleEndWatcherSound = createSound(BATTLE_END_WATCHER_URL);

import BATTLE_END_WINNER_URL from 'app/case/battle/assets/sounds/battle-end-winner.mp3';
export const CaseBattleInsightBattleEndWinnerSound = createSound(BATTLE_END_WINNER_URL);

import CASE_ROLL_URL from 'app/case/battle/assets/sounds/case-roll-compound.mp3';
// ffmpeg -i case-roll.wav -i end-of-case-roll.mp3 -filter_complex "concat=n=2:v=0:a=1" case-roll-compound.mp3
export const CaseBattleInsightCaseReelRollingSound = createSound(CASE_ROLL_URL);

import BIG_WIN_URL from 'app/case/battle/assets/sounds/big-win.mp3';
export const CaseBattleInsightBigWinSound = createSound(BIG_WIN_URL);

import ENTER_BATTLE_URL from 'app/case/battle/assets/sounds/enter-battle.mp3';
export const CaseBattleInsightEnterBattleSound = createSound(ENTER_BATTLE_URL);
