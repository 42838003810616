import { css } from '@emotion/css';
import { useCrushT } from 'app/crush/glossary';
import { getCrushOperator, getCrushScopeOperator, useCrushScopeRound } from 'app/crush/store';
import { useCrushStoreRoundExtraSwissState } from 'app/crush/store/extra';
import { CrushModeCode, CrushRoundStage } from 'domain/crush';
import { throttle } from 'lodash';
import { coilSend, coilReq } from 'packs/libs/coil';
import { StdKindButton } from 'packs/std/inputs/kind-button';

const littleThrottle = throttle((fn) => fn(), 100);

export const CrushControlGrabButton = (): JsxElement => {
  const t = useCrushT().sub('grab.control');

  const round = useCrushScopeRound();
  const [grabbing, grabbingSwiss] = useCrushStoreRoundExtraSwissState(() => false);

  const canGrab = !grabbing && !round.won && round.stage === CrushRoundStage.growth;

  const doGrab = () => {
    littleThrottle(async () => {
      const setGrabbing = grabbingSwiss.setState;

      const x = getCrushScopeOperator()!.getX();
      if (x === undefined) return;
      setGrabbing(true);
      try {
        await coilReq({
          action: 'crush.grab',
          data: CrushModeCode[getCrushOperator().getMode()],
        });
      } catch (e) {
        coilSend('sync');
      } finally {
        setGrabbing(false);
      }
    });
  };

  return (
    <StdKindButton
      className={buttonClass}
      kind="orange"
      children={t('grab')}
      disabled={!canGrab}
      onMouseDown={doGrab}
    />
  );
};

const buttonClass = css`
  flex: 1;
  padding: 12px;
  margin: 0 24px;
  font-size: 12px;
  min-height: 42px;
`;
