import { css } from '@emotion/css';
import ModalUnstyled from '@mui/base/ModalUnstyled';
import { LayoutBlurStack } from 'app/layout/blur';
import { STD_DIALOG_Z_INDEX } from 'packs/std/dialog/definitions';
import { createElement } from 'react';

type StdTopDialogProps = {
  open?: boolean;
  onClose(): void;
  className?: string;
  children: React.ReactNode;
};

export const StdTopDialog = ({ open = true, onClose, ...props }: StdTopDialogProps): JsxElement => {
  return (
    <ModalUnstyled
      className={mainClass}
      onClose={onClose}
      open={open}
      slots={slots}
      slotProps={slotProps}
    >
      <div className={boxClass}>
        {open && <LayoutBlurStack />}
        {createElement('div', props)}
      </div>
    </ModalUnstyled>
  );
};

const mainClass = css`
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: fixed;
`;

const boxClass = css`
  z-index: ${STD_DIALOG_Z_INDEX};
  border-radius: 32px;
  outline: none;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const backdropClass = css`
  height: 100%;
`;

const slots = {
  backdrop: 'div',
} as const;

const slotProps = {
  backdrop: {
    className: backdropClass,
  },
} as const;
