import { css } from '@emotion/css';
import { showToast, ToastType } from 'packs/libs/inf';
import { IntlGlossary } from 'packs/libs/intl/glossary';
import { useIntl } from 'packs/libs/intl/intl';

type IntlToastContentProps = {
  opts: { glossary: IntlGlossary; code: string; data?: Rsa };
};
const IntlToastContent = (props: IntlToastContentProps): JsxElement => {
  const opts = props.opts;
  const t = useIntl().get(opts.glossary);
  return t(opts.code, Object.assign({}, common, opts.data));
};

export const showIntlToast = (
  type: ToastType,
  glossary: IntlGlossary,
  code: string,
  data?: Rsa
) => {
  showToast(type, <IntlToastContent opts={{ glossary, code, data }} />);
};

export const showIntlInfoToast = (glossary: IntlGlossary, code: string, data?: Rsa) => {
  showIntlToast('info', glossary, code, data);
};

export const showIntlErrorToast = (glossary: IntlGlossary, code: string, data?: Rsa) => {
  showIntlToast('error', glossary, code, data);
};

export const showIntlSuccessToast = (glossary: IntlGlossary, code: string, data?: Rsa) => {
  showIntlToast('success', glossary, code, data);
};

const common = {
  title: (chunk) => <div className={titleClass}>{chunk}</div>,
};

const titleClass = css`
  padding-top: 4px;
  font-family: 'Onest';
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.035em;
  color: #dfdfdf;
`;
