import { createSoundGroup } from 'packs/libs/sound';

import CREATE_BATTLE_URL from 'app/case/battle/assets/sounds/create-battle.mp3';
import NEW_BATTLE_IN_LIST_URL from 'app/case/battle/assets/sounds/new-battle-in-list.mp3';
import ENTER_BATTLE_URL from 'app/case/battle/assets/sounds/enter-battle.mp3';

export const CaseBattleMonitorSoundGroup = createSoundGroup();
CaseBattleMonitorSoundGroup.disable();

const createSound = (url: string) => CaseBattleMonitorSoundGroup.createSound(url);
export const CaseBattleMonitorCreateBattleSound = createSound(CREATE_BATTLE_URL);
export const CaseBattleMonitorNewBattleInListSound = createSound(NEW_BATTLE_IN_LIST_URL);
export const CaseBattleMonitorEnterBattleSound = createSound(ENTER_BATTLE_URL);
