import { ContestStatus } from 'domain/contest/definitions';
import { ContestLiveInternalState } from 'domain/contest/live';
import { coilListenR } from 'packs/libs/coil';
import { createContext, createElement, useContext, useEffect } from 'react';
import { useBasicStore } from 'support/react/basic-store';

import { ContestFinishedSound, ContestStartedSound } from 'domain/contest/live/sounds';
import { ContestLiveState } from 'domain/contest/live/state';
import { UReader } from 'packs/libs/uint-8';
import { objToMap } from 'support/etc/obj-to-map';
import { Swiss } from 'support/react/swiss';

const Context = createContext<ContestLiveState>(null!);
if (__DEBUG__) Context.displayName = 'ContestLiveContext';

export const useContestLiveState = () => useContext(Context);

let globalState: ContestLiveState;
export const getContestLiveState = () => globalState;

export function ContestLiveStoreProvider({ children }: Rcp) {
  const [value, effect] = useBasicStore<ContestLiveState, any>(() => [
    (globalState = new ContestLiveState({ loading: true, data: null })),
    (setOriginState, getOriginState) => {
      let internal: ContestLiveInternalState = null!;
      const setState = (state: ContestLiveInternalState) => {
        setOriginState((globalState = new ContestLiveState((internal = state))));
      };

      const swiss = new Swiss(setState, () => internal);

      const dataOp = swiss.prop('data');
      const updData = dataOp.updState;

      const clear = () => setState({ loading: true, data: null });

      const setStatus = (status: ContestStatus) => updData({ status });

      const listeners = objToMap<(r: UReader) => void>({
        left: clear,
        state(r) {
          if (r.complete) setState({ loading: false, data: null });
          else {
            const data = r.pack();
            if (!r.complete) {
              data.top = [r.pack(), r.pack()];
            }
            setState({ loading: false, data });
          }
        },
        started(r) {
          ContestStartedSound.replay();
          updData({ status: ContestStatus.active, joined: r.uint16(), top: [r.pack(), r.pack()] });
        },
        finished: clear,
        calculation() {
          console.log('calculation fired');
          ContestFinishedSound.replay();
          setStatus(ContestStatus.calculation);
        },
        top(r) {
          updData({ top: r.pack() });
        },
        canceled(r) {
          const id = r.id();
          if (id === internal?.data?.id) clear();
        },
      });

      return () => {
        return coilListenR('contest.live', (r) => {
          listeners.get(r.action())!(r);
        });
      };
    },
  ]);

  useEffect(effect, []);

  return createElement(Context.Provider, { value }, children);
}
