import { css } from '@emotion/css';
import {
  CaseBattleCreationCaseList,
  ListCaseData,
} from 'app/case/battle/creation/case-list/case-list';
import { CaseBattleCreationCaseSelectDialogSocket } from 'app/case/battle/creation/case-select/dialog';
import { CaseBattleCreationConfig } from 'app/case/battle/creation/config/config';
import { CaseBattleCreationSoundGroup } from 'app/case/battle/creation/sounds';
import { CaseBattleCreationStoreProvider } from 'app/case/battle/creation/store';
import { useEnableSoundGroup } from 'packs/libs/sound';
import { useState } from 'react';
import { LayoutFooter } from 'app/layout/footer/footer';
import { stdBp } from 'packs/std';
import { linearClamp } from 'support/polished/linear-clamp';

export const CaseBattleCreationPage = (): JsxElement => {
  useEnableSoundGroup(CaseBattleCreationSoundGroup);
  const [selected, select] = useState<ListCaseData>(null!);
  return (
    <CaseBattleCreationStoreProvider>
      <CaseBattleCreationCaseSelectDialogSocket />
      <div className={mainClass}>
        <div className={boxClass}>
          <div className={contentClass}>
            <CaseBattleCreationConfig select={select} />
            <CaseBattleCreationCaseList select={select} selected={selected} />
          </div>
          <LayoutFooter />
        </div>
      </div>
    </CaseBattleCreationStoreProvider>
  );
};
const mainClass = css`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const boxClass = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 1548px;
  width: 100%;
  gap: 22px;
  flex: 1;
  scrollbar-width: none;
  padding: 2px 2px 0 2px;
  ${stdBp.up(1920)} {
    padding-top: ${linearClamp(1920, 2, 2560, 64)};
  }
`;

const contentClass = css`
  gap: 2px;
  width: 100%;
  display: flex;
  flex: 1;
  height: 100%;
`;
