import { SubCell } from 'support/react/sub-cell';
import { Swiss } from 'support/react/swiss';

type PlayerStatsState = {};

const Cell = new SubCell<PlayerStatsState>(null!);

const SwissCell = new Swiss(Cell.set, Cell.get);

const globalOperator = {
  setState: Cell.set,
  updState: SwissCell.updState,
};

export const getPlayerStatsOperator = () => globalOperator;

export const usePlayerStatsState = Cell.useValue;
