import { useEffect } from 'react';
import { Navigate, useParams } from 'react-router-dom';

export const ReferralInvitePage = (): JsxElement => {
  const { code } = useParams<{ code: string }>();
  useEffect(() => {
    if (!code) return;
    import('./dialog').then(({ openReferralInviteDialog }) => {
      openReferralInviteDialog({ code });
    });
  }, []);
  return <Navigate to="/" />;
};
