import { css, cx } from '@emotion/css';
import { ReactComponent as ActiveContestsIcon } from 'app/contest/assets/contests.svg';
import { useContestT } from 'app/contest/glossary';
import {
  ContestMonitorCardActive,
  ContestMonitorCardFinished,
  ContestMonitorCardUpcoming,
} from 'app/contest/monitor/card';
import { ContestMonitorPreset } from 'app/contest/monitor/definitions';
import { ContestMonitorList } from 'app/contest/monitor/list';
import { LayoutFooter } from 'app/layout/footer/footer';
import { ContestBriefData, ContestStatus } from 'domain/contest';
import { coilListenR, coilReq, coilSend } from 'packs/libs/coil';
import { UReader } from 'packs/libs/uint-8';
import { stdBp } from 'packs/std';
import { createElement, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { bunchCb } from 'support/etc/bunch-cb';
import { objToMap } from 'support/etc/obj-to-map';
import { linearClamp } from 'support/polished/linear-clamp';
import { useSwissState } from 'support/react/swiss';
import { launch, useLaunch } from 'support/react/use-launch';

type ContestMonitorPageProps = { preset: ContestMonitorPreset };

export const ContestMonitorPage = ({
  preset: current = ContestMonitorPreset.current,
}: ContestMonitorPageProps): JsxElement => {
  const t = useContestT().sub('monitor');

  return (
    <div className={mainClass}>
      <div className={boxClass}>
        <div className={contentClass}>
          <div className={headerClass}>
            {...Object.values(ContestMonitorPreset).map((preset) => {
              return (
                <Link
                  to={`/contests/${preset}`}
                  className={cx(titleClass, preset === current && activeTitleClass)}
                >
                  {t(preset)}
                </Link>
              );
            })}
          </div>
          {createElement(PresetMap.get(current)!)}
        </div>
        <LayoutFooter />
      </div>
    </div>
  );
};

type CurrentPresetData = {
  active: ContestBriefData[];
  upcoming: ContestBriefData[];
};

const CurrentPresetPage = (): JsxElement => {
  const [data, op] = useSwissState<CurrentPresetData | null>(null);
  const t = useContestT().sub('monitor');

  useEffect(() => {
    launch(async () => {
      op.setState(
        await coilReq({
          action: 'contest.monitor.enter',
          data: ContestMonitorPreset.current,
        })
      );
    });

    type Listener = (r: UReader) => void;
    const listeners = objToMap<Listener>({
      joined: (r) => {
        op.mutState((draft) => {
          const contestId = r.id();
          const contest = draft!.upcoming.find((v) => v.id === contestId)!;
          contest.joined += 1;
        });
      },
      started: (r) => {
        op.mutState((draft) => {
          const contestId = r.id();
          const contest = draft!.upcoming.find((v) => v.id === contestId);
          if (contest === undefined) return;
          contest.status = ContestStatus.active;
          draft!.upcoming = draft!.upcoming.filter((v) => v.id !== contestId);
          draft!.active.push(contest);
        });
      },
      appeared: (r) => {
        op.mutState((draft) => {
          const contest = r.pack();
          const result: any[] = [];
          const it = draft!.upcoming.values();
          for (const v of it) {
            if (v.start < contest.start) {
              result.push(v);
            } else {
              break;
            }
          }
          result.push(contest);
          result.push(...it);
          draft!.upcoming = result;
        });
      },
      canceled(r) {
        const contestId = r.id();
        op.mutState((draft) => {
          draft!.upcoming = draft!.upcoming.filter((c) => c.id !== contestId);
        });
      },
    });

    return bunchCb([
      () => coilSend('contest.monitor.leave'),
      coilListenR('contest.monitor', (r) => {
        console.log('contest.monitor', r.buf);
        const action = r.action();
        console.log({ action });
        listeners.get(action)!(r);
      }),
    ]);
    // const listeners = objToMap({
    //   add: (data: ContestBriefData) => {
    //     op.mutState((draft) => {
    //       draft!.upcoming.push(data);
    //     });
    //   },
    // });

    // return bunchCb([
    //   () => {
    //     coilSend('contest.monitor.current.leave');
    //   },
    // ]);
  }, []);

  if (data === null) return <div>loading...</div>;

  return (
    <div className={monitorClass}>
      {/* {data.active.map((v) => (
        <Link to={`/contests/${v.id}`}>{v.id}</Link>
      ))} */}
      <ContestMonitorList Card={ContestMonitorCardUpcoming} data={data.upcoming} />
      {data.active.length !== 0 && (
        <ContestMonitorList
          Card={ContestMonitorCardActive}
          data={data.active}
          title={
            <div className={activeSubTitleBoxClass}>
              <ActiveContestsIcon />
              <p className={activeSubTitleClass}>{t('active-contests')}</p>
            </div>
          }
        />
      )}
    </div>
  );
};

const HistoryPresetPage = (): JsxElement => {
  const [data, setData] = useState<ContestBriefData[] | null>(null);

  useEffect(() => {
    launch(async () => {
      setData(
        await coilReq({
          action: 'contest.monitor.enter',
          data: ContestMonitorPreset.history,
        })
      );
    });

    return () => {
      coilSend('contest.monitor.leave');
    };
  }, []);
  useLaunch(async () => {}, []);

  if (data === null) return <div>loading...</div>;

  return (
    <div className={monitorClass}>
      <ContestMonitorList Card={ContestMonitorCardFinished} data={data} />
    </div>
  );
};

const PresetMap = new Map([
  [ContestMonitorPreset.current, CurrentPresetPage],
  [ContestMonitorPreset.history, HistoryPresetPage],
]);

const mainClass = css`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

const boxClass = css`
  max-width: 1427px;
  padding: 21px 21px 0 21px;
  gap: 22px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const contentClass = css`
  display: flex;
  flex-direction: column;
  gap: 21px;

  ${stdBp.up(1920)} {
    padding: ${linearClamp(1920, 0, 2560, 43)} 0;
  }
`;

const headerClass = css`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 24px;
  justify-content: flex-start;
  border-bottom: 2px solid #1e1c22;
  padding-bottom: 12px;
`;

const titleClass = css`
  font-family: 'Onest';
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #988c92;
  cursor: pointer;

  &:hover {
    color: #ffffff;
  }
`;

const activeTitleClass = css`
  position: relative;
  color: #ffffff;
  font-size: 20px;

  &::after {
    content: '';
    position: absolute;
    display: flex;
    width: 100%;
    height: 2px;
    bottom: -14px;
    background: linear-gradient(180deg, #ffaa63 0%, #ea9a4e 100%);
  }
`;

const monitorClass = css`
  display: flex;
  flex-direction: column;
  gap: 21px;
`;

const activeSubTitleBoxClass = css`
  display: flex;
  align-items: center;
  gap: 6px;
  padding-top: 9px;
`;

const activeSubTitleClass = css`
  font-family: 'Commissioner';
  font-weight: 700;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #dfdfdf;
`;
