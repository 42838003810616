import { useMemo } from 'react';

import { useUpgradeState } from 'app/upgrade';
import { UpgradeBidding } from 'app/upgrade/circle/bidding/bidding';
import { UpgradeResultLost } from 'app/upgrade/circle/result/lost';
import { UpgradeResultWon } from 'app/upgrade/circle/result/won';
import { UpgradeSpinner } from 'app/upgrade/circle/spinner/spinner';
import { UpgradeFlowStage } from 'app/upgrade/definitions';
import { UpgradeStake } from 'app/upgrade/stake/stake';
import { UpgradeStreak } from 'app/upgrade/streak/plug';
import { UpgradeFrame } from 'app/upgrade/units/frame';
import {
  UpgradeUnitsLeftChickenLost,
  UpgradeUnitsLeftChickenUpgrading,
  UpgradeUnitsLeftChickenWon,
} from 'app/upgrade/units/left-chicken';
import { UpgradeWheelOffsetControl } from 'app/upgrade/units/wheel/units/offset-control';
import { css } from '@emotion/css';
import { stdBreakpoints } from 'packs/std';

export const UpgradeFlow = (): JsxElement => {
  const state = useUpgradeState();

  return useMemo(() => {
    if (state.stage === UpgradeFlowStage.planning) {
      return (
        <UpgradeFrame offset={<UpgradeWheelOffsetControl />}>
          <UpgradeStake />
          <UpgradeBidding />
          <UpgradeStreak />
        </UpgradeFrame>
      );
    }

    if (state.stage === UpgradeFlowStage.spinning) {
      return (
        <UpgradeFrame leftChicken={<UpgradeUnitsLeftChickenUpgrading />}>
          <UpgradeStake locked />
          <UpgradeSpinner />
          <UpgradeStreak />
        </UpgradeFrame>
      );
    }

    if (state.stage === UpgradeFlowStage.resulting) {
      // якщо вийшли з аккаунту до того як побачили результат - перезавантажуємо
      // сторінку, щоб уникнути будь яких помилок
      if (!state.result) {
        location.reload();
        return <></>;
      }

      return state.result.won ? (
        <UpgradeFrame leftChicken={<UpgradeUnitsLeftChickenWon />}>
          <UpgradeStake locked />
          <UpgradeResultWon />
          <UpgradeStreak />
        </UpgradeFrame>
      ) : (
        <UpgradeFrame leftChicken={<UpgradeUnitsLeftChickenLost />}>
          <UpgradeStake locked />
          <div className={lostResultClass}>
            <UpgradeResultLost />
          </div>
          <UpgradeStreak />
        </UpgradeFrame>
      );
    }

    if (state.stage === UpgradeFlowStage.loading) return <div>Loading...</div>;

    throw Error('Unexpected upgrade stage');
  }, [state.stage]);
};
const lostResultClass = css`
  ${stdBreakpoints.up(1695)} {
    transform: scaleX(0.9);
  }
  ${stdBreakpoints.up(1820)} {
    transform: scaleX(0.85);
  }
  ${stdBreakpoints.up(1920)} {
    transform: scaleX(0.78);
  }
`;
