/**
 * @link https://jsfiddle.net/upsidown/e6dx9oza
 */

function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
  return {};
}

export const upgradeUnitsWheelCircleArc = (centerX: number, centerY: number, radius: number) => {
  const calc = (angleInDegrees: number) => {
    const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;
    return {
      x: centerX + radius * Math.cos(angleInRadians),
      y: centerY + radius * Math.sin(angleInRadians),
    };
  };
  return (startAngle: number) => {
    const end = calc(startAngle);
    return (angleInDegrees: number) => {
      const start = calc(startAngle + angleInDegrees);
      // const arcSweep = angleInDegrees - startAngle <= 180 ? '0' : '1';
      // const arcSweep = '1';

      // prettier-ignore
      return [
      "M", start.x, start.y,
      "A", radius, radius, 0, 1, 0, end.x, end.y,
      "L", centerX,centerY,
      "L", start.x, start.y
    ].join(" ");
      // prettier-ignore-end
    };
  };
};
