import { css } from '@emotion/css';
import { CaseBattleInsightResultButtons } from 'app/case/battle/insight/results/buttons';
import { CaseBattleInsightResultMembers } from 'app/case/battle/insight/results/members';
import { CaseBattleInsightResultWinner } from 'app/case/battle/insight/results/winner';
import {
  CaseBattleInsightBattleEndLoserSound,
  CaseBattleInsightBattleEndWatcherSound,
  CaseBattleInsightBattleEndWinnerSound,
} from 'app/case/battle/insight/sounds';
import { getActor } from 'domain/actor';
import { createSyringe } from 'packs/libs/syringe';
import { StdTopDialog, stdBp } from 'packs/std';
import { StdCloseDialogIcon } from 'packs/std/dialog/blocks/close-icon';
import { roundCash } from 'support/etc/round-cash';
import { useCaseBattleInsightT } from '../glossary';

export type PlayerData = {
  id: string;
  name: string;
  image: string;
  bot?: boolean;
};

type CaseBattleInsightResultsDialogOpts = {
  winner: PlayerData;
  others: PlayerData[];
  total: number;
  cost: number;
  battleId: string;
  duplicate(): void;
};

if (__DEV__) {
  window['openCaseBattleInsightResultsDialog'] = async () => {
    const { CaseBattleInsightStub } = await import('../store/stub');
    openCaseBattleInsightResultsDialog({
      winner: CaseBattleInsightStub.getMember(false, true),
      others: [
        CaseBattleInsightStub.getMember(false, false),
        CaseBattleInsightStub.getMember(false, false),
        CaseBattleInsightStub.getMember(false, true),
      ],
      total: 3641.74,
      cost: 441.74,
      battleId: '',
      duplicate() {},
    });
  };
}

const [CaseBattleInsightDialogSocket, inject] = createSyringe();
export { CaseBattleInsightDialogSocket };

export const openCaseBattleInsightResultsDialog = ({
  winner,
  others,
  total,
  cost,
  battleId,
  duplicate,
}: CaseBattleInsightResultsDialogOpts): void => {
  const actor = getActor();
  let isMember = false;

  if (actor.signed) {
    if (winner.id === actor.id) {
      CaseBattleInsightBattleEndWinnerSound.replay();
      isMember = true;
    } else {
      for (const other of others) {
        if (other.id === actor.id) {
          CaseBattleInsightBattleEndLoserSound.replay();
          isMember = true;
          break;
        }
      }
    }
  }

  if (!isMember) {
    CaseBattleInsightBattleEndWatcherSound.replay();
  }

  inject(function CaseBattleInsightResultsDialog({ onClose }) {
    const t = useCaseBattleInsightT().sub('results');

    return (
      <StdTopDialog onClose={onClose}>
        <div className={mainClass}>
          <div className={headClass}>
            <div className={titleBoxClass}>
              <span className={titleClass}>{t('end')}</span>
              <div className={descriptionBoxClass}>
                <span className={descriptionClass}>{t('sum')}</span>
                <span className={descriptionCostClass}>${roundCash(total)}</span>
              </div>
            </div>
            <div onClick={onClose} className={closeClass}>
              <StdCloseDialogIcon className={closeIconClass} />
            </div>
          </div>
          <div className={bodyClass}>
            <CaseBattleInsightResultWinner winner={winner} />
            <CaseBattleInsightResultMembers members={others} />
            <CaseBattleInsightResultButtons
              battleId={battleId}
              duplicate={duplicate}
              onClose={onClose}
              cost={cost}
            />
          </div>
        </div>
      </StdTopDialog>
    );
  });
};

const mainClass = css`
  width: 400px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  border-radius: 32px;

  ${stdBp.up(1920)} {
    width: 480px;
  }
`;

const bodyClass = css`
  height: 425px;
  max-height: 90%;
  background: #26232d;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;

  ${stdBp.up(1920)} {
    height: 490px;
  }
`;

const titleBoxClass = css``;

const titleClass = css`
  font-family: 'Onest';
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;

  ${stdBp.up(1920)} {
    font-size: 19px;
  }
`;

const descriptionClass = css`
  font-weight: 500;
  font-size: 13px;
  line-height: 140%;
  color: #988c92;

  ${stdBp.up(1920)} {
    font-size: 15px;
  }
`;

const descriptionCostClass = css`
  font-family: 'Onest';
  font-weight: 700;
  font-size: 13px;
  line-height: 140%;
  color: #0fac7f;
  padding-left: 5px;

  ${stdBp.up(1920)} {
    font-size: 15px;
  }
`;

const descriptionBoxClass = css``;

const closeClass = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background: #18171c;
  border-radius: 12px;
  cursor: pointer;
`;

const closeIconClass = css`
  width: 14px;
  height: 14px;
`;

const headClass = css`
  padding: 20px 30px 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #26232d;
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;

  ${stdBp.up(1920)} {
    padding: 24px 36px 18px 24px;
  }
`;
