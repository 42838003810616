import { css, cx, keyframes } from '@emotion/css';
import { ReactComponent as BackgroundFirstMount } from 'app/crush/assets/background-first-mount.svg';
import { ReactComponent as BackgroundForest } from 'app/crush/assets/background-forest.svg';
import { ReactComponent as BackgroundSecondMount } from 'app/crush/assets/background-second-mount.svg';
import { ReactComponent as BackgroundTrack } from 'app/crush/assets/background-track.svg';
import { stdBreakpoints } from 'packs/std';
import { memo, useEffect } from 'react';

const fastAnimation = keyframes`
  0%{
    transform: translateX(0%);
  }

  100%{
    transform: translateX(calc(-100% + 25px));
  }
`;

type CrushSceneBackgroundAnimationProps = {
  playX2: boolean;
  stop: boolean;
};

export const CrushSceneBackgroundAnimation = memo(function CrushSceneBackgroundAnimation({
  playX2,
  stop,
}: CrushSceneBackgroundAnimationProps) {
  const uniquePrefix = 'CrushSceneBackgroundAnimation';

  useEffect(() => {
    const forest = document.getElementById(`${uniquePrefix}-forest`);
    const forestDelay = document.getElementById(`${uniquePrefix}-forest-delay`);
    const mountains = document.getElementById(`${uniquePrefix}-mountains`);
    const mountainsDelay = document.getElementById(`${uniquePrefix}-mountains-delay`);

    if (forest && forestDelay && mountains && mountainsDelay) {
      const animationsForest = forest.getAnimations();
      const animationsForestDelay = forestDelay.getAnimations();
      const animationsMountains = mountains.getAnimations();
      const animationsMountainsDelay = mountainsDelay.getAnimations();

      if (playX2) {
        animationsForest.forEach((animation) => {
          animation.playbackRate = 2;
        });
        animationsForestDelay.forEach((animation) => {
          animation.playbackRate = 2;
        });
        animationsMountains.forEach((animation) => {
          animation.playbackRate = 2;
        });
        animationsMountainsDelay.forEach((animation) => {
          animation.playbackRate = 2;
        });
      } else {
        animationsForest.forEach((animation) => {
          animation.playbackRate = 1;
        });
        animationsForestDelay.forEach((animation) => {
          animation.playbackRate = 1;
        });
        animationsMountains.forEach((animation) => {
          animation.playbackRate = 1;
        });
        animationsMountainsDelay.forEach((animation) => {
          animation.playbackRate = 1;
        });
      }
    }
  }, [playX2]);

  return (
    <div className={mainClass}>
      <div className={backgroundClass}>
        <div
          id={`${uniquePrefix}-mountains-delay`}
          className={cx(
            backgroundMountAnimationClass,
            backgroundMountDelayClass,
            stop && backgroundMountPausedClass
          )}
        >
          <BackgroundFirstMount className={backgroundFirstMountClass} />
          <BackgroundSecondMount className={backgroundSecondMountClass} />
        </div>
        <div
          id={`${uniquePrefix}-mountains`}
          className={cx(backgroundMountAnimationClass, stop && backgroundMountPausedClass)}
        >
          <BackgroundFirstMount className={backgroundFirstMountClass} />
          <BackgroundSecondMount className={backgroundSecondMountClass} />
        </div>

        <BackgroundForest
          id={`${uniquePrefix}-forest-delay`}
          className={cx(
            backgroundForestAnimationClass,
            backgroundForestDelayClass,
            stop && backgroundForestPausedClass
          )}
        />
        <BackgroundForest
          id={`${uniquePrefix}-forest`}
          className={cx(backgroundForestAnimationClass, stop && backgroundForestPausedClass)}
        />
        <BackgroundTrack className={backgroundTrackClass} />
      </div>
    </div>
  );
});

const backgroundClass = css`
  position: absolute;
  bottom: 20px;
  transform: scale(1.3);
  ${stdBreakpoints.between(1560, 1920)} {
    transform: scale(1.6);
    bottom: -50px;
    left: -75px;
  }
  ${stdBreakpoints.between(1920, 2200)} {
    transform: scale(2);
    bottom: -50px;
    left: -75px;
  }
  ${stdBreakpoints.up(2200)} {
    transform: scale(2.5);
    bottom: -50px;
    left: -75px;
  }
`;
const mainClass = css`
  overflow: hidden;
  display: flex;
`;

const backgroundTrackClass = css`
  position: absolute;
  left: 20px;
  bottom: 0px;
  transform: scale(1.1);
  ${stdBreakpoints.between(1560, 1920)} {
    transform: scale(1.2);
    bottom: 50px;
    left: 20px;
  }
  ${stdBreakpoints.between(1920, 2200)} {
    transform: scale(1.2);
    bottom: 30px;
    left: 50px;
  }
  ${stdBreakpoints.up(2200)} {
    transform: scale(1.2);
    bottom: 20px;
  }
`;

const backgroundMountAnimationClass = css`
  position: absolute;
  left: 20px;
  bottom: -100px;
  transform: scale(1.3);
  animation-name: ${fastAnimation};
  animation-duration: 20s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
`;
const backgroundForestAnimationClass = css`
  position: absolute;
  left: 20px;
  bottom: 0px;
  transform: scale(1.3);
  animation-name: ${fastAnimation};
  animation-duration: 10s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  ${stdBreakpoints.between(1560, 1920)} {
    bottom: 50px;
  }
`;
const backgroundForestPausedClass = css`
  animation-play-state: paused;
`;
const backgroundMountPausedClass = css`
  animation-play-state: paused;
`;
const backgroundMountDelayClass = css`
  left: 680px;
  animation-delay: 0s;
`;
const backgroundForestDelayClass = css`
  position: absolute;
  left: 20px;
  bottom: 0px;
  transform: scale(1.3);
  left: 680px;
  animation-delay: 0s;
`;

const backgroundFirstMountClass = css`
  position: relative;
  left: 20px;
  bottom: 20px;
  transform: scale(1.1);
`;
const backgroundSecondMountClass = css`
  position: relative;
  left: 20px;
  bottom: 165px;
  transform: scale(1.1);
`;
