import { css, cx } from '@emotion/css';
import { getChatOperator } from 'app/chat/store';
import { ReactComponent as CrossIcon } from 'app/chat/assets/cross.svg';
import { ReactComponent as PinIcon } from 'app/chat/assets/pin.svg';
import { ReactComponent as DiscordIcon } from 'app/chat/assets/discord.svg';
import { useChatT } from './glossary';

export const ChatPinMessage = (): JsxElement => {
  const t = useChatT().sub('pin-message');
  return (
    <div className={mainClass}>
      <div className={boxClass}>
        <div className={sliderBoxClass}>
          <div className={sliderClass}></div>
          <div className={sliderClass}></div>
          <div className={cx(sliderClass, sliderActiveClass)}></div>
        </div>
        <div className={pinClass}>
          <PinIcon />
        </div>
        <div className={textPinClass}>
          <div className={headerTextPinClass}>{t('message')}</div>
          <div>
            <a className={linkPinClass} href="https://discord.gg/jetskins" target="_blank">
              <div className={iconClass}>
                <DiscordIcon />
              </div>
              <div> {t('discord')}</div>
            </a>
          </div>
        </div>
        <div
          className={closeClass}
          onClick={() => getChatOperator().updState({ pinsClosed: false })}
        >
          <CrossIcon />
        </div>
      </div>
    </div>
  );
};

const mainClass = css`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background-image: linear-gradient(160deg, #1c1b20 0%, rgba(255, 57, 130, 10%) 50%, #ff3982 100%);
  cursor: default;
`;

const boxClass = css`
  width: calc(100% - 1px);
  height: calc(100% - 1px);
  background-color: rgba(28, 27, 32, 75%);
  border-radius: 8px;
  display: flex;
  gap: 5px;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px 10px 15px 4px;
`;

const sliderBoxClass = css`
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  align-items: center;
`;

const sliderClass = css`
  width: 2px;
  height: 24px;
  background: #ff3982;
  opacity: 0.15;
  border-radius: 64px;
`;

const sliderActiveClass = css`
  opacity: 100%;
`;

const pinClass = css`
  width: 12px;
`;

const textPinClass = css`
  padding-top: 5px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  width: 100%;
`;

const headerTextPinClass = css`
  cursor: text;
  width: 100%;
  font-weight: 400;
  font-size: 11px;
  line-height: 130%;
  color: #dfdfdf;
`;

const linkPinClass = css`
  background: #18171c;
  border-radius: 4px;
  width: 147px;
  height: 28px;
  font-weight: 700;
  font-size: 12px;
  line-height: 17px;
  color: #dfdfdf;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
`;

const closeClass = css`
  cursor: pointer;
  opacity: 0.35;
  width: 11px;
`;
const iconClass = css`
  display: flex;
  align-items: center;
  width: 17px;
`;
