import { css } from '@emotion/css';

import { LayoutSidebarSettingsPanel } from './settings/settings-panel';
import { LayoutSidebarNavPanel } from 'app/layout/sidebar/nav-panel';

export const LayoutSidebar = (): JsxElement => {
  return (
    <div className={sidebarBoxClass}>
      <LayoutSidebarNavPanel />
      <LayoutSidebarSettingsPanel />
    </div>
  );
};

const sidebarBoxClass = css`
  width: 84px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 32px;
  padding-bottom: 18px;
  background: linear-gradient(180deg, #1f1d23 0%, rgba(30, 28, 34, 0.6) 100%);
`;
