const LogoutIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.85593 2.03639C6.12189 1.19242 7.78476 1.96615 8.03139 3.38722H10.5C11.6391 3.38722 12.5625 4.31063 12.5625 5.44972C12.5625 5.76038 12.3107 6.01222 12 6.01222C11.6893 6.01222 11.4375 5.76038 11.4375 5.44972C11.4375 4.93195 11.0178 4.51222 10.5 4.51222H8.0625V13.8872H10.5C11.0178 13.8872 11.4375 13.4675 11.4375 12.9497C11.4375 12.6391 11.6893 12.3872 12 12.3872C12.3107 12.3872 12.5625 12.6391 12.5625 12.9497C12.5625 14.0888 11.6391 15.0122 10.5 15.0122H8.03139C7.78476 16.4333 6.12189 17.207 4.85593 16.363L3.35593 15.363C2.78215 14.9805 2.4375 14.3365 2.4375 13.6469V4.75249C2.4375 4.06289 2.78215 3.41891 3.35593 3.03639L4.85593 2.03639ZM11.6476 7.30196C11.8673 7.52163 11.8673 7.87778 11.6476 8.09745L11.1079 8.63721L14.9999 8.63721C15.3105 8.63721 15.5624 8.88905 15.5624 9.19971C15.5624 9.51037 15.3105 9.76221 14.9999 9.76221L11.1079 9.76221L11.6476 10.302C11.8673 10.5216 11.8673 10.8778 11.6476 11.0975C11.428 11.3171 11.0718 11.3171 10.8521 11.0975L9.88247 10.1278C9.36991 9.61522 9.36991 8.78419 9.88247 8.27163L10.8521 7.30196C11.0718 7.08229 11.428 7.08229 11.6476 7.30196Z"
        fill="#988C92"
      />
    </svg>
  );
};

export { LogoutIcon };
