import {
  CASE_BATTLE_HOLD_AFTER_FINISH_DURATION,
  CASE_BATTLE_ROUND_DURATION,
  CaseBattleResolveStatus,
  CaseBattleStage,
} from 'app/case/battle/definitions';
import { CaseBattleMonitorBattleState } from 'app/case/battle/monitor/definitions';
import { CaseBattleMonitorNewBattleInListSound } from 'app/case/battle/monitor/sounds';
import { splashWarning } from 'app/chat/splash';
import { coilListen, coilReq, coilSend, serverNow, serverSleepUntil } from 'packs/libs/coil';
import { createContext, useContext, useEffect } from 'react';
import { bunchCb } from 'support/etc/bunch-cb';
import { MutSpec, mut } from 'support/etc/immutate';
import { objToMap } from 'support/etc/obj-to-map';
import sleep from 'support/etc/sleep';
import { useBasicSwissStore } from 'support/react/swiss';
import { launch } from 'support/react/use-launch';
import { HashMap } from 'support/struct/hash-map';
import { IHashMap } from 'support/struct/i-hash-map';

type BM = IHashMap<string, Battle>;
type State = {
  loading: boolean;
  battles: BM;
};

type Battle = CaseBattleMonitorBattleState;

const Context = createContext<State>(null!);
if (__DEBUG__) Context.displayName = 'CaseBattleMonitorContext';

export const useCaseBattleMonitorState = () => useContext(Context);

export const CaseBattleMonitorStoreProvider = (props: Rcp): JsxElement => {
  const [value, effect] = useBasicSwissStore(() => [
    { loading: true, battles: IHashMap.empty() } as State,
    ({ updState, getState, setState }) => {
      let unmounted = false;

      const applyBattles = (fn: (battles: BM) => BM) => {
        updState({ battles: fn(getState().battles) });
      };

      const mutBattle = (id: string, fn: MutSpec<Battle>) => {
        applyBattles((map) => map.applyOne(id, (battle) => mut(battle, fn)));
      };

      const rotateLiveBattle = async (data: Battle) => {
        const id = data.id;
        for (let i = data.currentRound!; i <= data.cases.length; i++) {
          await serverSleepUntil(data.startedAt + i * CASE_BATTLE_ROUND_DURATION);
          if (unmounted) return;
          mutBattle(id, (draft) => {
            draft.currentRound = i;
          });
        }
        await sleep(CASE_BATTLE_ROUND_DURATION);
        if (unmounted) return;
        mutBattle(id, (draft) => {
          draft.stage = CaseBattleStage.finished;
        });
        await sleep(CASE_BATTLE_HOLD_AFTER_FINISH_DURATION);
        if (unmounted) return;
        applyBattles((map) => map.del(id));
      };

      const resolveBattle = (data: Battle) => {
        if (data.stage === CaseBattleStage.live) {
          data.currentRound =
            1 + Math.floor((serverNow() - data.startedAt) / CASE_BATTLE_ROUND_DURATION);
          if (data.currentRound > data.cases.length) {
            splashWarning({
              code: 'case.battle.monitor.resolve.current_round_out_of_bound',
              battleId: data.id,
              currentRound: data.currentRound,
              rounds: data.cases.length,
              start: data.startedAt,
            });
          } else {
            rotateLiveBattle(data);
          }
        }
        return data;
      };

      const loadBattles = () => {
        launch(async () => {
          const battles = await coilReq({ action: 'case.battle.monitor' });
          const map = new HashMap<string, Battle>();
          for (const battle of battles) {
            map.set(battle.id, resolveBattle(battle));
          }
          setState({ loading: false, battles: IHashMap.fromHashMap(map) });
        });
        return () => {
          coilSend('case.battle.monitor.leave');
        };
      };

      // do not delete
      // if (__DEV__) {
      //   import('./stub').then((m) => {
      //     setTimeout(() => {
      //       updState({
      //         battles: IHashMap.by(
      //           (v) => v.id,
      //           m.CaseBattleMonitorStub.getBattles().map(resolveBattle)
      //         ),
      //       });
      //     }, 1000);
      //   });
      // }

      const listeners = objToMap({
        new: (data) => {
          applyBattles((map) => map.prepend(data.id, { ...data, stage: CaseBattleStage.waiting }));
          CaseBattleMonitorNewBattleInListSound.replay();
        },
        joined: ([battleId, player]) => {
          mutBattle(battleId, (draft) => {
            draft.members.push(player);
          });
        },
        resolve: ([battleId, status, data]) => {
          if (status === CaseBattleResolveStatus.success) {
            mutBattle(battleId, (draft) => {
              draft.stage = CaseBattleStage.live;
              draft.startedAt = data.time;
              draft.currentRound = 1;
              if (data.bots) draft.members.push(...data.bots);
            });
            rotateLiveBattle(getState().battles.get(battleId)!);
          } else {
            applyBattles((map) => map.del(battleId));
          }
        },
      });

      const listenEvents = () =>
        coilListen('cb_m', ([action, data]) => {
          listeners.get(action)!(data);
        });

      return () =>
        bunchCb([
          () => {
            unmounted = true;
          },
          loadBattles(),
          listenEvents(),
        ]);
    },
  ]);

  useEffect(effect, []);

  return <Context.Provider value={value}>{props.children}</Context.Provider>;
};
