import { css } from '@emotion/css';
import { useCrushT } from 'app/crush/glossary';
import { openSignInDialog } from 'app/sign-in/dialog';

export const CrushPlaceholderGrabBonusButton = (): JsxElement => {
  const t = useCrushT().sub('placeholder');
  return (
    <button className={buttonClass} onClick={openSignInDialog}>
      {t('bonus')}
    </button>
  );
};

const buttonClass = css`
  width: 229px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 12px 11px;
  background: linear-gradient(180deg, #ffaa63 0%, #ea9a4e 100%);
  box-shadow: inset 0 4px 12px 2px #ff7324;
  border-radius: 4px;
  border: 0;
  color: #2d241c;
  font-family: 'Onest';
  font-weight: 700;
  font-size: 12px;
  z-index: 1;
  &:hover {
    background: #ffbe88;
    box-shadow: none;
  }
`;
