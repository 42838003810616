import { splashEmergency } from 'app/chat/splash';
import { collectBrowserData } from 'domain/tracking/tracking';
import { showErrorToast } from 'packs/libs/inf';
import { Component } from 'react';
import { formatComponentStack, formatError } from 'support/etc/format-error';

export class SimpleOutpost extends Component<any, any> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    if (__DEBUG__) {
      showErrorToast('some part of the site has crashed. look at the dev console');
    }
    splashEmergency({
      ...collectBrowserData(),
      error: formatError(error),
      stack: formatComponentStack(info.componentStack),
      code: 'bsod',
    });
  }

  render() {
    if (this.state.hasError) {
      return null;
    }

    return this.props.children;
  }
}
