import { css } from '@emotion/css';
import { useContestT } from 'app/contest/glossary';

export const ContestsInsightTopTableHead = (): JsxElement => {
  const t = useContestT().sub('insight.table');

  return (
    <div className={mainClass}>
      <div className={boxClass}>
        <span className={titleClass}>{t('place')}</span>
        <span className={titleClass}>{t('player')}</span>
        <span className={titleClass}>{t('bet')}</span>
        <span className={titleClass}>{t('profit')}</span>
        <span className={titleClass}>{t('prize')}</span>
        <span className={titleClass}>{t('history')}</span>
      </div>
    </div>
  );
};

const mainClass = css`
  width: 100%;
  background: #1e1c22;
  border-radius: 12px;
`;

const boxClass = css`
  padding: 18px 10px 18px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const titleClass = css`
  width: calc(100% / 6);
  font-family: 'Commissioner';
  font-weight: 700;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #696155;
`;
