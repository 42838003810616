import { css } from '@emotion/css';
import { ContestCardActive } from 'app/contest/card/builds/active';
import { ContestCardFinished } from 'app/contest/card/builds/finished';
import { ContestCardUpcoming } from 'app/contest/card/builds/upcoming';
import { ContestCardArrowButton } from 'app/contest/card/units/arrow-button';
import { ContestCardAttribute } from 'app/contest/card/units/attribute';
import { ContestCardDoubleColumnRow } from 'app/contest/card/units/double-column-row';
import { ContestCardRequirementList } from 'app/contest/card/units/requirement-list';
import { useContestT } from 'app/contest/glossary';
import { ContestBriefData, ContestStatus } from 'domain/contest';
import { chronicle } from 'packs/libs/chronicle';
import { StdKindButton } from 'packs/std/inputs/kind-button';
import { Link } from 'react-router-dom';
import { ReactComponent as ArrowIcon } from 'app/contest/assets/arrow.svg';
import { ContestCardCalculation } from 'app/contest/card/builds/calculation';

type CurrentCommonProps = {
  contest: ContestBriefData;
};

const CurrentCommon = ({ contest }: CurrentCommonProps): JsxElement => {
  const t = useContestT().sub('monitor.card');
  return (
    <>
      <ContestCardDoubleColumnRow>
        <ContestCardArrowButton
          className={buttonWithArrowClass}
          children={t('join')}
          onClick={() => {
            chronicle.push(`/contests/${contest.id}`);
          }}
        />
        <ContestCardAttribute title={t('cost')}>
          <span className={costValueClass}>${contest.cost ?? 0}</span>
        </ContestCardAttribute>
      </ContestCardDoubleColumnRow>
      <ContestCardRequirementList contest={contest} />
    </>
  );
};

export type ContestMonitorCardProps = {
  contest: ContestBriefData;
};

export const ContestMonitorCardActive = ({ contest }: ContestMonitorCardProps): JsxElement => {
  return contest.status === ContestStatus.calculation ? (
    <ContestCardCalculation contest={contest}>
      <CurrentCommon contest={contest} />
    </ContestCardCalculation>
  ) : (
    <ContestCardActive contest={contest}>
      <CurrentCommon contest={contest} />
    </ContestCardActive>
  );
};

export const ContestMonitorCardUpcoming = ({ contest }: ContestMonitorCardProps): JsxElement => {
  return (
    <ContestCardUpcoming contest={contest}>
      <CurrentCommon contest={contest} />
    </ContestCardUpcoming>
  );
};

const costValueClass = css`
  font-weight: 600;
  font-size: 14px;
  line-height: 130%;
  /* orange 900 */
  background: linear-gradient(180deg, #ffaa63 0%, #ffaa63 0.01%, #ea9a4e 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;

export const ContestMonitorCardFinished = ({ contest }: ContestMonitorCardProps): JsxElement => {
  const t = useContestT().sub('monitor.card');
  return (
    <ContestCardFinished contest={contest}>
      <Link className={linkClass} to={`/contests/${contest.id}`}>
        <StdKindButton className={buttonWithArrowClass}>
          {t('history.go-on')}
          <ArrowIcon />
        </StdKindButton>
      </Link>
    </ContestCardFinished>
  );
};

const linkClass = css`
  width: 100%;
`;

const buttonWithArrowClass = css`
  width: 100%;
  dispalay: flex;
  gap: 4px;
  align-items: center;
`;
