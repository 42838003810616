import sleep from 'support/etc/sleep';
import { css } from '@emotion/css';
import { lastMemo } from 'support/etc/last-memo';
import { stash_get } from 'support/etc/stash';

type RawNode = [backward: string | null, node: string, forward: string | null];

class Segment {
  constructor(
    public gte?: Shift,
    public lt?: Shift,
    public gteStill?: Shift,
    public ltStill?: Shift
  ) {}
}

export class UpgradeBiddingGlideGlider {
  private declare running: boolean;
  private declare segment: Segment;
  private declare chance: number;

  constructor(private head: Segment, private box: VideoBox) {}

  clear(): void {
    this.running = false;
    this.segment = null!;
    this.chance = null!;
    this.box.clear();
  }

  init(chance: number, el: Element): void {
    this.chance = chance;
    this.segment = this.getSegment(chance);
    this.box.attach(el);
    const shift = this.segment.gte ?? this.segment.lt;
    this.box.play(shift!);
  }

  setChance(chance: number) {
    this.chance = chance;
    if (!this.running) this.run();
  }

  run() {
    if (this.box.absent()) {
      if (__DEV__) {
        if (this.running) {
          throw Error();
        }
      }
      return;
    }

    const shift = this.getShift(this.chance);
    if (shift !== undefined) {
      this.running = true;
      this.box
        .play(shift)
        .then(() => sleep(100))
        .then(() => {
          this.segment = shift.segment;
        })
        .finally(() => {
          this.run();
        });
    } else {
      this.running = false;
    }
  }

  private getShift(chance: number): Shift | undefined {
    const lt = this.segment.lt;
    if (lt && lt.point >= chance) return lt;
    const gte = this.segment.gte;
    if (gte && gte.point < chance) return gte;
  }

  private getSegment(chance: number) {
    let current = this.head;
    while (true) {
      const lt = current.lt;
      if (lt && lt.point >= chance) {
        current = lt.segment;
      } else {
        const gte = current.gte;
        if (gte && gte.point < chance) {
          current = gte.segment;
        } else {
          break;
        }
      }
    }
    return current;
  }

  static create = lastMemo((data) => {
    const box = new VideoBox();
    let segment: Segment = new Segment();
    for (let i = 0; i < breakpoints.length; i++) {
      const bp = breakpoints[i];
      const next = new Segment();
      next.lt = new Shift(bp, segment, `b${i}.webm`, false);
      box.add(next.lt);
      next.ltStill = new Shift(bp, segment, `bs${i}.webm`, true);
      box.add(next.ltStill);
      segment.gte = new Shift(bp, next, `f${i}.webm`, false);
      box.add(segment.gte);
      segment.gteStill = new Shift(bp, next, `fs${i}.webm`, true);
      box.add(segment.gteStill);
      segment = next;
    }
    return new UpgradeBiddingGlideGlider(segment, box);
  });
}

const breakpoints = [10, 30, 60];

class Shift {
  constructor(
    public readonly point: number,
    public readonly segment: Segment,
    public readonly path: string,
    public readonly loop: boolean
  ) {}

  @stash_get
  get video() {
    const video = document.createElement('video');
    video.muted = true;
    video.preload = 'auto';
    video.loop = this.loop;
    video.autoplay = this.loop;
    video.muted = true;
    video.playsInline = this.loop;
    video.src = `/assets/upgrade/glide/${this.path}`;
    video.classList.add(videoClass);
    video.load();
    return video;
  }

  show() {
    this.display('block');
  }

  reset() {
    this.video.pause();
    this.display('none');
  }

  private display(value: string) {
    this.video.style.setProperty('display', value);
  }
}

class VideoBox {
  private displayed?: Shift;
  private box: Element;
  private set = new Set<Shift>();

  setBox(el: Element) {
    this.box = el;
  }

  absent() {
    return !this.box;
  }

  display(shift: Shift) {
    const prev = this.displayed;
    this.displayed = shift;
    shift.show();
    prev?.reset();

    const nextInfinitySegment = prev?.video.nextSibling as HTMLVideoElement;
    if (nextInfinitySegment) {
      nextInfinitySegment.style.display = 'none';
    }
  }

  attach(box: Element) {
    this.box = box;
    for (const shift of this.set) {
      shift.reset();
      box.appendChild(shift.video);
    }
  }

  add(shift: Shift) {
    this.set.add(shift);
  }

  clear() {
    this.box.replaceChildren();
    this.displayed = null!;
  }

  async play(shift: Shift): Promise<void> {
    this.display(shift);
    return videoPlayUntilEnd(shift.video);
  }
}

const videoPlayUntilEnd = (video: HTMLVideoElement) => {
  return new Promise<void>((resolve, reject) => {
    const doResolve = () => {
      const nextInfinitySegment = video.nextSibling as HTMLVideoElement;
      nextInfinitySegment.play()?.catch(() => {});
      nextInfinitySegment.style.display = 'block';
      video.style.display = 'none';

      clear();
      resolve();
    };
    const doReject = () => {
      clear();
      reject();
    };
    video.play()?.catch(doReject);
    video.addEventListener('ended', doResolve);
    video.addEventListener('abort', doReject);
    video.addEventListener('error', doReject);
    const clear = () => {
      video.removeEventListener('ended', doResolve);
      video.removeEventListener('abort', doReject);
      video.removeEventListener('error', doReject);
    };
  });
};

const videoClass = css`
  position: absolute;
  width: 100%;
  height: 100%;
`;
