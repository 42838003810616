import { createMetaResolver } from 'domain/etc/meta/resolver';
import { match } from 'path-to-regexp';

import routes from './pages.yml';

import IntlMessageFormat from 'intl-messageformat';

import en from './langs/en.yml';
import es from './langs/es.yml';
import ru from './langs/ru.yml';

import { chronicle } from 'packs/libs/chronicle';
import { OnLocaleChanged, getIntl } from 'packs/libs/intl';

const createMessage = (icu: string, locale: string) => {
  const message = new IntlMessageFormat(icu, locale);
  return message.format.bind(message);
};

const getMeta = createMetaResolver(routes, match, { en, es, ru }, createMessage);

export const startupMeta = () => {
  let currentTitle: string | null = null;
  const setTitle = (title: string) => {
    if (currentTitle === title) return;
    currentTitle = title;
    document.title = title;
  };

  let currentDescription: string | null = null;
  const setDescription = (description: string) => {
    if (currentDescription === description) return;
    currentDescription = description;
    document.querySelector('meta[name="og:description"]')!.setAttribute('content', description);
    document.querySelector('meta[name="description"]')!.setAttribute('content', description);
  };

  let currentKeywords: string | null = null;
  const setKeywords = (keywords: string) => {
    if (currentKeywords === keywords) return;
    currentKeywords = keywords;
    document.querySelector('meta[name="keywords"]')!.setAttribute('content', keywords);
  };

  let currentHeadline: string | null = null;
  const setHeadline = (tagline: string) => {
    if (currentHeadline === tagline) return;
    currentHeadline = tagline;
    document.querySelector('meta[name="og:title"]')!.setAttribute('content', tagline);
  };

  const update = () => {
    const meta = getMeta(chronicle.location.pathname, getIntl().locale);

    setTitle(meta.title);
    setDescription(meta.description);
    setKeywords(meta.keywords);
    setHeadline(meta.headline);
  };

  chronicle.listen(update);
  OnLocaleChanged.add(update);
};
