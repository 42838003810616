import { css } from '@emotion/css';
import { LeaderBoardCurrentTopData } from 'app/leader-board/definitions';
import { useLeaderBoardT } from 'app/leader-board/glossary';
import { ReactComponent as BackgroundBronze } from 'app/leader-board/top-3/assets/background-bronze.svg';
import { ReactComponent as BackgroundGold } from 'app/leader-board/top-3/assets/background-gold.svg';
import { ReactComponent as BackgroundSilver } from 'app/leader-board/top-3/assets/background-silver.svg';
import BRONZE_ICON from 'app/leader-board/top-3/assets/bronze.png';
import GOLD_ICON from 'app/leader-board/top-3/assets/gold.png';
import { ReactComponent as FooterIcon } from 'app/leader-board/top-3/assets/information.svg';
import SILVER_ICON from 'app/leader-board/top-3/assets/silver.png';
import { ReactComponent as UsersIcon } from 'app/leader-board/top-3/assets/users.svg';
import { LeaderBoardTop3Countdown } from 'app/leader-board/top-3/countdown';
import { LeaderBoardTop3LeaderCard } from 'app/leader-board/top-3/leader-card';
import { ReactNode } from 'react';

type LeaderBoardTop3Props = {
  data: LeaderBoardCurrentTopData;
};

type LeaderBackground = {
  gold: string;
  silver: string;
  bronze: string;
};

const TOP_3_CONFIG = [
  {
    icon: GOLD_ICON,
    color: 'linear-gradient(135deg, rgba(38, 35, 45, 0) 0%,  rgba(242, 160, 86, 1) 100%)',
    bg: BackgroundGold,
  },
  {
    icon: SILVER_ICON,
    color: 'linear-gradient(135deg, rgba(0, 0, 0, 0) 0%, rgba(152, 140, 146, 1) 100%)',
    bg: BackgroundSilver,
  },
  {
    icon: BRONZE_ICON,
    color: 'linear-gradient(135deg, rgba(38, 35, 45, 0) 0%, rgba(212, 94, 57, 1) 100%)',
    bg: BackgroundBronze,
  },
];

export const LeaderBoardTop3 = ({ data }: LeaderBoardTop3Props): JsxElement => {
  const t = useLeaderBoardT().sub('top3');

  const children: ReactNode[] = [];
  const it = data.list.values();
  for (const config of TOP_3_CONFIG) {
    const pos = it.next();
    children.push(
      pos.done ? null : (
        <LeaderBoardTop3LeaderCard
          leader={pos.value}
          icon={config.icon}
          background={config.color}
          BackgroundIcon={config.bg}
        />
      )
    );
  }

  return (
    <div className={mainClass}>
      <div className={boxClass}>
        <div className={contentClass}>
          <div className={headerClass}>
            <div className={titleClass}>
              <UsersIcon />
              <p>{t('top')}</p>
            </div>
            <LeaderBoardTop3Countdown finish={data.time.rotate} />
          </div>
          <div className={cardListClass}>{...children}</div>
        </div>
        <div className={footerClass}>
          <div className={footerIconClass}>
            <FooterIcon />
          </div>
          <p>{t('info')}</p>
        </div>
      </div>
    </div>
  );
};

const mainClass = css`
  background: #1e1c22;
  border-radius: 12px;
`;

const boxClass = css`
  padding: 11px 24px 24px 24px;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const contentClass = css`
  display: flex;
  flex-direction: column;
  gap: 13px;
`;

const headerClass = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const titleClass = css`
  display: flex;
  gap: 6px;
  align-items: center;

  font-weight: 700;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #dfdfdf;
`;

const cardListClass = css`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 18px;
`;

const footerIconClass = css`
  width: 24px;
  height: 24px;
`;

const footerClass = css`
  display: flex;
  gap: 13px;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  color: #988c92;
  align-items: center;
`;
