import lodashDebounce from 'lodash/debounce';
import { useEffect, useState } from 'react';

export function useScroll<T extends HTMLElement>(
  onBottom: () => void,
  offset = 0,
  debounce = 200
): (el: T) => void {
  const [node, setNode] = useState<T | null>(null);

  useEffect(() => {
    if (node === null) return;
    const callback = lodashDebounce(
      () => {
        if (node === null) return;
        const scrollContainerBottomPosition = Math.round(node.scrollTop + node.clientHeight);
        const scrollPosition = Math.round(node.scrollHeight - offset);

        if (scrollPosition <= scrollContainerBottomPosition) {
          onBottom();
        }
      },
      debounce,
      { trailing: true }
    );

    node.addEventListener('scroll', callback);

    return () => {
      node.removeEventListener('scroll', callback);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onBottom, node]);

  return setNode;
}
