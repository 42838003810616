import Dexie, { IndexableType } from 'dexie';

const db = new Dexie('cluck');
db.version(1).stores({
  kv: `k, v`,
});

export const kvFind = <T = unknown>(key: IndexableType): Promise<T> =>
  db
    .table('kv')
    .where('k')
    .equals(key)
    .first()
    .then((e) => e?.v);

export const kvSet = async (key: IndexableType, value: any): Promise<void> => {
  await db.table('kv').put({ k: key, v: value });
};
export const kvDel = async (key: IndexableType): Promise<void> => {
  await db.table('kv').delete(key);
};
export const kvResolve = async <T = unknown>(
  key: IndexableType,
  callback: () => T | Promise<T>
): Promise<T> => {
  const value = await kvFind<T>(key);
  if (value !== undefined) return value;
  const result = await callback();
  await kvSet(key, result);
  return result;
};

export function kvRepo<T>(key: string): [
  () => Promise<T | undefined>, // find
  (value: T) => Promise<void>, // set
  () => Promise<void> // delete
] {
  return [
    () => kvFind(key),
    // @ts-ignore
    (value: T) => kvSet(key, value),
    () => kvDel(key),
  ];
}

export default db;