import { stashCb } from 'support/etc/stash';
import { launch } from 'support/react/use-launch';

// https://developer.zendesk.com/api-reference/widget-messaging/web/core/
export const openZendeskWidget = stashCb(() => {
  let initialized = false;
  let isChatOpen = false;

  const checkInitialized = async () => {
    if (initialized) return;
    await new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.id = 'ze-snippet';
      script.src = `https://static.zdassets.com/ekr/snippet.js?key=${ENV_ZENDESK_KEY}`;
      script.async = true;
      document.body.appendChild(script);
      script.onload = resolve;
      script.onerror = reject;
    });
    initialized = true;
    exec('messenger:on', 'open', () => {
      isChatOpen = true;
    });
    exec('messenger:on', 'close', () => {
      isChatOpen = false;
    });
  };

  window['zESettings'] = {
    webWidget: {
      zIndex: 10000,
    },
  };

  // https://developer.zendesk.com/api-reference/widget/chat-api/
  const exec = (command: string, ...args: any[]) => {
    const ze = window['zE'];
    if (ze === undefined) throw Error('Zendesk widget is not initialized');
    ze(command, ...args);
  };

  const execWidget = (command: string, ...args: any[]) => {
    exec('messenger', command, ...args);
  };

  return () => {
    launch(async () => {
      await checkInitialized();
      if (isChatOpen) {
        execWidget('close');
      } else {
        execWidget('open');
      }
    });
  };
});
