import { css } from '@emotion/css';

import { LayoutStatsPanel } from './stats/stats-panel';
import { LayoutStatsSocialPanel } from 'app/layout/stats/social/social-panel';

export const LayoutStatsSocialMedia = (): JsxElement => {
  return (
    <div className={statsSocialsClass}>
      <LayoutStatsPanel />
      <LayoutStatsSocialPanel />
    </div>
  );
};

const statsSocialsClass = css`
  display: flex;
  height: 34px;
  border-bottom: 1px solid transparent;
  border-image: linear-gradient(
    270deg,
    rgba(217, 217, 217, 0.15) 0%,
    rgba(217, 217, 217, 0.05) 100%
  );
  border-image-slice: 1;
  align-items: center;
  justify-content: space-between;
  padding-left: 24px;
`;
