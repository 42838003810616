export function strGen(space: string) {
  const len = space.length;
  return (length: number) => {
    return () => {
      let result = '';
      for (let i = 0; i < length; i++) {
        result += space.charAt(Math.floor(Math.random() * len));
      }
      return result;
    };
  };
}

export const strGenBase64 = (with2Chars: string) =>
  strGen(`ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789${with2Chars}`);
export const strGenStdBase64 = strGenBase64('_-');
