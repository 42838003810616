import { css } from '@emotion/css';
import { appSyringe } from 'app/syringe';
import { StdCloseDialogIcon } from 'packs/std/dialog/blocks/close-icon';
import { StdTopDialog } from 'packs/std/dialog/top-dialog';
import { CaseBattleMonitorBattleState } from '../definitions';
import { CaseBattleMonitorAllCaseDialogCard } from './card';
import { ReactNode, useMemo } from 'react';
import { CaseData } from 'domain/case/definitions';
import { CaseBattleMonitorAllCaseStats } from './stats';
import { CaseBattlePlayerRole, CaseBattleStage } from '../../definitions';
import { Link } from 'react-router-dom';
import { StdButton } from 'packs/std';
import { getCaseBattleActorRole } from 'domain/case/battle/utils/get-actor-role';
import { useActor } from 'domain/actor';
import { useCaseBattleMonitorT } from '../glossary';
import { ReactComponent as SwordIcon } from 'app/case/battle/assets/sword.svg';
import { CaseBattleMonitorEnterBattleSound } from '../sounds';
import { ReactComponent as WatchIcon } from './assets/watch.svg';
import { CaseBattleMonitorAllCaseHeader } from './header';

export const openCaseBattleMonitorAllCaseDialog = (data: CaseBattleMonitorBattleState) => {
  appSyringe(function CaseBattleMonitorAllCaseDialog({ onClose }) {
    return (
      <StdTopDialog onClose={onClose}>
        <Content onClose={onClose} data={data} />
      </StdTopDialog>
    );
  });
};

type CaseBattleMonitorAllCaseProps = {
  onClose(): void;
  data: CaseBattleMonitorBattleState;
};

const Content = ({ onClose, data }: CaseBattleMonitorAllCaseProps): JsxElement => {
  const { stage, cases, id, members, size, cost, currentRound } = data;
  const actor = useActor();
  const role = getCaseBattleActorRole(data, actor);
  const t = useCaseBattleMonitorT().sub('all-cases');

  const composeActions = () => {
    const link = `/case-battles/${id}`;
    if (stage === CaseBattleStage.waiting) {
      if (role === CaseBattlePlayerRole.observer) {
        return (
          <LinkButton
            to={link}
            children={t('enter', { amount: cost.toFixed() })}
            icon={<SwordIcon />}
            onClick={() => {
              CaseBattleMonitorEnterBattleSound.replay();
              onClose();
            }}
          />
        );
      }
      return (
        <LinkButton
          to={link}
          children={t('watch')}
          icon={<WatchIcon className={iconSvgClass} />}
          onClick={onClose}
        />
      );
    }
    const watchButton = (
      <LinkButton
        to={link}
        children={t('watch')}
        icon={<WatchIcon className={iconSvgClass} />}
        onClick={onClose}
      />
    );
    if (stage === CaseBattleStage.live) {
      return watchButton;
    }
    return watchButton;
  };

  return (
    <div className={mainClass}>
      <div className={selectedDialogClass}>
        <div className={headClass}>
          <div className={titleBoxClass}>
            <CaseBattleMonitorAllCaseHeader stage={stage} size={size} members={members} />
          </div>
          <div onClick={onClose} className={closeClass}>
            <StdCloseDialogIcon />
          </div>
        </div>

        <div className={bodyClass}>
          <div className={bodyCaseListBoxScrollClass}>
            <div className={bodyCaseListBoxClass}>
              <CaseList cases={cases} />
            </div>
          </div>
          <div className={searchFooterClass}>
            <CaseBattleMonitorAllCaseStats
              rounds={currentRound ? currentRound : cases.length}
              total={cost}
            />
            {composeActions()}
          </div>
        </div>
      </div>
    </div>
  );
};
type CaseListProps = {
  cases: CaseData[];
};
const CaseList = ({ cases }: CaseListProps): JsxElement => {
  return useMemo(() => {
    return (
      <div className={listBoxClass}>
        {cases.map((item) => {
          return <CaseBattleMonitorAllCaseDialogCard key={item.id} data={item} />;
        })}
      </div>
    );
  }, [cases]);
};

type LinkButtonProps = {
  to: string;
  children: ReactNode;
  icon: ReactNode;
  onClick?: () => void;
};
const LinkButton = ({ to, children, icon, onClick }: LinkButtonProps): JsxElement => {
  return (
    <Link to={to}>
      <StdButton className={linkButtonClass} onClick={onClick}>
        {icon}
        {children}
      </StdButton>
    </Link>
  );
};
const iconSvgClass = css`
  width: 14px;
  height: 14px;
  padding-bottom: 3px;
`;
const baseButtonClass = css`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  gap: 5px;

  width: 143px;
  height: 30px;

  border-radius: 4px;
  border: 0;
  color: #2d241c;

  font-family: 'Onest';
  font-weight: 700;
  font-size: 11px;

  margin: 0 5px;

  &:hover {
    box-shadow: none;
  }
`;
const linkButtonClass = css`
  ${baseButtonClass};
  width: 228px;
  height: 48px;
  background: linear-gradient(180deg, #ffaa63 0%, #ea9a4e 100%);
  box-shadow: inset 0 4px 12px 2px #ff7324;

  &:hover {
    background: #ffbe88;
  }
`;

const mainClass = css`
  width: 580px;
  display: flex;
  border-radius: 32px;
  gap: 12px;
`;
const selectedDialogClass = css`
  width: 580px;
  display: flex;
  flex-direction: column;
  border-radius: 32px;
  gap: 2px;
`;

const bodyCaseListBoxScrollClass = css`
  padding-right: 8px;
  height: calc(100% - 78px);
`;
const bodyCaseListBoxClass = css`
  padding: 0 10px 0 4px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 2px;
  }
`;
const listBoxClass = css`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
`;

const searchFooterClass = css`
  position: relative;
  padding: 9px 24px;
  background: #26232d;
  align-items: center;
  display: flex;
  justify-content: space-between;
  gap: 38px;
  border-bottom-left-radius: 32px;
  border-bottom-right-radius: 32px;
`;

const bodyClass = css`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 557px;
  background: #26232d;
  border-bottom-left-radius: 32px;
  border-bottom-right-radius: 32px;
`;
const titleBoxClass = css`
  display: flex;
  align-items: center;
  gap: 40px;
`;

const closeClass = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background: #18171c;
  border-radius: 12px;
  cursor: pointer;
  svg {
    height: 14px;
  }
`;

const headClass = css`
  height: 81px;
  padding: 20px 30px 14px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #26232d;
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
`;
