export function formatError(error: any) {
  if (error instanceof Error) {
    const output: Rsa = { ...error };
    output._class = error.constructor.name;
    output._message = error.message;
    output._name = error.name;
    output._stack = formatStack(error.stack);
    if (error.cause) {
      output._cause = formatError(error.cause);
    }
    return output;
  }

  return error;
}

export const formatComponentStack = (stack: any) => {
  const lines: string[] = stack.split('\n');
  return lines.map((line) => line.slice(7));
};

const formatStack = (stack: any) => {
  if (stack === undefined) return undefined;
  const lines = stack.split('\n    at ').values();
  lines.next(); // skip first line as it's the error message
  return [...lines];
};
