import { css } from '@emotion/css';
import { stdBreakpoints } from 'packs/std';
import { ReactElement, ReactNode } from 'react';

type UpgradeBackgroundProps = {
  children: [ReactElement, ReactElement, ReactElement];
  offset?: ReactNode;
  leftChicken?: ReactNode;
};

export const UpgradeFrame = ({
  children: [left, center, right],
  offset,
  leftChicken,
}: UpgradeBackgroundProps): JsxElement => {
  const rightWingClipPathId = 'upgrade_frame_right_clip';
  const rightWingClipPath = (
    <clipPath id={rightWingClipPathId}>
      <path d="M513 24.9687C513 11.3414 501.667 0.448393 488.051 0.987438L23.0506 19.3956C10.1752 19.9053 0 30.4913 0 43.3768V190.647C0 203.609 10.292 214.229 23.2475 214.636L488.247 229.223C501.791 229.648 513 218.785 513 205.235V24.9687Z" />
    </clipPath>
  );
  const rightWingForeignObject = (
    <foreignObject
      x="455"
      y="33"
      width="518"
      height="232"
      clipPath={`url(#${rightWingClipPathId})`}
    >
      <div className={wingClass}>{right}</div>
    </foreignObject>
  );

  const leftWingClipPathId = 'upgrade_frame_left_clip';
  const leftWingClipPath = (
    <clipPath id={leftWingClipPathId}>
      <path d="M0 24.9689C0 11.3417 11.3328 0.448637 24.9494 0.987682L489.949 19.3959C502.825 19.9056 513 30.4916 513 43.3771V190.648C513 203.609 502.708 214.229 489.753 214.636L24.7525 229.223C11.2095 229.648 0 218.785 0 205.235V24.9689Z" />
    </clipPath>
  );
  const leftWingForeignObject = (
    <foreignObject x="0" y="33" width="518" height="232" clipPath={`url(#${leftWingClipPathId})`}>
      <div className={wingClass}>{left}</div>
    </foreignObject>
  );

  return (
    <div className={mainClass}>
      {offset && <div className={offsetClass}>{offset}</div>}
      {leftChicken && <div className={leftChickenClass}>{leftChicken}</div>}

      <svg
        className={svgMainClass}
        viewBox="0 0 970 264"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {leftWingForeignObject}
        {rightWingForeignObject}

        <foreignObject x="350" y="10" width="304" height="304">
          {center}
        </foreignObject>

        <defs>
          {leftWingClipPath}
          {rightWingClipPath}
        </defs>
      </svg>
    </div>
  );
};

const wingClass = css`
  width: 100%;
  height: 100%;
  background: #18171c;
  position: relative;
  padding: 15px;
`;
const svgMainClass = css`
  width: 100%;
  height: 304px;

  ${stdBreakpoints.up(1695)} {
    transform: scaleX(1.12);
  }
  ${stdBreakpoints.up(1820)} {
    transform: scaleX(1.22);
  }
  ${stdBreakpoints.up(1920)} {
    transform: scaleX(1.33);
  }
`;

const mainClass = css`
  max-height: 406px;
  position: relative;
  background: #1e1c22;
  border-radius: 12px;
  padding: 40px 32px 60px;
  ${stdBreakpoints.down(1440)} {
    padding: 0px 32px 5px;
  }
`;

const offsetClass = css`
  display: flex;
  align-items: center;
  position: absolute;
  left: 59%;
  top: 3%;
`;

const leftChickenClass = css`
  width: 40%;
  height: 100%;
  position: absolute;
  display: flex;
  bottom: 10%;
  align-items: center;
  justify-content: center;
`;
