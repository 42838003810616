import { css } from '@emotion/css';
import { debounce } from 'lodash';
import { ReactNode } from 'react';

import { useRanger } from 'packs/libs/ranger';
import { StdNumberInput } from 'packs/std/inputs/input';
import { useSingleton } from 'support/react/use-singleton';
import { useCaseBattleCreationT } from '../glossary';
import { ReactComponent as CostInputIcon } from './assets/input-cost-skin.svg';
import { ReactComponent as NameInputIcon } from './assets/input-name-skin.svg';
import { stdBp } from 'packs/std';

export const CaseBattleCaseSelectFilters = (): JsxElement => {
  const t = useCaseBattleCreationT().sub('case-select');
  return (
    <div className={inputBoxClass}>
      <TextInputFilter
        prop="name"
        icon={<NameInputIcon className={nameIconClass} />}
        placeholder={t('name')}
      />
      <NumberInputFilter
        prop="max"
        icon={<CostInputIcon className={costIconClass} />}
        placeholder={t('max-price')}
      />
    </div>
  );
};

type InputFilterProps = {
  prop: string;
  icon: ReactNode;
  placeholder: string;
};

const TextInputFilter = ({ icon, prop, placeholder }: InputFilterProps): JsxElement => {
  const ranger = useRanger();
  const onChange = useSingleton(() => {
    return debounce((e) => {
      const value = e.target.value;
      ranger[1].updQuery({ [prop]: value === '' ? undefined : value });
    }, 200);
  });

  return (
    <div className={inputClass}>
      {icon}
      <input className={inputFilterClass} onChange={onChange} placeholder={placeholder} />
    </div>
  );
};

type NumberInputFilterProps = {
  prop: string;
  icon: ReactNode;
  placeholder: string;
};

const NumberInputFilter = ({ icon, prop, placeholder }: NumberInputFilterProps): JsxElement => {
  const ranger = useRanger();
  const onChange = useSingleton(() => {
    const op = ranger[1];
    return debounce((value) => {
      if (value) {
        op.updQuery({ [prop]: value });
      } else {
        op.mutQuery((draft) => {
          delete draft[prop];
        });
      }
    }, 200);
  });

  return (
    <div className={inputClass}>
      {icon}
      <StdNumberInput className={inputFilterClass} onChange={onChange} placeholder={placeholder} />
    </div>
  );
};

const inputBoxClass = css`
  display: flex;
  align-items: center;
  gap: 12px;
`;
const inputClass = css`
  position: relative;
`;
const nameIconClass = css`
  position: absolute;
  height: 12px;
  top: 19px;
  left: 14px;
  ${stdBp.up(2560)} {
    top: 24px;
    height: calc(12px * 1.3);
  }
`;
const costIconClass = css`
  ${nameIconClass};
  height: 14px;
  ${stdBp.up(2560)} {
    top: 24px;
    height: calc(12px * 1.3);
  }
`;
const inputFilterClass = css`
  padding-left: 36px;
  width: 170px;
  background: #18171c;
  border-radius: 8px;
  border: none;
  outline: none;
  height: 48px;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #dfdfdf;
  ${stdBp.up(2560)} {
    height: calc(48px * 1.3);
    width: calc(170px * 1.3);
    font-size: calc(14px * 1.3);
  }
`;
