import { css } from '@emotion/css';
import { ReactComponent as PingIcon } from 'app/crush/assets/ping-icon.svg';
import { usePing } from 'packs/libs/coil';
import { CSSProperties } from 'react';

export const CrushFloorPing = (): JsxElement => {
  const ping = usePing();

  const color = getPingColor(ping!);

  return (
    <div className={mainClass} style={{ '--l-color': color } as CSSProperties}>
      <PingIcon className={iconClass} />
      {ping && (
        <>
          {ping}
          <span className={millisecondsClass}>ms</span>
        </>
      )}
    </div>
  );
};

const PING_THRESHOLDS = [
  [100, '20, 188, 140'],
  [Infinity, '255, 90, 90'],
] as const;

function getPingColor(ping: number): string {
  for (const [threshold, color] of PING_THRESHOLDS) {
    if (ping < threshold) return color;
  }
  throw new Error(`No color for ping ${ping}`);
}

const mainClass = css`
  position: absolute;
  right: 0;
  bottom: 38px;
  color: rgb(var(--l-color));
  font-weight: 600;
  font-size: 11px;
  letter-spacing: -0.01em;
  display: flex;
  align-items: center;
  gap: 2px;
`;

const millisecondsClass = css`
  color: rgba(var(--l-color), 0.4);
`;

const iconClass = css`
  width: 11px;
  height: 9px;
`;
