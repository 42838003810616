import { css } from '@emotion/css';
import { ReactComponent as HashIcon } from 'app/crush/assets/hash-icon.svg';
import { useCrushScopeRound } from 'app/crush/store/store';
import { useMemo } from 'react';

export const CrushFloorHash = (): JsxElement => {
  const round = useCrushScopeRound();

  return useMemo(() => {
    if (round.opts === undefined) return null;
    return (
      <div className={mainClass}>
        <div className={iconBoxClass}>
          <HashIcon className={iconClass} />
        </div>
        <div className={hashClass}>{round.opts.hash}</div>
      </div>
    );
  }, [round.opts]);
};

const mainClass = css`
  position: absolute;
  bottom: 32px;
  background: rgba(38, 35, 45, 0.4);
  border-radius: 4px;
  width: 321px;
  height: 25px;
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 4px;
`;

const iconBoxClass = css`
  padding-left: 8px;
`;

const iconClass = css`
  width: 12px;
  height: 12px;
`;

const hashClass = css`
  font-weight: 400;
  font-size: 11px;
  line-height: 130%;
  letter-spacing: -0.003em;
  color: #988c92;
  opacity: 0.5;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 4px;
`;
