export const UpgradeIcon = ({ active, current }) => {
  return !active && !current ? (
    <svg viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M26.25 15.75H19.5L21 6.75L9.75 20.25H16.5L15 29.25L26.25 15.75Z" fill="#988C92" />
    </svg>
  ) : current ? (
    <svg viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_i_24_12217)">
        <rect width="36" height="36" rx="9" fill="url(#paint0_linear_24_12217)" />
      </g>
      <rect
        x="0.75"
        y="0.75"
        width="34.5"
        height="34.5"
        rx="8.25"
        stroke="url(#paint1_linear_24_12217)"
        strokeWidth="1.5"
      />
      <path d="M26.25 15.75H19.5L21 6.75L9.75 20.25H16.5L15 29.25L26.25 15.75Z" fill="#2D241C" />
      <defs>
        <filter
          id="filter0_i_24_12217"
          x="0"
          y="0"
          width="36"
          height="39"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="1.5"
            operator="erode"
            in="SourceAlpha"
            result="effect1_innerShadow_24_12217"
          />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="4.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 0.450667 0 0 0 0 0.141667 0 0 0 1 0"
          />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_24_12217" />
        </filter>
        <linearGradient
          id="paint0_linear_24_12217"
          x1="18"
          y1="0"
          x2="18"
          y2="36"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFAA63" />
          <stop offset="1" stopColor="#EA9A4E" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_24_12217"
          x1="18"
          y1="0"
          x2="18"
          y2="36"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9457" />
          <stop offset="1" stopColor="#FFBA69" />
        </linearGradient>
      </defs>
    </svg>
  ) : (
    <svg viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M26.25 15.75H19.5L21 6.75L9.75 20.25H16.5L15 29.25L26.25 15.75Z"
        fill="url(#paint0_linear_1_5)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1_5"
          x1="18"
          y1="6.75"
          x2="18"
          y2="29.25"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFAA63" />
          <stop offset="1" stopColor="#EA9A4E" />
        </linearGradient>
      </defs>
    </svg>
  );
};
