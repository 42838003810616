import { IntlMessageFormat } from 'intl-messageformat';
import memoize from 'lodash/memoize';

import { stash_get } from 'support/etc/stash';
import { CallableClass } from 'support/patterns/callable-class';

export class IntlSub extends CallableClass {
  public declare readonly t: TFunc;
  public declare readonly tOrNull: (key: string, data?: Rsa) => string | null;

  constructor(
    public readonly locale,
    private readonly dict: Rsa,
    private readonly prefix?: string
  ) {
    if (typeof dict !== 'object') {
      if (__DEBUG__) {
        console.error(
          `dict on path ${prefix} should be an object. ${typeof dict} found instead`,
          new Error().stack
        );
      }
      dict = {};
    }

    const map = new Map<string, IntlMessageFormat>();
    const tOrNull = (key: string, data: Rsa) => {
      if (__DEBUG__) {
        if (!key) {
          throw Error(`unexpected key ${key}`);
        }
      }

      let value = map.get(key);
      if (value === undefined) {
        let message = get(dict, key);
        if (message === undefined) return null;
        value = new IntlMessageFormat(message, locale);
        map.set(key, value);
      }

      return value.format(data);
    };

    const t = (key: string, data: Rsa) => {
      let message = tOrNull(key, data);
      if (message === null) {
        if (__DEBUG__) {
          message = this.complete(key);
          console.error(
            `translation ${message} is missing for ${locale} language`,
            new Error().stack
          );
        }
        return message;
      }
      return message;
    };

    super(t);
    this.t = t;
    this.tOrNull = tOrNull;
  }

  @stash_get
  get lcRuEn() {
    return this.locale === 'ru' ? 'ru' : 'en';
  }

  @stash_get
  get obj() {
    return memoize(<T = any>(path: string): T => {
      return get(this.dict, path);
    });
  }

  @stash_get
  get sub() {
    const map = new Map<string, IntlSub>();
    return (prefix: string): IntlSub => {
      let sub = map.get(prefix);
      if (sub === undefined) {
        sub = new IntlSub(this.locale, get(this.dict, prefix), prefix);
        map.set(prefix, sub);
      }
      return sub;
    };
  }

  fork<T>(dict: Record<SupportedLocale, T>): T {
    return dict[this.locale];
  }

  // get cash() {
  //   return formatCash;
  // }

  ref(path: string, opts?: Rsa) {
    if (opts) {
      return (data: Rsa) => {
        if (data === undefined) {
          return this.t(path, opts);
        } else {
          return this.t(path, { ...opts, ...data });
        }
      };
    } else {
      return (data: Rsa) => this.t(path, data);
    }
  }

  @stash_get
  get plural(): (n: number) => 'zero' | 'one' | 'two' | 'few' | 'many' | 'other' {
    const rules = new Intl.PluralRules(this.locale);
    return rules.select.bind(rules);
  }

  private complete(path: string) {
    return this.prefix ? `${this.prefix}.${path}` : path;
  }
}

type TFunc = (key: string, data?: Rsa) => string;

const regexp = /\./;
const get = (dict: Rsa, path: string) => {
  const index = path.search(regexp);
  if (index === -1) return dict[path];

  const value = dict[path.substring(0, index)];

  if (value !== undefined) {
    return get(value, path.substring(index + 1));
  } else {
    return dict[path];
  }
};
