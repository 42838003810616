import { css } from '@emotion/css';
import { CaseBattlePlayerRole, CaseBattleStage } from 'app/case/battle/definitions';
import { CaseBattleInsightStand } from 'app/case/battle/insight/stand/stand';
import {
  useCaseBattleInsightBaseState,
  useCaseBattleInsightLiveState,
} from 'app/case/battle/insight/store';
import { ReactNode } from 'react';

export const CaseBattleInsightStandList = (): JsxElement => {
  const { members, cases, size, role, stage } = useCaseBattleInsightBaseState();
  const liveState = useCaseBattleInsightLiveState();

  const stands = liveState.stands?.values();
  const { currentCase } = liveState;

  const children: ReactNode[] = members.map((member) => {
    const stand = stands?.next().value!;
    return (
      <CaseBattleInsightStand
        cases={cases}
        player={member}
        stand={stand}
        currentCase={currentCase}
        size={size}
      />
    );
  });

  if (stage === CaseBattleStage.waiting) {
    const withAddBot = role === CaseBattlePlayerRole.author;
    for (let i = members.length; i < size; i++)
      children.push(<CaseBattleInsightStand withAddBot={withAddBot} cases={cases} size={size} />);
  }

  return <div className={mainClass}>{...children}</div>;
};

const mainClass = css`
  display: flex;
  gap: 2px;
`;
