import { css } from '@emotion/css';
import WreathIcon from 'app/case/battle/insight/results/assets/wreath.svg';
import GOLD_ICON_URL from 'app/case/battle/insight/results/assets/gold.png';
import { PlayerImage } from 'domain/player/image/image';
import { PlayerData } from 'app/case/battle/insight/results/dialog';
import { ReactComponent as BotAvatar } from 'app/case/battle/assets/bot-avatar.svg';
import { useCaseBattleInsightT } from '../glossary';
import { stdBp } from 'packs/std';

type CaseBattleInsightResultWinnerProps = {
  winner: PlayerData;
};

export const CaseBattleInsightResultWinner = ({
  winner,
}: CaseBattleInsightResultWinnerProps): JsxElement => {
  const t = useCaseBattleInsightT();

  const { name, image } = winner;

  return (
    <div className={winnerBodyClass}>
      <div className={winnerBorderClass}>
        {winner.bot ? (
          <BotAvatar className={winnerAvatarClass} />
        ) : (
          <PlayerImage code={image} className={winnerAvatarClass} />
        )}
        <div className={winnerInfoClass}>
          <span className={winnerNameClass}>{winner.bot ? t('bot.name') : name}</span>
          <img src={GOLD_ICON_URL} className={iconClass} alt="" />
        </div>

        <span className={winnerTextClass}>{t('results.winner')}</span>
      </div>
    </div>
  );
};

const winnerBodyClass = css`
  padding: 0 1px 1px 0;
  background: linear-gradient(165deg, rgba(38, 35, 45, 0.1) 20%, rgba(242, 160, 86, 0.5) 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    top: 5px;
    left: 50px;
    position: absolute;
    height: 100%;
    width: 110px;
    background: url(${WreathIcon});
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.15;

    ${stdBp.up(1920)} {
      top: 10px;
    }
  }

  &:after {
    content: '';
    top: 5px;
    right: 50px;
    transform: rotateY(180deg);
    position: absolute;
    height: 100%;
    width: 110px;
    background: url(${WreathIcon});
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.15;

    ${stdBp.up(1920)} {
      top: 10px;
    }
  }
`;

const winnerBorderClass = css`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 140px;
  background-image: linear-gradient(
    180deg,
    #1f1d23 0%,
    rgba(30, 28, 34, 0.6) 50%,
    rgba(30, 28, 34, 0.6) 100%
  );

  ${stdBp.up(1920)} {
    height: 168px;
  }
`;

const winnerAvatarClass = css`
  width: 48px;
  height: 48px;
  border-radius: 8px;

  ${stdBp.up(1920)} {
    width: 57px;
    height: 57px;
  }
`;

const winnerNameClass = css`
  font-family: 'Onest';
  font-weight: 500;
  letter-spacing: 0.04em;
  line-height: 140%;
  padding-right: 4px;
  font-size: 13px;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${stdBp.up(1920)} {
    font-size: 15px;
  }
`;

const iconClass = css`
  width: 15px;
  height: 16px;

  ${stdBp.up(1920)} {
    width: 18px;
    height: 19px;
  }
`;

const winnerInfoClass = css`
  padding: 8px 0 8px 0;
  display: flex;
  justify-content: center;
  gap: 4px;
  align-items: center;
`;

const winnerTextClass = css`
  font-weight: 600;
  font-size: 11px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  background: linear-gradient(180deg, #ffaa63 0%, #ffaa63 0.01%, #ea9a4e 100%);
  -webkit-text-fill-color: transparent;
  background-clip: text;

  ${stdBp.up(1920)} {
    font-size: 13px;
  }
`;
