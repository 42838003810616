import { css } from '@emotion/css';
import { serverNow } from 'packs/libs/coil';
import { CSSProperties } from 'react';

type CrushSceneCountdownCounterProps = {
  finish: number;
};

export const CrushSceneCountdownCounter = ({
  finish,
}: CrushSceneCountdownCounterProps): JsxElement => {
  const offset = 1e4 - (finish - serverNow());

  return <div style={{ '--delay': `-${offset}ms` } as CSSProperties}>{content}</div>;
};

const mainClass = css`
  display: flex;
  gap: 5px;
`;

const boxClass = css`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #26232d;
  border-radius: 10px;
  width: 48px;
  height: 48px;
  text-align: center;
  font-family: 'Onest';
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
  z-index: 1;
`;

const timerClass = css`
  background: #18171c;
  border: 2px solid #1e1c22;
  border-radius: 10px;
  width: 40px;
  height: 40px;
  position: relative;
  overflow: hidden;
`;

const seconds = css`
  position: absolute;
  left: 25%;
  top: 40%;

  animation-name: bottom;
  animation-duration: 10s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-delay: var(--delay);

  @keyframes bottom {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-94%);
    }
  }
`;

const greyClass = css`
  color: #988c92;
`;
const redClass = css`
  color: #ff5a5a;
`;
const whiteClass = css`
  color: #dfdfdf;
`;

const content = (
  <div className={mainClass}>
    <div className={boxClass}>
      <div className={timerClass}>
        <div className={greyClass}>0</div>
      </div>
    </div>
    <div className={boxClass}>
      <div className={timerClass}>
        <div className={seconds}>
          {...[9, 8, 7, 6, 5, 4, 3, 2, 1].map((value) => (
            <div className={value > 5 ? whiteClass : redClass}>{value}</div>
          ))}
        </div>
      </div>
    </div>
  </div>
);
