import { css } from '@emotion/css';
import { useState } from 'react';

import { changeLocale, INTL_SUPPORTED_LOCALES } from 'packs/libs/intl';
import { useLayoutT } from 'app/layout/glossary';
import { LayoutSidebarNavPanelOutsideClickHandler } from 'app/layout/sidebar/settings/outside-click-handler';

export const LayoutSidebarSettingsChangeLanguage = (): JsxElement => {
  const t = useLayoutT();
  const [open, setOpen] = useState<boolean>(false);

  const language = t.locale;

  return (
    <LayoutSidebarNavPanelOutsideClickHandler onOutsideClick={() => setOpen(false)}>
      <div className={mainClass} onClick={() => setOpen(!open)}>
        <div
          style={{ background: open ? '#2A2930' : '#26232D' }}
          className={currentLanguageBoxClass}
        >
          {icons[language]}
          <p className={textClass}>{language.toUpperCase()}</p>
        </div>
        {open && <Popup icons={icons} />}
      </div>
    </LayoutSidebarNavPanelOutsideClickHandler>
  );
};

type ChooseLanguagePopupProps = {
  icons: Object;
};

const Popup = ({ icons }: ChooseLanguagePopupProps) => {
  return (
    <div className={optionsBoxClass}>
      {...INTL_SUPPORTED_LOCALES.map((lc) => (
        <div onClick={() => changeLocale(lc)}>{icons[lc]}</div>
      ))}
    </div>
  );
};
const mainClass = css`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
`;
const optionsBoxClass = css`
  z-index: 1;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2a2930;
  gap: 10px;
  left: 120%;
  padding: 5px 10px;
  border-radius: 4px;
  height: 30px;
  cursor: pointer;

  svg {
    width: 18px;
  }
`;

const currentLanguageBoxClass = css`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  height: 30px;
  width:50px;
  border-radius: 4px;

  svg {
    width: 18px;
  }
`;
const textClass = css`
  color: #988c92;
  font-weight: 600;
  font-size: 12px;
  line-height: 130%;
`;

const icons = {
  en: (
    <svg viewBox="0 0 7.541 3.969" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill="#b22234" d="M0 0h7.54v3.969H0z" />
      <path
        d="M0 .458h7.54m0 .61H0m0 .611h7.54m0 .61H0M0 2.9h7.54m0 .61H0"
        stroke="#fff"
        strokeWidth=".30600000000000005"
      />
      <path fill="#3c3b6e" d="M0 0h3.016v2.137H0z" />
      <g fill="#fff" transform="scale(.00102)">
        <g id="d">
          <g id="c">
            <g id="e">
              <g id="b">
                <path id="a" d="M247 90l70.534 217.08-184.66-134.16h228.25l-184.66 134.16z" />
                <use y="420" width="100%" height="100%" href="#a" />
                <use y="840" width="100%" height="100%" href="#a" />
                <use y="1260" width="100%" height="100%" href="#a" />
              </g>
              <use y="1680" width="100%" height="100%" href="#a" />
            </g>
            <use x="247" y="210" width="100%" height="100%" href="#b" />
          </g>
          <use x="494" width="100%" height="100%" href="#c" />
        </g>
        <use x="988" width="100%" height="100%" href="#d" />
        <use x="1976" width="100%" height="100%" href="#c" />
        <use x="2470" width="100%" height="100%" href="#e" />
      </g>
    </svg>
  ),
  ru: (
    <svg viewBox="0 0 5.953 3.969" xmlns="http://www.w3.org/2000/svg">
      <path fill="#fff" d="M0 0h5.953v1.984H0z" />
      <path fill="#d52b1e" d="M0 1.984h5.953V3.97H0z" />
      <path fill="#0039a6" d="M0 1.323h5.953v1.323H0z" />
    </svg>
  ),
  es: (
    <svg viewBox="0 0 22 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22 0H0V15H22V0Z" fill="#C60B1E" />
      <path d="M22 3.75H0V11.25H22V3.75Z" fill="#FFC400" />
    </svg>
  ),
};
