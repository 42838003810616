import { css } from '@emotion/css';

export const CrushBetsCardStaticFrame = (color: string) => {
  const mainClass = css`
    position: relative;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 292px;
    background-color: ${color};
    &:hover {
      background-color: transparent;
      background-image: linear-gradient(
              135deg,
              rgba(0, 0, 0, 0) 45.02%,
              rgba(242, 160, 86, 1) 100%
      );

      > div {
        background-color: rgba(28, 27, 32, 75%);
        background-image: linear-gradient(
                135deg,
                rgba(30, 28, 34, 0) 0%,
                rgba(242, 160, 86, 0.2) 100%
        );
      }
    }
  `;
  const boxClass = css`
    padding: 0 16px;
    height: calc(100% - 2px);
    width: calc(100% - 2px);
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  
  `
  return function StaticFrame({ children }: Rcp) {
    return <div className={mainClass}><div className={boxClass}>{children}</div></div>;
  };
};
