import { css } from '@emotion/css';
import { ReactNode } from 'react';

import { useCaseBattleCreationStore } from 'app/case/battle/creation/store';
import { useCaseBattleCreationT } from '../glossary';
import { ReactComponent as RoundIcon } from './assets/round.svg';
import { ReactComponent as WalletIcon } from './assets/wallet.svg';
import { stdBp } from 'packs/std';

export const CaseBattleCreationCaseSelectStats = (): JsxElement => {
  const [{ rounds, total, enoughBalance }] = useCaseBattleCreationStore();
  const t = useCaseBattleCreationT().sub('case-select');
  return (
    <div className={statsBoxClass}>
      <Card icon={<RoundIcon />} label={t('round')} children={rounds} iconClass={roundIconClass} />
      <Card icon={<WalletIcon />} label={t('price')} iconClass={walletIconClass}>
        <span className={enoughBalance ? undefined : notEnoughBalanceClass}>
          ${total.toFixed(2)}
        </span>
      </Card>
    </div>
  );
};

type CardProps = {
  icon: ReactNode;
  label: ReactNode;
  children: ReactNode;
  iconClass: string;
};

const Card = ({ icon, label, children, iconClass }: CardProps): JsxElement => {
  return (
    <div className={cardBoxClass}>
      <div className={iconClass}>{icon}</div>
      <div>
        <div className={cardValueClass}>{children}</div>
        <div className={cardLabelClass}>{label}</div>
      </div>
    </div>
  );
};

const notEnoughBalanceClass = css`
  color: #ff5a5a;
`;

const cardBoxClass = css`
  display: flex;
  align-items: center;
  background: #18171c;
  border-radius: 8px;
  gap: 12px;
  padding: 10px;
`;
const statsBoxClass = css`
  display: flex;
  align-items: center;
  gap: 12px;
`;
const cardLabelClass = css`
  font-weight: 400;
  font-size: 11px;
  letter-spacing: -0.003em;
  color: #988c92;
  ${stdBp.up(2560)} {
    font-size: calc(11px * 1.3);
  }
`;
const cardValueClass = css`
  font-family: 'Onest';
  font-weight: 500;
  font-size: 13px;
  letter-spacing: -0.03em;
  color: #dfdfdf;
  ${stdBp.up(2560)} {
    font-size: calc(13px * 1.3);
  }
`;
const roundIconClass = css`
  svg {
    height: 16px;
    ${stdBp.up(2560)} {
      height: calc(16px * 1.3);
    }
  }
`;
const walletIconClass = css`
  svg {
    height: 20px;
    ${stdBp.up(2560)} {
      height: calc(20px * 1.3);
    }
  }
`;
