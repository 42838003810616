export abstract class CrushSceneChickBlockUnitsAbstract {
  public node: HTMLVideoElement;
  protected box?: HTMLElement;

  setBox(container: HTMLElement) {
    this.box = container;
  }

  abstract play(from: number);

  subPlay(from: number) {
    this.play(from);
    return () => {
      this.stop();
    };
  }

  abstract loop();

  stop() {
    if (this.node.parentNode !== null) {
      try {
        this.box?.removeChild(this.node);
      } catch (e) {
        console.error(e);
      }
    }
  }

  get style() {
    return this.node.style;
  }

  setVar(name: string, value: any) {
    this.node.style[name] = value;
  }

  addClass(...classes) {
    this.node.classList.add(...classes);
    return this;
  }
}
