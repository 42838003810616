import { css, cx } from '@emotion/css';
import { useInventoryState } from 'app/inventory';
import { InventoryEmpty } from 'app/inventory/empty/empty';
import { InventoryHeader } from 'app/inventory/header/header';
import { InventorySkins } from 'app/inventory/skins/skins';
import { useMemo } from 'react';

type Props = {
  className?: string;
};

export const Inventory = ({ className }: Props): JsxElement => {
  const { hasItems } = useInventoryState();

  return useMemo(() => {
    return (
      <div className={cx(mainClass, className)}>
        <InventoryHeader />
        {hasItems ? <InventorySkins /> : <InventoryEmpty />}
      </div>
    );
  }, [hasItems]);
};

const mainClass = css`
  background: #1e1c22;
`;
