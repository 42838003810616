import { decode } from 'msgpackr';
import { Reader } from 'protobufjs';
import { uint8ArrayToActionString } from 'support/conversion/action-string-uint8array';
import { uint8ArrayToStdId } from 'support/conversion/std-id-uint8array';

export class UReader extends Reader { }

UReader.prototype.pack = function () {
  return decode(this.bytes());
};

UReader.prototype.id = function () {
  return uint8ArrayToStdId(this.bytes());
};

UReader.prototype.action = function () {
  return uint8ArrayToActionString(this.bytes());
};

UReader.prototype.tail = function () {
  throw Error('not_implemented')
  const tail = this.buf.slice(this.pos);
  this.pos = this.len;
  return tail;
};

Object.defineProperty(UReader.prototype, 'complete', {
  get: function () {
    return this.pos === this.len;
  },
});

UReader.prototype.uint16 = Reader.prototype.uint32;
UReader.prototype.int16 = Reader.prototype.int32;
UReader.prototype.uint8 = Reader.prototype.uint32;
UReader.prototype.int8 = Reader.prototype.int32;
