export const CrashIcon = ({ active, current }) => {
  return !active && !current ? (
    <svg viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_198_1730)">
        <path
          d="M15.0276 14.5172C15.4776 14.2922 15.7401 14.1938 15.876 14.1376H23.7182C23.7885 10.2376 22.701 7.49068 19.7995 6.5438C19.0495 6.30005 18.3088 6.98443 18.5526 7.72974C18.9323 8.90161 18.9463 10.1719 18.6416 11.536C17.6807 7.3688 15.0557 5.59693 10.8745 6.07505C10.3823 6.1313 10.012 6.55786 10.0166 7.05005C10.0729 11.3907 11.7416 13.8797 15.0276 14.5172Z"
          fill="#988C92"
        />
        <path
          d="M28.6221 14.6484H24.2158V20.1422L28.5236 18.0187C28.7158 17.925 28.6455 17.6391 28.4346 17.6391H24.7033L28.7346 15.0187C28.9033 14.9109 28.8236 14.6484 28.6221 14.6484Z"
          fill="#988C92"
        />
        <path
          d="M19.6639 25.4391C19.392 24.5953 20.0202 23.7328 20.9061 23.7328H21.4123C22.3967 23.7328 23.1936 22.9359 23.1936 21.9516V20.8875C23.1936 20.5172 23.3998 20.1937 23.7045 20.0297V14.6484H15.9795C9.41703 17.3203 7.64516 25.2891 7.19047 28.5656C7.08266 29.325 7.67328 30 8.43734 30H22.4483C23.142 30 23.7092 29.4328 23.7092 28.7344V28.3781C21.8577 28.3828 20.2311 27.1969 19.6639 25.4391ZM18.6561 17.025C18.6561 16.3453 19.2092 15.7969 19.8889 15.7969C20.5686 15.7969 21.117 16.35 21.117 17.025C21.117 17.7047 20.5686 18.2578 19.8889 18.2578C19.2045 18.2578 18.6561 17.7094 18.6561 17.025Z"
          fill="#988C92"
        />
        <path
          d="M24.2482 20.4281C23.9623 20.3859 23.7091 20.6016 23.7091 20.8922V21.9563C23.7091 23.2219 22.6826 24.2484 21.417 24.2484H20.906C20.367 24.2484 19.9826 24.7734 20.1466 25.2891C20.6529 26.8547 22.1623 27.9656 23.9154 27.8719C25.7201 27.7734 27.3607 26.1188 27.4451 24.3141C27.5341 22.3453 26.1138 20.7 24.2482 20.4281Z"
          fill="#988C92"
        />
      </g>
      <defs>
        <clipPath id="clip0_198_1730">
          <rect width="24" height="24" fill="white" transform="translate(6 6)" />
        </clipPath>
      </defs>
    </svg>
  ) : current ? (
    <svg viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_i_24_12572)">
        <rect width="36" height="36" rx="9" fill="url(#paint0_linear_24_12572)" />
      </g>
      <rect
        x="0.75"
        y="0.75"
        width="34.5"
        height="34.5"
        rx="8.25"
        stroke="url(#paint1_linear_24_12572)"
        strokeWidth="1.5"
      />
      <g clipPath="url(#clip0_24_12572)">
        <path
          d="M15.0283 14.5172C15.4783 14.2922 15.7408 14.1938 15.8768 14.1376H23.7189C23.7893 10.2376 22.7018 7.49068 19.8002 6.5438C19.0502 6.30005 18.3096 6.98443 18.5533 7.72974C18.933 8.90161 18.9471 10.1719 18.6424 11.536C17.6814 7.3688 15.0564 5.59693 10.8752 6.07505C10.383 6.1313 10.0127 6.55786 10.0174 7.05005C10.0736 11.3907 11.7424 13.8797 15.0283 14.5172Z"
          fill="#2D241C"
        />
        <path
          d="M28.6221 14.6484H24.2158V20.1422L28.5236 18.0187C28.7158 17.925 28.6455 17.6391 28.4346 17.6391H24.7033L28.7346 15.0187C28.9033 14.9109 28.8236 14.6484 28.6221 14.6484Z"
          fill="#2D241C"
        />
        <path
          d="M19.6642 25.4391C19.3923 24.5953 20.0204 23.7328 20.9063 23.7328H21.4126C22.397 23.7328 23.1938 22.9359 23.1938 21.9516V20.8875C23.1938 20.5172 23.4001 20.1937 23.7048 20.0297V14.6484H15.9798C9.41727 17.3203 7.6454 25.2891 7.19071 28.5656C7.0829 29.325 7.67353 30 8.43759 30H22.4485C23.1423 30 23.7095 29.4328 23.7095 28.7344V28.3781C21.8579 28.3828 20.2313 27.1969 19.6642 25.4391ZM18.6563 17.025C18.6563 16.3453 19.2095 15.7969 19.8892 15.7969C20.5688 15.7969 21.1173 16.35 21.1173 17.025C21.1173 17.7047 20.5688 18.2578 19.8892 18.2578C19.2048 18.2578 18.6563 17.7094 18.6563 17.025Z"
          fill="#2D241C"
        />
        <path
          d="M24.2485 20.4281C23.9625 20.3859 23.7094 20.6016 23.7094 20.8922V21.9563C23.7094 23.2219 22.6828 24.2484 21.4172 24.2484H20.9063C20.3672 24.2484 19.9828 24.7734 20.1469 25.2891C20.6531 26.8547 22.1625 27.9656 23.9156 27.8719C25.7203 27.7734 27.361 26.1188 27.4453 24.3141C27.5344 22.3453 26.1141 20.7 24.2485 20.4281Z"
          fill="#2D241C"
        />
      </g>
      <defs>
        <filter
          id="filter0_i_24_12572"
          x="0"
          y="0"
          width="36"
          height="39"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="1.5"
            operator="erode"
            in="SourceAlpha"
            result="effect1_innerShadow_24_12572"
          />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="4.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 0.450667 0 0 0 0 0.141667 0 0 0 1 0"
          />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_24_12572" />
        </filter>
        <linearGradient
          id="paint0_linear_24_12572"
          x1="18"
          y1="0"
          x2="18"
          y2="36"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFAA63" />
          <stop offset="1" stopColor="#EA9A4E" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_24_12572"
          x1="18"
          y1="0"
          x2="18"
          y2="36"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9457" />
          <stop offset="1" stopColor="#FFBA69" />
        </linearGradient>
        <clipPath id="clip0_24_12572">
          <rect width="24" height="24" fill="white" transform="translate(6 6)" />
        </clipPath>
      </defs>
    </svg>
  ) : (
    <svg viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.0281 14.5172C15.4781 14.2922 15.7406 14.1937 15.8766 14.1375H23.7188C23.7891 10.2375 22.7016 7.49062 19.8 6.54374C19.05 6.29999 18.3094 6.98437 18.5531 7.72968C18.9328 8.90155 18.9469 10.1719 18.6422 11.5359C17.6813 7.36874 15.0563 5.59687 10.875 6.07499C10.3828 6.13124 10.0125 6.5578 10.0172 7.04999C10.0735 11.3906 11.7422 13.8797 15.0281 14.5172Z"
        fill="url(#paint0_linear_1_8)"
      />
      <path
        d="M28.6219 14.6484H24.2156V20.1421L28.5234 18.0187C28.7156 17.9249 28.6453 17.639 28.4344 17.639H24.7031L28.7344 15.0187C28.9031 14.9109 28.8234 14.6484 28.6219 14.6484Z"
        fill="url(#paint1_linear_1_8)"
      />
      <path
        d="M19.6641 25.439C19.3922 24.5952 20.0203 23.7327 20.9062 23.7327H21.4125C22.3969 23.7327 23.1937 22.9359 23.1937 21.9515V20.8874C23.1937 20.5171 23.4 20.1937 23.7047 20.0296V14.6484H15.9797C9.41718 17.3202 7.6453 25.289 7.19062 28.5655C7.0828 29.3249 7.67343 29.9999 8.43749 29.9999H22.4484C23.1422 29.9999 23.7094 29.4327 23.7094 28.7343V28.378C21.8578 28.3827 20.2312 27.1968 19.6641 25.439ZM18.6562 17.0249C18.6562 16.3452 19.2094 15.7968 19.8891 15.7968C20.5687 15.7968 21.1172 16.3499 21.1172 17.0249C21.1172 17.7046 20.5687 18.2577 19.8891 18.2577C19.2047 18.2577 18.6562 17.7093 18.6562 17.0249Z"
        fill="url(#paint2_linear_1_8)"
      />
      <path
        d="M24.2485 20.4281C23.9625 20.3859 23.7094 20.6015 23.7094 20.8921V21.9562C23.7094 23.2218 22.6828 24.2484 21.4172 24.2484H20.9063C20.3672 24.2484 19.9828 24.7734 20.1469 25.289C20.6532 26.8546 22.1625 27.9656 23.9157 27.8718C25.7203 27.7734 27.361 26.1187 27.4453 24.314C27.5344 22.3452 26.1141 20.6999 24.2485 20.4281Z"
        fill="url(#paint3_linear_1_8)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1_8"
          x1="16.8696"
          y1="5.99896"
          x2="16.8696"
          y2="14.5172"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFAA63" />
          <stop offset="1" stopColor="#EA9A4E" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1_8"
          x1="26.5209"
          y1="14.6484"
          x2="26.5209"
          y2="20.1421"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFAA63" />
          <stop offset="1" stopColor="#EA9A4E" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1_8"
          x1="15.4436"
          y1="14.6484"
          x2="15.4436"
          y2="29.9999"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFAA63" />
          <stop offset="1" stopColor="#EA9A4E" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1_8"
          x1="23.779"
          y1="20.4228"
          x2="23.779"
          y2="27.8773"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFAA63" />
          <stop offset="1" stopColor="#EA9A4E" />
        </linearGradient>
      </defs>
    </svg>
  );
};
