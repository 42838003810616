export class HashMap<K, V> extends Map<K, V> {
  static by<K, V>(indexBy: (item: V) => K, list: V[]): HashMap<K, V> {
    return new HashMap(list.map((value) => [indexBy(value), value]));
  }

  find(key: K): V | undefined {
    return super.get(key);
  }

  get(key: K): V {
    const val = super.get(key);
    if (val === undefined) throw Error('HashMap::get: key value is missing');
    return val;
  }

  applyOne(key: K, cb: (val: V) => V): this {
    const value = this.find(key);
    if (value === undefined) return this;
    return this.set(key, cb(value));
  }

  updOne(key: K, value: Partial<V>): this {
    const val = this.find(key);
    if (val === undefined) return this;
    return this.set(key, { ...val, ...value });
  }

  // listFilter(cb: (value: V, key: K) => boolean): V[] {
  //   const result: V[] = [];
  //   for (const entry of this.entries()) {
  //     if (cb(entry[1], entry[0]))
  //       result.push(entry[1]);
  //   }
  //   return result;
  // }
  //
  // mapEntries<T = unknown>(cb: (value: [K, V]) => T): T[] {
  //   const result: T[] = [];
  //   for (const entry of this.entries()) {
  //     result.push(cb(entry));
  //   }
  //   return result;
  // }
  //
  // map<T = unknown>(cb: (value: V, key: K) => T): T[] {
  //   const result: T[] = [];
  //   for (const entry of this.entries()) {
  //     result.push(cb(entry[1], entry[0]));
  //   }
  //   return result;
  // }
  //
  // updateOneStrict(key: K, cb: (value: V) => V): V {
  //   const value = this.get(key);
  //   const result = cb(value);
  //   this.set(key, value);
  //   return result;
  // }
  //
  // pull(key: K): V {
  //   const value = this.get(key);
  //   this.delete(key);
  //   return value;
  // }
  //
  // pullMany(filter: (value: V) => boolean): V[] {
  //   const result: V[] = [];
  //   for (let [key, bet] of this) {
  //     if (filter(bet)) {
  //       result.push(bet);
  //       this.delete(key);
  //     }
  //   }
  //   return result;
  // }
}
