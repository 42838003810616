export function lastMemo<F extends (...args: any[]) => any>(fn: F): F {
  let last_arg: any = Symbol();
  let last_result: any;

  // @ts-ignore
  return function lastMemoized() {
    if (arguments[0] !== last_arg) {
      last_arg = arguments[0];
      last_result = fn(...arguments);
    }
    return last_result;
  };
}
