type MatchFactory = any;

type MetaResult = {
  title: string;
  description: string;
  headline: string;
  keywords: string;
};

type RoutesInput = [code: string, rule: string, sub?: RoutesInput[]];

export const createMetaResolver = (
  routes: RoutesInput[],
  createMatch: MatchFactory,
  dicts: Record<string, any>,
  createMessage: (message: string, locale: string) => (data?: string) => string
) => {
  type Rule = (path: string) => false | ((lang: string) => MetaResult);

  const getDefaultMeta = memoize((lang: string): any => {
    const dict = dicts[lang];
    return {
      title: dict.title,
      keywords: dict.keywords,
      description: dict.description,
      headline: dict.headline,
    };
  });

  const main = () => {
    const rules = routes.map(compile);

    return (path: string, lang: string): MetaResult => {
      for (const rule of rules) {
        const result = rule(path);
        if (result) return result(lang);
      }

      return getDefaultMeta(lang);
    };
  };

  const compile = ([code, rule]: RoutesInput): Rule => {
    const match = createMatch(rule);

    const createTranslation = (prop: string) => {
      return memoize((lang: string) => {
        const dict = get(dicts[lang].codes, code);
        if (dict === undefined) throw new Error('unexpected_code');
        const icu = dict[prop];
        if (icu === undefined) {
          const defaultMessage = getDefaultMeta(lang)[prop];
          return () => defaultMessage;
        }
        const message = createMessage(icu, lang);
        return (data: any) => message(data);
      });
    };

    const getTitle = createTranslation('title');
    const getDescription = createTranslation('description');
    const getHeadline = createTranslation('headline');
    const getKeywords = createTranslation('keywords');

    return (path) => {
      const result = match(path);
      if (!result) return false;

      const { params } = result;
      return (lang: string) => {
        return {
          title: getTitle(lang)(params),
          description: getDescription(lang)(params),
          headline: getHeadline(lang)(params),
          keywords: getKeywords(lang)(params),
        };
      };
    };
  };

  return main();
};

const memoize = <A, R>(fn: (arg: A) => R): ((arg: A) => R) => {
  const cache = new Map<A, R>();

  return (arg: A): R => {
    if (cache.has(arg)) {
      return cache.get(arg)!;
    }

    const result = fn(arg);
    cache.set(arg, result);
    return result;
  };
};

const regexp = /\./;
const get = (dict: Record<string, any>, path: string): any => {
  const index = path.search(regexp);
  if (index === -1) return dict[path];

  const value = dict[path.substring(0, index)];

  if (value !== undefined) {
    return get(value, path.substring(index + 1));
  } else {
    return dict[path];
  }
};
