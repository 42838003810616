import { css } from '@emotion/css';
import { NavLink } from 'react-router-dom';

import { LogoIcon } from '../assets/logo-icon';

export const LayoutHeaderLogoPanel = (): JsxElement => {
  return (
    <div className={logoClass}>
      <NavLink to={'/'}>
        <LogoIcon />
      </NavLink>
    </div>
  );
};

const logoClass = css`    
  flex-basis: 198px;
  display: flex;
  place-items: center;
  padding: 13px 24px;
  background: linear-gradient(180deg, #1f1d23 0%, rgba(30, 28, 34, 0.6) 100%);
  cursor: pointer;
  svg{
    height: 37px;
  }
`;
