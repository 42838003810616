import { css } from '@emotion/css';
import { CSSProperties, memo, ReactNode } from 'react';

import { ReactComponent as SkinGridSvg } from 'app/case/battle/insight/assets/grid-image.svg';
import { CaseBattleInsightLiveDropData } from 'app/case/battle/insight/store';
import { skinNameViewOnSkin } from 'domain/skin/name-view';
import { SkinImg } from 'domain/skin/units/image';
import { skinColorMemoize } from 'domain/skin/utils/color-memoize';
import { juxProp } from 'support/etc/juxtapose';
import { roundCash } from 'support/etc/round-cash';
import { InstantTooltip } from 'packs/single/instant-tooltip';

const LowChance = 3;

type CaseBattleInsightStandDropListProps = {
  size: number;
  drops: any[];
  numberOfPlayers: number;
};
const DropWidth = new Map<number, string>([
  [4, '200'],
  [3, '200'],
  [2, '200'],
]);
export const CaseBattleInsightStandDropList = ({
  size,
  drops,
  numberOfPlayers,
}: CaseBattleInsightStandDropListProps): JsxElement => {
  const children: ReactNode[] = drops.map((data) => <Item data={data} />);
  for (let i = drops.length; i < size; i++) children.push(emptySlot);

  return (
    <div
      className={mainClass}
      style={{ '--drop-width': DropWidth.get(numberOfPlayers) } as CSSProperties}
    >
      <div className={listBoxClass}>{...children}</div>
    </div>
  );
};

const mainClass = css`
  --l-size: 107px;
  padding-top: 11px;
  display: flex;
  justify-content: center;
`;

const listBoxClass = css`
  max-width: var(--drop-width);
  overflow: auto;
  max-height: 461px;
  display: flex;
  gap: 7px;
  flex-wrap: wrap;
  justify-content: center;

  ::-webkit-scrollbar {
    width: 3px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(152, 140, 146, 0.5);
    border-radius: 128px;
  }
`;

const slotBoxClass = css`
  width: var(--l-size);
  height: 108px;
  background: #18171c;
  border-radius: 7px;
`;

const emptySlot = <div className={slotBoxClass} />;

type ItemProps = {
  data: CaseBattleInsightLiveDropData;
};

export const Item = memo(function Item({ data }: ItemProps): JsxElement {
  const { skin, cost } = data;
  const view = skinNameViewOnSkin(skin);
  const Frame = getSlotFrame(skin.color);

  return (
    <Frame>
      <InstantTooltip title={skin.id} arrow>
        <div className={skinContainerClass} title={`${view.model} | ${view.brand}`}>
          <SkinGridSvg className={skinGridClass} />
          <div className={imageBoxClass}>
            <SkinImg code={skin.image} alt={'img'} className={imageClass} />
          </div>
          <div>
            <div className={skinTitleClass}>
              {view.model}{' '}
              <span title={view.brand} className={modelClass}>
                {view.brand}
              </span>
            </div>
            <div className={newTitleClass}>{view.level?.full}</div>
            <div className={priceClass}>${roundCash(cost)}</div>
          </div>
        </div>
      </InstantTooltip>
    </Frame>
  );
}, juxProp('data'));

const priceClass = css`
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #dfdfdf;
`;
const newTitleClass = css`
  font-weight: 400;
  font-size: 11px;
  line-height: 130%;
  display: flex;
  align-items: center;
  letter-spacing: -0.003em;
  color: #988c92;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const skinTitleClass = css`
  font-weight: 400;
  font-size: 11px;
  line-height: 130%;
  display: flex;
  gap: 3px;
  align-items: center;
  color: rgb(var(--skin-rgb));
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 78px;
`;
const modelClass = css`
  font-weight: 600;
  font-size: 11px;
  line-height: 130%;
  display: flex;
  align-items: center;
  color: #dfdfdf;
`;
const skinContainerClass = css`
  position: relative;
  padding: 12px;
`;

const skinGridClass = css`
  width: 59px;
  position: absolute;
  top: 5px;
  left: 23px;
`;
const imageClass = css`
  width: 71px;
`;
const imageBoxClass = css`
  position: relative;
  z-index: 2;
`;

export const getSlotFrame = skinColorMemoize((color: number[]) => {
  const mkRP = (alpha: number) => (rgb: number[], percent: string | number) =>
    `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${alpha}) ${percent}%`;

  const fgRp = mkRP(1);
  const bgRp = mkRP(0.2);

  const bgLine = ['rgba(30, 28, 34, 0) 0%'];
  const fgLine = ['rgba(0, 0, 0, 0) 45.02%'];

  fgLine.push(fgRp(color, 100));
  bgLine.push(bgRp(color, 100));

  const toGradient = (line) => `linear-gradient(135deg, ${line.join(', ')});`;

  const mainClass = css`
    --skin-rgb: ${color.join(', ')};
    position: relative;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 106px;
    width: var(--l-size);
    background-image: ${toGradient(fgLine)};
  `;

  const boxClass = css`
    height: calc(100% - 3px);
    width: calc(100% - 2px);
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(30, 28, 34, 75%);
    background-image: ${toGradient(bgLine)};
    padding: 0 16px;
  `;

  return function GradientFrame({ children }: Rcp) {
    return (
      <div className={mainClass}>
        <div className={boxClass}>{children}</div>
      </div>
    );
  };
});
