import { useEffect } from 'react';
import { mut, MutSpec } from 'support/etc/immutate';
import { stash_get } from 'support/etc/stash';
import { useForceRefresh } from 'support/react/use-force-refresh';

type Listener<T> = (value: T) => void;
export class SubCell<T = any> extends Set<Listener<T>> {
  constructor(private value?: T) {
    super();
  }

  set = (value: T) => {
    this.value = value;
    for (const listener of this.values()) listener(value);
  };

  sub = (listener: Listener<T>) => {
    this.add(listener);
    return () => {
      this.delete(listener);
    };
  };

  get = () => this.value;

  path = (path: string[]) => {
    const get = (value: any) => {
      for (const prop of path) {
        if (value) {
          value = value[prop];
        } else {
          return value;
        }
      }
      return value;
    };
    const cell = new SubCell(get(this.value));
    this.sub((data) => {
      const next = get(data);
      if (cell.value !== next) {
        cell.set(next);
      }
    });
    return cell;
  };

  prop = (prop: string) => {
    const cell = new SubCell(this.value?.[prop]);
    this.sub((data) => {
      const next = data?.[prop];
      if (cell.value !== next) {
        cell.set(next);
      }
    });
    return cell;
  };

  @stash_get
  get mut(): (spec: MutSpec<T>) => void {
    return (spec: MutSpec<T>) => this.set(mut(this.get()!, spec));
  }

  @stash_get
  get useValue() {
    return () => {
      const commit = useForceRefresh();
      useEffect(() => this.sub(commit), []);
      return this.value;
    };
  }
}
