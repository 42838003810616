import { CrushOnActorBetAccepted, CrushOnAfterNewRound } from 'app/crush/store/store';
import { onceActorAuthenticated } from 'domain/actor';
import { CrushMode } from 'domain/crush';
import { getPlayerStatsOperator } from 'domain/player/stats';
import { coilListen } from 'packs/libs/coil';
import { bunchCb } from 'support/etc/bunch-cb';

onceActorAuthenticated(() => {
  const unSub = coilListen('account', ({ $set: state }) => {
    if (state === undefined) return;
    if (state.playedOnce === true) return unSub();

    let status = new Map<CrushMode, true | undefined>();
    const unSubAll = bunchCb([
      unSub,
      CrushOnAfterNewRound.sub((op) => {
        if (status.get(op.mode)) {
          unSubAll();
          getPlayerStatsOperator().updState({ playedOnce: true });
        }
      }),
      CrushOnActorBetAccepted.sub((op) => {
        status.set(op.mode, true);
      }),
    ]);
  });
});
