import { memoize } from 'lodash';
import { stashCb } from 'support/etc/stash';

export enum UpgradeUnitsWheelTheme {
  default,
  success,
  fail,
}

export enum UpgradeUnitsWheelColor {
  frame = '--w-frame',
  circles = '--w-circles',
}

export type UpgradeWheelThemeColors = {};

export const upgradeUnitsWheelGetColors = stashCb(() => {
  const failColor = '#FF5A5A';
  const successColor = '#0FAC7F';

  const COLORS: Record<UpgradeUnitsWheelTheme, Partial<UpgradeWheelThemeColors>> = {
    [UpgradeUnitsWheelTheme.default]: {
      [UpgradeUnitsWheelColor.frame]: '#802F9D',
      [UpgradeUnitsWheelColor.circles]: '#2A2930',
    },
    [UpgradeUnitsWheelTheme.success]: {
      [UpgradeUnitsWheelColor.frame]: successColor,
      [UpgradeUnitsWheelColor.circles]: successColor,
    },
    [UpgradeUnitsWheelTheme.fail]: {
      [UpgradeUnitsWheelColor.frame]: failColor,
      [UpgradeUnitsWheelColor.circles]: failColor,
    },
  };

  return memoize((theme = UpgradeUnitsWheelTheme.default) => {
    return COLORS[theme];
  });
});
