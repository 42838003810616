import { css } from '@emotion/css';
import { ChatMessageProps } from 'app/chat/message';
import { ChatPinMessage } from 'app/chat/pin-message';
import { useChatState } from 'app/chat/store';
import { debounce } from 'lodash';
import { SimpleOutpost } from 'packs/single/simple-outpost';
import React, { useEffect, useMemo, useRef } from 'react';

type ChatHistoryProps = {
  Message: React.ComponentType<ChatMessageProps>;
};

export const ChatHistory = ({ Message }: ChatHistoryProps) => {
  const { messages, pinsClosed = true } = useChatState();
  const ref = useRef<HTMLDivElement>(null);
  const trackRef = useRef<boolean>(true);

  useEffect(() => {
    if (ref.current === null || !trackRef.current) return;
    ref.current.scrollTop = 0;
  }, [messages[0]?.id]);

  useEffect(() => {
    const el = ref.current;
    if (el === null) return;

    const onScroll = debounce(() => {
      trackRef.current = el.scrollTop > -10;
    }, 200);

    el.addEventListener('scroll', onScroll);

    return () => {
      el.removeEventListener('scroll', onScroll);
    };
  }, [ref]);

  const list = useMemo(() => {
    return (
      <div className={mainClass} ref={ref}>
        {messages.map((data) => (
          <SimpleOutpost key={data.id}>
            <Message message={data} />
          </SimpleOutpost>
        ))}
      </div>
    );
  }, [Message, messages]);

  // const showPins = actor.signed && pinned[0] && !pinsClosed && !collapsed;

  return (
    <>
      {pinsClosed && (
        <div className={pinMessage}>
          <ChatPinMessage />
        </div>
      )}
      {list}
    </>
  );
};

const mainClass = css`
  display: flex;
  flex-direction: column-reverse;
  overflow-y: auto;
  overflow-x: hidden;
  background: #1c1b20;
  flex: 1;
  position: relative;
`;

const pinMessage = css`
  padding: 8px 8px 0 8px;
  display: flex;
  justify-content: center;
`;
