import { ContestStatus, ContestUpon } from 'domain/contest/definitions';
import { ContestLiveInternalData, ContestLiveInternalState } from 'domain/contest/live/definitions';
import { stash_get } from 'support/etc/stash';

export class ContestLiveState {
  public declare readonly loading: boolean;
  private declare readonly data: ContestLiveInternalData | null;

  constructor(internal: ContestLiveInternalState) {
    Object.assign(this, internal);
  }

  @stash_get
  get alive() {
    return this.data !== null;
  }

  @stash_get
  get active(): boolean {
    return this.data?.status === ContestStatus.active;
  }

  get contest() {
    return this.data!;
  }

  get id() {
    return this.data!.id;
  }

  get status() {
    return this.data!.status;
  }

  getIdIfActive(): string | null {
    if (!this.alive) return null;
    if (this.data!.status !== ContestStatus.active) return null;
    return this.data!.id;
  }

  checkUpon(upon: ContestUpon) {
    if (!this.alive) return false;
    return this.data!.upon.includes(upon);
  }
}
