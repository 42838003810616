import { css } from '@emotion/css';
import { useXPanelState } from 'app/crush/control/x-panel/store';
import { useCrushT } from 'app/crush/glossary';
import { useCrushScopeRound } from 'app/crush/store';
import { StdKindButton } from 'packs/std/inputs/kind-button';

export const CrushControlStartButton = () => {
  const [{ canCommitBet }, { commitBet }] = useXPanelState();
  const { isPlaying } = useCrushScopeRound();
  const t = useCrushT().sub('grab.panel');

  return (
    <StdKindButton
      className={buttonClass}
      kind="orange"
      disabled={!canCommitBet}
      onClick={commitBet}
    >
      {t(isPlaying ? 'start-grab-ingame' : 'start-grab')}
    </StdKindButton>
  );
};

const buttonClass = css`
  flex: 1;
  padding: 12px;
  margin: 0 24px;
  font-size: 13px;
  min-height: 42px;
`;
