import { css } from '@emotion/css';
import { CrushFloorHash } from 'app/crush/floor/hash';
import { CrushFloorPing } from 'app/crush/floor/ping';

export const CrushFloor = (): JsxElement => {
  return (
    <div className={mainClass}>
      <CrushFloorHash />
      <CrushFloorPing />
    </div>
  );
};

const mainClass = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;
