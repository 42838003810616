import { css } from '@emotion/css';
import { ReactComponent as ArrowIcon } from 'app/contest/assets/winner-arrow.svg';
import { useContestT } from 'app/contest/glossary';
import { ContestBriefData } from 'domain/contest';
import { PlayerImage } from 'domain/player/image/image';

type ContestCardWinnerProps = {
  contest: ContestBriefData;
};

export const ContestCardWinner = ({ contest }: ContestCardWinnerProps): JsxElement => {
  const t = useContestT().sub('card');
  const { winner, prize } = contest;

  return (
    <div className={mainClass}>
      <div className={boxClass}>
        <div className={contentClass}>
          <div className={winnerBoxClass}>
            <PlayerImage className={imageClass} code={winner!.image} />
            <div className={nameBoxClass}>
              <p className={titleClass}>{t('winner')}</p>
              <p className={nameClass}>{winner?.name}</p>
            </div>
          </div>
          <div className={prizeBoxClass}>
            <p className={prizeClass}>${prize[0]}</p>
            <ArrowIcon />
          </div>
        </div>
      </div>
    </div>
  );
};

const mainClass = css`
  background: linear-gradient(170deg, rgba(38, 35, 45, 0) 30%, rgba(242, 160, 86, 1) 100%);
  border-radius: 8px;
  width: 100%;
  height: 51px;
`;

const boxClass = css`
  background: linear-gradient(170deg, rgba(30, 28, 34, 70%) 0%, rgba(30, 28, 34, 75%) 100%);
  border-radius: 8px;
  width: calc(100% - 1px);
  height: calc(100% - 1px);
`;

const contentClass = css`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px 9px 12px;
`;

const winnerBoxClass = css`
  display: flex;
  gap: 10px;
`;

const imageClass = css`
  width: 32px;
  height: 32px;
  border-radius: 6px;
`;

const nameBoxClass = css`
  display: flex;
  flex-direction: column;
`;

const titleClass = css`
  font-family: 'Commissioner';
  font-weight: 600;
  font-size: 10px;
  line-height: 130%;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #696155;
`;

const nameClass = css`
  font-family: 'Commissioner';
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #dfdfdf;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 111px;
`;

const prizeBoxClass = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const prizeClass = css`
  font-family: 'Onest';
  font-weight: 700;
  font-size: 13px;
  line-height: 140%;
  background: linear-gradient(180deg, #ffaa63 0%, #ea9a4e 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;
