import { GraphChickElement } from 'app/crush/scene/chick/block/units/element';

const chicks: GraphChickElement[] = [];
let box: HTMLElement;

export type GraphChickContext = typeof GraphChickContext;
export const GraphChickContext = {
  setBox(elem: HTMLElement) {
    box = elem;
    for (const chick of chicks) {
      chick.setBox(elem);
    }
  },

  addChick(chick: GraphChickElement) {
    chick.setBox(box);
    chicks.push(chick);
  },

  clean() {
    chicks.length = 0;
  },
};
