import { openReplenishDialog } from 'app/replenish';
import { onceActorResolved } from 'domain/actor';
import { AnyPromo, PromoType, ReplPromo } from 'domain/promo';
import { openWelcomeFlowPreReplenishDialog } from 'domain/promo/welcome-flow/pre-replenish-dialog';
import { coilListen, coilReq } from 'packs/libs/coil';
import { kvRepo } from 'packs/libs/db';
import { EffectCallback, createContext, useContext, useEffect } from 'react';
import { looseAsync } from 'support/etc/loose-async';
import { useBasicSwissStore } from 'support/react/swiss';
import { launch } from 'support/react/use-launch';

const UTM_PROMO_KEY = 'utm_promo';

export enum PROMO_ERROR_CODE {
  expired = 'promo.expired',
  alreadyUsed = 'promo.already-used',
}

const [anonRepoFind, anonRepoSet, anonRepoDel] = kvRepo<AnyPromo>('anon_promo');

const activate = async (code: string) => {
  return coilReq<AnyPromo>({
    action: 'promo.activate',
    data: code,
  });
};

const apply = (code: string) => {
  launch(async () => {
    const promo = await activate(code);
    if (promo.type === PromoType.replenish) {
      openReplenishDialog({ promo });
    } else {
      // if (promo.errorMessage) {
      //   showErrorToast(promo.errorMessage);
      // }
      // infSuccess(`alarm.promo.applied.${promo.type}`, {
      //   value: promo.type === PROMO_TYPE_BALANCE ? promo.reward : promo.reward.name,
      // });
    }
  });
};

const clear = looseAsync(async () => {
  await coilReq({
    action: 'promo.repl.last-activated.del',
  });
});

const PromoContext = createContext<PromoState>(null!);
if (__DEV__) PromoContext.displayName = 'PromoContext';

export const usePromoState = () => useContext(PromoContext);
export const getPromoOperator = () => globalPromoOperator;

type PromoState = {
  list: AnyPromo[];
};

const staticOperator = {
  activate,
  apply,
  clear,
};

type PromoOperator = typeof staticOperator & {
  findRush(): ReplPromo | void;
  timerIsOver(promo: ReplPromo): void;
};

let globalPromoOperator: PromoOperator;

export const PromoStoreProvider = (props: Rcp) => {
  const [value, effect] = useBasicSwissStore<PromoState, EffectCallback>(() => [
    {
      list: [],
    },
    ({ getState, updState, mutState }) => {
      globalPromoOperator = {
        ...staticOperator,
        findRush() {
          throw new Error('not implemented');
          let mostPromo: ReplPromo;
          for (const promo of getState().list) {
            if (promo.type !== PromoType.replenish) continue;

            // if (
            //   mostPromo === undefined ||
            //   mostPromo.timer === undefined ||
            //   mostPromo.timerEnds > promo.timerEnds
            // )
            //   mostPromo = promo;
          }
          return mostPromo;
        },
        timerIsOver(promo: ReplPromo) {
          mutState((draft) => {
            draft.list.filter((p) => p.code !== promo.code);
          });
        },
      };

      const addPromo = (promo: AnyPromo) => {
        mutState((draft) => {
          draft.list.push(promo);
        });
      };

      const delPromo = (code: string) => {
        mutState((draft) => {
          draft.list.filter((p) => code !== p.code);
        });
      };

      const replacePromo = ({ it, by }) => {
        mutState((draft) => {
          if (it) draft.list.filter((p) => p.code !== it);
          draft.list.push(by);
        });
      };

      const listeners = {
        add: addPromo,
        del: delPromo,
        replace: replacePromo,
        set: (list) => {
          updState({ list });
        },
      };

      return () =>
        coilListen('promo', (event, data) => {
          listeners[event](data);
        });
    },
  ]);

  useEffect(effect, []);

  return <PromoContext.Provider value={value} children={props.children} />;
};

coilListen('welcome-activated', (welcome) => {
  openWelcomeFlowPreReplenishDialog({
    data: welcome,
    onClose: () => {
      openReplenishDialog({ promo: welcome.promo });
    },
  });
});

export const startupPromo = (query: Rsa) => {
  let utmCode: string = query[UTM_PROMO_KEY];

  onceActorResolved(async (actor) => {
    const activate = async (code: string) => {
      const promo = await staticOperator.activate(code);
      // if (promo.errorMessage) {
      //   showErrorToast(promo.errorMessage);
      // }
      return promo;
    };

    if (actor.signed) {
      let code = utmCode;
      if (!code) {
        const promo = await anonRepoFind();
        if (!promo) return;
        code = promo.code;
      }
      apply(code);
    } else {
      if (!utmCode) return;
      const promo = await coilReq<AnyPromo>({
        action: 'promo.get',
        data: utmCode,
      });
      // openWelcomeFlowOnBoardDialog({ promo });
      await anonRepoSet(promo);
    }

    // if (utmCode) {
    //   if (actor.signed) {
    //     await activate(utmCode);
    //   } else {
    //     const promo = await coilReq<AnyPromo>({
    //       action: 'promo.get',
    //       data: utmCode,
    //     });
    //     openWelcomeFlowOnBoardDialog({ promo });
    //     await anonRepoSet(promo);
    //   }
    // } else {
    //   if (actor.signed) {
    //     const promo = await anonRepoFind();

    //     if (promo) {
    //       try {
    //         await activate(promo.code);
    //       } finally {
    //         anonRepoDel();
    //       }
    //     }
    //   }
    // }
  });
};
