import { css } from '@emotion/css';
import { ComboDotsBig } from 'app/inventory/assets/combo-dots-big';
import { InventoryEmptyBuySkinsButton } from 'app/inventory/empty/buy-skins-button';
import { useInventoryT } from 'app/inventory/glossary';

export const InventoryEmpty = (): JsxElement => {
  const t = useInventoryT().sub('empty');

  return (
    <div className={mainClass}>
      <div className={bodyListClass}>
        <ComboDotsBig className={dotsClass} />
        <span>{t('empty')}</span>
        <span>{t('start')}</span>
      </div>
      <InventoryEmptyBuySkinsButton />
    </div>
  );
};

const mainClass = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
`;

const bodyListClass = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-family: 'Commissioner';
  font-weight: 600;
  font-size: 12px;
  color: #988c92;
`;

const dotsClass = css`
  margin: 7px 0 26px 0;
  width: 44px;
  hight: 44px;
`;
