import { css } from '@emotion/css';
import { NavLink } from 'react-router-dom';
import { openCrushProvablyFairHowItWorkDialog } from 'app/crush/provably-fair/how-it-work-dialog';
import { stdBp } from 'packs/std';
import { useLayoutT } from '../glossary';
import { ReactComponent as AgeIcon } from './assets/age-icon.svg';

const ROUTES = [
  { title: 'help', to: '/help' },
  { title: 'privacy-policy', to: '/privacy-policy' },
  { title: 'terms-of-service', to: '/terms-of-services' },
];

export const LayoutFooterLinks = (): JsxElement => {
  const t = useLayoutT().sub('footer');
  return (
    <div className={linksBoxClass}>
      <div className={reservedClass}>
        <div className={ageIconClass}>
          <AgeIcon className={ageIconClass} />
        </div>
        <p className={textClass}>
          ©2023 Dristelia LTD (HE 424334) Thermopylon, 23, Pissouri, 4607, Limassol, Cyprus
        </p>
      </div>
      <div className={linksClass}>
        <div className={provablyFairClass} onClick={openCrushProvablyFairHowItWorkDialog}>
          {t('provably-fair')}
        </div>
        {...ROUTES.map((value) => (
          <NavLink className={textClass} to={value.to}>
            {t(`${value.title}`)}
          </NavLink>
        ))}
      </div>
    </div>
  );
};

const ageIconClass = css`
  width: 18px;
  height: 18px;
`;

const linksBoxClass = css`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 32px;
`;
const reservedClass = css`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  svg {
    height: 18px;
  }
`;

const linksClass = css`
  display: flex;
  gap: 10px;
`;

const textClass = css`
  font-weight: 400;
  font-size: 11px;
  color: #696155;
  ${stdBp.up(1610)} {
    font-size: 12px;
  }
  ${stdBp.up(1900)} {
    font-size: 14px;
  }
`;

const provablyFairClass = css`
  ${textClass}
  cursor: pointer;
`;
