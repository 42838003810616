import { css, cx } from '@emotion/css';
import { CSSProperties, memo } from 'react';

import { CaseData } from 'domain/case/definitions';
import { CaseImg } from 'domain/case/units/image';
import { juxProps } from 'support/etc/juxtapose';
import { ReactComponent as MinusIcon } from './assets/minus.svg';
import { ReactComponent as PlusIcon } from './assets/plus.svg';
import { CaseBattleCreationClickOnCaseWhileCreatingSound } from 'app/case/battle/creation/sounds';
import { ListCaseData } from '../case-list/case-list';
import { stdBp } from 'packs/std';

type CaseBattleCreationCaseSelectCardProps = {
  data: CaseData;
  amount?: number;
  actions: {
    setAmount(id: string, amount: number): void;
    addCase(data: CaseData): void;
    delCase(caseId: string): void;
    open(data: CaseData): void;
    select(data: ListCaseData | null): void;
  };
};
const MIN_COUNT = 1;

export const CaseBattleCreationCaseSelectCard = memo(function CaseBattleCreationCaseSelectCard({
  data,
  amount = 0,
  actions,
}: CaseBattleCreationCaseSelectCardProps): JsxElement {
  const { id, name, price, color } = data;
  const handleChangeInput = (event) => {
    actions.setAmount(id, +event.target.value ?? MIN_COUNT);
  };
  const toggle = () => {
    if (amount < 1) actions.addCase(data);
    else {
      actions.delCase(data.id);
      actions.select(null);
    }
  };

  return (
    <div
      className={cx(cardMainClass, amount > 0 && cardMainSelectedClass)}
      style={{ '--l-color': color?.join(', ') } as CSSProperties}
      onClick={() => {
        toggle();
        actions.open(data);
        CaseBattleCreationClickOnCaseWhileCreatingSound.replay();
      }}
    >
      <div className={cardHeadClass}>
        {amount > 0 && (
          <div
            className={cardCaseAmountClass}
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <div
              onClick={() => {
                actions.setAmount(id, amount - 1);
                if (amount <= 1) actions.select(null);
                CaseBattleCreationClickOnCaseWhileCreatingSound.replay();
              }}
              className={cardCaseIconBoxClass}
            >
              <MinusIcon />
            </div>
            <input
              type="number"
              onChange={handleChangeInput}
              value={amount}
              onKeyDown={(event) => {
                if (event.key === ',' || event.key === '.') {
                  event.preventDefault();
                }
              }}
              className={cx(inputClass, amount >= 10 && inputActiveClass)}
            />
            <div
              onClick={() => {
                actions.setAmount(id, amount + 1);
                CaseBattleCreationClickOnCaseWhileCreatingSound.replay();
              }}
              className={cardCaseIconBoxClass}
            >
              <PlusIcon />
            </div>
          </div>
        )}
        <span className={cardPriceClass}>${price}</span>
      </div>
      <CaseImg className={cardCaseImgClass} id={id} draggable={false} />
      <div className={cardCaseNameClass}>{name}</div>
    </div>
  );
},
juxProps(['amount', 'selected']));

const cardCaseAmountClass = css`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  user-select: none;
  top: 8px;
  left: 0;
  width: 100%;
`;

const inputClass = css`
  width: 8px;
  font-family: 'Onest';
  font-weight: 500;
  font-size: 13px;
  line-height: 140%;
  color: #dfdfdf;
  outline: 0;
  border: none;
  background: none;
  -moz-appearance: textfield;
  ${stdBp.up(2560)} {
    width: calc(8px * 1.3);
  }
  &::placeholder {
    font-family: 'Onest';
    font-weight: 500;
    font-size: 13px;
    line-height: 140%;
    color: #dfdfdf;
    ${stdBp.up(2560)} {
      font-size: calc(13px * 1.3);
    }
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  &:hover,
  &:focus {
    appearance: none;
    -moz-appearance: textfield;
  }
`;

const inputActiveClass = css`
  width: 16px;
  ${stdBp.up(2560)} {
    width: calc(16px * 1.3);
  }
`;

const cardCaseIconBoxClass = css`
  cursor: pointer;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${stdBp.up(2560)} {
    width: calc(15px * 1.3);
    height: calc(15px * 1.3);
  }
`;

const cardMainClass = css`
  cursor: pointer;
  user-select: none;
  position: relative;
  width: 136px;
  height: 140px;
  background: #18171c;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6px;
  overflow: hidden;
  gap: 5px;
  ${stdBp.up(2560)} {
    width: calc(136px * 1.3);
    height: calc(140px * 1.3);
    border-radius: calc(16px * 1.3);
  }
  &:hover {
    border: 1px solid #ea9a4e;
  }
  &:before {
    position: absolute;
    content: '';
    height: 134px;
    width: 103px;
    left: 16px;
    top: 69px;
    opacity: 0.3;
    border-radius: 50%;
    filter: blur(64px);
    background: rgb(var(--l-color));
  }
`;
const cardMainSelectedClass = css`
  border: 1px solid #ea9a4e;
`;
const cardHeadClass = css`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 1;
`;
const cardPriceClass = css`
  font-family: 'Onest';
  font-weight: 500;
  font-size: 13px;
  line-height: 140%;
  color: #dfdfdf;
  ${stdBp.up(2560)} {
    font-size: calc(13px * 1.3);
  }
`;
const cardCaseImgClass = css`
  height: 80px;
  width: 96px;
  z-index: 1;
  object-fit: cover;
  ${stdBp.up(2560)} {
    height: calc(80px * 1.3);
    width: calc(96px * 1.3);
  }
`;
const cardCaseNameClass = css`
  font-weight: 600;
  font-size: 12px;
  line-height: 130%;
  z-index: 1;
  color: #dfdfdf;
  ${stdBp.up(2560)} {
    font-size: calc(12px * 1.3);
  }
`;
