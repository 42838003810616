import { Duration } from 'luxon';
import { useEffect, useState } from 'react';
import { ServerTime } from 'packs/libs/coil';
import { css } from '@emotion/css';
import { useLeaderBoardT } from 'app/leader-board/glossary';

type LeaderBoardTop3CountdownProps = {
  finish: number;
};

type TimeObject = {
  d: string;
  h: string;
  m: string;
  s: string;
};

export const LeaderBoardTop3Countdown = ({
  finish,
}: LeaderBoardTop3CountdownProps): JsxElement => {
  const t = useLeaderBoardT().sub('top3');

  const initial: TimeObject = { d: '00', h: '00', m: '00', s: '00' };
  const [timerObject, setTimerObject] = useState(initial);
  const UPDATE_TIME = 1000;

  useEffect(() => {
    if (!finish) {
      setTimerObject(initial);
      return;
    }

    const finishServerTime = new ServerTime(finish);

    let timer;
    const update = () => {
      const duration = finishServerTime.ms - Date.now();

      if (duration <= 0) {
        setTimerObject(initial);
        clearInterval(timer);
      } else {
        setTimerObject(getFormattedTimeObject(duration));
      }
    };

    update();
    timer = setInterval(update, UPDATE_TIME);

    return () => clearTimeout(timer);
  }, [finish]);

  return (
    <div className={mainClass}>
      <div className={boxClass}>
        <p className={timerHeaderClass}>{t('countdown')}</p>
        <div className={timerBoxClass}>
          <div className={timerItemClass}>{timerObject.d}</div>
          <div className={timerDividerClass}>:</div>
          <div className={timerItemClass}>{timerObject.h}</div>
          <div className={timerDividerClass}>:</div>
          <div className={timerItemClass}>{timerObject.m}</div>
          <div className={timerDividerClass}>:</div>
          <div className={lastTimerItemClass}>{timerObject.s}</div>
        </div>
      </div>
    </div>
  );
};

const getFormattedTimeObject = (ms: number): TimeObject => {
  const duration = Duration.fromMillis(ms)
    .shiftTo('days', 'hours', 'minutes', 'seconds', 'milliseconds')
    .toObject();

  const addPad = (val?: number) => String(val).padStart(2, '0'); // 00, 01, 02...

  return {
    d: addPad(duration.days),
    h: addPad(duration.hours),
    m: addPad(duration.minutes),
    s: addPad(duration.seconds),
  };
};

const mainClass = css`
  background: linear-gradient(135deg, rgba(30, 28, 34, 0) 0%, rgba(255, 57, 130, 0.2) 100%);
  border-radius: 8px;
  height: 51px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* &::after {
    content: '';
    display: inherit;
    width: 20%;
    margin: 0 auto;
    border-bottom: 1px solid #ff5a5a;
    border-radius: 8px;
  } */
`;

const boxClass = css`
  padding: 8px 13px;
  display: flex;
  align-items: center;
  gap: 6px;
`;

const timerHeaderClass = css`
  font-weight: 600;
  font-size: 13px;
  line-height: 130%;
  color: #dfdfdf;
  text-align: center;
`;

const timerBoxClass = css`
  display: flex;
  gap: 2px;
  align-items: center;
`;

const timerItemClass = css`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #18171c;
  border: 1px solid #26232d;
  border-radius: 4px;
  font-family: 'Commissioner';
  font-weight: 600;
  font-size: 12.7px;
  line-height: 130%;
  color: #988c92;
  padding: 2.5px 3.5px;
  width: 30px;
  height: 28px;
`;

const lastTimerItemClass = css`
  ${timerItemClass};
  color: #dfdfdf;
`;

const timerDividerClass = css`
  font-family: 'Commissioner';
  font-weight: 600;
  font-size: 10px;
  line-height: 130%;
  color: #dfdfdf;
  opacity: 0.3;
`;
