import {
  CaseBattlePlayerRole,
  CaseBattleResolveStatus,
  CaseBattleStage,
} from 'app/case/battle/definitions';
import { CaseBattleInsightGlossary } from 'app/case/battle/insight/glossary';
import {
  CaseBattleInsightBaseState as BaseState,
  CaseBattleInsightInternalStore,
  CaseBattleInsightLiveState as LiveState,
} from 'app/case/battle/insight/store/definitions';
import { CaseBattleInsightLiveStore } from 'app/case/battle/insight/store/live';
import { getActor } from 'domain/actor';
import { chronicle } from 'packs/libs/chronicle';
import { coilConnect, coilListen, coilReq, coilSend } from 'packs/libs/coil';
import { showIntlErrorToast, showIntlInfoToast } from 'packs/libs/intl/toasts';
import { getQue, queReplace } from 'packs/libs/que';
import { ReactNode, createContext, useContext, useEffect } from 'react';
import { bunchCb } from 'support/etc/bunch-cb';
import { objToMap } from 'support/etc/obj-to-map';
import { Swiss } from 'support/react/swiss';
import { useForceRefresh } from 'support/react/use-force-refresh';
import { launch } from 'support/react/use-launch';
import { useSingleton } from 'support/react/use-singleton';

const BaseContext = createContext<BaseState>(null!);
if (__DEBUG__) BaseContext.displayName = 'CaseBattleInsightBaseContext';
const LiveContext = createContext<LiveState>(null!);
if (__DEBUG__) LiveContext.displayName = 'CaseBattleInsightLiveContext';
const OperatorContext = createContext<Operator>(null!);
if (__DEBUG__) OperatorContext.displayName = 'CaseBattleInsightOperatorContext';

export const useCaseBattleInsightBaseState = () => useContext(BaseContext);
export const useCaseBattleInsightLiveState = () => useContext(LiveContext);
export const useCaseBattleInsightOperator = () => useContext(OperatorContext);

type Operator = {
  join(): void;
  replay(): void;
  addBot(): void;
};

type CaseBattleInsightStoreProviderProps = {
  id: string;
  children: ReactNode;
};

export const CaseBattleInsightStoreProvider = (
  props: CaseBattleInsightStoreProviderProps
): JsxElement => {
  const commit = useForceRefresh();
  const getStore = useSingleton(() => {
    let battleId: string;
    let BaseState: BaseState = null!;
    let LiveState: LiveState = null!;

    const setBaseState = (state: BaseState) => {
      BaseState = state;
    };

    const setLiveState = (state: LiveState) => {
      LiveState = state;
    };

    const getBaseState = () => BaseState;
    const getLiveState = () => LiveState;

    const baseSwiss = new Swiss(setBaseState, getBaseState);
    const liveSwiss = new Swiss(setLiveState, getLiveState);

    const { mutState: mutBaseState, updState: updBaseState } = baseSwiss;

    let unmounted = false;
    const sleep = async (ms: number) => {
      if (unmounted) throw 'unmounted';
      await new Promise((resolve) => setTimeout(resolve, ms));
    };

    const _op: CaseBattleInsightInternalStore = {
      base: baseSwiss,
      live: liveSwiss,
      commit,
      sleep,
    };

    const liveOp = new CaseBattleInsightLiveStore(_op);

    const replay = () => {
      liveOp.run(false);
    };

    const join = async () => {
      await coilReq({ action: 'case.battle.insight.join' });
      updBaseState({ role: CaseBattlePlayerRole.member });
    };

    const addBot = () => {
      launch(async () => {
        await coilReq({
          action: 'case.battle.insight.add-bot',
          data: battleId,
        });
      });
    };

    const operator: Operator = {
      join,
      replay,
      addBot,
    };

    const listeners = objToMap({
      joined: (player) => {
        mutBaseState((draft) => {
          draft.members.push(player);
        });
        commit();
      },
      resolved: ([status, data]) => {
        if (status === CaseBattleResolveStatus.success) {
          mutBaseState((draft) => {
            draft.stage = CaseBattleStage.live;
            draft.results = data.results;
            draft.startedAt = data.time;
            if (data.bots) draft.members.push(...data.bots);
          });
          liveOp.run(true);
        } else if (status === CaseBattleResolveStatus.canceled) {
          showIntlInfoToast(CaseBattleInsightGlossary, 'resolve.canceled', { reason: data });
          chronicle.replace('/case-battles');
        } else if (status === CaseBattleResolveStatus.failed) {
          showIntlErrorToast(CaseBattleInsightGlossary, 'resolve.failed', { reason: data });
          chronicle.replace('/case-battles');
        }
      },
      // left: (playerId) => {
      //   mutBaseBattle((draft) => {
      //     draft.members = draft.members.filter((p) => p.id !== playerId);
      //   });
      //   commit();
      // },
    });

    const getSelfRole = (battle) => {
      const actor = getActor();
      if (actor.anon) return CaseBattlePlayerRole.observer;
      const id = actor.id;
      for (const member of battle.members) {
        if (member.id === id) {
          if (member.author) return CaseBattlePlayerRole.author;
          return CaseBattlePlayerRole.member;
        }
      }
      return CaseBattlePlayerRole.observer;
    };

    const loadBattle = () =>
      coilConnect(() => {
        const resolveBattle = (battle) => {
          const role = getSelfRole(battle);
          const stage = battle.stage as CaseBattleStage;
          setBaseState({ ...battle, role });

          setLiveState({
            round: 1,
            stands: battle.members.map(() => ({ drops: [] })),
          });

          if (stage !== CaseBattleStage.waiting) {
            // liveOp.run(true);
            liveOp.run(stage === CaseBattleStage.live);
          } else {
            commit();

            // dykiy-kostyl-start
            const query = getQue();
            if (query.bots) {
              launch(async () => {
                await coilReq({
                  action: 'case.battle.start',
                  data: { battleId: battle.id, addBots: true },
                });
                queReplace({});
              });
            }
            // dykiy-kostyl-end
          }
        };

        if (__DEV__ && battleId === 'test') {
          import('./stub').then((m) => {
            resolveBattle(m.CaseBattleInsightStub.loadBattle());
          });
        } else {
          launch(async () => {
            resolveBattle(
              await coilReq({
                action: 'case.battle.insight.enter',
                data: battleId,
              })
            );
          });

          return () => {
            coilSend('case.battle.insight.leave', battleId);
          };
        }
      });

    const listenEvents = () =>
      coilListen('cb_i', ([action, data]) => {
        listeners.get(action)!(data);
      });

    const effect = (id: string) => {
      battleId = id;
      return bunchCb([
        loadBattle(),
        listenEvents(),
        () => {
          liveOp.unmount();
          battleId = null!;
          BaseState = null!;
          LiveState = null!;
        },
      ]);
    };

    return () => [BaseState, LiveState, operator, effect] as const;
  });

  const [base, live, op, load] = getStore();

  useEffect(() => load(props.id), [props.id]);

  if (base === null) return null;

  return (
    <OperatorContext.Provider value={op}>
      <BaseContext.Provider value={base}>
        <LiveContext.Provider value={live}>{props.children}</LiveContext.Provider>
      </BaseContext.Provider>
    </OperatorContext.Provider>
  );
};
