import { appSyringe } from 'app/syringe';
import { ActorOperator } from 'domain/actor/operator';
import { useActor } from 'domain/actor/store';
import { useEffect } from 'react';

export const onceActorResolved = (action: (actor: ActorOperator) => any) => {
  appSyringe(function OnceActorResolvedInjection({ onClose }) {
    const actor = useActor();
    useEffect(() => {
      if (actor.resolved) {
        onClose();
        action(actor);
      }
    }, [actor.resolved]);

    return null;
  });
};

export const onceActorAuthenticated = (action: (actor: ActorOperator) => any) => {
  appSyringe(function OnceActorAuthenticatedInjection({ onClose }) {
    const actor = useActor();
    useEffect(() => {
      if (actor.signed) {
        onClose();
        action(actor);
      }
    }, [actor.resolved]);

    return null;
  });
};
