import { css } from '@emotion/css';
import { useEffect } from 'react';

import { ReactComponent as TopArrowIcon } from 'app/case/battle/insight/assets/top-arrow-icon.svg';
import {
  useCaseBattleInsightBaseState,
  useCaseBattleInsightLiveState,
} from 'app/case/battle/insight/store';
import { CaseImg } from 'domain/case/units/image';
import { createRefEffect } from 'support/react/ref-effect';
import { useSingleton } from 'support/react/use-singleton';

const CASE_BOX_WIDTH = 110;
const CASE_BOX_GAP = 15;

export const CaseBattleInsightHeaderCases = (): JsxElement => {
  const { cases } = useCaseBattleInsightBaseState();
  const { round } = useCaseBattleInsightLiveState();

  const [children, op] = useSingleton(() => {
    let el: HTMLDivElement;
    const ref = createRefEffect<HTMLDivElement>((elem) => {
      el = elem;
    });
    const op = {
      turn(round: number) {
        el.style.transform = `translateX(-${
          (round - 1) * (CASE_BOX_WIDTH + CASE_BOX_GAP) + CASE_BOX_WIDTH / 2
        }px)`;
      },
      reset() {},
    };

    const children = (
      <div className={mainClass}>
        <div className={shroudClass} />
        <div className={tapeOffsetClass}>
          <div ref={ref} className={tapeClass}>
            {...cases.map((data) => {
              return <CaseImg className={imageClass} id={data.id} />;
            })}
          </div>
        </div>
        <TopArrowIcon className={topArrowClass} />
      </div>
    );

    return [children, op] as const;
  });

  useEffect(() => {
    op.turn(round);
  }, [round]);

  return children;
};

const imageClass = css`
  height: ${CASE_BOX_WIDTH}px;
  object-fit: contain;
`;

const mainClass = css`
  display: flex;
  align-items: center;
  flex: 1;
  position: relative;
  overflow: hidden;
`;

const tapeOffsetClass = css`
  position: absolute;
  left: 50%;
  top: 0;
  right: 0;
  bottom: 0;
`;

const tapeClass = css`
  display: flex;
  transition: transform 0.5s ease-in-out;
  gap: ${CASE_BOX_GAP}px;
  transform: translateX(-${CASE_BOX_WIDTH / 2}px);
`;

const shroudClass = css`
  position: absolute;
  inset: 0;
  background: linear-gradient(180deg, #1f1d23 0%, rgba(30, 28, 34, 0.6) 100%);

  &:before {
    position: absolute;
    content: '';
    box-shadow: 0 0 28.5px 28.5px #1e1d22;
    height: 100%;
    width: 2.7px;
    background-color: #1e1d22;
    z-index: 2;
    left: 0;
    top: 0;
  }

  &:after {
    position: absolute;
    content: '';
    box-shadow: 0 0 28.5px 28.5px #1e1d22;
    height: 100%;
    width: 2.7px;
    background-color: #1e1d22;
    z-index: 2;
    right: 0;
    top: 0;
  }
`;

const topArrowClass = css`
  position: absolute;
  top: 0;
  left: 49.5%;
  width: 15px;
  height: 21px;
`;
