import { ComponentType } from 'react';
import { css, cx } from '@emotion/css';
import { ReactComponent as VkIcon } from 'app/layout/stats/assets/vk-icon.svg';
import { ReactComponent as TelegramIcon } from 'app/layout/stats/assets/telegram-icon.svg';
import { ReactComponent as DiscordIcon } from 'app/layout/stats/assets/discord-icon.svg';
import { ReactComponent as FacebookIcon } from 'app/layout/stats/assets/facebook-icon.svg';
import { ReactComponent as TwitterIcon } from 'app/layout/stats/assets/twitter-icon.svg';
import { Link } from 'react-router-dom';
import { linearClamp } from 'support/polished/linear-clamp';
import { useLayoutT } from 'app/layout/glossary';

const widthClass = css`
  svg {
    width: 18px;
  }
`;
const vkWidthClass = css`
  svg {
    width: 20px;
  }
`;
export const LayoutStatsSocialPanel = (): JsxElement => {
  const language = useLayoutT().locale;

  const LINKSRU: [string, ComponentType, string][] = [
    ['https://vk.com/jetskins', VkIcon, vkWidthClass],
    ['https://t.me/joinchat/TkO8nsu-CmHQjPNP', TelegramIcon, widthClass],
    ['https://discord.gg/jetskins', DiscordIcon, widthClass],
  ];

  const LINKS: [string, ComponentType, string][] = [
    ['https://twitter.com/jetskinsmedia', TwitterIcon, widthClass],
    ['https://www.facebook.com/jetskinsgg', FacebookIcon, vkWidthClass],
    ['https://discord.gg/jetskins', DiscordIcon, widthClass],
  ];

  const links = language === 'ru' ? LINKSRU : LINKS;

  return (
    <div className={iconsClass}>
      {...links.map(([link, Icon, className]) => (
        <Link className={cx(linkBoxClass, className)} target="_blank" to={link}>
          <Icon />
        </Link>
      ))}
    </div>
  );
};

const iconsClass = css`
  display: flex;
  align-items: center;
  height: 100%;
`;

const linkBoxClass = css`
  height: 100%;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(50, 49, 53, 0.3);
  &:hover {
    path {
      fill: url(#linearGrad);
    }
  }
`;
