import { css } from '@emotion/css';
import { ReactComponent as MembersIcon } from 'app/contest/assets/player.svg';
import { useContestT } from 'app/contest/glossary';
import { ContestBriefData } from 'domain/contest';

type ContestCardMembersProps = {
  contest: ContestBriefData;
};

export const ContestCardJoined = ({
  contest: { joined, cap },
}: ContestCardMembersProps): JsxElement => {
  const t = useContestT().sub('card');

  return (
    <div className={membersBoxClass}>
      <p className={titleTextClass}>{t('members')}</p>
      <div className={membersClass}>
        <MembersIcon />
        {joined}{' '}
        <span className={maxMembersClass}>
          {t('out-of')} {cap}
        </span>
      </div>
    </div>
  );
};

const titleTextClass = css`
  font-family: 'Commissioner';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 140%;
  color: #dfdfdf;
`;

const membersBoxClass = css`
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 2px;
  width: 100%;
`;

const membersClass = css`
  font-family: 'Onest';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 140%;
  display: flex;
  align-items: center;
  gap: 4px;
  color: #dfdfdf;
`;

const maxMembersClass = css`
  font-family: 'Commissioner';
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  color: #988c92;
`;
