import { css } from '@emotion/css';
import { CaseBattleInsightHeaderCases } from 'app/case/battle/insight/header/cases';
import { CaseBattleInsightHeaderInfo } from 'app/case/battle/insight/header/info';
import { CaseBattleInsightHeaderStats } from 'app/case/battle/insight/header/stats';

export const CaseBattleInsightHeader = (): JsxElement => {
  return (
    <div className={mainClass}>
      <CaseBattleInsightHeaderStats />
      <div className={boxClass}>
        <CaseBattleInsightHeaderCases />
        <CaseBattleInsightHeaderInfo />
      </div>
    </div>
  );
};

const mainClass = css`
  height: 120px;
  display: flex;
  width: 100%;
  gap: 2px;
  background: linear-gradient(180deg, #1f1d23 0%, rgba(30, 28, 34, 0.6) 100%);
`;

const boxClass = css`
  display: flex;
  width: calc(100% - 163px);
`;
