import lensPath from 'ramda/es/lensPath';
import set from 'ramda/es/set';
import view from 'ramda/es/view';
import { mut, MutSpec } from 'support/etc/immutate';
import { iup, IupSpec } from 'support/etc/iup';
import { stash_get } from 'support/etc/stash';
import { useBasicStore } from 'support/react/basic-store';
import { execIfFn } from 'support/etc/exec-if-fn';

type OperatorFactory<S, Operator> = (swiss: Swiss<S>) => Operator;

export function useBasicSwissStore<S, O>(init: () => [S, OperatorFactory<S, O>]): [S, O] {
  return useBasicStore(() => {
    const [state, factory] = init();

    return [
      state,
      (setState, getState) => {
        return factory(new Swiss(setState, getState));
      },
    ];
  });
}

export function useSwissState<S>(state: S | (() => S)) {
  // @ts-ignore
  return useBasicStore<S, Swiss<S>>(() => [execIfFn(state), makeSwiss]);
}

export class Swiss<S> {
  setState: (state: S) => void;
  getState: () => S;

  constructor(setState: (state: S) => void, getState: () => S) {
    this.setState = setState;
    this.getState = getState;
  }

  @stash_get
  get iupState(): (spec: IupSpec<S>) => void {
    return (spec: IupSpec<S>) => this.setState(iup(this.getState(), spec));
  }

  @stash_get
  get mutState(): (spec: MutSpec<S>) => void {
    return (spec: MutSpec<S>) => this.setState(mut(this.getState(), spec));
  }

  @stash_get
  get updState(): (state: Partial<S>) => void {
    return (state) => this.setState({ ...this.getState(), ...state });
  }

  get prop() {
    return <T>(prop: string): Swiss<T> => {
      return new Swiss(
        (state) => this.setState({ ...this.getState(), [prop]: state }),
        () => this.getState()[prop]
      );
    };
  }

  get path() {
    return <T>(path: (string | number)[]): Swiss<T> => {
      const lens = lensPath(path);
      return new Swiss(
        (state) => this.setState(set(lens, state, this.getState())),
        () => view(lens, this.getState())
      );
    };
  }
}

export function makeSwiss<S>(setState: (state: S) => void, getState: () => S) {
  return new Swiss(setState, getState);
}
