import { CaseData } from 'domain/case/definitions';
import { usePlayerBalance } from 'domain/player/balance';
import { throttle } from 'lodash';
import { chronicle } from 'packs/libs/chronicle';
import { coilReq } from 'packs/libs/coil';
import { createContext, useContext } from 'react';
import { MutSpec, mut } from 'support/etc/immutate';
import { Swiss, useBasicSwissStore } from 'support/react/swiss';
import { launch } from 'support/react/use-launch';
import { IHashMap } from 'support/struct/i-hash-map';

type SelectedCase = { amount: number; case: CaseData };
type Cases = IHashMap<string, SelectedCase>;
type Config = {
  size: 2 | 3 | 4;
  bots?: boolean;
};
type State = {
  cases: Cases;
  rounds: number;
  total: number;
  config: Config;
};

type FinalState = State & {
  enoughBalance: boolean;
  balance: number;
};

type Operator = {
  config: Swiss<Config>;
  cases: {
    add(data: CaseData): void;
    del(caseId: string): void;
    clear(): void;
    setAmount(caseId: string, amount: number): void;
  };
  create(): void;
};

const Context = createContext<[FinalState, Operator]>(null!);
if (__DEBUG__) Context.displayName = 'CaseBattleCreateContext';

export const useCaseBattleCreationStore = () => useContext(Context);

export const CaseBattleCreationStoreProvider = (props: Rcp): JsxElement => {
  const balance = usePlayerBalance();
  const [state, op] = useBasicSwissStore<State, Operator>(() => [
    { cases: IHashMap.empty(), config: { size: 2 }, rounds: 0, total: 0 },
    ({ updState, getState, prop }) => {
      const applyCases = (fn: (battles: Cases) => Cases) => {
        const cases = fn(getState().cases);
        let rounds = 0;
        let total = 0;
        for (const data of cases.values()) {
          rounds += data.amount;
          total += data.amount * data.case.price;
        }
        updState({ cases, rounds, total });
      };

      const addCase = (data: CaseData) => {
        applyCases((m) => m.set(data.id, { amount: 1, case: data }));
      };

      const mutSelectedCase = (id: string, spec: MutSpec<SelectedCase>) => {
        applyCases((map) => map.applyOne(id, (sel) => mut(sel, spec)));
      };

      const delCase = (id: string) => {
        applyCases((map) => map.del(id));
      };

      const clearCases = () => {
        applyCases(() => IHashMap.empty());
      };

      const setCaseAmount = (caseId: string, amount: number) => {
        if (amount < 1) delCase(caseId);
        else {
          mutSelectedCase(caseId, (draft) => {
            draft.amount = amount;
          });
        }
      };

      const create = throttle(() => {
        launch(async () => {
          const { cases, config } = getState();
          console.log(getState());
          const id = await coilReq({
            action: 'case.battle.create',
            data: {
              cases: cases.listMapV((v) => ({ id: v.case.id, amount: v.amount })),
              ...config,
            },
          });
          let url = `/case-battles/${id}`;
          if (config.bots) url += '?bots=1';
          chronicle.replace(url);
        });
      }, 300);

      return {
        create,
        cases: {
          add: addCase,
          del: delCase,
          clear: clearCases,
          setAmount: setCaseAmount,
        },
        config: prop('config'),
      };
    },
  ]);

  const finalState = { ...state, balance, enoughBalance: state.total <= balance };

  return <Context.Provider value={[finalState, op]}>{props.children}</Context.Provider>;
};

// type Cb = () => void;
// class EffectCollection {
//   private readonly callbacks = new Set<Cb>();

//   add(callback: Cb) {
//     this.callbacks.add(callback);
//   }

//   launch(action: () => Promise<void>) {
//     const cb = () => {
//       action().finally(() => {
//         this.callbacks.delete(cb);
//       });
//     };
//   }

//   del(callback: Cb) {
//     this.callbacks.delete(callback);
//   }

//   collect() {
//     return () => {
//       for (const cb of this.callbacks) cb();
//     };
//   }
// }
