import { AsyncMutSpec, mut, MutSpec } from 'support/etc/immutate';
import { Swiss } from './swiss';

export class Switz<S> {
  setState: (state: S) => void;
  getState: () => S;

  constructor(setState: (state: S) => void, getState: () => S) {
    this.setState = setState;
    this.getState = getState;
  }

  protected updState(state: Partial<S>) {
    this.setState({ ...this.getState(), ...state });
  }

  protected mutState(spec: MutSpec<S>): void {
    this.setState(mut(this.getState(), spec));
  }

  protected async asyncMutState(spec: AsyncMutSpec<S>) {
    this.setState(await mut(this.getState(), spec));
  }

  protected swissProp<V>(prop: string) {
    return new Swiss<V>(
      (value) => {
        // @ts-ignore
        this.updState({ [prop]: value });
      },
      () => this.getState()[prop]
    );
  }
}
