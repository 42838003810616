import { getCrushOperator, useCrushT } from 'app/crush';
import { CrushMode } from 'domain/crush';
import { InstantTooltip } from 'packs/single/instant-tooltip';
import { stdBreakpoints, StdButton } from 'packs/std';
import { css, cx } from '@emotion/css';
import { memo } from 'react';
import { useXPanelState } from './store';

type CrushControlXPanelProps = {
  disabled?: boolean;
};

export const X_MAX = (mode: CrushMode) =>
  getCrushOperator().scope(mode).getState().round.opts?.grow.max ?? 999;
export const X_STEP = 0.01;

export const CrushControlXPanel = memo(function CrushControlXPanel({
  disabled,
}: CrushControlXPanelProps): JsxElement {
  const t = useCrushT().sub('grab.panel');
  const exceptThisSymbols = ['e', 'E', '+', '-'];
  const [{ locked, invalid, value, presets, autoStopActive, mode }, op] = useXPanelState();

  return (
    <div className={mainClass} key={mode}>
      <div className={interactionClass}>
        <div className={rightTopSectionClass}>
          <div className={autoStopLabelClassActiveClass}>x</div>
          <InstantTooltip
            className={tooltipClass}
            open={!!invalid}
            arrow
            placement="top"
            title={t('coefficient-restrictions', {
              min: op.getMinValue(),
              max: op.getMaxValue(),
            })}
          >
            <input
              type="number"
              className={cx(
                cefInputClass,
                invalid && invalidInputClass,
                !autoStopActive && cefInputInactiveClass
              )}
              placeholder={t('your-coefficient')}
              step={X_STEP}
              value={value}
              disabled={locked}
              min={op.getMinValue()}
              max={op.getMaxValue()}
              onKeyDown={(e) => exceptThisSymbols.includes(e.key) && e.preventDefault()}
              onChange={(e) => {
                op.onValueChange(e.target.value);
              }}
            />
          </InstantTooltip>
          <div className={rateClass}>
            <div
              className={cx(autoStopSwitchClass, autoStopActive && autoStopSwitchActiveClass)}
              onClick={op.toggleAutoStop}
            />
            <span className={cx(autoStopActive && autoStopLabelClassActiveClass)}>
              {t('auto-stop')}
            </span>
          </div>
        </div>

        <div className={leftTopSectionClass}>
          {presets.map((x, i) => (
            <StdButton
              className={presetButtonClass}
              key={i}
              disabled={locked}
              onClick={() => op.setPreset(x)}
            >
              x{x}
            </StdButton>
          ))}
        </div>
      </div>
    </div>
  );
});

const mainClass = css`
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  margin-bottom: 18px;
`;

const interactionClass = css`
  position: relative;
`;

const presetButtonClass = css`
  background: #26232d;
  border-radius: 6px;
  color: #988c92;
  display: inline-flex;
  flex: 1;
  font-size: 12px;
  padding: 6px 8px;

  &:not(:first-child) {
    margin-left: 8px;
  }

  &:hover {
    background-color: var(--dark-blue-100);
  }

  &:disabled {
    &:hover {
      background: #26232d;
      cursor: default;
    }
  }
`;

const leftTopSectionClass = css`
  flex: 1;
  display: flex;
  justify-content: space-between;
  margin: 0 24px;
  font-family: 'Commissioner';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
`;

const rightTopSectionClass = css`
  display: flex;
  align-items: center;
  margin: 0 24px;
  padding: 2px 10px;
  background: #26232d;
  border-radius: 8px;
  margin-bottom: 12px;

  ${stdBreakpoints.up(2560)} {
    flex: 2;
    justify-content: space-between;
  }
`;

const rateClass = css`
  display: flex;
  gap: 10px;
  align-items: center;
  color: #988c92;
  font-family: 'Commissioner';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 130%;
`;

const autoStopLabelClassActiveClass = css`
  color: #ffaa63;
  font-size: 12px;
`;

const autoStopSwitchClass = css`
  background: #2e2b35;
  height: 8px;
  border-radius: 12px;
  width: 18px;
  min-width: 28px;
  position: relative;
  display: flex;
  align-items: center;
  padding: 2px;
  cursor: pointer;
  &:before {
    content: '';
    background: #988c92;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    transition: all ease 0.5s;
    position: absolute;
  }
`;

const autoStopSwitchActiveClass = css`
  &:before {
    background: linear-gradient(180deg, #ffaa63 0%, #ffaa63 0.01%, #ea9a4e 100%);
    transform: translateX(14px);
  }
`;

const cefInputClass = css`
  width: 100%;
  flex: 1;
  background: transparent;
  padding: 8px 0;
  font-family: 'Onest';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  border: 0;
  color: #ffffff;
  margin-left: 2.4px;
  position: relative;
  letter-spacing: -0.4px;
  appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:focus-visible {
    outline: 0;
  }

  &::placeholder {
    font-size: 14px;
    overflow: visible;
    position: absolute;
    bottom: 9px;
  }
`;

const invalidInputClass = css`
  color: #988c92;
`;

const cefInputInactiveClass = css`
  color: #988c92;
`;

const tooltipClass = css`
  & .tooltip {
    align-items: center;
    display: flex;
    margin: 10px;
  }
`;
