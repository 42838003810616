import { css } from '@emotion/css';
import { useState } from 'react';

import { useSoundVolumeState } from 'packs/libs/sound';
import { ReactComponent as CrossIcon } from 'app/layout/sidebar/assets/cross-icon.svg';
import { SoundIcon } from 'app/layout/sidebar/assets/sound-icon';
import { LayoutSidebarNavPanelOutsideClickHandler } from 'app/layout/sidebar/settings/outside-click-handler';

export const LayoutSidebarSettingsSoundVolumeMixer = (): JsxElement => {
  const [volume, setVolume] = useSoundVolumeState();
  const [open, setOpen] = useState<boolean>(false);

  return (
    <LayoutSidebarNavPanelOutsideClickHandler onOutsideClick={() => setOpen(false)}>
      <div className={soundBoxClass}>
        {open && <SoundSlider onChange={setVolume} value={volume} />}
        <div
          onClick={() => setOpen(!open)}
          className={speakerBoxClass}
          style={{ background: open ? '#2A2930' : '#26232D' }}
        >
          <SoundIcon active={volume > 0} />
          <p className={textClass}>{volume > 0 ? 'ON' : <CrossIcon className={crossIconClass}/>}</p>
        </div>
      </div>
    </LayoutSidebarNavPanelOutsideClickHandler>
  );
};

type SoundSliderProps = {
  value: number;
  onChange(value: number): void;

};

const SoundSlider = ({ value, onChange }: SoundSliderProps): JsxElement => {
  return (
    <div className={volumeInputBoxClass}>
      <input
        min="0"
        max="100"
        step="1"
        type="range"
        defaultValue={value}
        onChange={(e) => {
          onChange(Number(e.target.value));
        }}
        className={volumeInputClass}
        style={{ backgroundSize: `${value}% 100%` }}
      />
    </div>
  );
};

const volumeInputBoxClass = css`
  position: absolute;
  border-radius: 4px;
  cursor: pointer;
  background: #18171c;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(-90deg);
  padding: 21px 15px;
  top: -400%;
`;

const volumeInputClass = css`
  cursor: pointer;
  border-radius: 5px;
  height: 7px;
  width: 140px;
  z-index: 5;
  -webkit-appearance: none;
  background: rgba(38, 35, 45, 1);
  background-image: linear-gradient(180deg, #ffaa63 0%, #ea9a4e 100%);
  background-repeat: no-repeat;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 14px;
    width: 8px;
    border-radius: 5px;
    background: #ffffff;
    transition: background 0.3s ease-in-out;
  }

  & ::-moz-range-thumb {
    -webkit-appearance: none;
    height: 14px;
    width: 8px;
    border-radius: 5px;
    background: #ffffff;
    transition: background 0.3s ease-in-out;
  }

  & ::-ms-thumb {
    -webkit-appearance: none;
    height: 14px;
    width: 8px;
    border-radius: 5px;
    background: #ffffff;
    transition: background 0.3s ease-in-out;
  }

  &::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }

  &::-moz-range-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }

  &::-ms-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }
`;

const speakerBoxClass = css`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3.5px;
  height: 30px;
  width:50px;
  border-radius: 4px;
  border: 1px solid #26232d;
  cursor: pointer;

  >svg {
    width: 18px;
  }
`;
const crossIconClass = css`
  width: 8px;

`
const soundBoxClass = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
`;
const textClass = css`
  color: transparent;
  background-clip: text;
  background-image: linear-gradient(180deg, #ffaa63 0%, #ea9a4e 100%);
  font-weight: 600;
  font-size: 12px;
  line-height: 130%;
`;
