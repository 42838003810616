import { css, cx } from '@emotion/css';
import { StdCloseDialogIcon } from 'packs/std/dialog/blocks/close-icon';
import { ReactNode } from 'react';
import { ReactComponent as ArrowIcon } from 'app/crush/provably-fair/assets/arrow.svg';
import { openCrushProvablyFairHowItWorkDialog } from '../how-it-work-dialog';

type CrushProvablyFairLeftDialogProps = {
  title: string;
  onClose(): void;
  children: ReactNode;
  className?: string;
  id?: string;
};

export const CrushProvablyFairLeftDialog = ({
  title,
  onClose,
  children,
  className,
  id,
}: CrushProvablyFairLeftDialogProps): JsxElement => {
  return (
    <div className={cx(mainClass, className)}>
      <div className={headClass}>
        <div className={headBoxClass}>
          <div className={titleBoxClass}>
            <div
              className={boxIconClass}
              onClick={() => {
                onClose();
                openCrushProvablyFairHowItWorkDialog()
              }}
            >
              <ArrowIcon />
            </div>
            <div className={titleClass}>{title}</div>
          </div>
          <div className={idClass}>{id}</div>
        </div>
        <div onClick={onClose} className={closeClass}>
          <StdCloseDialogIcon className={closeIconClass} />
        </div>
      </div>
      <div className={bodyClass}>{children}</div>
    </div>
  );
};

const mainClass = css`
  display: flex;
  flex-direction: column;
  gap: 2px;
  border-radius: 32px;
`;

const titleClass = css`
  font-family: 'Onest';
  font-weight: 700;
  font-size: 18px;
  line-height: 140%;
`;

const closeClass = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background: #18171c;
  border-radius: 12px;
  cursor: pointer;
`;

const idClass = css`
  font-family: 'Commissioner';
  font-weight: 600;
  font-size: 12px;
  line-height: 130%;
  color: #988c92;
`;

const boxIconClass = css`
  background: #18171c;
  border-radius: 9px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const closeIconClass = css`
  width: 14px;
  height: 14px;
`;

const headClass = css`
  padding: 22px 32px 12px 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #26232d;
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
`;

const headBoxClass = css`
  display: flex;
  flex-direction: column;
  gap: 7px;
`;
const titleBoxClass = css`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const bodyClass = css`
  padding: 24px;
  background: #26232d;
  border-bottom-left-radius: 32px;
  border-bottom-right-radius: 32px;
`;
