import { css } from '@emotion/css';
import { useSingleton } from 'support/react/use-singleton';
import { useEffect } from 'react';
import { UpgradeUnitsWheel, UpgradeWheelOperator } from 'app/upgrade/units/wheel/wheel';
import { useUpgradeState } from 'app/upgrade';
import { UpgradeBiddingGlideBlock } from 'app/upgrade/circle/bidding/glide/block';
import { stdBreakpoints } from 'packs/std';

export const UpgradeBidding = (): JsxElement => {
  const { chance } = useUpgradeState();

  const op = useSingleton(() => {
    let wheelOp: UpgradeWheelOperator = null!;
    const wheelRef = (op: UpgradeWheelOperator) => {
      wheelOp = op;
      op.chance.set(chance);
      objectWheelRef.val = op;
    };

    const objectWheelRef: { val?: UpgradeWheelOperator } = {};

    const updateChance = (chance: string) => {
      wheelOp?.chance.turn(chance);
    };

    return { updateChance, wheelOp, wheelRef, objectWheelRef };
  });

  useEffect(() => op.updateChance(chance), [chance]);

  return (
    <div className={mainClass}>
      <UpgradeUnitsWheel op={op.wheelRef}>
        <UpgradeBiddingGlideBlock />
      </UpgradeUnitsWheel>
    </div>
  );
};

const mainClass = css`
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  ${stdBreakpoints.up(1695)} {
    transform: scaleX(0.9);
  }
  ${stdBreakpoints.up(1820)} {
    transform: scaleX(0.85);
  }
  ${stdBreakpoints.up(1920)} {
    transform: scaleX(0.78);
  }
`;
