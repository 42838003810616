import { css } from '@emotion/css';
import { usePlayerBalance } from 'domain/player/balance';

import { BalanceIcon } from '../assets/balance-icon';

export const LayoutHeaderAccountPanelBalance = (): JsxElement => {
  const balance = usePlayerBalance();
  return (
    <div className={balanceClass}>
      <BalanceIcon />
      <span className={textClass}>{balance && '$'+balance.toFixed(2)}</span>
    </div>
  );
};

const balanceClass = css`
  background: #222025;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  gap: 9px;
  padding: 0 15px;
  min-width: 50px;
  svg{
    height: 18px;
  }
`;

const textClass = css`
  color: transparent;
  background-clip: text;
  background-image: linear-gradient(180deg, #ffaa63 0%, #ea9a4e 100%);
  font-weight: 500;
  font-size: 14px;
`;
