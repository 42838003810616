import { css, cx } from '@emotion/css';
import { ReactNode } from 'react';

type CrushProvablyFairRightDialogProps = {
  children: ReactNode;
  title: string;
  className?: string;
  titleClassName?: string;
};

export const CrushProvablyFairRightDialog = ({
  children,
  title,
  className,
  titleClassName,
}: CrushProvablyFairRightDialogProps): JsxElement => {
  return (
    <div className={cx(mainClass, className)}>
      <div className={boxClass}>
        <div className={cx(titleClass, titleClassName)}>{title}</div>
        {children}
      </div>
    </div>
  );
};

const mainClass = css`
  background: #26232d;
  border-radius: 32px;
`;

const boxClass = css`
  padding: 24px 10px 15px 24px;
  display: flex;
  flex-direction: column;
  min-width: 256px;
`;

const titleClass = css`
  font-family: 'Commissioner';
  font-weight: 700;
  font-size: 12px;
  line-height: 140%;
  display: flex;
  align-items: center;
  letter-spacing: 0.08em;
  color: #dfdfdf;
  padding-bottom: 24px;
`;
