export enum PlayerRole {
  admin = 'a',
  moderator = 'm',
  support = 's',
  blogger = 'b',
  vip = 'v',
  player = 'p',
}

export type PlayerBriefData = {
  id: string;
  name: string;
  image: string;
};
