type SimpleEventListener<T> = (arg: T) => any;
export class SimpleEventTarget<T = void> extends Set<SimpleEventListener<T>> {
  emit(arg: T): void {
    for (const cb of this.values()) cb(arg);
  }

  sub = (listener: SimpleEventListener<T>) => {
    this.add(listener);
    return () => {
      this.delete(listener);
    };
  };
}
