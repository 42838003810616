import { css, cx } from '@emotion/css';
import { LeaderBoardCurrentTopData, LeaderBoardItemData } from 'app/leader-board/definitions';
import { useActor } from 'domain/actor';
import { PlayerImage } from 'domain/player/image/image';
import { SkinImg } from 'domain/skin/units/image';
import { useLeaderBoardT } from '../glossary';
import { returnGradientFrame } from '../skin-border';

type LeaderBoardTableBodyProps = {
  data: LeaderBoardCurrentTopData;
};

export const LeaderBoardTableBody = ({ data }: LeaderBoardTableBodyProps): JsxElement => {
  const list = data.list.slice(3);

  return (
    <div className={leaderBoardTableBodyClass}>
      {list.map((item) => {
        return (
          <Row
            key={item.player.id}
            data={item}
            self={data.self ? data.self.position === item.position : false}
          />
        );
      })}
      {/* {data.self && <SelfRow data={data.self} />} */}
    </div>
  );
};

const SelfRow = ({ data }): JsxElement => {
  const actor = useActor();
  return (
    <div className={selfBoxClass}>
      <Row data={{ ...data, player: actor.payload }} self />
    </div>
  );
};

const selfBoxClass = css`
  padding-top: 8px;
  border-top: 1px solid #2e2c33;
`;

type RowProps = {
  data: LeaderBoardItemData;
  self?: boolean;
};

export const Row = ({ data, self }: RowProps): JsxElement => {
  const t = useLeaderBoardT().sub('table');

  const getFrame = () => {
    if (data.prize != null) {
      const Frame = returnGradientFrame(data.prize.skin.color);
      return (
        <Frame>
          <SkinImg className={tableRowItemPrizeImgClass} code={data.prize.skin.image} />
        </Frame>
      );
    }

    return null;
  };

  return (
    <div className={cx(tableRowBackgroundClass)}>
      <div className={cx(leaderBoardTableRowClass, self ? tableRowActiveClass : '')}>
        <span
          className={cx(
            tableRowItemClass,
            tablePositionColumnClass,
            self ? tableRowActiveBorderClass : ''
          )}
        >
          {data.position}
          {self && <span className={tableRowPlaceYouClass}>{t('self')}</span>}
        </span>
        <span
          className={cx(
            tableRowItemClass,
            tableRowItemPlayerBoxClass,
            self ? tableRowActiveClass : tableRowItemPlayerClass
          )}
        >
          <PlayerImage className={tableRowItemPlayerImgClass} code={data.player?.image} />
          <span className={tableRowItemPlayerTextClass}>{data.player?.name}</span>
        </span>
        <span className={cx(tableRowItemClass, tableRowItemGamesClass)}>{data?.games}</span>
        <span className={cx(tableRowItemClass, tableRowItemGamesClass)}>${data?.profit}</span>
        <span className={cx(tableRowItemClass, tableRowItemPrizeClass)}>{getFrame()}</span>
      </div>
    </div>
  );
};

const leaderBoardTableBodyClass = css`
  padding-top: 8px;
  display: flex;
  width: 100%;
  height: calc(100% - 53px);
  flex-direction: column;
  column-gap: 8px;
  row-gap: 8px;
  overflow: scroll;
  max-height: 455px;

  ::-webkit-scrollbar {
    width: 0;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: transparent;
  }
`;
const tableRowBackgroundClass = css`
  min-height: 53px;
  background-color: #1e1c22;
  border-radius: 12px;
  overflow: hidden;
`;
const leaderBoardTableRowClass = css`
  position: relative;

  height: 53px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 96px 18px 24px;
  color: #988c92;
`;
const tableRowItemPlayerBoxClass = css`
  min-width: 150px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const tableRowItemClass = css`
  width: calc(717px / 5);
  color: inherit;
  font-family: 'Onest';
  font-weight: 700;
  font-size: 13px;
  display: flex;
`;

const tableRowItemPlayerClass = css`
  color: #dfdfdf;
`;
const tableRowItemGamesClass = css`
  justify-content: center;
`;
const tablePositionColumnClass = css`
  max-width: 100px;
  justify-content: center;
`;
const tableRowItemPrizeImgClass = css`
  width: 64.62px;
  height: 37.12px;
`;

const tableRowActiveClass = css`
  background-image: linear-gradient(180deg, #ffaa63 0%, #ea9a4e 100%);
  background-clip: text;
  color: transparent;
`;
const tableRowItemPlayerImgClass = css`
  width: 24px;
  height: 24px;
  border-radius: 4px;
`;
const tableRowItemPlayerTextClass = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 130px;
  padding-left: 8px;
`;
const tableRowPlaceYouClass = css`
  padding-left: 16px;
`;
const tableRowItemPrizeClass = css`
  width: 142px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
`;
const tableRowActiveBorderClass = css`
  &:before {
    content: '';
    position: absolute;
    height: 75px;
    right: 99.8%;
    top: 0;
    border-left: 7px solid #ffaa63;
  }
`;
