import { css, cx } from '@emotion/css';
import { useContestT } from 'app/contest/glossary';
import { stdBp } from 'packs/std';
import { ContestUpon } from 'domain/contest';
import { ReactComponent as CrushIcon } from 'app/contest/assets/crush-icon.svg';
import { ReactComponent as CaseBattleIcon } from 'app/contest/assets/case-battle-icon.svg';
import { ReactComponent as UpgradeIcon } from 'app/contest/assets/upgrade-icon.svg';
import { ReactNode } from 'react';
import { IntlSub } from 'packs/libs/intl/operator';
import { round2DP } from 'support/etc/round-decimal-places';

type ContestInsightGameHistoryTableProps = {
  data: {
    upon: ContestUpon;
    stake: number;
    profit: number;
  }[];
};

export const ContestInsightGameHistoryTable = ({
  data,
}: ContestInsightGameHistoryTableProps): JsxElement => {
  const t = useContestT();
  return (
    <div className={mainClass}>
      <div className={boxClass}>
        <span className={cx(headerClass, winClass)}>{t('insight.dialog.win-loss')}</span>
        <span className={cx(headerClass, winClass)}>{t('insight.dialog.input')}</span>
        <span className={cx(headerClass, widthClass)}>{t('insight.dialog.regimes')}</span>
      </div>
      <div className={listClass}>{...data.map((item) => <Row item={item} t={t} />)}</div>
    </div>
  );
};

type RowProps = {
  item: {
    upon: ContestUpon;
    stake: number;
    profit: number;
  };
  t: IntlSub;
};

const Row = ({ item, t }: RowProps): JsxElement => {
  const { profit, stake, upon } = item;

  const diff = profit === 0 ? -stake : profit;

  return (
    <>
      <div className={boxClass}>
        <span className={cx(textClass, winClass, diff > 0 ? winTextColor : lossTextColor)}>
          ${round2DP(diff)}
        </span>
        <span className={cx(textClass, winClass)}>${stake}</span>
        <span className={cx(textClass, regimeClass)}>
          {ICONS.get(upon)}
          {t(`card.upon.${upon}`)}
        </span>
      </div>
    </>
  );
};

const ICONS = new Map<ContestUpon, ReactNode>([
  [ContestUpon.crush, <CrushIcon />],
  [ContestUpon.caseBattle, <CaseBattleIcon />],
  [ContestUpon.upgrade, <UpgradeIcon />],
]);

const mainClass = css`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  overflow: hidden;
`;

const boxClass = css`
  background: #18171c;
  border-radius: 12px;
  padding: 18px 24px;
  display: flex;
  align-items: center;
`;

const headerClass = css`
  font-family: 'Commissioner';
  font-weight: 700;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #696155;
`;

const winClass = css`
  width: 40%;
`;

const widthClass = css`
  width: 20%;
`;

const regimeClass = css`
  ${widthClass};
  display: flex;
  align-items: center;
  gap: 6px;

  > svg {
    width: 16px;
    height: 16px;
  }
`;

const textClass = css`
  font-family: 'Onest';
  font-weight: 700;
  font-size: 13px;
  line-height: 140%;
  color: #988c92;
`;

const winTextColor = css`
  color: #0fac7f;
  display: flex;
  align-items: center;

  &:after {
    content: '';
    position: relative;
    bottom: 1px;
    left: 4px;
    border-width: 0 7px 7px 7px;
    border-radius: 2px;
    border-style: solid;
    border-color: transparent transparent #0fac7f transparent;
  }
`;

const lossTextColor = css`
  color: #ff5a5a;
  display: flex;
  align-items: center;

  &:after {
    content: '';
    position: relative;
    top: 1px;
    left: 4px;
    border-width: 7px 7px 0 7px;
    border-radius: 2px;
    border-style: solid;
    border-color: #ff5a5a transparent transparent transparent;
  }
`;

const listClass = css`
  display: flex;
  flex-direction: column-reverse;
  gap: 8px;
  width: 100%;
  padding-bottom: 30px;
  max-height: 220px;

  ${stdBp.up(1440)} {
    max-height: 374px;
    padding-bottom: 20px;
  }
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 0;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: transparent;
  }
`;
