import { openSignInDialog } from 'app/sign-in/dialog';
import { useSignInT } from 'app/sign-in/glossary';
import { StdKindButton } from 'packs/std/inputs/kind-button';

type SignInButtonProps = {
  className?: string;
  disabled?: boolean;
};

export const SignInButton = ({ className, disabled }: SignInButtonProps) => {
  const t = useSignInT();

  return (
    <StdKindButton disabled={disabled} className={className} onClick={openSignInDialog}>
      {t('sign-in-steam')}
    </StdKindButton>
  );
};
