import { css, cx } from '@emotion/css';
import { CartIcon } from 'app/inventory/assets/cart';
import { openInventoryBuySkinsDialog } from 'app/inventory/buy-skins';
import { useInventoryT } from 'app/inventory/glossary';
import { getInventoryOperator, useInventoryState } from 'app/inventory/store';
import { useContestLiveState } from 'domain/contest/live';
import { stdBreakpoints } from 'packs/std';
import { useMemo, useState } from 'react';

export const InventorySkinsActionPanel = (): JsxElement => {
  const t = useInventoryT().sub('buttons');
  const { selectedCount } = useInventoryState();
  const contest = useContestLiveState();

  const noItemsSelected = selectedCount === 0;

  return useMemo(() => {
    const op = getInventoryOperator();
    return (
      <div className={mainClass}>
        <ActionButton
          onClick={openInventoryBuySkinsDialog}
          className={buySkinClass}
          title={t('buy-skins')}
          icon={<CartIcon className={iconClass} />}
        />
        {!contest.active && (
          <SubmitButton
            title={t('withdraw-skins')}
            disabled={noItemsSelected}
            submit={op.withdrawSelected}
          />
        )}
        <SubmitButton
          title={t('exchange-skins')}
          disabled={noItemsSelected}
          submit={op.exchangeSelected}
        />
      </div>
    );
  }, [t, noItemsSelected, contest.active]);
};

type SubmitButtonProps = {
  title: string;
  disabled?: boolean;
  submit(): Promise<void>;
};

const SubmitButton = ({ title, disabled, submit }: SubmitButtonProps): JsxElement => {
  const [processing, setProcessing] = useState(false);
  const onClick = async () => {
    try {
      setProcessing(true);
      await submit();
    } finally {
      setProcessing(false);
    }
  };

  return <ActionButton title={title} onClick={onClick} disabled={disabled || processing} />;
};

type ActionButtonProps = {
  className?: string;
  title: string;
  icon?: JsxElement;
  onClick?(): void;
  disabled?: boolean;
};

const ActionButton = ({
  className,
  title,
  icon,
  onClick,
  disabled,
}: ActionButtonProps): JsxElement => {
  return (
    <button className={cx(actionButtonClass, className)} onClick={onClick} disabled={disabled}>
      {icon}
      {title}
    </button>
  );
};

const mainClass = css`
  display: grid;
  grid-template-columns: 1fr 0.8fr 1fr;
  grid-auto-rows: 34px;
  grid-gap: 14px;
  margin: 0 18px 6px;
  max-width: 395px;

  ${stdBreakpoints.between(1280, 1440)} {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 8px;
    margin: 6px 12px 0;
  }
`;

const actionButtonClass = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 9px;
  background: #26232d;
  border-radius: 4px;
  border: 0;
  cursor: pointer;
  padding: 10px 8px;

  font-family: 'Onest';
  font-weight: 700;
  font-size: 13px;
  color: #988c92;
  max-width: 110px;
  margin-bottom: 18px;
  &:hover {
    background: linear-gradient(180deg, #ffaa63 0%, #ffaa63 0.01%, #ea9a4e 100%);
    color: #18171c;
    path {
      fill: #18171c;
    }
  }
  height: 38px;

  &:first-child {
    max-width: 130px;
  }

  ${stdBreakpoints.between(1280, 1440)} {
    margin-bottom: 0px;
    min-width: 100%;
  }
`;

const buySkinClass = css`
  ${stdBreakpoints.between(1280, 1440)} {
    grid-area: 1 / 1 / 2 / 3;
  }
`;

const iconClass = css`
  width: 14px;
  height: 14px;
`;
