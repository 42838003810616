import { css, cx } from '@emotion/css';
import { LayoutFooterLinks } from 'app/layout/footer/links';
import { LayoutFooterBrands } from 'app/layout/footer/brands';
import { ReactComponent as ChatIcon } from 'app/layout/footer/assets/chat-icon.svg';
import { openZendeskWidget } from 'packs/single/zendesk';
import { useLayoutT } from '../glossary';

export const LayoutFooter = (): JsxElement => {
  const t = useLayoutT().sub('footer');

  return (
    <div className={footerBoxClass}>
      <div className={buttonBoxClass}>
        <button className={buttonClass} onClick={openZendeskWidget}>
          <ChatIcon />
          {t('chat')}
        </button>
      </div>
      <LayoutFooterLinks />
      <LayoutFooterBrands />
    </div>
  );
};

const footerBoxClass = css`
  width: 100%;
  display: flex;
  height: fit-content;
  padding-right: 18px;
  padding-bottom: 18px;
  justify-content: space-between;
  align-items: center;
`;

const buttonBoxClass = css`
  padding-right: 20px;
`;

const buttonClass = css`
  background: #26232d;
  border: none;
  border-radius: 12px;
  padding: 9px 9px 8px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  cursor: pointer;
  font-family: 'Onest';
  font-weight: 700;
  font-size: 13px;
  line-height: 140%;
  color: #dfdfdf;

  &:hover {
    background: #2a2930;
  }
`;
