import { check_should_ignore_contest_dependent_event } from 'domain/contest/fns/should-ignore-event';
import { coilListenR, coilReq } from 'packs/libs/coil';
import { EffectCallback, createContext, createElement, useContext, useEffect } from 'react';
import { round2DP } from 'support/etc/round-decimal-places';
import { useBasicStore } from 'support/react/basic-store';

type PlayerBalanceState = number;

const Context = createContext<PlayerBalanceState>(null!);
if (__DEV__) Context.displayName = 'PlayerBalanceContext';

export const usePlayerBalance = () => useContext(Context);

type Operator = {
  reload(): void;
  get(): number;
  set(val: number): void;
  add(val: number): void;
};

let globalOperator: Operator;

export const getPlayerBalanceOperator = () => globalOperator;

export function PlayerBalanceProvider({ children }: Rcp) {
  const [value, effect] = useBasicStore<PlayerBalanceState, EffectCallback>(() => [
    null!,
    (setState, getState) => {
      const set = (val: number) => {
        if (getState() !== val) setState(val);
      };

      const reload = () => {
        coilReq({
          action: 'balance.load',
        });
      };

      const add = (addend: number) => {
        setState(getState() + addend);
      };

      globalOperator = {
        get: getState,
        set,
        add,
        reload,
      };

      return () =>
        coilListenR('b', (r) => {
          if (check_should_ignore_contest_dependent_event(r)) return;

          set(round2DP(r.float()));
        });
    },
  ]);

  useEffect(effect, []);

  return createElement(Context.Provider, { children, value });
}
