import { ContestInsightPage } from 'app/contest/insight/page';
import { ContestMonitorPreset } from 'app/contest/monitor';
import { ContestMonitorPage } from 'app/contest/monitor/page';
import { useActor } from 'domain/actor';
import { ReactNode } from 'react';
import { useParams } from 'react-router-dom';

const PreSet = new Set(Object.values(ContestMonitorPreset));
export const ContestJunction = (): JsxElement => {
  const { a, b } = useParams<{ a?: string; b?: string }>();

  if (a === 'b') return <ContestInsightPage type={'code'} code={b!} />;

  if (a === undefined || PreSet.has(a as any))
    return <ContestMonitorPage preset={a as ContestMonitorPreset} />;

  return (
    <SimpleActorResolveWaiter>
      <ContestInsightPage type={'id'} code={a} />
    </SimpleActorResolveWaiter>
  );
};

type SimpleActorResolveWaiterProps = {
  children: ReactNode;
};

const SimpleActorResolveWaiter = (props: SimpleActorResolveWaiterProps): JsxElement => {
  return useActor().resolved ? <>{props.children}</> : null;
};
