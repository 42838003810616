/**
 * if <0 -> a
 * if =0 -> a
 * if >0 -> b
 */
export function getMost<T>(sort: (a: T, b: T) => number, list: T[]): T {
  const it = list.values();
  const first = it.next();
  if (first.done) throw new Error('list is empty');
  let most: T = first.value;
  for (const item of it) {
    if (sort(item, most) > 0) most = item;
  }
  return most;
}

export function getMostIndex<T>(sort: (a: T, b: T) => number, list: T[]): number {
  const it = list.entries();
  const first = it.next();
  if (first.done) throw new Error('list is empty');
  let [index, most] = first.value;
  for (const [i, item] of it) {
    if (sort(item, most) > 0) {
      most = item;
      index = i;
    }
  }
  return index;
}

type Sort<T> = (a: T, b: T) => number | boolean;

export const pipeMost = <T>(list: T[], ...sorts: Sort<T>[]): T => {
  const it = list.values();
  const first = it.next();
  if (first.done) throw new Error('list is empty');
  let most: T = first.value;
  for (const item of it) {
    for (const sort of sorts) {
      const comparison = sort(item, most);
      if (comparison === 0) continue;
      else most = item;
    }
  }
  return most;
};
