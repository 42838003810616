import { css, cx } from '@emotion/css';
import { FC } from 'react';
import { ReactComponent as CheckMark } from 'app/inventory/assets/check-mark.svg';
interface Props {
  active?: boolean;
  onClick?: () => void;
}

export const InventoryHeaderCheckbox: FC<Props> = ({ active = false, onClick }) => {
  return (
    <div className={cx(checkboxClass, active && checkboxActiveClass)} onClick={onClick && onClick}>
      {active && <CheckMark className={checkMarkClass} />}
    </div>
  );
};

const checkboxClass = css`
  width: 14px;
  height: 14px;
  background: #2a2930;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const checkboxActiveClass = css`
  background: rgba(255, 170, 99, 0.1);
`;

const checkMarkClass = css`
  widht: 10px;
  height: 8px;
`;
