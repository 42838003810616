import { CaseBattleData, CaseBattlePlayerRole } from 'app/case/battle/definitions';
import { Actor } from 'domain/actor';

export const getCaseBattleActorRole = (
  battle: CaseBattleData,
  actor: Actor
): CaseBattlePlayerRole => {
  if (actor.anon) return CaseBattlePlayerRole.observer;
  const id = actor.id;
  for (const member of battle.members) {
    if (member.id === id) {
      if (member.author) return CaseBattlePlayerRole.author;
      return CaseBattlePlayerRole.member;
    }
  }
  return CaseBattlePlayerRole.observer;
};
