import { css } from '@emotion/css';
import { ContestMonitorCardProps } from 'app/contest/monitor/card';
import { ContestBriefData } from 'domain/contest';
import { ComponentType, ReactNode } from 'react';

type ContestListProps = {
  Card: ComponentType<ContestMonitorCardProps>;
  data: ContestBriefData[];
  title?: ReactNode;
};

export const ContestMonitorList = ({ Card, data, title }: ContestListProps): JsxElement => {
  if (data.length === 0) return null;
  return (
    <div className={mainClass}>
      <div className={contentClass}>
        {title}
        <div className={gridClass}>
          {data.map((contest) => (
            <div className={cardAreaClass} key={contest.id}>
              <div className={cardBoxClass}>
                <Card contest={contest} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const mainClass = css`
  background: #1e1c22;
  border-radius: 12px;
`;

const contentClass = css`
  padding: 24px 32px 24px 24px;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

/* grid-template-columns: repeat(auto-fit, minmax(310px, 1fr)); */
const gridClass = css`
  display: grid;
  grid-template-columns: repeat(auto-fit, 310px);
  grid-template-rows: auto;
  grid-auto-flow: dense;
  grid-auto-rows: auto;
  gap: 24px;
`;

const cardAreaClass = css`
  display: flex;
  justify-content: center;
`;

const cardBoxClass = css`
  background: rgba(24, 23, 28, 1);
  width: 310px;
  border-radius: 12px;
`;

type MediaGridOpts = {
  box: {
    min: number;
    max: number;
  };
  area: {
    min: number;
    max: number;
  };
  gap: {
    x: number;
    y: number;
  };
};

const mediaGrid = ({ box, area, gap }: MediaGridOpts) => {
  return css`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(${box.min}px, ${box.max}px));
    grid-template-rows: repeat(auto-fit, minmax(${box.min}px, ${box.max}px));
    grid-auto-flow: dense;
    grid-auto-rows: minmax(${area.min}px, ${area.max}px);
    gap: ${gap.x}px ${gap.y}px;
  `;
};
