import { CrushSceneChickBlockUnitsAbstract } from 'app/crush/scene/chick/block/units/abstract';
import { VideoGraphChickElement } from 'app/crush/scene/chick/block/units/video-element';

export type GraphChickElementStatic = {
  create(code: string): GraphChickElement;
};

export type GraphChickElement = CrushSceneChickBlockUnitsAbstract;

type GraphicsBackend = 'webm';

type GraphicsFork<T> = Record<GraphicsBackend, T>;

const chickGraphicsFork = <T>({ webm }: GraphicsFork<T>): T => {
  return webm;
};

const ElementStatic = chickGraphicsFork<GraphChickElementStatic>({
  webm: VideoGraphChickElement,
});

export function makeGraphChickElement(code: string): GraphChickElement {
  return ElementStatic.create(code);
}
