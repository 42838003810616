import { Decimal } from 'decimal.js';
import { serverNow } from 'packs/libs/coil';
import { stashProto } from 'support/etc/stash';

export type GrowOptions = {
  period: number;
  add: number;
  exp: number;
  at: number;
  max: number;
};

export type GrowNum = Decimal & {
  final?: boolean;

  readonly num: number;
  readonly dp2n: number;
  readonly dp2: GrowNum;
  readonly selectors: Map<any, any>;
};
export type GrowSelector<T> = (x: GrowNum) => T;

// @ts-ignore
export const GrowNum: typeof GrowNum = Decimal.config({ precision: 30 });

stashProto<GrowNum>(GrowNum.prototype, {
  dp2() {
    return this.toDP(2);
  },
  dp2n() {
    return this.dp2.toNumber();
  },
  selectors() {
    return new Map<GrowSelector<any>, any>();
  },
});

stashProto<GrowNum>(GrowNum.prototype, {
  num() {
    return this.toNumber();
  },
});

type GrowListener = (x: GrowNum | undefined) => void;

export type Grow = {
  stopped: boolean;
  onGrow(listener: GrowListener): void;
  offGrow(listener: GrowListener): void;
  subGrow(listener: GrowListener): () => void;
  setFinal(x: number): void;
  setX(x: Decimal.Value): void;
  getX(): GrowNum;
  start(opts: GrowOptions);
};

export default function makeGrow(): Grow {
  return new GrowImpl();
}

export class GrowImpl implements Grow {
  private timer: any;
  private x: GrowNum;
  private next: number;
  public stopped: boolean;
  private ev = new Set<any>();

  start({ period, exp, add, at, max }: GrowOptions) {
    const tick = () => {
      const now = serverNow();
      while (this.next < now + 1) {
        this.next += period;
        this.x = this.x.add(add).pow(exp) as GrowNum;
      }
      if (this.x.lt(max)) {
        this.timer = setTimeout(tick, this.next - now);
      } else {
        this.x = GrowNum(max).toDP(2);
      }
      this.emitGrow();
    };

    this.x = GrowNum(1);
    this.next = at + period;
    tick();
  }

  private emitGrow() {
    for (const cb of this.ev) cb(this.x);
  }

  offGrow(listener: GrowListener): void {
    this.ev.delete(listener);
  }

  onGrow(listener: GrowListener): void {
    this.ev.add(listener);
  }

  subGrow(listener: GrowListener): () => void {
    this.onGrow(listener);
    return () => this.offGrow(listener);
  }

  setX(x: Decimal.Value): void {
    this.x = GrowNum(x);
    this.emitGrow();
  }

  getX(): GrowNum {
    return this.x;
  }

  setFinal(x: number): void {
    this.x = GrowNum(x);
    this.x.final = true;
    this.emitGrow();
    this.stopped = true;
    clearTimeout(this.timer);
  }
}

export function createGrowSelector<T>(selector: (x: GrowNum) => T): (x: GrowNum) => T {
  return (x: GrowNum) => {
    let value = x.selectors.get(selector);
    if (value === undefined) {
      value = selector(x);
      x.selectors.set(selector, value);
    }
    return value;
  };
}
