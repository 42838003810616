import { css, cx } from '@emotion/css';
import { ChatRoleBadge } from 'app/chat/role-badge';
import { useContestT } from 'app/contest/glossary';
import { ReactComponent as BagIcon } from 'app/contest/insight/assets/bag-dialog.svg';
import { ReactComponent as CardIcon } from 'app/contest/insight/assets/card-dialog.svg';
import { ReactComponent as PrizeIcon } from 'app/contest/insight/assets/prize-dialog.svg';
import { ContestTopListItem } from 'domain/contest';
import { PlayerImage } from 'domain/player/image/image';
import { ReactNode } from 'react';

type ContestInsightGameHistoryStatsProps = {
  prize: string;
  item: ContestTopListItem;
};

export const ContestInsightGameHistoryStats = ({
  item,
  prize,
}: ContestInsightGameHistoryStatsProps): JsxElement => {
  const t = useContestT().sub('insight.dialog');
  return (
    <div className={statsMainClass}>
      <div className={statsBoxClass}>
        <div className={userBoxClass}>
          <PlayerImage code={item.player.image} className={imageClass} />
          <div className={roleBoxClass}>
            <div className={roleClass}>
              <ChatRoleBadge role={'p'} />
            </div>
            <p className={nameClass}>{item.player.name}</p>
          </div>
        </div>
        <div className={infoBoxClass}>
          <StatsBlockItem title={t('input')} value={`$${item.stake}`} icon={<CardIcon />} />
          <StatsBlockItem title={t('profit')} value={`$${item.profit}`} icon={<BagIcon />} />
          <StatsBlockItem
            title={t('prize')}
            value={`$${prize}`}
            icon={<PrizeIcon />}
            className={redBorderClass}
          />
        </div>
      </div>
    </div>
  );
};

type StatsBlockItemProps = {
  title: string;
  value: string;
  icon: ReactNode;
  className?: string;
};

const StatsBlockItem = ({ title, value, icon, className }: StatsBlockItemProps): JsxElement => {
  return (
    <div className={infoClass}>
      <div className={cx(iconBoxClass, className)}>{icon}</div>
      <div className={infoBlockBoxClass}>
        <p className={infoValueClass}>{value}</p>
        <p className={infoTitleClass}>{title}</p>
      </div>
    </div>
  );
};

const statsMainClass = css`
  background: #18171c;
  border-radius: 12px;
`;

const statsBoxClass = css`
  padding: 19px 34px 19px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const userBoxClass = css`
  display: flex;
  gap: 14px;
  align-items: center;
`;

const imageClass = css`
  width: 48px;
  height: 48px;
  border-radius: 12px;
`;

const roleBoxClass = css`
  display: flex;
  flex-direction: column;
  gap: 6px;
  overflow: hidden;
`;

const roleClass = css`
  display: flex;
  justify-self: flex-start;
`;

const nameClass = css`
  font-family: 'Onest';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #ffffff;
  overflow: hidden;
  max-width: 150px;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const infoBoxClass = css`
  display: flex;
  gap: 18px;
`;

const infoClass = css`
  min-width: 120px;
  background: #1e1c22;
  border-radius: 8px;
  padding: 14px 24px 15px 14px;
  display: flex;
  align-items: center;
  jusify-content: center;
  gap: 9px;
`;

const iconBoxClass = css`
  width: 26px;
  height: 26px;
  background: #1e1c22;
  border-radius: 6px;
  border: 1px solid rgba(255, 170, 99, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const infoBlockBoxClass = css`
  display: flex;
  flex-direction: column;
`;

const infoValueClass = css`
  font-family: 'Onest';
  font-weight: 700;
  font-size: 13px;
  line-height: 120%;
  letter-spacing: -0.03em;
  color: #dfdfdf;
`;

const infoTitleClass = css`
  font-family: 'Commissioner';
  font-weight: 400;
  font-size: 11px;
  line-height: 130%;
  letter-spacing: -0.003em;
  color: #988c92;
`;

const redBorderClass = css`
  border: 1px solid rgba(255, 90, 90, 1);
`;
