import { css } from '@emotion/css';
import { ReactComponent as RoundsIcon } from './icons/rounds-icon.svg';
import { ReactComponent as TotalIcon } from './icons/total-icon.svg';
import { useCaseBattleInsightT } from 'app/case/battle/insight/glossary';
import {
  useCaseBattleInsightBaseState,
  useCaseBattleInsightLiveState,
} from 'app/case/battle/insight/store';
import { ComponentType, ReactNode } from 'react';

export const CaseBattleInsightHeaderStats = (): JsxElement => {
  const { cases, cost } = useCaseBattleInsightBaseState();
  const { round } = useCaseBattleInsightLiveState();
  const t = useCaseBattleInsightT().sub('game-header');

  const totalRounds = cases.length;
  return (
    <div className={mainBoxClass}>
      <Stat Icon={RoundsIcon} title={t('round')}>
        {round} / {totalRounds}
      </Stat>
      <Stat Icon={TotalIcon} title={t('total')}>
        ${cost}
      </Stat>
    </div>
  );
};

type StatProps = {
  Icon: ComponentType<{ className?: string }>;
  title: ReactNode;
  children: ReactNode;
};

const Stat = ({ Icon, title, children }: StatProps): JsxElement => {
  return (
    <div className={iconWrapperClass}>
      <Icon className={iconClass} />
      <div>
        <div className={textClass}>{title}</div>
        <div className={whiteTextClass}>{children}</div>
      </div>
    </div>
  );
};

const mainBoxClass = css`
  background: linear-gradient(180deg, #1f1d23 0%, rgba(30, 28, 34, 0.6) 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  align-items: center;
  height: 120px;
  width: 163px;
`;

const iconClass = css`
  width: 32px;
`;
const iconWrapperClass = css`
  display: flex;
  gap: 11px;
`;
const textClass = css`
  font-weight: 500;
  font-size: 13px;
  line-height: 140%;
  color: #988c92;
`;

const whiteTextClass = css`
  ${textClass};
  color: #dfdfdf;
`;
