import { css } from '@emotion/css';
import { ReactNode } from 'react';

import { ReactComponent as CloseIcon } from 'packs/libs/inf/assets/close.svg';
import { ReactComponent as ErrorIcon } from 'packs/libs/inf/assets/error.svg';
import { ReactComponent as InfoIcon } from 'packs/libs/inf/assets/info.svg';
import { ReactComponent as SuccessIcon } from 'packs/libs/inf/assets/success.svg';

const ICONS = new Map<string, ReactNode>([
  ['success', <SuccessIcon />],
  ['error', <ErrorIcon />],
  ['info', <InfoIcon />],
]);

type InfToastProps = {
  type: string;
  children: ReactNode;
};
export function InfToast({ type, children }: InfToastProps) {

  return (
    <div className={mainClass}>
      <div className={boxClass}>
        <div className={iconBoxClass}>
          <div className={iconClass}>
            {ICONS.get(type)}
          </div>
        </div>
        <div className={bodyClass}>
          <div className={contentClass}>{children}</div>
          <CloseIcon className={closeIconClass} />
        </div>
      </div>
    </div>
  );
}

const bodyClass = css`
  display: flex;
  width: 273px;
`;

const mainClass = css`
  width: 341px;
  height: 100%;
`;

const boxClass = css`
  background: #26232d;
  border-radius: 12px;
  width: 100%;
  height: 100%;
  padding: 10px;
  padding-bottom: 14px;
  padding-left: 19px;
  display: flex;
`;

const iconBoxClass = css`
  padding-right: 20px;
`;

const iconClass = css`
  width: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
`;

const contentClass = css`
  font-family: 'Commissioner';
  padding-top: 6px;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #988c92;
  flex: 1;
`;
const closeIconClass = css`
  width: 10px;
  height: fit-content;
`;
