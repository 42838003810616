import { stash_get } from 'support/etc/stash';
import { SimpleEventTarget } from 'support/struct/simple-event-target';

type Listener<T> = (value: T) => any;

export class SubSub<K, T> extends Map<K, SimpleEventTarget<T>> {
  on(key: K, listener: Listener<T>) {
    let list = this.get(key);
    if (list === undefined) {
      this.set(key, (list = new SimpleEventTarget<T>()));
    }
    list.add(listener);
  }

  off(key: K, listener: Listener<T>) {
    this.get(key)?.delete(listener);
  }

  emit(key: K, data: T) {
    const list = this.get(key);
    if (list !== undefined) {
      for (const listener of list) {
        listener(data);
      }
    }
  }

  @stash_get
  get sub() {
    return (key: K, listener: Listener<T>) => {
      this.on(key, listener);
      return () => this.off(key, listener);
    };
  }
}
