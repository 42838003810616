import { CaseData } from 'domain/case/definitions';

export const CASE_BATTLE_HOLD_AFTER_FINISH_DURATION = 1900;
export const CASE_BATTLE_ROUND_REEL_DURATION = 4500;
export const CASE_BATTLE_ROUND_DURATION =
  CASE_BATTLE_HOLD_AFTER_FINISH_DURATION + CASE_BATTLE_ROUND_REEL_DURATION;
export const CASE_BATTLE_RARE_CHANCE = 3;

export type CaseBattleMemberData = {
  id: string;
  name: string;
  image: string;
  author?: boolean;
  bot?: boolean;
};

export type CaseBattleData = {
  id: string;
  stage: CaseBattleStage;
  cost: number;
  size: number;
  members: CaseBattleMemberData[];
  cases: CaseData[];
  contestId?: string;
};

export enum CaseBattlePlayerRole {
  author,
  member,
  observer,
}

export enum CaseBattleStage {
  waiting,
  live,
  finished,
}

export enum CaseBattleResolveStatus {
  success,
  canceled,
  failed,
}

export enum CaseBattleAnimationStatusColor {
  default,
  win,
  lose,
  winHighest,
}

export type FinalResultObj = {
  dropColor: string | null;
  highestColor: string | null;
};
