import { InventoryItemData } from 'app/inventory';
import { InventoryGlossary } from 'app/inventory/glossary';
import { check_should_ignore_contest_dependent_event } from 'domain/contest/fns/should-ignore-event';
import { ItemData, ItemLockCode } from 'domain/item';
import { WithdrawalStage } from 'domain/item/withdrawal';
import { coilListenR, coilReq } from 'packs/libs/coil';
import { showIntlInfoToast, showIntlSuccessToast } from 'packs/libs/intl/toasts';
import { UReader } from 'packs/libs/uint-8';
import { EffectCallback, createContext, createElement, useContext, useEffect } from 'react';
import { objToMap } from 'support/etc/obj-to-map';
import { useBasicStore } from 'support/react/basic-store';
import { HashMap } from 'support/struct/hash-map';
import { IHashMap } from 'support/struct/i-hash-map';

type InvItem = InventoryItemData;

export type InvItemMap = IHashMap<string, InvItem>;
type RawItemMap = HashMap<string, InvItem>;

export type InventoryState = {
  items: InvItemMap;
  loading: boolean;
  total: number;
  count: number;
  hasItems: boolean;
  selectedItems: InvItem[];
  selectedTotal: number;
  selectedCount: number;
  hasSelectedItems: boolean;
};

type Operator = {
  addItem(item: InvItem): void;
  getSelectedItems(): InvItem[];
  getSelectedItemsIds(): string[];
  getItems(): InvItemMap;
  getState(): InventoryState;
  toggleItem(id: string): void;

  deselectItem(id: string): void;
  deselectAll(): void;

  updateItemById(id: string, upd: (item: InvItem) => InvItem): void;
  updateItemsByIds(ids: string[], upd: (item: InvItem) => InvItem): void;
  excludeItems(ids: string[]): void;
  selectAll(selected: boolean): void;
  unlockItem(id: string): Promise<void>;
  reload(): void;

  exchangeByIds(ids: string[]): Promise<void>;
  exchangeSelected(): Promise<void>;

  withdrawFailedById(id: string): Promise<void>;
  withdrawSelected(): Promise<void>;
};

const Context = createContext<InventoryState>(null!);
if (__DEBUG__) Context.displayName = 'InventoryContext';

export const useInventoryState = () => useContext(Context);

let globalOperator: Operator;

export const getInventoryOperator = () => globalOperator;
if (__DEBUG__) window['getInventoryOperator'] = getInventoryOperator;

export default function InventoryStoreProvider({ children }: Rcp) {
  const [value, effect] = useBasicStore<InventoryState, EffectCallback>(() => [
    {
      loading: true,
      items: IHashMap.empty(),
      total: 0,
      count: 0,
      hasItems: false,
      selectedTotal: 0,
      selectedCount: 0,
      hasSelectedItems: false,
      selectedItems: [],
    },
    (setState, getState) => {
      const getItems = () => getState().items;
      const getSelectedItems = () => [...getItems().values()].filter((i) => i.selected);

      const updState = (state: Partial<InventoryState>) => setState({ ...getState(), ...state });
      const setItems = (items: InvItemMap) => {
        let total = 0;
        let selectedTotal = 0;
        let selectedItems: InvItem[] = [];
        for (const item of items.values()) {
          total += item.cost;
          if (item.selected) {
            selectedItems.push(item);
            selectedTotal += item.cost;
          }
        }

        updState({
          items,
          total,
          count: items.size,
          hasItems: total !== 0,
          selectedTotal,
          selectedCount: selectedItems.length,
          selectedItems,
          hasSelectedItems: selectedTotal !== 0,
        });
      };

      const success = (code: string) => showIntlSuccessToast(InventoryGlossary, code);

      const applyItems = (cb: (items: InvItemMap) => InvItemMap) => setItems(cb(getItems()));
      const applyRawItems = (cb: (items: RawItemMap) => void) => setItems(getItems().apply(cb));

      const applyItemById = (id: string, upd: (item: InvItem) => InvItem) => {
        const map = getItems();
        const item = map.find(id);
        if (item !== undefined) setItems(map.set(id, upd(item)));
      };

      const updateItemsByIds = (ids: string[], upd: (item: InvItem) => InvItem) => {
        applyRawItems((map) => {
          for (const id of ids) {
            map.set(id, upd(map.get(id)));
          }
        });
      };

      function reload() {
        coilReq({
          action: 'item.load',
        });
      }

      const deselectItem = (id: string) => {
        applyItemById(id, (item) => ({ ...item, selected: false }));
      };
      // const deselectItems = (ids: string[]) => {
      //   updateItemsByIds(ids, (item) => ({ ...item, selected: false }));
      // };
      const deselectAll = () => {
        applyRawItems((map) => {
          for (const item of map.values()) {
            if (item.selected) {
              map.set(item.id, { ...item, selected: false });
            }
          }
        });
      };

      const excludeItem = (id: string) => {
        setItems(getItems().del(id));
      };
      const excludeItems = (ids: string[]) => {
        setItems(getItems().delMany(ids));
      };

      const exchangeByIds = async (ids: string[]) => {
        await coilReq({
          action: 'item.exchange',
          data: ids,
        });
        excludeItems(ids);
        success('exchange.success');
      };

      const exchangeSelected = async () => {
        const ids = getSelectedItemsIds();
        await exchangeByIds(ids);
      };

      const addItem = (item: InvItem) => {
        const map = new HashMap<string, InvItem>();
        map.set(item.id, item);
        for (const item of getItems().values()) {
          map.set(item.id, item);
        }
        setItems(IHashMap.fromHashMap(map));
      };

      const getSelectedItemsIds = () => getSelectedItems().map((v) => v.id);

      const withdrawFailedById = async (id: string) => {
        const [[, lock]] = await coilReq({
          action: 'withdrawal.apply',
          data: [id],
        });
        applyItemById(id, (item) => ({ ...item, lock, selected: false }));
        success('withdrawal.success');
      };

      const withdrawSelected = async () => {
        const ids = getSelectedItemsIds();
        const result = await coilReq({
          action: 'withdrawal.apply',
          data: ids,
        });
        applyRawItems((map) => {
          for (const [id, lock] of result) {
            const item = map.find(id);
            if (item) map.set(id, { ...item, lock, selected: false });
          }
        });
        success('withdrawal.success');
      };

      const unlockItem = async (id: string) => {
        await coilReq({ action: 'item.unlock', data: id });
        applyItemById(id, ({ lock, ...data }) => data);
      };

      globalOperator = {
        getState,
        getSelectedItems,
        getSelectedItemsIds: () => getSelectedItems().map((v) => v.id),
        reload,
        getItems,
        addItem,

        toggleItem: (id: string) => {
          applyItemById(id, (i) => ({ ...i, selected: !i.selected }));
        },

        updateItemById: applyItemById,
        updateItemsByIds,

        deselectItem,
        // deselectItems,
        deselectAll,

        excludeItems,

        unlockItem,

        selectAll: (selected: boolean) => {
          applyItems((items) =>
            IHashMap.fromEntries(
              items.listMapV((item) => [item.id, item.lock ? item : { ...item, selected }])
            )
          );
        },

        exchangeByIds,
        exchangeSelected,

        withdrawFailedById,
        withdrawSelected,
      };

      if (__DEV__) {
        window['inventoryOperator'] = globalOperator;
      }

      // const silent = [ITEM_STATUS_TRADE_SENT];

      const listeners = objToMap<(r: UReader) => void>({
        release: () => {
          throw 'not implemented';
          // updateItemsByIds
        },

        upd(r) {
          applyRawItems((map) => {
            while (!r.complete) {
              map.applyOne(r.id(), (item) => ({ ...item, ...r.pack() }));
            }
          });
        },

        add(r) {
          applyItems((already) => {
            const map = new HashMap<string, InvItem>();
            for (const added of readManyItems(r)) map.set(added.id, added);
            for (const item of already.values()) map.set(item.id, item);
            return IHashMap.fromHashMap(map);
          });
        },

        del(r) {
          const ids: string[] = [];
          while (!r.complete) ids.push(r.id());
          excludeItems(ids);
        },

        all(r) {
          const items = readManyItems(r);
          setItems(IHashMap.fromEntries(items.map((item) => [item.id, item])));
          if (getState().loading) updState({ loading: false });
        },

        withdrawal: (r) => {
          const [itemId, stage, data] = r.pack();
          const item = getItems().find(itemId);
          if (!item) return console.log(`item ${itemId} not found`);
          showIntlInfoToast(InventoryGlossary, 'withdrawal.progress', {
            skin: item.skin.id,
            stage: InventoryGlossary.t(`lock.codes.wd.${stage}`),
          });

          if (stage === WithdrawalStage.completed) {
            excludeItem(itemId);
          } else {
            applyItemById(itemId, (item) => ({
              ...item,
              lock: { code: `wd.${stage}` as ItemLockCode, ...data },
            }));
          }
        },
      });

      return () =>
        coilListenR('item', (r) => {
          if (check_should_ignore_contest_dependent_event(r)) return;
          listeners.get(r.id())!(r);
        });
    },
  ]);

  useEffect(effect, []);

  return createElement(Context.Provider, { value, children });
}

const readOneSkin = (r: UReader) => {
  return {
    id: r.string(),
    image: r.string(),
    color: [r.uint8(), r.uint8(), r.uint8()] as RgbTuple,
  };
};

const readOneItem = (r: UReader) => {
  const item: ItemData = {
    id: r.id(),
    cost: r.double(),
    skin: readOneSkin(r),
  };
  if (r.bool()) item.lock = r.pack();
  if (r.bool()) item.meta = r.pack();
  return item;
};

const readManyItems = (r: UReader) => {
  const result: ItemData[] = [];
  while (!r.complete) result.push(readOneItem(r));
  return result;
};
