import bezier from 'bezier-easing';
import memoize from 'lodash/memoize';
import { roundDP } from 'support/etc/round-decimal-places';

const period = 10;
const duration = 1e3;
const calc = bezier(0.3, 0.0, 0.1, 1.0);
// const calc = bezier( 0.45, 0.10, 0.30, 1.00);

const makeAccelerator = memoize((steps: number) => {
  const eases: number[] = [];
  for (let i = 1; i <= steps; i++) {
    eases.push(calc(i / steps));
  }
  return function* accelerator(from: number, to: number) {
    const diff = to - from;
    for (const ease of eases) {
      yield from + diff * ease;
    }
  };
});

type Opts = {
  onUpdate(v: number): void;
  // duration: number
  // period: number
  decimals: number;
};

export function graduator({ onUpdate, decimals }: Opts) {
  const steps = Math.ceil(duration / period);
  const accelerator = makeAccelerator(steps);

  let timer: any = null;
  let current: number | null = null;
  let normalized: number | null = null;
  let generator: null | Generator<number> = null;

  const round = decimals === 0 ? Math.round : roundDP(decimals);
  function setCurrent(value: number) {
    current = value;
    const nextNormalized = round(value);
    if (normalized !== nextNormalized) {
      normalized = nextNormalized;
      onUpdate(normalized);
    }
  }

  function reset(value: number) {
    clearTimeout(timer as any);
    timer = null;
    generator = null;
    setCurrent(value);
  }

  const flush = () => {
    onUpdate(normalized!);
  };

  function update(value: number) {
    generator = accelerator(current!, value);
    if (timer === null) {
      timer = setInterval(() => {
        const it = generator!.next();
        if (it.done) {
          clearTimeout(timer);
          timer = null;
          generator = null;
        } else {
          setCurrent(it.value);
        }
      }, period);
    }
  }

  return {
    update,
    reset,
    flush,
  };
}
