import { css } from '@emotion/css';
import { Duration } from 'luxon';
import { ServerTime, serverNow } from 'packs/libs/coil';
import { ReactNode, useEffect, useState } from 'react';

type ContestCardCountdownProps = {
  title?: ReactNode;
  finish: number;
};

type TimeObject = {
  h: string;
  m: string;
  s: string;
};

export const ContestCardCountdown = ({ finish, title }: ContestCardCountdownProps): JsxElement => {
  const initial: TimeObject = { h: '00', m: '00', s: '00' };
  const [timerObject, setTimerObject] = useState(initial);
  const UPDATE_TIME = 1000;

  useEffect(() => {
    if (!finish) {
      setTimerObject(initial);
      return;
    }

    const finishServerTime = new ServerTime(finish).ms;

    let timer;
    const update = () => {
      const duration = finishServerTime - serverNow();

      if (duration <= 0) {
        setTimerObject(initial);
        clearInterval(timer);
      } else {
        setTimerObject(getFormattedTimeObject(duration));
      }
    };

    update();
    timer = setInterval(update, UPDATE_TIME);

    return () => clearTimeout(timer);
  }, [finish]);

  return (
    <div className={containteClass}>
      {!!title && <p className={titleClass}>{title}</p>}
      <div className={mainClass}>
        <div className={timerItemClass}>{timerObject.h}</div>
        <div className={timerDividerClass}>:</div>
        <div className={timerItemClass}>{timerObject.m}</div>
        <div className={timerDividerClass}>:</div>
        <div className={lastTimerItemClass}>{timerObject.s}</div>
      </div>
    </div>
  );
};

const getFormattedTimeObject = (ms: number): TimeObject => {
  const duration = Duration.fromMillis(ms)
    .shiftTo('hours', 'minutes', 'seconds', 'milliseconds')
    .toObject();

  const addPad = (val?: number) => String(val).padStart(2, '0'); // 00, 01, 02...

  return {
    h: addPad(duration.hours),
    m: addPad(duration.minutes),
    s: addPad(duration.seconds),
  };
};

const containteClass = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
`;

const titleClass = css`
  font-family: 'Commissioner';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 140%;
  color: #dfdfdf;
  padding-left: 3px;
  white-space: nowrap;
  display: flex;
  gap: 6px;
  align-items: center;
`;

const mainClass = css`
  display: flex;
  gap: 2.5px;
  align-items: center;
`;

const timerItemClass = css`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #18171c;
  border: 1.77778px solid #26232d;
  border-radius: 7.11111px;
  font-family: 'Commissioner';
  font-weight: 600;
  font-size: 14px;
  line-height: 130%;
  color: #988c92;
  padding: 3.8px 4.7px;
  width: 28px;
`;

const lastTimerItemClass = css`
  ${timerItemClass};
  color: #dfdfdf;
`;

const timerDividerClass = css`
  font-family: 'Commissioner';
  font-weight: 600;
  font-size: 12.7725px;
  line-height: 130%;
  color: #dfdfdf;
  opacity: 0.3;
`;
