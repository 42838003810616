export enum CrushBetStatus {
  pending = 'p',
  won = 'w',
  crashed = 'c',
}

export enum CrushRoundStage {
  countdown = 'a',
  growth = 'b',
  crash = 'c',
}

export enum CrushMode {
  normal = 'normal',
  extreme = 'extreme',
}

export enum CrushModeCode {
  normal,
  extreme,
}
