import { css } from '@emotion/css';

import { LayoutHeaderLogoPanel } from 'app/layout/header/logo-panel/logo-panel';
import { LayoutHeaderAccountPanel } from 'app/layout/header/account-panel/account-panel';
import { LayoutHeaderNavPanel } from 'app/layout/header/nav-panel/nav-panel';
import { ChatHeader } from 'app/chat/header';
import { linearClamp } from 'support/polished/linear-clamp';

export const LayoutHeader = (): JsxElement => {
  return (
    <div className={headerBoxClass}>
      <LayoutHeaderLogoPanel />
      <div className={accountNavClass}>
        <LayoutHeaderNavPanel />
        <LayoutHeaderAccountPanel />
      </div>
      <ChatHeader />
    </div>
  );
};

const headerBoxClass = css`
  display: flex;
  height: 62px;
  gap: 2px;
`;

const accountNavClass = css`
  flex-grow: 1;
  background: #1e1d22;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 19px;
`;
