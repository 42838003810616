import styled from '@emotion/styled';
import { createGlossary, getIntl } from 'packs/libs/intl';
import { logEmergency } from 'packs/libs/logger';
import React from 'react';
import { formatError } from 'support/etc/format-error';
import { strGenStdBase64 } from 'support/etc/str-gen';

const Glossary = createGlossary({
  en: { title: 'The Cock is Dead', token: 'your token' },
  es: { title: 'Кура Сдохла', token: 'твой токен' },
  ru: { title: 'La gallina ha muerto', token: 'Tu tóken' },
});

// Blue Screen of Death
export class Bsod extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    const token = strGenStdBase64(32);
    const [, ...componentStack] = info.componentStack.split('\n    at ');
    logEmergency({
      error: formatError(error),
      stack: componentStack,
      code: 'bsod',
      token,
    });
    this.setState({ token });
    if (__DEBUG__) {
      import('js-yaml').then((m) => {
        this.setState({
          data: m.dump({
            error: formatError(error),
            component: componentStack,
          }),
        });
      });
    }
  }

  render() {
    const t = getIntl().get(Glossary);

    if (this.state.hasError) {
      if (__DEBUG__) {
        return (
          <div
            style={{
              width: '100vw',
              height: '100vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              overflow: 'auto',
            }}
          >
            <pre>{this.state.data}</pre>
          </div>
        );
      } else {
        return (
          <ContainerDiv>
            <WindowDiv>
              <h3>{t('title')}</h3>
              <TokenTitleDiv>{t('token')}:</TokenTitleDiv>
              <TokenDiv>{this.state.token}</TokenDiv>
            </WindowDiv>
          </ContainerDiv>
        );
      }
    }

    return this.props.children;
  }
}

const ContainerDiv = styled.div`
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100vw;
`;

const WindowDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const TokenTitleDiv = styled.div`
  padding: 8px;
`;

const TokenDiv = styled.div`
  border-radius: 3px;
  display: flex;
  justify-content: center;
  padding: 6px;
`;
