import { css } from '@emotion/css';
import { useContestLiveT } from 'domain/contest/live/glossary';
import { ReactComponent as RegimeIcon } from '../assets/regime.svg';

export const LayoutHeaderAccountPanelContestMode = (): JsxElement => {
  const t = useContestLiveT().sub('mode');
  return (
    <div className={mainClass}>
      <div className={headerClass}>
        <RegimeIcon />
        <p className={regimeClass}>{t('title')}</p>
      </div>
      <p className={footerClass}>{t('about')}</p>
    </div>
  );
};

const mainClass = css`
  display: flex;
  flex-direction: column;
  jusify-content: center;
`;

const headerClass = css`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const regimeClass = css`
  color: #dfdfdf;
  font-size: 13px;
  font-family: Onest;
  font-weight: 700;
  line-height: 140%;
`;

const footerClass = css`
  color: #988c92;
  font-size: 12px;
  font-family: Commissioner;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
`;
