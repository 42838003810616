import { createSoundGroup } from 'packs/libs/sound';

import NUMBER_OF_PLAYERS_URL from 'app/case/battle/assets/sounds/number-of-players.mp3';
import CLICK_ON_CASE_WHILE_CREATING_URL from 'app/case/battle/assets/sounds/click-on-case-while-creating.mp3';
import CREATE_BATTLE_URL from 'app/case/battle/assets/sounds/create-battle.mp3';

export const CaseBattleCreationSoundGroup = createSoundGroup();

const createSound = (url: string) => CaseBattleCreationSoundGroup.createSound(url);
export const CaseBattleCreationNumberOfPlayersSound = createSound(NUMBER_OF_PLAYERS_URL);
export const CaseBattleCreationClickOnCaseWhileCreatingSound = createSound(CLICK_ON_CASE_WHILE_CREATING_URL);
export const CaseBattleCreationCreateBattleSound = createSound(CREATE_BATTLE_URL);
