import { css, cx } from '@emotion/css';
import TouchRipple from '@mui/material/ButtonBase/TouchRipple';
import * as React from 'react';
import { ButtonHTMLAttributes, ReactNode, createElement } from 'react';

import { Sound } from 'packs/libs/sound';
import { useSingleton } from 'support/react/use-singleton';

export type StdButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  sound?: Sound;
  buttonRef?: React.Ref<HTMLButtonElement>;
};

export function StdButton(props: StdButtonProps): JsxElement {
  type Store = { props: StdButtonProps; base: StdButtonProps; ripple: ReactNode };
  const op = useSingleton<Store>(() => {
    const base = {
      sound: undefined,
      onMouseDown: (e) => {
        rippleOp?.start(e);
        store.props.onMouseDown?.(e);
      },
      onMouseUp: (e) => {
        rippleOp?.stop(e);
        store.props.onMouseUp?.(e);
      },
      onTouchStart: (e) => {
        rippleOp?.start(e);
        store.props.onTouchStart?.(e);
      },
      onTouchEnd: (e) => {
        rippleOp?.stop(e);
        store.props.onTouchEnd?.(e);
      },
      onMouseLeave: (e) => {
        rippleOp?.stop(e);
        store.props.onMouseLeave?.(e);
      },
      onDragLeave: (e) => {
        rippleOp?.stop(e);
        store.props.onDragLeave?.(e);
      },
      onClick: (e) => {
        store.props.onClick?.(e);
        store.props.sound?.replay();
      },
    };

    let rippleOp: any;
    const ripple = (
      <TouchRipple
        className={rippleClass}
        ref={(op) => {
          rippleOp = op;
        }}
        center={false}
      />
    );

    const store: Store = { props: null!, base, ripple };

    return store;
  });

  op.props = props;

  return createElement(
    'button',
    { ...props, ...op.base, className: cx(mainClass, props.className), ref: props.buttonRef },
    props.children,
    op.ripple
  );
}

export const classifiedStdButton = (className: string) => {
  return function ClassifiedStdButton(props: StdButtonProps) {
    return createElement(StdButton, {
      ...props,
      className: cx(className, props.className),
    });
  };
};

const rippleClass = css`
  color: #fff;
`;

const mainClass = css`
  border: 0;
  outline: 0;
  cursor: pointer;
  position: relative;
  display: inline-flex;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  background: transparent;
`;

export const stdButtonStyles = css`
  outline: none;
  border: 0;
  justify-content: center;
  user-select: none;
  padding: 10px 20px;
  white-space: nowrap;
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 3px;
  transition: all 0.3s;
  overflow: hidden;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  height: 38px;
`;

const wrap = (cls: string) => `${stdButtonStyles} ${cls}`;

export const stdButtonGreenHollowTheme = wrap(css`
  background: transparent;
  color: #50ae30;
  border: 1px solid #50ae30;
`);

export const stdButtonGreenTheme = wrap(css`
  background: #50ae30;
`);

export const stdButtonGradientBlueTheme = wrap(css`
  background: linear-gradient(90deg, #4b69ff 0%, #1d4eff 100%);

  &.selected,
  &:hover:not(:disabled) {
    background: linear-gradient(90deg, #425ad5 0%, #163fd2 100%);
  }

  &:disabled {
    color: rgba(255, 255, 255, 0.3);
    cursor: not-allowed;
    pointer-events: none;

    background: linear-gradient(90deg, #4b69ff 0%, #1d4eff 100%);
  }
`);

export const stdButtonGreyTheme = wrap(css`
  background: #2c3053;
`);
