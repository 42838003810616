import { CrushStoreRound } from 'app/crush/store/round-state';
import { useCrushScopeRound } from 'app/crush/store/store';
import { useMemo } from 'react';
import { Swiss } from 'support/react/swiss';
import { useForceRefresh } from 'support/react/use-force-refresh';

export function useCrushStoreRoundExtraSwissState<T>(factory: () => T): [T, Swiss<T>] {
  const round = useCrushScopeRound();
  const commit = useForceRefresh();

  const store = useMemo(() => {
    const getState = () => store[0];
    const setState = (next) => {
      store = [next, operator];
      commit();
    };

    const operator = new Swiss(setState, getState);

    let store = [factory(), operator];

    let id = round.opts!.id;

    return {
      getStore: () => store,
      update(round: CrushStoreRound) {
        if (id !== round.opts!.id) {
          store = [factory(), operator];
        }
      },
    };
  }, []);

  store.update(round);

  return store.getStore();
}
