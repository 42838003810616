import { css } from '@emotion/css';
import { ChatBody } from 'app/chat/body';

import { useLayoutBlurRef } from 'app/layout/blur';
import { LayoutJunction } from 'app/layout/junction';
import { LayoutHeader } from './header/header';
import { LayoutSidebar } from './sidebar/sidebar';
import { LayoutStatsSocialMedia } from './stats/stats-social-media';

export const LayoutDesktop = (): JsxElement => {
  return (
    <div className={mainClass} ref={useLayoutBlurRef()}>
      <LayoutStatsSocialMedia />
      <LayoutHeader />
      <div className={contentClass}>
        <LayoutSidebar />
        <div className={centralClass}>
          <LayoutJunction />
        </div>
        <ChatBody />
      </div>
    </div>
  );
};

const mainClass = css`
  display: flex;
  height: 100vh;
  flex-direction: column;
  overflow: hidden;
`;

const contentClass = css`
  flex: 1;
  display: flex;
  overflow-y: hidden;
  position: relative;
`;

const centralClass = css`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
`;
