import { css, cx } from '@emotion/css';

import { PlayerRole } from 'domain/player/definitions';
import { ChatRoleText } from 'app/chat/definitions';

const displayedRolesColors = {
  [PlayerRole.admin]: 'linear-gradient(180deg, #FF5A5A 0%, #BE1010 100%)',
  [PlayerRole.blogger]: 'linear-gradient(180deg, #FF9457 0%, #FFBA69 100%)',
  [PlayerRole.moderator]: 'linear-gradient(180deg, #207E5C 0%, #005B3A 100%)',
  [PlayerRole.support]: 'linear-gradient(180deg, #988C92 0%, #4B4B4B 100%)',
  [PlayerRole.vip]: 'linear-gradient(180deg, #B439FF 0%, #7700C0 100%)',
};

type RoleBadgeProps = {
  role: string;
};

export function ChatRoleBadge({ role }: RoleBadgeProps) {
  const color = displayedRolesColors[role];
  if (!color) return null;

  return (
    <div
      className={cx(mainClass)}
      style={{ background: color }}
    >
      <div className={cx(boxClass, role === PlayerRole.blogger && bloggerTextClass)}>
        {ChatRoleText[role]}
      </div>
    </div>
  );
}

const mainClass = css`
  font-weight: 700;
  font-size: 10px;
  line-height: 130%;
  color: #dfdfdf;
  border-radius:4px;
  cursor: default;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const boxClass = css`
  padding: 0 4px 2px;
`;

const bloggerTextClass = css`
  color: #18171c;
  font-weight: 800;
`;