import { FC } from 'react';

interface Props {
  className?: string;
}

export const ComboDotsBig: FC<Props> = ({ className }) => (
  <svg
    className={className}
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.66675 16.6666C13.085 16.6666 16.6667 13.0849 16.6667 8.66663C16.6667 4.24835 13.085 0.666626 8.66675 0.666626C4.24847 0.666626 0.666748 4.24835 0.666748 8.66663C0.666748 13.0849 4.24847 16.6666 8.66675 16.6666ZM35.3334 43.3333C39.7517 43.3333 43.3334 39.7516 43.3334 35.3333C43.3334 30.915 39.7517 27.3333 35.3334 27.3333C30.9151 27.3333 27.3334 30.915 27.3334 35.3333C27.3334 39.7516 30.9151 43.3333 35.3334 43.3333ZM16.6667 35.3333C16.6667 39.7516 13.085 43.3333 8.66675 43.3333C4.24847 43.3333 0.666748 39.7516 0.666748 35.3333C0.666748 30.915 4.24847 27.3333 8.66675 27.3333C13.085 27.3333 16.6667 30.915 16.6667 35.3333ZM35.3334 16.6666C39.7517 16.6666 43.3334 13.0849 43.3334 8.66663C43.3334 4.24835 39.7517 0.666626 35.3334 0.666626C30.9151 0.666626 27.3334 4.24835 27.3334 8.66663C27.3334 13.0849 30.9151 16.6666 35.3334 16.6666Z"
      fill="#2A2930"
    />
  </svg>
);
