import { doubleLinkedList, DoubleLinkedPoint } from 'support/etc/dll';
import { stash_get } from 'support/etc/stash';

type Entry = [number, (point: ChickTimelinePoint) => () => void];

export function chickTimeline(...items: Entry[]) {
  const dll = doubleLinkedList(items);

  return (diff: number) => {
    let spot: DoubleLinkedPoint<Entry>;
    for (spot of dll.iterRev()) {
      if (spot.val[0] >= diff) break;
      diff -= spot.val[0];
    }

    let dispose;
    let timer;

    function rotate() {
      const point = new ChickTimelinePoint();
      point.duration = spot.val[0];
      point.left = diff;

      dispose = spot.val[1](point);
      timer = setTimeout(() => {
        spot = spot.next!;
        dispose();
        if (spot !== undefined) {
          diff = spot.val[0];
          rotate();
        }
      }, diff);
    }

    rotate();

    return () => {
      clearTimeout(timer);
      dispose?.();
    };
  };
}

export class ChickTimelinePoint {
  duration: number;
  left: number;

  @stash_get
  get passed(): number {
    return this.duration - this.left;
  }
}
