export const BalanceIcon = () => {
  return (
    <svg  viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.50012 4.5H16.5001V13.5C16.5001 15.1569 15.157 16.5 13.5001 16.5H4.50012C2.84327 16.5 1.50012 15.1569 1.50012 13.5V13.125C1.50018 13.125 1.50024 13.125 1.50029 13.125H4.50029C5.95004 13.125 7.12529 11.9497 7.12529 10.5C7.12529 9.05025 5.95004 7.875 4.50029 7.875L1.50029 7.875C1.50024 7.875 1.50018 7.875 1.50012 7.875V7.5C1.50012 5.84315 2.84327 4.5 4.50012 4.5Z"
        fill="url(#paint0_linear_208_1383)"
      />
      <path
        opacity="0.4"
        d="M8.99993 1.50012H13.4999C14.7589 1.50012 15.8368 2.27569 16.2819 3.37512H6.218C6.66304 2.27569 7.74091 1.50012 8.99993 1.50012Z"
        fill="url(#paint1_linear_208_1383)"
      />
      <path
        opacity="0.4"
        d="M1.50012 9L1.50012 12L4.50012 12C5.32855 12 6.00012 11.3284 6.00012 10.5C6.00012 9.67157 5.32855 9 4.50012 9L1.50012 9Z"
        fill="url(#paint2_linear_208_1383)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_208_1383"
          x1="9.00012"
          y1="4.5"
          x2="9.00012"
          y2="16.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFAA63" />
          <stop offset="1" stopColor="#EA9A4E" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_208_1383"
          x1="11.2499"
          y1="1.50012"
          x2="11.2499"
          y2="3.37512"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFAA63" />
          <stop offset="1" stopColor="#EA9A4E" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_208_1383"
          x1="1.50012"
          y1="10.5"
          x2="6.00012"
          y2="10.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFAA63" />
          <stop offset="1" stopColor="#EA9A4E" />
        </linearGradient>
      </defs>
    </svg>
  );
};
