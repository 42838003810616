import { CrushMode, CrushRoundStage } from 'domain/crush';
import { Grow, GrowOptions } from 'domain/crush/growth';

export type InnerRound = {
  id?: string;
  opts?: {
    id: string;
    hash: string;
    grow: GrowOptions;
  };
  stage?: CrushRoundStage;
  growth?: Grow;
  x?: number;
  won?: {
    x: number;
    item: {
      cost: number;
    };
  };
  stake?: {
    total: number;
    size: number;
  };
  crashAt?: number;
  resolved?: boolean;
};

export type CrushStoreRound = InnerRound & Selectors;

type Selectors = {
  readonly clashAt: number;
  readonly mode: CrushMode;
  readonly isPlaying: boolean;
};

export function crushStoreRoundMakeProtoFactory(mode: CrushMode) {
  class Class {
    [x: string]: any;

    get mode() {
      return mode;
    }

    get isPlaying() {
      return this.stake !== undefined;
    }

    get clashAt() {
      return this.opts?.grow.at;
    }
  }

  return (obj: InnerRound): CrushStoreRound => Object.assign(new Class(), obj);
}
