import { ContestCardCountdown } from 'app/contest/card/units/countdown';
import { ContestCardDoubleColumnRow } from 'app/contest/card/units/double-column-row';
import { ContestCardMainBox } from 'app/contest/card/units/main-box';
import { ContestCardPrize } from 'app/contest/card/units/prize';
import { useContestT } from 'app/contest/glossary';
import { ContestBriefData } from 'domain/contest';
import { ReactNode } from 'react';

type ContestCardActiveProps = {
  contest: ContestBriefData;
  children?: ReactNode;
};

export const ContestCardActive = ({ contest, children }: ContestCardActiveProps): JsxElement => {
  const finish = contest.start + contest.duration;

  const t = useContestT().sub('card');
  return (
    <ContestCardMainBox data={contest}>
      <ContestCardDoubleColumnRow>
        <ContestCardCountdown finish={finish} title={t('countdown.sum-up')} />
        <ContestCardPrize contest={contest} />
      </ContestCardDoubleColumnRow>
      {children}
    </ContestCardMainBox>
  );
};
