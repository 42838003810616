type F<T> = (color: RgbTuple) => T;
export const skinColorMemoize = <T>(factory: F<T>): F<T> => {
  const cache = new Map<string, T>();
  return (color) => {
    const key = color.join('.');
    let value = cache.get(key);
    if (value === undefined) {
      value = factory(color);
      cache.set(key, value);
    }
    return value;
  };
};
