import { css } from '@emotion/css';
import { ComponentType } from 'react';
import { Link } from 'react-router-dom';

import { useLayoutT } from 'app/layout/glossary';
import { getActor, useActor } from 'domain/actor';
import { LogoutIcon } from '../assets/logout-icon';
import { ProfileIcon } from '../assets/profile-icon';
import { ReferralIcon } from '../assets/referral-icon';

export const LayoutHeaderAccountPanelMenuPopup = (): JsxElement => {
  const actor = useActor();

  type Route = {
    code: string;
    to: string;
    Icon: ComponentType;
  };

  const ROUTES: Route[] = [
    { code: 'profile', to: `/player/${actor.id}`, Icon: ProfileIcon },
    { code: 'referral', to: `/player/${actor.id}/referral`, Icon: ReferralIcon },
  ];

  const t = useLayoutT().sub('navigation');

  return (
    <div className={menuBoxClass}>
      {...ROUTES.map(({ code, to, Icon }) => {
        return (
          <Link to={to}>
            <div className={menuItemClass}>
              <Icon />
              <p className={textClass}>{t(code)}</p>
            </div>
          </Link>
        );
      })}
      <div className={sepClass} />
      <div className={menuItemClass} onClick={() => getActor().logout()}>
        <LogoutIcon />
        <p className={textClass}>{t('logout')}</p>
      </div>
    </div>
  );
};

const sepClass = css`
  border-top: 1px solid #2a2930;
  height: 10px;
`;

const menuBoxClass = css`
  backdrop-filter: blur(16px);
  background: rgba(30, 28, 34, 0.8);

  width: 164px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 18px 14px 15px;
`;

const menuItemClass = css`
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
`;

const textClass = css`
  font-weight: 600;
  font-size: 12px;
  line-height: 130%;
  color: #dfdfdf;
  &:hover {
    background-image: linear-gradient(180deg, #ffaa63 0%, #ea9a4e 100%);
    background-clip: text;
    color: transparent;
  }
`;
