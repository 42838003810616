import { css } from '@emotion/css';
import { CSSProperties } from 'react';

// todo: create external script to generate grads out of runtime
export function crushRecentCreateGradientFrame(fgColor: number[], bgColor: number[]) {
  const mkRP = (rgb: number[], alpha: number, percent: number | string) =>
    `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${alpha}) ${percent}%`;

  const fgLine: string[] = [];

  const alphaPercentArray: [number, number][] = [
    [0, 0],
    [0, 54.89],
    [0.81, 100],
    [1, 100],
  ];

  for (const [alpha, percent] of alphaPercentArray) {
    fgLine.push(mkRP(fgColor, alpha, percent));
  }

  const toRadialGradient = (line) =>
    `radial-gradient(117.31% 287.38% at 0% 50%, ${line.join(', ')})`;

  const box1Class = css`
    background-image: ${toRadialGradient(fgLine)},
      linear-gradient(0deg, rgba(var(--bg-color), 0.15), rgba(var(--bg-color), 0.15));

    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    height: 100%;
    width: 100%;
    color: rgb(var(--bg-color));

    &:hover {
      color: #18171c;

      > div {
        background: rgb(var(--bg-color));

        :before {
          background: transparent;
        }
        :after {
          background-image: none;
        }
      }
    }
  `;

  const box2Class = css`
    height: calc(100% - 2px);
    width: calc(100% - 2px);
    overflow: hidden;
    background: #212020;
    border-radius: 6px;
    display: inline-flex;
    position: relative;

    &:before {
      background: var(--dark-blue-300);
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 1;
    }

    &:after {
      position: absolute;
      content: '';
      z-index: 2;
      background-image: linear-gradient(
        0deg,
        rgba(var(--bg-color), 0.15),
        rgba(var(--bg-color), 0.15)
      );
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  `;

  const box3Class = css`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0 8px;
    border-radius: 6px;
    z-index: 3;
  `;

  return function GradientFrame({ children }: Rcp) {
    return (
      <div
        className={box1Class}
        style={
          {
            '--bg-color': `${bgColor[0]}, ${bgColor[1]}, ${bgColor[2]}`,
          } as CSSProperties
        }
      >
        <div className={box2Class}>
          <div className={box3Class}>{children}</div>
        </div>
      </div>
    );
  };
}
