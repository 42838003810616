export const QuestsIcon = ({ active, current }) => {
  return active || current ? (
    <svg viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle
        opacity="0.4"
        cx="8"
        cy="8"
        r="7.5"
        transform="rotate(180 8 8)"
        fill="url(#paint0_linear_207_1984)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8763 0.831902C16.1072 1.03972 16.1259 1.39539 15.9181 1.6263L9.36493 8.8243C8.63402 9.63641 7.39661 9.73763 6.54345 9.0551L3.89863 6.93924C3.65604 6.74518 3.61671 6.3912 3.81078 6.14861C4.00485 5.90603 4.35883 5.8667 4.60141 6.06077L7.24623 8.17662C7.63403 8.48686 8.19649 8.44085 8.52872 8.07171L15.0819 0.873712C15.2897 0.6428 15.6454 0.624081 15.8763 0.831902Z"
        fill="url(#paint1_linear_207_1984)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_207_1984"
          x1="8"
          y1="0.5"
          x2="8"
          y2="15.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFAA63" />
          <stop offset="1" stopColor="#EA9A4E" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_207_1984"
          x1="9.87501"
          y1="0.6875"
          x2="9.87501"
          y2="9.50709"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFAA63" />
          <stop offset="1" stopColor="#EA9A4E" />
        </linearGradient>
      </defs>
    </svg>
  ) : (
    <svg  viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle opacity="0.4" cx="8" cy="8" r="7.5" transform="rotate(180 8 8)" fill="#988C92" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8763 0.831902C16.1072 1.03972 16.1259 1.39539 15.9181 1.6263L9.36493 8.8243C8.63402 9.63641 7.39661 9.73763 6.54345 9.0551L3.89863 6.93924C3.65604 6.74518 3.61671 6.3912 3.81078 6.14861C4.00485 5.90603 4.35883 5.8667 4.60141 6.06077L7.24623 8.17662C7.63403 8.48686 8.19649 8.44085 8.52872 8.07171L15.0819 0.873712C15.2897 0.6428 15.6454 0.624081 15.8763 0.831902Z"
        fill="#988C92"
      />
    </svg>
  );
};
