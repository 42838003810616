import { CrushBetData } from 'app/crush/store/definitions';
import { CrushBetStatus } from 'domain/crush/definitions';

const statusSequence = [CrushBetStatus.pending, CrushBetStatus.crashed];

export const crushCompareBets = (a: CrushBetData, b: CrushBetData) => {
  if (a.status === CrushBetStatus.won) {
    if (b.status === CrushBetStatus.won) return a.prize!.total - b.prize!.total;
    else return 1;
  }

  for (const status of statusSequence) {
    if (a.status === status) {
      if (b.status === status) return b.stake.total - a.stake.total;
      else return 1;
    } else if (b.status === status) return -1;
  }

  throw new Error('status_not_valid');
};
