export const VolatileMap: VolatileMapConstructor = Map;
type VolatileMapConstructor = {
  new (map?: VolatileMap | Map<any, any> | [any, any][]): VolatileMap;
};

export type VolatileMap = {
  get<T>(id: any): T;
  has(id: any): boolean;
  set(id: any, value: any): void;
  delete(id: any): void;
};
