import { css } from '@emotion/css';
import { Link } from 'react-router-dom';

import { ReactComponent as VisaIcon } from 'app/layout/footer/assets/visa-icon.svg';
import { ReactComponent as TrustpilotIcon } from 'app/layout/footer/assets/trustpilot-icon.svg';
import { ReactComponent as FlatonicaIcon } from 'app/layout/footer/assets/flatonica-icon.svg';

export const LayoutFooterBrands = (): JsxElement => {
  return (
    <div className={brandsBoxClass}>
      <div className={brandsLinksClass}>
        <VisaIcon className={visaIconClass} />
        <Link target="_blank" to={'https://www.trustpilot.com/review/csgocock.com'}>
          <TrustpilotIcon className={trustpilotIconClass} />
        </Link>
      </div>
      <Link
        className={flatonicaBoxClass}
        target="_blank"
        to={'https://flatonica.com/'}
      >
        Designed by
        <FlatonicaIcon className={flatonicaIconClass} />
      </Link>
    </div>
  );
};
const visaIconClass = css`
  height: 15px;
`;
const brandsBoxClass = css`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
`;
const flatonicaBoxClass = css`
  display: flex;
  align-items: center;
  gap: 3px;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  color: #696155;
`;
const trustpilotIconClass = css`
  height: 20px;
`;
const flatonicaIconClass = css`
  &:hover {
    path {
      fill: url(#flatonica_gradient);
    }
  }
  height: 15px;
`;

const brandsLinksClass = css`
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: center;
`;
