import { css, cx } from '@emotion/css';
import { ReactComponent as SelectedCheckmark } from 'app/inventory/assets/selected-checkmark.svg';
import { InventoryItemData } from 'app/inventory/definitions';
import { InventoryItemLock } from 'app/inventory/skins/lock/lock';
import { getInventoryOperator } from 'app/inventory/store';
import { skinNameViewOnSkin } from 'domain/skin/name-view';
import { SkinImg } from 'domain/skin/units/image';
import { CSSProperties, memo, useState } from 'react';
import { juxProp } from 'support/etc/juxtapose';
import { roundCash } from 'support/etc/round-cash';

interface InventorySkinsItemProps {
  data: InventoryItemData;
}

export const InventorySkinsItem = memo(function InventorySkinsItem({
  data,
}: InventorySkinsItemProps): JsxElement {
  const { lock, skin, selected } = data;
  const [hover, setHover] = useState<boolean>(false);
  const name = skinNameViewOnSkin(skin);

  return (
    <div
      className={cx(mainWrapClass, !lock && selectableClass)}
      style={{ '--l-color': skin.color.join(', ') } as CSSProperties}
      onClick={() => {
        if (!lock) getInventoryOperator().toggleItem(data.id);
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {hover ? (
        <>
          <div className={hoveredContainer}>
            <div className={brandClass}>
              {name.brand}
              <span className={modelClass}> {name.model}</span>
            </div>
            <div className={levelClass}>{name.level?.full}</div>
          </div>
        </>
      ) : (
        <>
          <div className={mainClass}>
            <div className={backgroundClass}></div>
            <SkinImg className={imageClass} code={skin.image} />
            <div className={priceClass}>${roundCash(data.cost, 2)}</div>
          </div>
        </>
      )}
      {lock ? (
        <InventoryItemLock data={data} />
      ) : (
        selected && (
          <div className={overlayClass}>
            <SelectedCheckmark className={selectedIconClass} />
          </div>
        )
      )}
    </div>
  );
},
juxProp('data'));

const mainWrapClass = css`
  user-select: none;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 78px;
  height: 78px;
  background: linear-gradient(135deg, rgb(0, 0, 0, 0) 60%, rgba(var(--l-color), 1) 100%);
  box-sizing: border-box;
  &:hover {
    background: linear-gradient(135deg, rgba(30, 28, 34, 0) 0%, rgba(var(--l-color), 0.2) 100%);
    border: 1px solid rgb(var(--l-color));
  }
`;

const selectableClass = css`
  cursor: pointer;
`;

const mainClass = css`
  color: white;
  width: 76px;
  height: 76px;
  background: #1e1c22;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const hoveredContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow: hidden;
`;
const brandClass = css`
  width: 100%;
  padding-left: 3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
  font-size: 11px;
  line-height: 130%;
  color: rgb(var(--l-color));
`;
const modelClass = css`
  font-weight: 600;
  color: #dfdfdf;
`;
const levelClass = css`
  font-weight: 400;
  font-size: 10px;
  line-height: 130%;
  letter-spacing: -0.003em;
  color: #988c92;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const backgroundClass = css`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(135deg, rgba(30, 28, 34, 0) 0%, rgba(var(--l-color), 0.2) 100%);
`;

const imageClass = css`
  width: 66px;
  height: 48px;
`;

const priceClass = css`
  padding-top: 8px;
  font-family: 'Onest';
  font-weight: 500;
  font-size: 11px;
  color: #dfdfdf;
`;

const overlayClass = css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.03) 0%, rgba(234, 154, 78, 0.3) 100%);
  backdrop-filter: blur(1px);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const selectedIconClass = css`
  width: 32px;
  height: 32px;
`;
