import { css } from '@emotion/css';
import { UpgradeUnitsWheelSpin, UpgradeWheelSpinOperator } from 'app/upgrade/units/wheel/spin';
import { UpgradeUnitsWheel, UpgradeWheelOperator } from 'app/upgrade/units/wheel/wheel';
import {
  UPGRADE_SPINNING_ANIMATION_DURATION,
  UPGRADE_SPINNING_ANIMATION_TURNS_COUNT,
} from 'app/upgrade/definitions';
import { getUpgradeOperator } from 'app/upgrade';
import { stdBreakpoints } from 'packs/std';

if (__DEV__) {
  window['upgrade_spinner_plays_forever'] = !!window['upgrade_spinner_plays_forever'];
}

export const UpgradeSpinner = (): JsxElement => {
  const { chance, offset, result } = getUpgradeOperator().getState();
  const { dropped } = result!;

  const ref = (op: UpgradeWheelOperator) => {
    op.chance.init(chance, offset * 3.6);
    const spinOp = new UpgradeWheelSpinOperator(op);

    spinOp
      .spin(
        UPGRADE_SPINNING_ANIMATION_TURNS_COUNT + dropped / 100,
        UPGRADE_SPINNING_ANIMATION_DURATION
      )
      .finally(() => {
        if (__DEV__ && window['upgrade_spinner_plays_forever']) {
          ref(op);
        } else {
          getUpgradeOperator().onSpinningFinished();
        }
      });
  };

  return (
    <div className={mainClass}>
      <UpgradeUnitsWheel op={ref}>
        <UpgradeUnitsWheelSpin streak={false} />
      </UpgradeUnitsWheel>
    </div>
  );
};

const mainClass = css`
  display: flex;
  flex-direction: column;
  ${stdBreakpoints.up(1695)} {
    transform: scaleX(0.9);
  }
  ${stdBreakpoints.up(1820)} {
    transform: scaleX(0.85);
  }
  ${stdBreakpoints.up(1920)} {
    transform: scaleX(0.78);
  }
`;
