import { css } from '@emotion/css';
import { appSyringe } from 'app/syringe';
import { StdTopDialog } from 'packs/std/dialog/top-dialog';
import { useCrushT } from '../glossary';
import { ReactComponent as RtpIcon } from 'app/crush/provably-fair/assets/rtp.svg';
import { CrushProvablyFairRightDialog } from 'app/crush/provably-fair/componets/right-dialog';
import { StdHarmonicDialogBlock } from 'packs/std/dialog/blocks/harmonic';
import { CrushProvablyFairBetFrame } from 'app/crush/provably-fair/componets/bet-frame';
import Decimal from 'decimal.js';
import { coilReq } from 'packs/libs/coil';
import { launch } from 'support/react/use-launch';

export const openCrushProvablyFairHowItWorkDialog = () => {
  launch(async () => {
    const config = await coilReq({ action: 'crush.config.x', data: 'normal' });

    appSyringe(function CrushProvablyFairHowItWorkDialog({ onClose }) {
      const t = useCrushT().sub('provably-fair');
      const { rtp } = config;

      return (
        <StdTopDialog onClose={onClose}>
          <div className={mainClass}>
            <StdHarmonicDialogBlock onClose={onClose} title={t('title')}>
              <div className={boxClass}>
                <p className={textClass}>{t('crush-cef')}</p>
                <div className={aboutClass}>
                  <div className={iconBoxClass}>
                    <RtpIcon />
                    <h4 className={aboutTitleClass}>{t('rtp')}</h4>
                  </div>
                  <p className={textClass}>{t('about-rtp')}</p>
                </div>
                <p className={textClass}>
                  {t('round-hash')}{' '}
                  <a
                    className={linkClass}
                    href="https://emn178.github.io/online-tools/sha256.html"
                    target="_blank"
                  >
                    {t('generator-hash')}
                  </a>
                </p>
                <p className={textClass}>{t('check-cef')}</p>
              </div>
            </StdHarmonicDialogBlock>
            <CrushProvablyFairRightDialog title={t('chance-to-win')}>
              <div className={listClass}>
                {...getChanceAndСoefficient(rtp).map((item) => {
                  return (
                    <div className={listItemClass}>
                      <CrushProvablyFairBetFrame x={item[0]} />
                      <div className={chanceClass}>{item[1]}%</div>
                    </div>
                  );
                })}
              </div>
            </CrushProvablyFairRightDialog>
          </div>
        </StdTopDialog>
      );
    });
  });
};

const getChanceAndСoefficient = (rtp: number): [number, number][] => {
  const base = new Decimal(rtp);

  function getChance(step: Decimal): number {
    return +base.div(step).mul(100).toFixed(1);
  }

  const graph: [number, number][] = [];
  let n = new Decimal(1);
  while (n.lt(2)) {
    n = n.plus('0.1');
    graph.push([+n.toDP(2), getChance(n)]);
  }
  while (n.lt(10)) {
    n = n.plus(1);
    graph.push([+n.toDP(2), getChance(n)]);
  }
  while (n.lt(20)) {
    n = n.plus(5);
    graph.push([+n.toDP(2), getChance(n)]);
  }

  return graph;
};

const mainClass = css`
  display: flex;
  gap: 18px;
`;

const boxClass = css`
  display: flex;
  flex-direction: column;
  gap: 24px;
  min-width: 448px;
  max-width: 448px;
`;

const aboutClass = css`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const iconBoxClass = css`
  display: flex;
  gap: 6px;
  align-items: center;
`;

const aboutTitleClass = css`
  font-family: 'Onest';
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: #dfdfdf;
`;

const textClass = css`
  font-family: 'Onest';
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #988c92;
`;

const linkClass = css`
  background: linear-gradient(180deg, #ffaa63 0%, #ffaa63 0.01%, #ea9a4e 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;

const listClass = css`
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-height: 451px;
  overflow-y: scroll;
`;

const listItemClass = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const chanceClass = css`
  font-family: 'Onest';
  font-style: normal;
  font-size: 14px;
  line-height: 140%;
  color: #988c92;
  padding-right: 14px;
`;
