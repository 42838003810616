import ReactGA from 'react-ga4';
import { formatError } from 'support/etc/format-error';

export const initializeGA = () => {
  if (ENV_GA_CODE !== undefined) {
    if (ENV_GA_TEST) {
      ReactGA.initialize(ENV_GA_CODE, {
        testMode: true,
      });
    } else {
      ReactGA.initialize(ENV_GA_CODE);
    }
  }
};

export const getGoogleAnalyticsClientId = async (): Promise<string | undefined> => {
  try {
    return await Promise.race<string>([
      new Promise((resolve) => {
        ReactGA.ga((tracker) => {
          resolve(tracker.get('clientId'));
        });
      }),
      new Promise((_, reject) => {
        setTimeout(() => {
          reject(Error('ga-blocked'));
        }, 100);
      }),
    ]);
  } catch (e) {
    console.log({ code: 'sign-in.ga-error', error: formatError(e) });

    return undefined;
  }
};

/*
 * modalview:
 * a proxy to basic GA pageview tracking to consistently track
 * modal views that are an equivalent UX to a traditional pageview
 * */
export const googleAnalyticsModalView = (code: string) => {
  const path = `/modal/${code}`;
  ReactGA.send({
    hitType: 'pageview',
    page: path,
    title: `ModalView: ${code}`,
  });
};
