import { googleAnalyticsModalView } from 'domain/tracking/google-analytics';
import { coilSend, listenCoilConnect } from 'packs/libs/coil';
import { useEffect } from 'react';

// const trackLocation = debounce((location: any) => {
//   let loc = `${location.pathname}${location.search}`;
//   pageview(location.pathname);
//   activityTrack('page', loc);
// });

// chronicle.listen((location) => {
//   trackLocation(location);
// });

// onceActorResolved(() => {
//   const actor = getActor();

//   if (actor.anon) {
//     set({
//       userId: undefined,
//     });
//   } else {
//     set({
//       userId: actor.id,
//     });
//   }

//   pageview(location.pathname);
// });

export function activityTrack(event: string, data?: any) {
  coilSend('at', [event, Date.now(), data]);
}

export const useActivityTrackModal = (code: string) =>
  useEffect(() => {
    googleAnalyticsModalView(code);
    activityTrack('modal.open', code);
    return () => activityTrack('modal.close', code);
  }, []);

listenCoilConnect(() => {
  activityTrack('browser', collectScreenData());
});

export function collectBrowserData() {
  return {
    ...collectScreenData(),
    browser: detectBrowser(),
  };
}

const collectScreenData = () => {
  const { screen } = window;
  return {
    screen: {
      width: screen.width,
      height: screen.height,
      pixelDepth: screen.pixelDepth,
    },

    outer: {
      width: window.outerWidth,
      height: window.outerHeight,
    },

    inner: {
      width: window.innerWidth,
      height: window.innerHeight,
    },
  };
};

// https://stackoverflow.com/a/5918791
function detectBrowser() {
  const ua = navigator.userAgent;
  let tem;
  let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return 'IE ' + (tem[1] || '');
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
    if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
  return M.join(' ');
}
