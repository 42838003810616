import { css } from '@emotion/css';
import { useContestT } from 'app/contest/glossary';
import { ContestInsightAbout } from 'app/contest/insight/about';
import { ReactComponent as ArrowIcon } from 'app/contest/insight/assets/arrow.svg';
import { ContestInsightCard } from 'app/contest/insight/card';
import { ContestInsightState } from 'app/contest/insight/definitions';
import { ContestInsightTopTable } from 'app/contest/insight/top/table';
import { LayoutFooter } from 'app/layout/footer/footer';
import { useActor } from 'domain/actor';
import { ContestStatus } from 'domain/contest';
import { chronicle } from 'packs/libs/chronicle';
import { coilConnect, coilListenR, coilReq, coilSend } from 'packs/libs/coil';
import { UReader } from 'packs/libs/uint-8';
import { stdBp } from 'packs/std';
import { useEffect } from 'react';
import { bunchCb } from 'support/etc/bunch-cb';
import { objToMap } from 'support/etc/obj-to-map';
import { linearClamp } from 'support/polished/linear-clamp';
import { useSwissState } from 'support/react/swiss';
import { launch } from 'support/react/use-launch';

type ContestInsightPageProps = { type: string; code: string };
export const ContestInsightPage = (props: ContestInsightPageProps): JsxElement => {
  const [state, op] = useSwissState<ContestInsightState>(null!);
  const t = useContestT().sub('insight');
  const actor = useActor();

  useEffect(
    () =>
      coilConnect(() => {
        launch(async () => {
          op.setState(
            await coilReq({
              action: 'contest.insight.enter',
              data: [props.type, props.code],
            })
          );
        });

        const topOp = op.prop<ContestInsightState['top']>('top');

        const listeners = objToMap<(r: UReader) => void>({
          joined: (r) => {
            op.mutState((draft) => {
              draft.contest.joined += 1;
              draft.top.push({
                player: r.pack(),
              });
            });
          },
          'top.upd': (r) => {
            topOp.setState(r.pack());
          },
          status: (r) => {
            op.mutState((draft) => {
              draft.contest.status = r.action() as ContestStatus;
            });
          },
          finished: (data) => {
            op.mutState((draft) => {
              draft.contest.status = ContestStatus.finished;
              draft.contest.winner = data.pack().player;
            });
          },
        });

        return bunchCb([
          () => {
            if (state !== null) {
              coilSend('contest.insight.leave', state.contest.id);
            }
          },
          coilListenR('contest.insight', (r) => {
            listeners.get(r.action())!(r);
          }),
        ]);
      }),
    [props.type, props.code]
  );

  if (state === null) return null;

  const { contest, top } = state;
  const joined = actor.signed && top.some((item) => item.player.id === actor.id);

  return (
    <div className={mainClass}>
      <div className={boxClass}>
        <div className={contentClass}>
          <div>
            <button onClick={() => chronicle.back()} className={backButtonClass}>
              <ArrowIcon />
              {t('back-link')}
            </button>
          </div>
          <div className={contestClass}>
            <ContestInsightCard contest={contest} joined={joined} />
            <div className={contestInfoClass}>
              <ContestInsightAbout data={contest} />
              <ContestInsightTopTable contest={contest} top={top} />
            </div>
          </div>
        </div>
        <LayoutFooter />
      </div>
    </div>
  );
};

const mainClass = css`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

const boxClass = css`
  max-width: 1427px;
  padding: 21px 21px 0 21px;
  gap: 22px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const contentClass = css`
  display: flex;
  flex-direction: column;
  gap: 21px;

  ${stdBp.up(1920)} {
    padding: ${linearClamp(1920, 0, 2560, 43)} 0;
  }
`;

const backButtonClass = css`
  font-family: 'Onest';
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #988c92;
  gap: 2px;
  justify-content: flex-start;
  cursor: pointer;
  background: none;
  outline: none;
  border: 0;
`;

const contestClass = css`
  display: flex;
  width: 100%;
  gap: 21px;
`;

const cardClass = css`
  background: #1e1c22;
  min-width: 300px;
  height: fit-content;
`;

const contestInfoClass = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
