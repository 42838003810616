export const createCharUint8ArrayConverter = (space: string) => {
  const CharNumMap = new Map<string, number>();
  for (let i = 0; i < space.length; i++) {
    CharNumMap.set(space.charAt(i), i);
  }

  const encode = (id: string): Uint8Array => {
    const result = new Uint8Array(id.length);
    let i = 0;
    for (const char of id) {
      result[i++] = CharNumMap.get(char)!;
    }
    return result;
  };

  const NumCharMap = new Map<number, string>();
  for (let i = 0; i < space.length; i++) {
    NumCharMap.set(i, space.charAt(i));
  }

  const decode = (arr: Uint8Array): string => {
    const result = new Array(arr.length);
    for (let i = 0; i < arr.length; i++) {
      result[i] = NumCharMap.get(arr[i])!;
    }
    return result.join('');
  };

  space = null as any;
  return [encode, decode] as const;
};
