import { css, cx } from '@emotion/css';
import { useCaseBattleCreationT } from '../glossary';
import { ReactComponent as RankIcon } from './assets/rank.svg';
import { ReactComponent as WalletIcon } from './assets/wallet.svg';

type Props = {
  rounds: number;
  total: number;
  enough: boolean;
};

export const ConfigHeader = ({ rounds, total, enough }: Props): JsxElement => {
  const t = useCaseBattleCreationT().sub('config');
  return (
    <div className={mainClass}>
      <h6 className={headerClass}>{t('creation')}</h6>
      <div className={headerInfoBoxClass}>
        <div className={headerPartClass}>
          <RankIcon className={cx(iconClass, rankIconClass)} />
          <div className={infoBoxClass}>
            <span className={topPartHeaderClass}>{t('rounds')}</span>
            <span className={bottomPartHeaderClass}>{rounds}</span>
          </div>
        </div>
        <div className={headerPartClass}>
          <WalletIcon className={cx(iconClass, walletIconClass)} />
          <div className={infoBoxClass}>
            <span className={topPartHeaderClass}>{t('cost')}</span>
            <span className={cx(bottomPartHeaderClass, !enough && notEnoughBalanceClass)}>
              ${total.toFixed(2)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const notEnoughBalanceClass = css`
  color: #ff5a5a;
`;

const mainClass = css`
  background: #1e1c22;
`;
const headerInfoBoxClass = css`
  padding: 0 18px 20px 18px;
  display: flex;
  gap: 16px;
`;
const headerClass = css`
  padding: 24px 18px;
  font-family: 'Onest';
  font-weight: 700;
  font-size: 20px;
  line-height: 140%;
`;
const iconClass = css`
  width: 32px;
  height: 32px;
  background: #1d1b21;
  border: 2px solid #332926;
  border-radius: 6px;
`;
const walletIconClass = css`
  padding: 6px;
`;
const rankIconClass = css`
  padding: 3.69px 4.92px 6.15px 4.92px;
`;
const infoBoxClass = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
`;
const headerPartClass = css`
  display: flex;
  gap: 12px;
  align-items: flex-end;
`;
const topPartHeaderClass = css`
  font-weight: 500;
  font-size: 13px;
  color: #988c92;
  padding-bottom: 4px;
`;
const bottomPartHeaderClass = css`
  font-family: 'Onest';
  font-weight: 700;
  font-size: 13px;
  line-height: 120%;
  color: #dfdfdf;
`;
