import produce, { Draft, setAutoFreeze } from 'immer';

/// нужно было для костыля в квестах
/// /home/elijah/code/csgocock/pwa/src/site/Bonus/Quests/quests.single-list.tsx:60
setAutoFreeze(false);

export type MutSpec<State> = (state: Draft<State>) => void;
export type AsyncMutSpec<State> = (state: Draft<State>) => Promise<void>;
export const mut = produce;

// export function useMutState<State>(state?: State | (() => State)) {
//   return useBasicStore(() => [
//     execIfFn(state) as State,
//     ((setState, getState) =>
//       (spec: ImeSpec<State>) =>
//         setState(ime(getState(), spec))),
//   ]);
// }