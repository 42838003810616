import fastDeepEqual from 'fast-deep-equal';
import { createContext, ReactNode, useContext, useEffect } from 'react';
import { MutSpec } from 'support/etc/immutate';
import { createRefEffect } from 'support/react/ref-effect';
import { Swiss, useBasicSwissStore } from 'support/react/swiss';
import { useScroll } from 'support/react/use-scroll';
import { useSingleton } from 'support/react/use-singleton';
import { IHashMap } from 'support/struct/i-hash-map';

type State<K = any, V = any, Q = Rsa> = {
  data: IHashMap<K, V>;
  meta: Rsa;
  loading: boolean;
  size: number;
  page: number;
  query: Q;
  hasMore: boolean;
};

export type RangerRequest<T, Q> = (
  query: Q,
  context: { page: number; size: number }
) => undefined | Promise<{ meta: Rsa; data: T[] }>;

export type RangerOperator<K = any, V = any, Q = Rsa> = {
  doQuery(): void;
  setSize(size: number): void;
  turn(page: number): void;
  loadMore(): void;
  updQuery(query: Partial<Q>): void;
  mutQuery(query: MutSpec<Q>): void;
  setRequest(request: RangerRequest<V, Q>);
  getState(): State<K, V, Q>;
  opItem(key: K): Swiss<V>;
  query: Swiss<Q>;
};

const Context = createContext<[State, RangerOperator]>(null!);
if (__DEV__) Context.displayName = 'RangerProvider';

export function useRanger<K = any, V = any, Q = Rsa>() {
  return useContext(Context) as [State<K, V, Q>, RangerOperator<K, V, Q>];
}

export const useRangerScroll = () => useScroll<any>(useRanger()[1].loadMore);
export const useRangerReload = () => useRanger()[1].doQuery;

export function useRangerQueryProp<T>(prop: string): [T, (val: T) => void] {
  const ranger = useRanger();

  return [
    ranger[0].query[prop],
    (val) => {
      ranger[1].updQuery({ [prop]: val });
    },
  ];
}

export type RangerProviderProps = {
  children: ReactNode;
  request: RangerRequest<any, Rsa>;
  pk?: string | ((item: any) => any);
  query?: Rsa;
  size?: number;
};

export const RangerProvider = ({
  children,
  pk,
  request, // be careful! it SHOULD NOT be NEW every render. use useCallback if you need to change it
  ...state
}: RangerProviderProps) => {
  useEffect(() => {
    value[1].setRequest(request);
  }, [request]);

  const value = useBasicSwissStore<State, RangerOperator>(() => [
    {
      loading: true,
      page: 1,
      data: IHashMap.empty(),
      meta: {},
      hasMore: true,
      size: 10,
      query: {},
      ...state,
    },

    ({ setState, getState }) => {
      let request: RangerRequest<any, any>;

      const getKey = resolvePrimaryKey(pk);

      const getData = () => getState().data;

      const updState = (state: Partial<State>) => {
        setState({ ...getState(), ...state });
      };

      const getItem = (pk) => getState().data.get(pk);
      const setItem = (key, val) => {
        const data = getData();
        updState({
          data: data.set(key, val),
        });
      };

      const setLoading = (loading: boolean) => {
        updState({ loading });
      };

      const toData = (data) => IHashMap.fromEntries(data.map((i) => [getKey(i), i]));

      const doQuery = async () => {
        if (!request) return;
        setLoading(true);
        const { query, size } = getState();
        try {
          const response = request(query, { page: 1, size });
          if (response === undefined) return;
          const { data, meta } = await response;
          updState({
            meta,
            data: toData(data),
            page: 1,
            hasMore: data.length === size,
          });
        } finally {
          setLoading(false);
        }
      };

      const getQuery = () => getState().query;
      const queryOp = new Swiss((query) => {
        if (fastDeepEqual(query, getQuery())) return;
        updState({ query });
        doQuery();
      }, getQuery);

      return {
        doQuery,
        getState,

        setSize(size: number) {
          updState({ size });
          doQuery();
        },

        setRequest(req) {
          request = req;
          doQuery();
        },

        turn: async (page: number) => {
          if (!request) return;
          setLoading(true);

          const { size, query } = getState();
          try {
            const response = request(query, { page, size });
            if (response === undefined) return;
            const { data, meta } = await response;
            updState({
              meta,
              data: toData(data),
              page,
              hasMore: data.length === size,
            });
          } finally {
            setLoading(false);
          }
        },

        loadMore: async () => {
          if (!request) return;
          try {
            setLoading(true);
            const { query, page: prev, size } = getState();
            const page = prev + 1;
            const response = request(query, { page, size });
            if (response === undefined) return;
            const { data, meta } = await response;
            updState({
              data: getData().apply((map) => {
                for (const item of data) {
                  map.set(getKey(item), item);
                }
              }),
              page,
              meta,
              hasMore: data.length === size,
            });
          } finally {
            setLoading(false);
          }
        },

        opItem(key) {
          return new Swiss(
            (val) => setItem(key, val),
            () => getItem(key)
          );
        },

        query: queryOp,

        mutQuery: queryOp.mutState,
        updQuery: queryOp.updState,
      };
    },
  ]);

  // if (typeof children === 'function')
  //   // @ts-ignore
  //   children = el(children, value[0]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export function resolvePrimaryKey(pk: string | ((item: any) => any) | undefined) {
  switch (typeof pk) {
    // @ts-ignore
    case 'undefined':
      pk = 'id';
    case 'string':
      // @ts-ignore
      return (item) => item[pk];
    case 'function':
      return pk;
    default:
      throw Error('pk is invalid');
  }
}

type RangerIntersectionLineProps = Partial<IntersectionObserver> & {
  freezeOnceVisible?: boolean;
  threshold?: number;
};

export const RangerIntersectionLine = (props: RangerIntersectionLineProps): JsxElement => {
  const ranger = useRanger();
  return useSingleton(() => {
    const { threshold = 0, root = null, rootMargin = '0%', freezeOnceVisible = false } = props;
    const hasIOSupport = !!window.IntersectionObserver;
    const op = ranger[1];
    const ref = createRefEffect((node) => {
      let frozen = false;
      const updateEntry = ([entry]: IntersectionObserverEntry[]) => {
        if (!hasIOSupport || frozen || !node) return;
        if (!op.getState().loading) op.loadMore();
        frozen = entry?.isIntersecting && freezeOnceVisible;
      };

      const observerParams = { threshold, root, rootMargin };
      const observer = new IntersectionObserver(updateEntry, observerParams);

      observer.observe(node);

      return () => observer.disconnect();
    });

    return <div ref={ref} />;
  });
};
