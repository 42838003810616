export const OnlineIcon = () => {
  return (
    <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect opacity="0.2" width="14" height="14" rx="4" fill="#696155" />
      <circle opacity="0.3" cx="7.00001" cy="7.00005" r="3.33333" fill="url(#paint0_linear_1_22)" />
      <circle opacity="0.05" cx="7" cy="7" r="5" fill="url(#paint1_linear_1_22)" />
      <circle cx="6.99999" cy="6.99995" r="1.66667" fill="url(#paint2_linear_1_22)" />
      <defs>
        <linearGradient
          id="paint0_linear_1_22"
          x1="7.00001"
          y1="3.66672"
          x2="7.00001"
          y2="10.3334"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9457" />
          <stop offset="1" stopColor="#FFBA69" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1_22"
          x1="7"
          y1="2"
          x2="7"
          y2="12"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9457" />
          <stop offset="1" stopColor="#FFBA69" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1_22"
          x1="6.99999"
          y1="5.33328"
          x2="6.99999"
          y2="8.66662"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9457" />
          <stop offset="1" stopColor="#FFBA69" />
        </linearGradient>
      </defs>
    </svg>
  );
};
