import { coilReq } from 'packs/libs/coil';
import { showCoilResponseError } from 'packs/libs/coil/error';
import { showErrorToast } from 'packs/libs/inf';

export const playerRequirementsCoilRequest = async <T = any>(
  action: string,
  data?: any
): Promise<T> => {
  try {
    return await coilReq({ action, data, toast: false });
  } catch (e) {
    if (e.code === 'requirements') {
      for (const problem of e.problems) {
        showErrorToast(problem);
      }
    } else {
      showCoilResponseError(e);
    }
    throw e;
  }
};

// export const caughtPlayerRequirementsProblems = async () => {
  
// }
