import { css } from '@emotion/css';
import { CaseData, CaseSlotData } from 'domain/case/definitions';
import { skinNameViewOnSkin } from 'domain/skin/name-view';
import { SkinImg } from 'domain/skin/units/image';
import { useCaseBattleCreationT } from '../glossary';
import { ReactComponent as DotSmallIcon } from './assets/apps-circle-small.svg';
import { returnGradientFrame } from './skin-border';
import { memoize } from 'lodash';

type CaseBattleCaseSelectDetailsProps = {
  data: CaseData | null;
};

export const CaseBattleCaseSelectDetails = ({
  data,
}: CaseBattleCaseSelectDetailsProps): JsxElement => {
  const t = useCaseBattleCreationT().sub('case-list');
  return (
    <div className={mainClass}>
      <div className={listDetailsHeaderClass}>
        <DotSmallIcon className={dotIconClass} />
        <div className={listDetailsHeaderTextClass}>{t('details-title')}</div>
      </div>
      <div className={detailsBodyClass}>
        <Cards slots={data?.slots!} />
      </div>
    </div>
  );
};

type DetailsCardsProps = {
  slots: CaseSlotData[];
};
export const Cards = ({ slots }: DetailsCardsProps): JsxElement => {
  return (
    <div className={cardsBoxClass}>
      {slots?.map((slot) => {
        const skinInfo = skinNameViewOnSkin(slot.skin);
        const Frame = returnGradientFrame(slot.skin.color);
        return (
          <Frame>
            <div className={cardMainClass}>
              <div className={detailsCardInfoBoxClass}>
                <div className={detailsCardChanceClass}>{slot.chance}%</div>
                <div className={detailsCardCostClass}>${slot.cost}</div>
              </div>
              <SkinImg className={skinImageClass} code={slot.skin.image} />
              <div className={skinModelClass(slot.skin.color)}>{skinInfo.model}</div>
              <div className={skinBrandClass}>{skinInfo.brand}</div>
              <div className={skinLevelClass}>{skinInfo.level?.full}</div>
            </div>
          </Frame>
        );
      })}
    </div>
  );
};

const mainClass = css`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
`;
const listDetailsHeaderClass = css`
  display: flex;
  align-items: center;
  gap: 9px;
  padding: 16px 0 15px 18px;
`;
const listDetailsHeaderTextClass = css`
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #dfdfdf;
`;
const dotIconClass = css`
  width: 12px;
  height: 12px;
`;
const detailsBodyClass = css`
  height: 100%;
  display: flex;
  overflow: auto;
`;

const cardsBoxClass = css`
  width: 100%;
  max-height: 140px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
`;
const skinImageClass = css`
  height: 60px;
  width: 96px;
`;
const cardMainClass = css`
  width: 100%;
  height: 100%;
  padding: 6px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;
const detailsCardInfoBoxClass = css`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const detailsCardChanceClass = css`
  font-family: 'Onest';
  font-weight: 500;
  font-size: 13px;
  line-height: 140%;
  color: #988c92;
`;
const detailsCardCostClass = css`
  font-family: 'Onest';
  font-weight: 500;
  font-size: 13px;
  line-height: 140%;
  color: #dfdfdf;
`;
const skinModelClass = memoize(
  (color) => css`
    text-align: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: rgb(${color[0]}, ${color[1]}, ${color[2]});
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `
);
const skinBrandClass = css`
  font-weight: 600;
  font-size: 12px;
  line-height: 130%;
  color: #dfdfdf;
`;
const skinLevelClass = css`
  font-weight: 400;
  font-size: 11px;
  line-height: 130%;

  color: #988c92;
`;
