import { css, cx } from '@emotion/css';
import { CSSProperties, memo } from 'react';
import { CaseData } from 'domain/case/definitions';
import { CaseImg } from 'domain/case/units/image';
import { juxProps } from 'support/etc/juxtapose';
import { CaseBattleCreationClickOnCaseWhileCreatingSound } from 'app/case/battle/creation/sounds';

type CaseBattleMonitorAllCaseDialogProps = {
  data: CaseData;
  amount?: number;
};
export const CaseBattleMonitorAllCaseDialogCard = memo(function CaseBattleMonitorAllCaseDialog({
  data,
}: CaseBattleMonitorAllCaseDialogProps): JsxElement {
  const { id, name, price, color } = data;

  return (
    <div
      className={cx(cardMainClass)}
      style={{ '--l-color': color?.join(', ') } as CSSProperties}
      onClick={() => {
        CaseBattleCreationClickOnCaseWhileCreatingSound.replay();
      }}
    >
      <div className={cardHeadClass}>
        <span className={cardPriceClass}>${price}</span>
      </div>
      <CaseImg className={cardCaseImgClass} id={id} draggable={false} />
      <div className={cardCaseNameClass}>{name}</div>
    </div>
  );
},
juxProps(['data']));

const cardMainClass = css`
  cursor: pointer;
  user-select: none;
  position: relative;
  width: 136px;
  height: 140px;
  background: #18171c;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6px;
  overflow: hidden;
  gap: 5px;
  &:hover {
    border: 1px solid #ea9a4e;
  }
  &:before {
    position: absolute;
    content: '';
    height: 134px;
    width: 103px;
    left: 16px;
    top: 69px;
    opacity: 0.3;
    border-radius: 50%;
    filter: blur(64px);
    background: rgb(var(--l-color));
  }
`;

const cardHeadClass = css`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 1;
`;
const cardPriceClass = css`
  font-family: 'Onest';
  font-weight: 500;
  font-size: 13px;
  line-height: 140%;
  color: #dfdfdf;
`;
const cardCaseImgClass = css`
  height: 80px;
  width: 96px;
  z-index: 1;
`;
const cardCaseNameClass = css`
  font-weight: 600;
  font-size: 12px;
  line-height: 130%;
  z-index: 1;
  color: #dfdfdf;
`;
