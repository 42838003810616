import { css, cx } from '@emotion/css';
import { ComponentType, useMemo } from 'react';
import { voidMemo } from 'support/react/void-memo';
import { useCrushScopeState, CrushRoundRecord } from '../store';
import { crushRecentCrateStaticFrame as stat } from './static';
import { crushRecentCreateGradientFrame as grad } from './gradient';
import { openCrushProvablyFairRoundCheckDialog } from '../provably-fair/round-check-dialog';
import { coilReq } from 'packs/libs/coil';

const THRESHOLDS: [number, ComponentType<Rcp>][] = [
  // gold
  [50, grad([191, 116, 46], [255, 170, 99])],
  //red
  [10, grad([255, 90, 90], [255, 90, 90])],
  [4, stat('#d32ee6')],
  [2, stat('#8847ff')],
  [1.5, stat('#4b69ff')],
  [1.2, stat('#00a98f')],
  [0, stat('#4C4D53')],
];

export function getFrameByX(x: number) {
  for (const [xThreshold, frame] of THRESHOLDS) if (xThreshold <= x) return frame;
}

export const CrushRecent = (): JsxElement => {
  const { recent } = useCrushScopeState();
  // const list = [1.0, 2.34, 32, 99, 2, 1.6, 44].map((x) => ({ id: x, x }));
  return useMemo(() => {
    return (
      <div className={mainClass}>
        {recent.map((data) => (
          <CrashItem key={data.id} data={data} />
        ))}
      </div>
    );
  }, [recent]);
};

type CrashItemProps = {
  data: {
    id: string;
    x: number;
  };
};
const CrashItem = voidMemo(function CrashItem({ data: { id, x } }: CrashItemProps) {
  const Frame = getFrameByX(x)!;

  return (
    <div
      key={id}
      className={cx(
        itemLinkClass,
        css`
          color: #988c92;
        `
      )}
      onClick={async () => {
        const roundCrash = await coilReq<CrushRoundRecord>({
          action: 'crush.round.pf.get',
          data: id,
        });
        openCrushProvablyFairRoundCheckDialog({ roundCrash });
      }}
    >
      <Frame>x{x.toFixed(2)}</Frame>
    </div>
  );
});

const mainClass = css`
  position: absolute;
  top: 16px;
  left: 0;
  display: flex;
  width: 100%;
  overflow: hidden;
  z-index: 1;
`;

const itemLinkClass = css`
  display: inline-block;
  height: 24px;
  margin: 0 4px;
  font-family: 'Commissioner';
  font-style: normal;
  line-height: 130%;
  font-weight: 500;
  font-size: 12px;
  color: #988c92;
  cursor: pointer;
`;
