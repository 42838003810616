import { css } from '@emotion/css';
import { CSSProperties } from 'react';

import { CaseData, CaseSlotData } from 'domain/case/definitions';
import { CaseImg } from 'domain/case/units/image';
import { skinNameViewOnSkin } from 'domain/skin/name-view';
import { SkinImg } from 'domain/skin/units/image';
import { memoize } from 'lodash';
import { InstantTooltip } from 'packs/single/instant-tooltip';
import { returnGradientFrame } from './skin-border';
import { stdBp } from 'packs/std';

type CaseBattleCreationCaseSelectDetailsProps = {
  data: CaseData | null;
};

export const CaseBattleCreationCaseSelectDetails = ({
  data,
}: CaseBattleCreationCaseSelectDetailsProps): JsxElement => {
  if (data === null) return null;
  const { name, price, id, color } = data;

  return (
    <div className={mainClass}>
      <div
        className={detailsHeaderClass}
        style={{ '--c-color': color?.join(', ') } as CSSProperties}
      >
        <CaseImg className={cardCaseImgClass} id={id} />
        <div className={detailsCaseInfoBox}>
          <div className={detailCaseNameClass}>{name}</div>
          <div className={detailCasePriceClass}>${price}</div>
        </div>
      </div>
      <div className={detailsBodyScrollClass}>
        <div className={detailsBodyClass}>
          <Cards slots={data.slots} />
        </div>
      </div>
    </div>
  );
};
type DetailsCardsProps = {
  slots: CaseSlotData[];
};
export const Cards = ({ slots }: DetailsCardsProps): JsxElement => {
  return (
    <div className={cardsBoxClass}>
      {...slots.map((slot) => {
        const skinInfo = skinNameViewOnSkin(slot.skin);
        const Frame = returnGradientFrame(slot.skin.color);
        return (
          <Frame>
            <InstantTooltip title={slot.skin.id} arrow>
              <div className={cardMainClass}>
                <div className={detailsCardInfoBoxClass}>
                  <div className={detailsCardChanceClass}>{slot.chance}%</div>
                  <div className={detailsCardCostClass}>${slot.cost}</div>
                </div>
                <SkinImg className={skinImageClass} code={slot.skin.image} />
                <div className={skinModelClass(slot.skin.color)}>{skinInfo.model}</div>
                <div className={skinBrandClass}>{skinInfo.brand}</div>
                <div className={skinLevelClass}>{skinInfo.level?.full}</div>
              </div>
            </InstantTooltip>
          </Frame>
        );
      })}
    </div>
  );
};

const notEnoughBalanceClass = css`
  color: red;
`;

const mainClass = css`
  width: 298px;
  display: flex;
  flex-direction: column;
  border-radius: 32px;
  overflow: hidden;
  ${stdBp.up(2560)} {
    width: calc(298px * 1.3);
  }
`;
const detailsHeaderClass = css`
  background: #18171c;
  display: flex;
  gap: 17px;
  align-items: center;
  padding: 10px 0 0 16px;
  position: relative;

  &:before {
    position: absolute;
    content: '';
    background: rgb(var(--c-color));
    height: 134px;
    width: 157px;
    left: 16px;
    top: 57px;
    border-radius: 50%;
    opacity: 0.3;
    filter: blur(64px);
  }
`;
const cardCaseImgClass = css`
  height: 90px;
  z-index: 1;
  ${stdBp.up(2560)} {
    height: calc(90px * 1.3);
  }
`;
const detailsCaseInfoBox = css`
  display: flex;
  flex-direction: column;
  gap: 3px;
  z-index: 1;
`;
const detailCaseNameClass = css`
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #dfdfdf;
  ${stdBp.up(2560)} {
    font-size: calc(14px * 1.3);
  }
`;
const detailCasePriceClass = css`
  font-family: 'Onest';
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #0fac7f;
  ${stdBp.up(2560)} {
    font-size: calc(14px * 1.3);
  }
`;
``;
const detailsBodyScrollClass = css`
  position: relative;
  background: #26232d;
  padding-right: 8px;
  height: 540px;
  ${stdBp.up(2560)} {
    height: calc(540px * 1.3);
  }
`;
const detailsBodyClass = css`
  height: 100%;
  padding-right: 8px;
  overflow-x: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 2px;
  }
`;

const cardsBoxClass = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
`;
const skinImageClass = css`
  width: 90px;
  height: 60px;
  ${stdBp.up(2560)} {
    width: calc(90px * 1.3);
    height: calc(60px * 1.3);
  }
`;
const cardMainClass = css`
  width: 100%;
  height: 100%;
  padding: 6px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;
const detailsCardInfoBoxClass = css`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const detailsCardChanceClass = css`
  font-family: 'Onest';
  font-weight: 500;
  font-size: 13px;
  line-height: 140%;
  color: #988c92;
  ${stdBp.up(2560)} {
    font-size: calc(13px * 1.3);
  }
`;
const detailsCardCostClass = css`
  font-family: 'Onest';
  font-weight: 500;
  font-size: 13px;
  line-height: 140%;
  color: #dfdfdf;
  ${stdBp.up(2560)} {
    font-size: calc(13px * 1.3);
  }
`;
const skinModelClass = memoize(
  (color) => css`
    text-align: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: rgb(${color[0]}, ${color[1]}, ${color[2]});
    ${stdBp.up(2560)} {
      font-size: calc(12px * 1.3);
    }
  `
);
const skinBrandClass = css`
  font-weight: 600;
  font-size: 12px;
  line-height: 130%;
  color: #dfdfdf;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${stdBp.up(2560)} {
    font-size: calc(12px * 1.3);
  }
`;
const skinLevelClass = css`
  font-weight: 400;
  font-size: 10px;
  line-height: 130%;
  color: #988c92;
  ${stdBp.up(2560)} {
    font-size: calc(10px * 1.3);
  }
`;
