import { css } from '@emotion/css';
import { CaseBattleCreationCreateBattleSound } from 'app/case/battle/creation/sounds';
import { CaseBattleCreationNumberOfPlayersSound } from 'app/case/battle/creation/sounds';
import { useCaseBattleCreationStore } from 'app/case/battle/creation/store';
import { useActor } from 'domain/actor';
import { StdButton, StdSwitchToggle } from 'packs/std';
import { StdKindButton } from 'packs/std/inputs/kind-button';
import { useMemo, useState } from 'react';
import { ListCaseData } from '../case-list/case-list';
import { CaseBattleCreationGlossary, useCaseBattleCreationT } from '../glossary';
import { ReactComponent as BattleIcon } from './assets/battle.svg';
import { ReactComponent as BotIcon } from './assets/robot.svg';
import { ConfigHeader } from './header-config';
import { ConfigPlayers } from './players';
import { showIntlInfoToast } from 'packs/libs/intl/toasts';
import sleep from 'support/etc/sleep';

type CaseBattleCreationConfigProps = {
  select(data: ListCaseData): void;
};
const MAX_COUNT = 50;
export const CaseBattleCreationConfig = ({
  select,
}: CaseBattleCreationConfigProps): JsxElement => {
  const [state] = useCaseBattleCreationStore();
  const t = useCaseBattleCreationT().sub('config');
  const { cases } = state;
  if (state.rounds > MAX_COUNT) {
    showIntlInfoToast(CaseBattleCreationGlossary, t('max-case'));
  }
  return (
    <div className={mainClass}>
      <div className={bottomBorderClass}>
        <ConfigHeader rounds={state.rounds} total={state.total} enough={state.enoughBalance} />
      </div>
      <div className={bottomBorderClass}>
        <ConfigPlayers />
      </div>
      <div className={bottomBorderClass}>
        <BotsToggle />
      </div>
      {cases.size !== 0 ? (
        <>
          <div className={buttonsBoxClass}>
            <CreateButton />
          </div>
          <div>
            <CancelButton select={select} />
          </div>
        </>
      ) : (
        <div className={buttonCreateBattleBoxClass}></div>
      )}
    </div>
  );
};

const CreateButton = (): JsxElement => {
  const [state, op] = useCaseBattleCreationStore();
  const [processing, setProcessing] = useState<boolean>(false);
  const actor = useActor();
  const t = useCaseBattleCreationT().sub('config');
  return useMemo(() => {
    return (
      <div className={buttonCreateBattleBoxClass}>
        <StdKindButton
          className={buttonCreateBattleClass}
          kind="orange"
          disabled={actor.anon || !state.enoughBalance || state.rounds > MAX_COUNT || processing}
          onClick={async () => {
            setProcessing(true);
            try {
              op.create();
              CaseBattleCreationCreateBattleSound.replay();
            } finally {
              await sleep(1000);
              setProcessing(false);
            }
          }}
        >
          <BattleIcon className={iconClass} />
          {t('create')}
        </StdKindButton>
      </div>
    );
  }, [actor, state.enoughBalance, state.rounds, t, processing]);
};
const CancelButton = ({ select }: CaseBattleCreationConfigProps): JsxElement => {
  const [, op] = useCaseBattleCreationStore();
  const actor = useActor();
  const t = useCaseBattleCreationT().sub('config');
  const clear = () => {
    op.cases.clear();
    select(null!);
  };
  return useMemo(() => {
    return (
      <div className={buttonCancelBattleBoxClass}>
        <StdButton className={buttonCancelBattleClass} disabled={actor.anon} onClick={clear}>
          {t('cancel')}
        </StdButton>
      </div>
    );
  }, [actor, t]);
};

const BotsToggle = (): JsxElement => {
  const [state, op] = useCaseBattleCreationStore();
  const checked = state.config.bots;
  const t = useCaseBattleCreationT().sub('config');
  return useMemo(() => {
    return (
      <div className={botsTogleMainClass}>
        <div className={labelBotClass}>
          <BotIcon className={iconBotClass} />
          <span className={labelBotTextClass}>{t('bots')}:</span>
        </div>
        <StdSwitchToggle
          checked={checked}
          onChange={(checked) => {
            op.config.updState({ bots: checked });
            CaseBattleCreationNumberOfPlayersSound.replay();
          }}
        />
      </div>
    );
  }, [checked, t.locale]);
};

const mainClass = css`
  display: flex;
  flex-direction: column;
  min-width: 364px;
  max-height: 890px;
`;
const botsTogleMainClass = css`
  background: #1e1c22;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 18px;
`;
const bottomBorderClass = css`
  padding-bottom: 2px;
`;
const buttonsBoxClass = css`
  padding-bottom: 2px;
  height: 100%;
`;
const buttonCreateBattleClass = css`
  display: flex;
  align-items: center;
  gap: 7px;
  width: 100%;
  border-radius: 4px;
  height: 42px;
  font-size: 13px;
`;
const iconClass = css`
  width: 18px;
  height: 18px;
`;
const buttonCancelBattleClass = css`
  background: #26232d;
  padding: 10px;
  border-radius: 4px;
  height: 38px;
  font-family: 'Onest';
  font-weight: 700;
  font-size: 13px;
  color: #988c92;
  &:hover {
    color: #18171c;
    background: linear-gradient(180deg, #ffaa63 0%, #ffaa63 0.01%, #ea9a4e 100%);
  }
`;
const buttonCreateBattleBoxClass = css`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: #1e1c22;
  height: 100%;
  padding: 18px;
`;
const buttonCancelBattleBoxClass = css`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: #1e1c22;
  height: 100%;
  padding: 18px 18px 24px 18px;
`;
const labelBotClass = css`
  display: flex;
  align-items: center;
  gap: 6px;
`;
const iconBotClass = css`
  width: 20px;
  height: 20px;
`;
const labelBotTextClass = css`
  font-weight: 700;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #dfdfdf;
`;
