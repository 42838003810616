import { css, cx } from '@emotion/css';
import Popover, { PopoverOrigin } from '@mui/material/Popover';
import React, { createElement, ReactNode, useRef } from 'react';

export type SimplePopoverDropProps = {
  onClose(): void;
};

// https://mui.com/material-ui/react-popover/#anchor-playground
const defaultAnchorOrigin: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'center',
};
const defaultTransformOrigin: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'center',
};

type SimplePopoverProps = {
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  popup: ReactNode;
  className?: string;
  children: React.ReactNode;
  open: boolean;
  onClose(): void;
};

export const SimplePopover = ({
  children,
  open,
  onClose,
  className,
  popup: drop,
  anchorOrigin = defaultAnchorOrigin,
  transformOrigin = defaultTransformOrigin,
}: SimplePopoverProps) => {
  const ref = useRef<any>();

  return (
    <div ref={ref} className={cx(mainClass, className)}>
      {open &&
        createElement(Popover, {
          open: open,
          onClose,
          anchorEl: ref.current,
          anchorOrigin,
          transformOrigin,
          // @ts-ignore
          PaperProps: SimplePopoverPaperProps,
          children: drop,
        })}
      {children}
    </div>
  );
};

type PaperProps = {
  children: React.ReactNode;
  style?: React.CSSProperties;
};
const Paper = React.forwardRef<HTMLDivElement, PaperProps>(({ children, style }, ref) => {
  return createElement('div', { ref, children, style, tabIndex: -1, className: paperClass });
});

export const SimplePopoverPaperProps = { component: Paper };

const mainClass = css`
  display: inline-block;
`;
const paperClass = css`
  position: absolute;
  outline: none;
`;
