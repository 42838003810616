import { css } from '@emotion/css';

import { ReactComponent as LiveIcon } from 'app/upgrade/assets/live-icon.svg';
import { useUpgradeT } from 'app/upgrade/glossary';
import { UpgradeLiveBidsBid } from 'app/upgrade/live-bids/bid';
import { LiveBidData } from 'app/upgrade/store';
import { coilListenR } from 'packs/libs/coil';
import { UReader } from 'packs/libs/uint-8';
import { SubCell } from 'support/react/sub-cell';

type UpgradeLiveBidsState = {
  list: LiveBidData[];
};

const cell = new SubCell<UpgradeLiveBidsState>();

enum Actions {
  setState,
  addBid,
}

const decodeBid = (r: UReader) => {
  const data = r.pack();
  data.id = Math.random();
  return data;
};

const actionHandlers = new Map<Actions, (r: UReader) => void>([
  [
    Actions.setState,
    (r) => {
      const list: any[] = [];
      while (!r.complete) {
        list.push(decodeBid(r));
      }
      cell.set({ list });
    },
  ],
  [
    Actions.addBid,
    (r) => {
      cell.mut((draft) => {
        draft.list.unshift({ added: true, ...decodeBid(r) });
        draft.list = draft.list.slice(0, 20);
      });
    },
  ],
]);

// when hot reloading reinitialize cell, coil listener still referencing old cell
coilListenR('upgrade_live', (r) => {
  actionHandlers.get(r.uint8())!(r);
});

export const UpgradeLiveBids = (): JsxElement => {
  const t = useUpgradeT().sub('live-upgrade');

  const data = cell.useValue();

  if (data === undefined) return <div className={loadingClass}>loading...</div>;

  const { list } = data;

  return (
    <div className={mainClass}>
      <div className={liveIconBoxClass}>
        <p className={liveTitleClass}>{t('live')}</p>
        <LiveIcon />
      </div>
      <div className={skinsContainerClass}>
        {list.map((bid) => (
          <UpgradeLiveBidsBid key={bid.id} bid={bid} />
        ))}
      </div>
    </div>
  );
};

const mainClass = css`
  display: flex;
  background: linear-gradient(180deg, #1f1d23 0%, rgba(30, 28, 34, 0.6) 100%);
  padding: 8px;
  position: relative;
  overflow: hidden;
  width: 100%;
  min-height: 73px;
  &:before {
    content: '';
    position: absolute;
    height: 100%;
    width: 2px;
    background: #1e1c22;
    box-shadow: 0px 0px 32px 32px #1e1c22;
    top: 0;
    right: 0;
    z-index: 1;
  }
`;
const liveIconBoxClass = css`
  display: flex;
  gap: 4px;
  padding-top: 18px;
  justify-content: center;
  height: 50px;
  transform: rotate(-90deg);
  svg {
    height: 16px;
  }
`;
const liveTitleClass = css`
  font-weight: 700;
  font-size: 10px;
  line-height: 140%;
  display: flex;
  align-items: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #dfdfdf;
  height: fit-content;
`;
const skinsContainerClass = css`
  display: flex;
  gap: 8px;
`;
const loadingClass = css`
  ${mainClass};
  font-weight: 600;
  font-size: 25px;
  line-height: 130%;
  text-align: center;
  color: #988c92;
  height: 73px;
`;
