import { coilListen } from 'packs/libs/coil';
import React, { createContext, createRef, EffectCallback, useContext, useEffect } from 'react';
import { iup, IupSpec } from 'support/etc/iup';
import { objToMap } from 'support/etc/obj-to-map';
import { useBasicStore } from 'support/react/basic-store';
import { CHAT_MAX_MESSAGES, ChatMessageData, ChatPinnedMessageData } from './definitions';

type State = {
  room?: string;
  messages: ChatMessageData[];
  pinned: ChatPinnedMessageData[];
  slowMode: boolean;
  pinsClosed?: boolean;
  collapsed?: boolean;
};

type Operator = {
  getState(): State;
  updState(state: Partial<State>);
  inputRef: React.RefObject<HTMLTextAreaElement>;
  replyTo(name: string): void;
  unpin(id: string): void;
};

const Context = createContext<State>(null!);
if (__DEV__) Context.displayName = 'ChatContext';

export const useChatStore = (): [State, Operator] => [useContext(Context), globalOperator];
export const useChatState = () => useContext(Context);

let globalOperator: Operator;

export const getChatOperator = () => globalOperator;

export const ChatStoreProvider = ({ children }: Rcp) => {
  const [value, effect] = useBasicStore<State, EffectCallback>(() => [
    {
      messages: [],
      pinned: [],
      slowMode: false,
    },

    (setState, getState) => {
      const iupState = (spec: IupSpec<State>) => setState(iup(getState(), spec));
      const updState = (state: Partial<State>) => setState({ ...getState(), ...state });

      // backward compatibility
      const dispatch = (action) => {
        if (typeof action === 'function') action(dispatch, getState);
        else iupState(action);
      };

      const inputRef = createRef<HTMLTextAreaElement>();

      const applyMessages = (fn: (messages: ChatMessageData[]) => ChatMessageData[]) => {
        iupState({ messages: fn });
      };

      const unpin = (id: string) => {
        iupState({ pinned: (list) => list.filter((m) => id !== m.id) });
      };

      const coilListeners = objToMap({
        room: (state) => {
          iupState({ $merge: state });
        },

        'msg.add': (msg) => {
          applyMessages((messages) => [msg, ...messages.slice(0, CHAT_MAX_MESSAGES)]);
        },

        'user.mute': (id) => {
          applyMessages((messages) => messages.filter((m) => id !== m.sender.id));
        },

        'msg.del': (id) => {
          applyMessages((messages) => messages.filter((m) => id !== m.id));
        },

        pin: (message) => {
          iupState({ pinned: (list) => [message, ...list] });
        },

        unpin,

        'slow-mode': (slowMode) => {
          iupState({ $merge: { slowMode } });
        },
      });

      globalOperator = Object.assign(dispatch, {
        getState,
        updState,

        replyTo(name: string) {
          const element = inputRef.current;
          if (!element) return;
          element.value = `> ${name} `;
          element.setAttribute('replying-to', name);
          element.focus();
        },

        inputRef,

        unpin,
      });

      return () =>
        coilListen('chat', (action, data) => {
          coilListeners.get(action)!(data);
        });
    },
  ]);

  useEffect(effect, []);

  return <Context.Provider value={value}>{children}</Context.Provider>;
};
