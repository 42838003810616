import { css } from '@emotion/css';
import { CASE_BATTLE_ROUND_REEL_DURATION, FinalResultObj } from 'app/case/battle/definitions';
import { launchCaseBattleInsightReelCostAnimation } from 'app/case/battle/insight/units/reel/cost';
import { getCaseBattleInsightReelSlotsToShowInTape } from 'app/case/battle/insight/units/reel/slots-to-show';
import { CaseData, CaseSlotData } from 'domain/case/definitions';
import { completeSkinImageUrl } from 'domain/skin/units/image';
import { ComponentType, Dispatch, useEffect } from 'react';
import { createNativeElement as el } from 'support/html/native-jsx';
import { createRefEffect } from 'support/react/ref-effect';
import { useSingleton } from 'support/react/use-singleton';
import { launchCaseBattleInsightReelStatusAnimation } from 'app/case/battle/insight/units/reel/animation-status';

const REEL_KEY = Symbol();
export const getCaseBattleInsightReel = (
  cases: CaseData[]
): ComponentType<CaseBattleInsightReelProps> => (cases[REEL_KEY] ??= createReel(cases));

type Drop = {
  slotIndex: number;
  highest?: true;
};
type CaseBattleInsightReelProps = {
  caseData: CaseData;
  drop: Drop;
  setFinalResult: Dispatch<FinalResultObj>;
};

const createReel = (cases: CaseData[]) => {
  const createTape = createTapeRender(cases);

  return function CaseBattleInsightReel({
    caseData,
    drop,
    setFinalResult,
  }: CaseBattleInsightReelProps): JsxElement {
    const [children, update] = useSingleton(() => {
      let box: HTMLDivElement;
      let currentAnimation: Animation | undefined;
      const ref = createRefEffect((el) => {
        box = el;
        return () => currentAnimation?.cancel();
      });

      const update = (caseData: CaseData, drop: Drop) => {
        const [tape, animation] = createTape(caseData)(drop.slotIndex);
        box.replaceChildren(tape);
        animation.onfinish = () => {
          currentAnimation = undefined;
          const { cost, chance, skin } = caseData.slots[drop.slotIndex];
          launchCaseBattleInsightReelCostAnimation(box, cost);
          launchCaseBattleInsightReelStatusAnimation(box, drop.highest, chance, skin, setFinalResult);
        };
        currentAnimation = animation;
      };

      return [<div className={boxClass} ref={ref} />, update] as const;
    });

    useEffect(() => update(caseData, drop), [caseData]);

    return children;
  };
};

const createTapeRender = (cases: CaseData[]) => {
  const tapesMap = createTapesMap(cases);
  return (caseData: CaseData) => tapesMap.get(caseData.id)!;
};

const createTapesMap = (cases: CaseData[]) => {
  const caseMap = new Map(cases.map((caseData) => [caseData.id, caseData]));
  const tapesMap = new Map<string, ReturnType<typeof createCaseTape>>();
  for (const caseData of caseMap.values()) {
    tapesMap.set(caseData.id, createCaseTape(caseData));
  }
  return tapesMap;
};

const createCaseTape = (caseData: CaseData) => {
  const originCount = caseData.slots.length;
  const slotsToShow = getCaseBattleInsightReelSlotsToShowInTape(originCount);

  const originTape = createTapeNode(caseData, slotsToShow);

  return (dropIndex: number) => {
    // const destinationIndex = slotsToShow - originCount + dropIndex - 1;

    const tape = originTape.cloneNode(true) as HTMLDivElement;
    const offset = (100 / slotsToShow) * (slotsToShow - 1 + (dropIndex - originCount));
    const animation = tape.animate(
      [{ transform: 'translateY(0)' }, { transform: `translateY(calc(-${offset}% + 40px))` }],
      {
        duration: CASE_BATTLE_ROUND_REEL_DURATION,
        easing: 'cubic-bezier(0.13, 0.04, 0, 1)',
        fill: 'forwards',
      }
    );

    animation.onfinish = () => {};

    return [tape, animation] as const;
  };
};

const createTapeNode = (caseData: CaseData, slotsToShow: number) => {
  const children: any[] = [];
  const getSlot = loop(caseData.slots.map(createSlotNode));
  for (let i = 0; i < slotsToShow; i++) {
    children.push(getSlot().cloneNode(true));
  }

  return el('div', { className: tapeClass }, ...children);
};

const createSlotNode = (slot: CaseSlotData) => {
  return el(
    'div',
    { className: slotBoxClass, style: `--l-color: ${slot.skin.color?.join(', ')}` },
    el('img', { src: completeSkinImageUrl({ code: slot.skin.image }), className: imageClass })
  );
};

const boxClass = css`
  position: relative;
  width: 100%;
  height: 100%;
`;

const tapeClass = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  transform: translateY(0);
`;

function loop<T>(list: T[]) {
  function* createIterator() {
    while (true) yield* list;
  }

  const iterator = createIterator();
  return (): T => iterator.next().value as T;
}

const slotBoxClass = css`
  position: relative;
  padding: 0 15%;
  text-align: center;

  &:after {
    content: '';
    display: block;
    border-radius: 50%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: -1;
    width: 130px;
    height: 130px;
    opacity: .5;
    background-color: rgb(var(--l-color));
    mask: url('/assets/case-battles/chick.svg') no-repeat center;
    background-size: 80%;
  }

  &:before {
    content: '';
    display: block;
    border-radius: 50%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: -1;
    filter: blur(20px);
    background: rgb(var(--l-color));
    width: 90px;
    height: 90px;
    opacity: .2;
  }

  &:has(> #dashed-path-stroke) {
    stroke: red;
  }
`;

const imageClass = css`
  width: 100%;
  object-fit: contain;
`;
