import { useCrushScopeRound } from 'app/crush';
import { CrushSceneCountdownCounter } from 'app/crush/scene/counters/countdown';
import { CrushSceneXCounter } from 'app/crush/scene/counters/x-counter';
import { CrushRoundStage } from 'domain/crush';
import { useMemo } from 'react';

export const CrushSceneCounters = (): JsxElement => {
  const round = useCrushScopeRound();

  return useMemo(() => {
    switch (round.stage) {
      case CrushRoundStage.countdown:
        return <CrushSceneCountdownCounter finish={round.clashAt} />;
      case CrushRoundStage.growth:
      case CrushRoundStage.crash:
        return <CrushSceneXCounter growth={round.growth!} />;
      default:
        return null;
    }
  }, [round.stage]);
};
