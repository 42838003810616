import { css } from '@emotion/css';
import { ReactComponent as CaseBattleIcon } from 'app/contest/assets/case-battle-icon.svg';
import { ReactComponent as CrushIcon } from 'app/contest/assets/crush-icon.svg';
import { ReactComponent as UpgradeIcon } from 'app/contest/assets/upgrade-icon.svg';
import { useContestT } from 'app/contest/glossary';
import { ContestUpon } from 'domain/contest';

const IconMap = new Map([
  [ContestUpon.crush, <CrushIcon />],
  [ContestUpon.upgrade, <UpgradeIcon />],
  [ContestUpon.caseBattle, <CaseBattleIcon />],
]);

type ContestUponIconListProps = {
  upon: ContestUpon[];
};

export const ContestCardUponIconList = ({ upon }: ContestUponIconListProps): JsxElement => {
  const t = useContestT().sub('card.upon');

  return (
    <div className={iconsContainerClass}>
      {upon.map((upon) => {
        return (
          <div className={iconBoxClass} key={upon}>
            <div className={iconClass}>
              {IconMap.get(upon)!}
              <p className={tooltipClass}>{t(upon)}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const iconsContainerClass = css`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const iconClass = css`
  background: linear-gradient(135deg, rgba(24, 23, 28, 50%) 0, rgba(24, 23, 28, 75%) 100%);
  width: 31px;
  border-radius: 8px;
  height: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  > svg {
    path {
      fill: #988c92;
    }
  }
`;

const tooltipClass = css`
  position: absolute;
  display: none;
  font-family: 'Commissioner';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  color: #dfdfdf;
  background: #18171c;
  border-radius: 4px;
  padding: 9px 12px;
  left: 40px;

  &:before {
    content: '';
    position: absolute;
    right: 100%;
    bottom: 10px;
    border-top: 7px solid transparent;
    border-right: 7px solid #18171c;
    border-bottom: 7px solid transparent;
  }
`;

const iconBoxClass = css`
  width: 32px;
  height: 32px;
  background: linear-gradient(135deg, rgba(38, 35, 45, 0) 0, rgba(152, 140, 146, 1) 100%);
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background: linear-gradient(135deg, rgba(38, 35, 45, 0) 0, rgba(242, 160, 86, 1) 100%);

    > div {
      > svg {
        path {
          fill: url(#paint1_linear_9037_9138);
        }
      }
    }

    ${'.' + tooltipClass} {
      display: block;
    }
  }
`;
