/* eslint-disable */
import { useMemo, useRef } from 'react';

const INIT = Symbol();

/**
 * @link https://reactjs.org/docs/hooks-reference.html#usememo
 * You may rely on useMemo as a performance optimization, not as a semantic guarantee.
 * In the future, React may choose to “forget” some previously memoized values
 * and recalculate them on next render, e.g. to free memory for offscreen components.
 *
 * But useRef does not refresh on hot reloading
 */
export function useSingleton<T>(factory: () => T): T {
  if (__DEV__) {

    return useMemo(factory, []);

  } else {

    const ref = useRef<T | symbol>(INIT);

    if (ref.current === INIT) {
      ref.current = factory();
    }

    return ref.current as T;

  }
}