import { PlayerBriefData } from 'domain/player';
import { PlayerRequirementData } from 'domain/player/requirements/definitions';
import { EnumMap } from 'support/struct/enum-map';

export enum ContestStatus {
  pending = 'pending',
  exposed = 'exposed',
  appeared = 'appeared',
  active = 'active',
  calculation = 'calculation',
  finished = 'finished',
}

export enum ContestUpon {
  crush = 'crush',
  upgrade = 'upgrade',
  caseBattle = 'case_battle',
}

export const CONTEST_UPCOMING_STATUSES = new Set([
  ContestStatus.pending,
  ContestStatus.exposed,
  ContestStatus.appeared,
]);

export type ContestPlayerGameHistoryItem = {
  stake: number;
  prize: number;
} & (
  | {
      upon: ContestUpon.crush;
      x: number;
    }
  | {
      upon: ContestUpon.upgrade;
    }
  | {
      upon: ContestUpon.caseBattle;
    }
);

export type ContestBriefData = {
  id: string;
  title: LcRecord<string>;
  status: ContestStatus;
  start: number;
  duration: number;
  joined: number;
  cap: number; // max members
  requirements: PlayerRequirementData[];
  cost: number;
  prize: number[];
  upon: ContestUpon[];

  winner?: {
    id: string;
    image: string;
    name: string;
  };
};

export type ContestTopListItem = {
  stake?: number;
  profit?: number;
  player: PlayerBriefData;
};

export type ContestData = ContestBriefData & {
  code?: string;
  skins: {
    count: number;
    price: number;
  };
};

export enum ContestUponCode {
  crush,
  upgrade,
  caseBattle,
}

export const ContestUponCodeMap = EnumMap.create(ContestUpon, ContestUponCode);
