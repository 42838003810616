import { css, cx } from '@emotion/css';
import { ChatSystemContent } from 'app/chat/content/system';
import { ChatMessageData, ChatMessageType } from 'app/chat/definitions';
import { TextMessageContent } from 'app/chat/message-content/text-message-content';
import { ChatRoleBadge } from 'app/chat/role-badge';
import { getChatOperator } from 'app/chat/store';
import { format } from 'date-fns';
import { PlayerRole } from 'domain/player/definitions';
import { PlayerLinkedImage } from 'domain/player/image/linked-image';
import { InstantTooltip } from 'packs/single/instant-tooltip';
import { createElement, useMemo } from 'react';
import { ReactComponent as ReplyIcon } from './assets/reply.svg';
// import { SharedMessageContent } from 'app/chat/message-content/shared-message-content';

const CONTENT_TYPES = {
  // [ChatMessageType.shared]: SharedMessageContent,
  [ChatMessageType.text]: TextMessageContent,
  [ChatMessageType.system]: ChatSystemContent,
};

export type ChatMessageProps = {
  message: ChatMessageData;
  collapsed?: boolean;
};

export const ChatMessage = ({ message }: ChatMessageProps) => {
  return useMemo(() => {
    const { at, type, sender } = message;
    const { name, role } = sender;

    return (
      <div className={mainClass}>
        <PlayerLinkedImage
          player={sender}
          className={cx(imageClass, role === PlayerRole.admin && adminImageClass)}
        />
        <div className={cx(contentClass, role === PlayerRole.admin && adminMessageClass)}>
          <div className={headerBoxClass}>
            <div className={headerUserInfoClass}>
              <InstantTooltip title={name}>
                <div
                  className={cx(nameClass, role === PlayerRole.admin && adminNameClass)}
                  onClick={() => getChatOperator().replyTo(name)}
                >
                  {name}
                </div>
              </InstantTooltip>
              <ChatRoleBadge role={role} />
              <div className={timeClass}>{!at ? null : format(new Date(at), 'HH:mm')}</div>
            </div>
            <ReplyIcon
              className={cx(replyButtonClass, 'replyButtonClass')}
              onClick={() => getChatOperator().replyTo(name)}
            />
          </div>
          <div className={messageClass}>{createElement(CONTENT_TYPES[type], { message })}</div>
        </div>
      </div>
    );
  }, [message.id]);
};

const mainClass = css`
  display: flex;
  position: relative;
  padding: 6px 8px;
  align-items: flex-end;
  gap: 6px;
`;

const imageClass = css`
  width: 32px;
  border-radius: 6px;
`;
const replyButtonClass = css`
  cursor: pointer;
  opacity: 0;
`;

const contentClass = css`
  overflow: hidden;
  cursor: default;
  width: 100%;
  background: #242328;
  border-radius: 12px;
  padding: 12px;
  &:hover {
    background: #2a2930;
    .replyButtonClass {
      opacity: 1;
    }
  }
`;
const headerBoxClass = css`
  display: flex;
  align-items: center;
  padding-bottom: 4px;
`;
const headerUserInfoClass = css`
  display: flex;
  align-items: center;

  width: 100%;
  gap: 6px;
`;

const nameClass = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: copy;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #dfdfdf;
  display: flex;
  align-items: center;
`;

const timeClass = css`
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  color: #696155;
  cursor: default;
`;

const messageClass = css`
  font-weight: 400;
  width: 100%;
  font-size: 12px;
  line-height: 140%;
  color: #988c92;
  overflow-wrap: break-word;
  cursor: text;
`;

const adminImageClass = css`
  pointer-events: none;
`;

const adminNameClass = css`
  font-weight: 700;
  font-size: 12px;
  line-height: 140%;
  color: transparent;
  text-fill-color: transparent;
  background-clip: text;
  background-image: linear-gradient(180deg, #ffaa63 0%, #ea9a4e 100%);
`;

const adminMessageClass = css`
  background: linear-gradient(100deg, #242328 0%, #242328 60%, rgba(240, 167, 98, 15%) 100%);
`;
