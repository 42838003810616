import { getUpgradeOperator } from 'app/upgrade/store';
import { upgradeUnitsWheelCircleArc } from 'app/upgrade/units/wheel/circle-arc';
import {
  UpgradeUnitsWheelColor as Color,
  upgradeUnitsWheelGetColors,
  UpgradeUnitsWheelTheme,
} from 'app/upgrade/units/wheel/get-colors';
import { CSSProperties, ReactNode } from 'react';
import { stash_get } from 'support/etc/stash';
import { createRefEffect } from 'support/react/ref-effect';
import { useSingleton } from 'support/react/use-singleton';
import { graduator } from 'support/struct/graduator';

const CHANCE_CLIP_ID = 'chance_scale_mask';
const BACKGROUND_COLOR = [
  '0 0 0 0 0.172066 0 0 0 0 0.174098 0 0 0 0 0.220833 0 0 0 1 0',
  '0 0 0 0 0.172066 0 0 0 0 0.4098 0 0 0 0 0.220833 0 0 0 0.453999 0',
  '0 0 0 0 0.3967 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0',
];

export type UpgradeWheelProps = {
  op?: (op: UpgradeWheelOperator) => void | (() => void);
  theme?: UpgradeUnitsWheelTheme;
  children?: ReactNode;
};

export class UpgradeWheelOperator {
  constructor(private readonly main: SVGSVGElement) {}

  @stash_get
  get chance() {
    const maskPath = this.chanceClipPath;
    const createCircleArc = upgradeUnitsWheelCircleArc(500, 500, 500);
    let circleArc = createCircleArc(0);
    const grad = graduator({
      decimals: 0,
      onUpdate: (val) => {
        maskPath.setAttribute('d', circleArc(val));
      },
    });

    let currentOffset = 0;

    const normalize = (chance: string) => {
      if (chance === '') return 0;
      const num = parseFloat(chance);
      if (isNaN(num)) return currentOffset;
      if (num === Infinity) return currentOffset;
      return num * 3.6;
    };

    const init = (chance: string, offset: number) => {
      currentOffset = offset;
      circleArc = createCircleArc(currentOffset);
      set(chance);
    };

    const set = (chance: string) => {
      grad.reset(normalize(chance));
    };

    const turn = (chance: string) => {
      grad.update(normalize(chance));
    };

    const move = (delta: number) => {
      let offset = (currentOffset + delta) % 360;
      if (offset < 0) offset = 360 - offset;
      setOffset(offset);
    };

    const setOffset = (offset: number) => {
      currentOffset = offset;
      circleArc = createCircleArc(currentOffset);
      grad.flush();
    };

    const resetOffset = () => {
      setOffset(0);
    };

    const getOffset = () => currentOffset;

    return { set, turn, move, resetOffset, getOffset, init };
  }

  private get chanceClipPath() {
    return this.findById(CHANCE_CLIP_ID).querySelector('path')!;
  }

  public findById<T extends Element>(id: string): T {
    return this.main.querySelector<T>(`#${id}`)!;
  }
}

export const UpgradeUnitsWheel = (props: UpgradeWheelProps): JsxElement => {
  return useSingleton(() => {
    const mainRef = createRefEffect<SVGSVGElement>((el) => {
      const op = new UpgradeWheelOperator(el);
      getUpgradeOperator().wheel = op;
      if (__DEV__) {
        window['upgradeWheelSandbox'] = op;
      }
      return props.op?.(op);
    });

    const compileInnerFrame = () => (
      <>
        {blackBack}
        {whiteBack}
        {props.theme === UpgradeUnitsWheelTheme.success && successGreenBlock}
        {linesInCenter}
        {chanceCircle}
        {arrowBlock}
        {defs}
        <g>{props.children}</g>
      </>
    );

    const toVar = (code: string) => `var(${code})`;
    const VAR_CIRCLES = toVar(Color.circles);

    const chanceCircle = (
      <g>
        <circle cx="500" cy="500" r="440.051" stroke={VAR_CIRCLES} strokeWidth="15" />
        <circle
          cx="500"
          cy="500"
          r="440.051"
          stroke="#EA9A4E"
          strokeWidth="15"
          clipPath={`url(#${CHANCE_CLIP_ID})`}
        />
        <clipPath id={CHANCE_CLIP_ID}>
          <path />
        </clipPath>
      </g>
    );

    const blackBack = (
      <g opacity="1">
        <circle cx="500" cy="500" r="500" fill="#18171C" />
      </g>
    );
    const whiteBack = (
      <g filter="url(#filter0_i_530_3598)">
        <circle cx="499.91" cy="499.911" r="420.324" fill="#1E1C22" />
      </g>
    );
    const successGreenBlock = (
      <g opacity="0.15">
        <circle cx="500" cy="500" r="300" fill="#03ff00">
          <animate
            attributeName="r"
            begin="0s"
            calcMode="spline"
            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
            values="390;330;390"
            keyTimes="0;0.5;1"
            dur="1s"
            repeatCount="indefinite"
          />
        </circle>
      </g>
    );

    const arrowBlock = (
      <g transform="translate(455, -20) scale(4)">
        <path
          d="M0 0L14.501 36L21.7505 18L29 0H0ZM14.501 25.5068L5.6852 3.61997H23.3167L14.501 25.5068Z"
          fill="url(#paint0_linear_530_4228)"
        />
        <path
          d="M14.501 26.5068 L3.6852 2.61997 H25.3167 L14.501 26.5068Z"
          fill="url(#paint1_linear_530_4228)"
        />
      </g>
    );

    const linesInCenter = (
      <g transform="translate(210 190) scale(2.2)">
        <line
          opacity="0.07"
          x1="36.2049"
          y1="0.573975"
          x2="36.2048"
          y2="287.603"
          stroke="url(#paint0_linear_2507_17419)"
          strokeWidth="3.47222"
        />
        <line
          opacity="0.07"
          x1="69.0115"
          y1="0.573975"
          x2="69.0115"
          y2="287.603"
          stroke="url(#paint1_linear_2507_17419)"
          strokeWidth="3.47222"
        />
        <line
          opacity="0.07"
          x1="101.818"
          y1="0.573975"
          x2="101.818"
          y2="287.603"
          stroke="url(#paint2_linear_2507_17419)"
          strokeWidth="3.47222"
        />
        <line
          opacity="0.07"
          x1="134.613"
          y1="0.573975"
          x2="134.613"
          y2="287.603"
          stroke="url(#paint3_linear_2507_17419)"
          strokeWidth="3.47222"
        />
        <line
          opacity="0.07"
          x1="167.424"
          y1="0.573975"
          x2="167.424"
          y2="287.603"
          stroke="url(#paint4_linear_2507_17419)"
          strokeWidth="3.47222"
        />
        <line
          opacity="0.07"
          x1="200.215"
          y1="0.573975"
          x2="200.215"
          y2="287.603"
          stroke="url(#paint5_linear_2507_17419)"
          strokeWidth="3.47222"
        />
        <line
          opacity="0.07"
          x1="233.025"
          y1="0.573975"
          x2="233.025"
          y2="287.603"
          stroke="url(#paint6_linear_2507_17419)"
          strokeWidth="3.47222"
        />
        <line
          opacity="0.07"
          x1="0.537109"
          y1="56.458"
          x2="266.162"
          y2="56.458"
          stroke="url(#paint7_linear_2507_17419)"
          strokeWidth="3.47222"
        />
        <line
          opacity="0.07"
          x1="0.537109"
          y1="122.065"
          x2="266.162"
          y2="122.065"
          stroke="url(#paint8_linear_2507_17419)"
          strokeWidth="3.47222"
        />
        <line
          opacity="0.07"
          x1="0.537109"
          y1="89.261"
          x2="266.162"
          y2="89.261"
          stroke="url(#paint9_linear_2507_17419)"
          strokeWidth="3.47222"
        />
        <line
          opacity="0.07"
          x1="0.537109"
          y1="154.868"
          x2="266.162"
          y2="154.868"
          stroke="url(#paint10_linear_2507_17419)"
          strokeWidth="3.47222"
        />
        <line
          opacity="0.07"
          x1="0.537109"
          y1="187.672"
          x2="266.162"
          y2="187.672"
          stroke="url(#paint11_linear_2507_17419)"
          strokeWidth="3.47222"
        />
        <line
          opacity="0.07"
          x1="0.537109"
          y1="253.277"
          x2="266.162"
          y2="253.277"
          stroke="url(#paint12_linear_2507_17419)"
          strokeWidth="3.47222"
        />
        <line
          opacity="0.07"
          x1="0.537109"
          y1="220.474"
          x2="266.162"
          y2="220.474"
          stroke="url(#paint13_linear_2507_17419)"
          strokeWidth="3.47222"
        />
      </g>
    );

    const defs = (
      <defs>
        <clipPath id="uw-child-clip">
          <circle cx={500} cy={500} r={500} />
        </clipPath>

        <filter
          id="filter0_i_530_3598"
          x="0.626953"
          y="0.626709"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="30"
            operator="erode"
            in="SourceAlpha"
            result="effect1_innerShadow_530_3598"
          />
          <feOffset dx="12" dy="12" />
          <feGaussianBlur stdDeviation="80" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values={BACKGROUND_COLOR[props.theme ?? 0]} />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_530_3598" />
        </filter>

        <linearGradient
          id="paint0_linear_530_4228"
          x1="14.0067"
          y1="36.1308"
          x2="14.1918"
          y2="0.119874"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FAE175" />
          <stop offset="0.19" stopColor="#FAE278" />
          <stop offset="0.31" stopColor="#FBE580" />
          <stop offset="0.41" stopColor="#FDEA8E" />
          <stop offset="0.49" stopColor="#FFF0A0" />
          <stop offset="1" stopColor="#985800" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_530_4228"
          x1="14.0067"
          y1="36.1308"
          x2="14.1918"
          y2="0.119874"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FAE175" />
          <stop offset="0.19" stopColor="#FAE278" />
          <stop offset="0.31" stopColor="#FBE580" />
          <stop offset="0.41" stopColor="#FDEA8E" />
          <stop offset="0.49" stopColor="#FFF0A0" />
          <stop offset="1" stopColor="#985800" />
        </linearGradient>

        <linearGradient
          id="paint0_linear_2507_17419"
          x1="33.4708"
          y1="287.603"
          x2="33.4708"
          y2="0.573975"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#988C92" stopOpacity="0.07" />
          <stop offset="0.510417" stopColor="#988C92" stopOpacity="0.8" />
          <stop offset="1" stopColor="#988C92" stopOpacity="0.07" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2507_17419"
          x1="66.2774"
          y1="287.603"
          x2="66.2774"
          y2="0.573975"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#988C92" stopOpacity="0.07" />
          <stop offset="0.510417" stopColor="#988C92" stopOpacity="0.8" />
          <stop offset="1" stopColor="#988C92" stopOpacity="0.07" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2507_17419"
          x1="99.0841"
          y1="287.603"
          x2="99.0841"
          y2="0.573975"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#988C92" stopOpacity="0.07" />
          <stop offset="0.510417" stopColor="#988C92" stopOpacity="0.8" />
          <stop offset="1" stopColor="#988C92" stopOpacity="0.07" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2507_17419"
          x1="131.879"
          y1="287.603"
          x2="131.879"
          y2="0.573975"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#988C92" stopOpacity="0.07" />
          <stop offset="0.510417" stopColor="#988C92" stopOpacity="0.8" />
          <stop offset="1" stopColor="#988C92" stopOpacity="0.07" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_2507_17419"
          x1="164.69"
          y1="287.603"
          x2="164.69"
          y2="0.573975"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#988C92" stopOpacity="0.07" />
          <stop offset="0.510417" stopColor="#988C92" stopOpacity="0.8" />
          <stop offset="1" stopColor="#988C92" stopOpacity="0.07" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_2507_17419"
          x1="197.481"
          y1="287.603"
          x2="197.481"
          y2="0.573975"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#988C92" stopOpacity="0.07" />
          <stop offset="0.510417" stopColor="#988C92" stopOpacity="0.8" />
          <stop offset="1" stopColor="#988C92" stopOpacity="0.07" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_2507_17419"
          x1="230.291"
          y1="287.603"
          x2="230.291"
          y2="0.573975"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#988C92" stopOpacity="0.07" />
          <stop offset="0.510417" stopColor="#988C92" stopOpacity="0.8" />
          <stop offset="1" stopColor="#988C92" stopOpacity="0.07" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_2507_17419"
          x1="266.162"
          y1="59.192"
          x2="0.537109"
          y2="59.1921"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#988C92" stopOpacity="0.1" />
          <stop offset="0.510417" stopColor="#988C92" stopOpacity="0.65" />
          <stop offset="1" stopColor="#988C92" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_2507_17419"
          x1="266.162"
          y1="124.799"
          x2="0.537109"
          y2="124.799"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#988C92" stopOpacity="0.1" />
          <stop offset="0.510417" stopColor="#988C92" stopOpacity="0.65" />
          <stop offset="1" stopColor="#988C92" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_2507_17419"
          x1="266.162"
          y1="91.995"
          x2="0.537109"
          y2="91.995"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#988C92" stopOpacity="0.1" />
          <stop offset="0.510417" stopColor="#988C92" stopOpacity="0.65" />
          <stop offset="1" stopColor="#988C92" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_2507_17419"
          x1="266.162"
          y1="157.602"
          x2="0.537109"
          y2="157.602"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#988C92" stopOpacity="0.1" />
          <stop offset="0.510417" stopColor="#988C92" stopOpacity="0.65" />
          <stop offset="1" stopColor="#988C92" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_2507_17419"
          x1="266.162"
          y1="190.406"
          x2="0.537109"
          y2="190.406"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#988C92" stopOpacity="0.1" />
          <stop offset="0.510417" stopColor="#988C92" stopOpacity="0.65" />
          <stop offset="1" stopColor="#988C92" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_2507_17419"
          x1="266.162"
          y1="256.011"
          x2="0.537109"
          y2="256.011"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#988C92" stopOpacity="0.1" />
          <stop offset="0.510417" stopColor="#988C92" stopOpacity="0.65" />
          <stop offset="1" stopColor="#988C92" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_2507_17419"
          x1="266.162"
          y1="223.208"
          x2="0.537109"
          y2="223.208"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#988C92" stopOpacity="0.1" />
          <stop offset="0.510417" stopColor="#988C92" stopOpacity="0.65" />
          <stop offset="1" stopColor="#988C92" stopOpacity="0.1" />
        </linearGradient>
      </defs>
    );

    return (
      <svg
        ref={mainRef}
        viewBox="0 0 1200 1200"
        fill="none"
        style={upgradeUnitsWheelGetColors(props.theme) as CSSProperties}
      >
        {compileInnerFrame()}
      </svg>
    );
  });
};
