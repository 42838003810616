import { css } from '@emotion/css';

import { getUpgradeOperator } from 'app/upgrade/store';
import { StdButton } from 'packs/std';
import { createRefEffect } from 'support/react/ref-effect';
import { useUpgradeT } from 'app/upgrade/glossary';
import { useMemo } from 'react';

export const UpgradeWheelOffsetControl = (): JsxElement => {
  const t = useUpgradeT().sub('live-upgrade');

  return useMemo(() => {
    const ref = createRefEffect<HTMLButtonElement>((el) => {
      let closeWindowListener;
      const mouseDownListener = () => {
        const onMouseMove = (e) => {
          getUpgradeOperator().wheel?.chance.move(e.movementY * 2);
        };
        closeWindowListener = () => {
          window.removeEventListener('mousemove', onMouseMove);
          window.removeEventListener('mouseup', closeWindowListener);
          window.removeEventListener('mousedown', closeWindowListener);
        };
        window.addEventListener('mousemove', onMouseMove);
        window.addEventListener('mouseup', closeWindowListener);
        window.removeEventListener('mousedown', closeWindowListener);
      };
      el.addEventListener('mousedown', mouseDownListener);
      return () => {
        el.removeEventListener('mousedown', mouseDownListener);
        closeWindowListener?.();
      };
    });

    return (
      <>
        <StdButton
          buttonRef={ref}
          className={offsetButtonClass}
          onDoubleClick={() => {
            getUpgradeOperator().wheel?.chance.resetOffset();
          }}
        >
          <svg
            width="22px"
            height="24px"
            viewBox="0 0 22 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19.3781 10.4761C18.0805 9.17047 17.3885 8.52887 16.2357 7.51447C15.3133 6.51527 13.5573 6.13527 12.6069 7.29527C11.4629 6.43207 9.98207 7.17047 9.35807 8.30647C8.17567 7.86647 7.13327 8.72967 6.52687 9.67847L4.39727 7.55287C4.01647 7.12887 3.48047 6.87607 2.91087 6.85207C1.67567 6.94167 0.530869 8.02727 0.480469 9.28247C0.504469 9.85207 0.757269 10.3881 1.18127 10.7697L5.85727 15.4457C3.36847 17.2393 5.95007 19.6929 7.45407 21.2833C10.2829 24.6465 16.1029 24.5969 19.0925 21.4673C22.2045 18.5209 22.3485 13.5761 19.3781 10.4761ZM18.4237 20.4409C16.7773 22.2961 14.2037 22.9153 11.8197 22.4385C9.40687 22.1537 7.92127 20.0729 6.47967 18.3377C5.93887 17.6849 5.73647 16.9217 6.71087 16.2993L10.0397 19.6281C10.5933 20.1593 11.4245 19.3393 10.8885 18.7801L2.02927 9.92087C1.21167 9.28247 2.09647 8.14967 2.90127 8.05047C3.15407 8.07927 3.38687 8.20407 3.54927 8.40007L9.52767 14.3785C10.0821 14.9121 10.9117 14.0873 10.3765 13.5305L7.39007 10.5441C8.27167 9.10727 8.94527 9.05207 10.1261 10.2393L12.1197 12.2313C12.6645 12.7929 13.5269 11.9641 12.9821 11.3969C12.5349 10.9953 10.8693 9.20967 10.4069 8.89287C11.0117 8.13207 11.3589 7.62647 12.6445 8.88487L14.7933 11.0313C15.3493 11.5833 16.1957 10.7401 15.6421 10.1825L13.5301 8.07127C14.1453 7.48247 14.6517 7.74967 15.4445 8.41847C16.5685 9.40807 17.1581 9.95367 18.4301 11.2249C20.9597 13.7689 20.9557 17.9001 18.4237 20.4409Z"
              fill="url(#paint0_linear_1313_8616)"
            />
            <path
              d="M4.31487 4.71127H6.28687C7.07167 4.70807 7.07167 3.51447 6.28687 3.51127H5.95487C10.2981 0.377666 16.2509 0.755266 20.1629 4.41287C20.7381 4.94567 21.5565 4.07127 20.9821 3.53287C16.5341 -0.619134 9.73487 -0.940734 4.91487 2.77287V2.13287C4.91167 1.34887 3.71807 1.34807 3.71487 2.13287V4.11127C3.71487 4.44247 3.98367 4.71127 4.31487 4.71127Z"
              fill="url(#paint1_linear_1313_8616)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_1313_8616"
                x1="4"
                y1="5.96941e-07"
                x2="20"
                y2="25"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0.395833" stopColor="#988C92" stopOpacity="0.2" />
                <stop offset="1" stopColor="#AAA0A6" stopOpacity="0.6" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_1313_8616"
                x1="4"
                y1="5.96941e-07"
                x2="20"
                y2="25"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0.395833" stopColor="#988C92" stopOpacity="0.2" />
                <stop offset="1" stopColor="#AAA0A6" stopOpacity="0.6" />
              </linearGradient>
            </defs>
          </svg>
        </StdButton>
        <div className={offsetButtonText}>{t('offset-button')}</div>
      </>
    );
  }, [t]);
};

const offsetButtonClass = css`
  cursor: ns-resize;
  background: transparent;
  border-radius: 4px;
  width: 50px;
  height: 50px;
`;

const offsetButtonText = css`
  font-weight: 400;
  font-size: 11px;
  letter-spacing: -0.003em;
  color: #988c92;
`;
