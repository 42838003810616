import { css } from '@emotion/css';
import { PlayerImage } from 'domain/player/image/image';
import { PlayerData } from 'app/case/battle/insight/results/dialog';
import { ReactComponent as BotAvatar } from 'app/case/battle/assets/bot-avatar.svg';
import { ReactComponent as BotIcon } from 'app/case/battle/insight/assets/bot-icon.svg';
import { useCaseBattleInsightT } from '../glossary';
import { stdBp } from 'packs/std';

type CaseBattleInsightResultMembersProps = {
  members: PlayerData[];
};

export const CaseBattleInsightResultMembers = ({
  members,
}: CaseBattleInsightResultMembersProps): JsxElement => {
  const t = useCaseBattleInsightT();
  return (
    <div className={mainClass}>
      {...members.map((member) => (
        <div className={memberBoxClass}>
          {member.bot ? (
            <BotAvatar className={memberAvatarClass} />
          ) : (
            <PlayerImage code={member.image} className={memberAvatarClass} />
          )}

          {member.bot ? (
            <div className={nameBoxClass}>
              <div className={memberNameClass}>{t('bot.name')}</div>
              <BotIcon className={botIconClass} />
            </div>
          ) : (
            <div className={memberNameClass}>{member.name}</div>
          )}

          {/* <span className={memberNameClass}>{member.bot ? t('bot.name') : member.name}</span> */}
        </div>
      ))}
    </div>
  );
};

const mainClass = css`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 32px;
  gap: 2px;

  ${stdBp.up(1920)} {
    padding: 38px 32px;
    gap: 4px;
  }
`;

const memberBoxClass = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2px;

  ${stdBp.up(1920)} {
    gap: 4px;
  }
`;

const memberAvatarClass = css`
  border-radius: 8px;
  width: 48px;
  height: 48px;

  ${stdBp.up(1920)} {
    width: 57px;
    height: 57px;
  }
`;
const botIconClass = css`
  height: 14px;
  path {
    fill: #0fac7f;
  }

  ${stdBp.up(1920)} {
    height: 16px;
  }
`;

const memberNameClass = css`
  font-family: 'Onest';
  font-weight: 700;
  font-size: 13px;
  line-height: 140%;
  color: #ffffff;
  max-width: 88px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${stdBp.up(1920)} {
    font-size: 15px;
  }
`;
const nameBoxClass = css`
  display: flex;
  gap: 3px;
  align-items: center;
  padding-top: 10px;

  ${stdBp.up(1920)} {
    gap: 4px;
  }
`;
