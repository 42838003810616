import { css } from '@emotion/css';
import { ReactComponent as BattleIcon } from 'app/case/battle/insight/results/assets/battle.svg';
import { ReactComponent as ReturnIcon } from 'app/case/battle/insight/results/assets/return.svg';
import { useCaseBattleInsightOperator } from 'app/case/battle/insight/store';
import { StdKindButton } from 'packs/std/inputs/kind-button';
import { Link } from 'react-router-dom';
import { useCaseBattleInsightT } from '../glossary';
import { stdBp } from 'packs/std';

type CaseBattleInsightResultButtonsProps = {
  onClose(): void;
  duplicate(): void;
  cost: number;
  battleId: string;
};

export const CaseBattleInsightResultButtons = ({
  cost,
  battleId,
  onClose,
  duplicate,
}: CaseBattleInsightResultButtonsProps): JsxElement => {
  const t = useCaseBattleInsightT().sub('results.buttons');
  const op = useCaseBattleInsightOperator();

  const doDuplicate = () => {
    onClose();
    duplicate();
  };

  const doReplay = () => {
    onClose();
    op.replay();
  };

  return (
    <div className={mainClass}>
      <Link to={`/case-battles/${battleId}`} onClick={doReplay}>
        <div className={returnBattleClass}>
          <ReturnIcon />
          {t('viewing-battle')}
        </div>
      </Link>
      <StdKindButton className={buttonCreateClass} kind="orange" onClick={doDuplicate}>
        <BattleIcon />
        <span>
          {t('make-battle')} ${cost.toFixed(2)}
        </span>
      </StdKindButton>
      <Link to={'/case-battles'} className={returnBattleExitClass} onClick={onClose}>
        {t('back-to-list')}
      </Link>
    </div>
  );
};

const mainClass = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;

  ${stdBp.up(1920)} {
    gap: 21px;
  }
`;

const buttonCreateClass = css`
  width: 350px;
  height: 40px;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 13px;
  border-radius: 4px;

  svg {
    width: 13px;
    height: 13px;
  }

  ${stdBp.up(1920)} {
    font-size: 15px;
    width: 420px;
    height: 48px;

    svg {
      width: 15px;
      height: 15px;
    }
  }
`;

const returnBattleExitClass = css`
  padding-top: 4px;
  font-family: 'Onest';
  font-weight: 700;
  font-size: 13px;
  color: #988c92;
  cursor: pointer;

  ${stdBp.up(1920)} {
    font-size: 15px;
`;

const returnBattleClass = css`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-family: 'Onest';
  font-weight: 700;
  font-size: 13px;
  color: #988c92;
  cursor: pointer;

  svg {
    width: 13px
    height: 13px;
  }

  ${stdBp.up(1920)} {
    font-size: 15px;
  
    svg {
      width: 15px;
      height: 15px;
    }
  }
`;
