import { ContestCardDoubleColumnRow } from 'app/contest/card/units/double-column-row';
import { ContestCardJoined } from 'app/contest/card/units/joined';
import { ContestCardMainBox } from 'app/contest/card/units/main-box';
import { ContestCardPrize } from 'app/contest/card/units/prize';
import { ContestCardStatusFinished } from 'app/contest/card/units/status-finished';
import { ContestCardWinner } from 'app/contest/card/units/winner';
import { ContestBriefData } from 'domain/contest';
import { ReactNode } from 'react';

type ContestCardStatusFinishedProps = {
  contest: ContestBriefData;
  children?: ReactNode;
};

export const ContestCardFinished = ({
  children,
  contest,
}: ContestCardStatusFinishedProps): JsxElement => {
  return (
    <ContestCardMainBox data={contest}>
      <ContestCardStatusFinished contest={contest} />
      <ContestCardDoubleColumnRow>
        <ContestCardJoined contest={contest} />
        <ContestCardPrize contest={contest} />
      </ContestCardDoubleColumnRow>
      {children}
      <ContestCardWinner contest={contest} />
    </ContestCardMainBox>
  );
};
