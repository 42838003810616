import { FC } from 'react';

interface Props {
  className?: string;
}

export const CartIcon: FC<Props> = ({ className }) => (
  <svg className={className} viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.333252 1.33337C0.333252 1.05723 0.55711 0.833374 0.833252 0.833374H2.16659C3.17905 0.833374 3.99983 1.6541 3.99992 2.66654H10.9999C12.3018 2.66654 13.3857 3.59945 13.6198 4.83321H2.99991V2.66654H2.99992C2.99983 2.20638 2.62677 1.83337 2.16659 1.83337H0.833252C0.55711 1.83337 0.333252 1.60952 0.333252 1.33337ZM5.33324 14.6665C5.88553 14.6665 6.33324 14.2188 6.33324 13.6665C6.33324 13.1143 5.88553 12.6665 5.33324 12.6665C4.78096 12.6665 4.33324 13.1143 4.33324 13.6665C4.33324 14.2188 4.78096 14.6665 5.33324 14.6665ZM11.3332 14.6665C11.8855 14.6665 12.3332 14.2188 12.3332 13.6665C12.3332 13.1143 11.8855 12.6665 11.3332 12.6665C10.781 12.6665 10.3332 13.1143 10.3332 13.6665C10.3332 14.2188 10.781 14.6665 11.3332 14.6665ZM2.99991 5.83321H13.6666V8.66654C13.6666 10.1393 12.4727 11.3332 10.9999 11.3332H5.66658C4.19382 11.3332 2.99991 10.1393 2.99991 8.66654V5.83321Z"
      fill="#988C92"
    />
  </svg>
);
