import { css } from '@emotion/css';
import { ReactNode } from 'react';

type ContestCardDoubleColumnRowProps = {
  children: [ReactNode, ReactNode];
};

export const ContestCardDoubleColumnRow = ({
  children,
}: ContestCardDoubleColumnRowProps): JsxElement => {
  return <div className={mainClass}>{children}</div>;
};

const mainClass = css`
  display: grid;
  gap: 20px;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  width: 100%;
`;
