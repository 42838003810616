import { css } from '@emotion/css';
import { ReactComponent as SwordIcon } from 'app/case/battle/assets/sword.svg';
import { useCaseBattleMonitorT } from 'app/case/battle/monitor/glossary';
import { CaseBattleMonitorCreateBattleSound } from 'app/case/battle/monitor/sounds';
import { ContestUpon } from 'domain/contest';
import { ContestLiveGameModeBadge } from 'domain/contest/live/units/game-mode-badge';
import { chronicle } from 'packs/libs/chronicle';
import { StdButton } from 'packs/std';
import { Link } from 'react-router-dom';

export const CaseBattleMonitorHeader = (): JsxElement => {
  const t = useCaseBattleMonitorT().sub('header');
  return (
    <div className={mainClass}>
      <div className={titleClass}>{t('title')}</div>
      <div className={boxClass}>
        <ContestLiveGameModeBadge upon={ContestUpon.caseBattle} className={contestNameClass} />
        <Link to={'/case-battles/create'}>
          <StdButton
            className={createBattleClass}
            onClick={() => {
              CaseBattleMonitorCreateBattleSound.replay();
              chronicle.push('/case-battles/create');
            }}
          >
            <SwordIcon />
            {t('create-battle')}
          </StdButton>
        </Link>
      </div>
    </div>
  );
};

const mainClass = css`
  max-width: 1872px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(180deg, #1f1d23 0%, rgba(30, 28, 34, 0.6) 100%);
  padding: 13px 32px 12px;
  border-radius: 12px;
`;

const titleClass = css`
  font-family: 'Onest';
  font-weight: 700;
  font-size: 20px;
  line-height: 140%;
`;

const boxClass = css`
  display: flex;
  gap: 32px;
  align-items: center;
`;

const contestNameClass = css`
  background: #18171c;
`;

const createBattleClass = css`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  gap: 5px;

  width: 166px;
  height: 48px;

  background: linear-gradient(180deg, #ffaa63 0%, #ea9a4e 100%);
  box-shadow: inset 0 4px 12px 2px #ff7324;
  border-radius: 4px;
  border: 0;
  color: #2d241c;

  font-family: 'Onest';
  font-weight: 700;
  font-size: 13px;

  &:hover {
    background: #ffbe88;
    box-shadow: none;
  }
`;
