import { css, cx } from '@emotion/css';
import { useMemo } from 'react';
import { CaseBattleInsightGlossary, useCaseBattleInsightT } from 'app/case/battle/insight/glossary';
import { useCaseBattleInsightBaseState } from 'app/case/battle/insight/store';
import { showIntlSuccessToast } from 'packs/libs/intl/toasts';
import { ReactComponent as BattleLinkIcon } from './icons/battle-link-icon.svg';
import { ReactComponent as FairIcon } from './icons/fair-icon.svg';

export const CaseBattleInsightHeaderInfo = (): JsxElement => {
  return (
    <div className={mainClass}>
      <ProvablyFair />
      <BattleLink />
    </div>
  );
};

const BattleLink = (): JsxElement => {
  const { id } = useCaseBattleInsightBaseState();
  const t = useCaseBattleInsightT().sub('game-header');
  return useMemo(() => {
    return (
      <div className={cx(linkBoxClass)}>
        <div className={battleIdClass}>ID {id}</div>
        <div
          className={linkClass}
          onClick={async () => {
            await navigator.clipboard.writeText(window.location.href);
            showIntlSuccessToast(CaseBattleInsightGlossary, 'game-header.copied');
          }}
        >
          <BattleLinkIcon /> {t('battle-link')}
        </div>
      </div>
    );
  }, [id]);
};

const ProvablyFair = (): JsxElement => {
  const { ticketId } = useCaseBattleInsightBaseState();
  const t = useCaseBattleInsightT().sub('game-header');
  return useMemo(() => {
    return (
      <a
        href={`https://api.random.org/tickets/form?ticket=${ticketId}`}
        target="_blank"
        rel="noreferrer"
        className={fairBoxClass}
      >
        <FairIcon />
        {t('fair-game')}
      </a>
    );
  }, [ticketId]);
};

const mainClass = css`
  display: flex;
  background: linear-gradient(180deg, #1f1d23 0%, rgba(30, 28, 34, 0.6) 100%);
  width: 270px;
`;

const textClass = css`
  font-weight: 500;
  font-size: 11px;
  line-height: 140%;
  color: #988c92;
`;
const whiteTextClass = css`
  ${textClass};
  color: #dfdfdf;
`;
const fairBoxClass = css`
  ${textClass};
  flex: 1;
  color: #0fac7f;
  display: flex;
  gap: 3.5px;
  align-items: center;
  justify-content: center;
  background: rgba(15, 172, 127, 0.06);
  height: 100%;
  width: 129px;

  svg {
    height: 13px;
  }
`;
const linkBoxClass = css`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 4px;
`;
const battleIdClass = css`
  ${textClass};
  color: #988c92;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 16px;
`;
const linkClass = css`
  ${whiteTextClass};
  display: flex;
  font-size: 11px;
  align-items: center;
  cursor: pointer;
  padding-left: 16px;
  gap: 1.5px;

  svg {
    height: 13px;
  }
`;
