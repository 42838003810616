import { ReactNode, useEffect, useRef } from 'react';


type LayoutSidebarNavPanelOutsideClickHandlerProps = {
  children: ReactNode;
  onOutsideClick: ()=> void;
}
export const LayoutSidebarNavPanelOutsideClickHandler = ({ children, onOutsideClick }: LayoutSidebarNavPanelOutsideClickHandlerProps) => {
  const targetRef = useRef<any>(null);

  useEffect(() => {
    const handleClick = (event) => {
      if (targetRef.current && !targetRef.current.contains(event.target)) {
        onOutsideClick();
      }
    };
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [onOutsideClick]);

  return <div ref={targetRef}>{children}</div>;
}