import { css, cx } from '@emotion/css';
import { CaseBattleCreationNumberOfPlayersSound } from 'app/case/battle/creation/sounds';
import { StdButton } from 'packs/std';
import { useCaseBattleCreationT } from '../glossary';
import { useCaseBattleCreationStore } from '../store';
import { ReactComponent as PlayerIcon } from './assets/user.svg';

const SIZES = [2, 3, 4] as const;

export const ConfigPlayers = (): JsxElement => {
  const [state, op] = useCaseBattleCreationStore();
  const t = useCaseBattleCreationT().sub('config');
  return (
    <div className={mainClass}>
      <div className={labelClass}>
        <PlayerIcon className={iconClass} />
        <span className={labelTextClass}>{t('players')}</span>
      </div>
      <div className={buttonsBoxClass}>
        {SIZES.map((size) => (
          <StdButton
            className={cx(buttonClass, state.config.size === size && activeClass)}
            key={size}
            onClick={() => {
              op.config.updState({ size });
              CaseBattleCreationNumberOfPlayersSound.replay();
            }}
          >
            {size}
          </StdButton>
        ))}
      </div>
    </div>
  );
};

const mainClass = css`
  background: #1e1c22;
  height: 66px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 18px;
`;
const labelClass = css`
  display: flex;
  align-items: center;
  gap: 6px;
`;
const iconClass = css`
  width: 20px;
  height: 20px;
`;
const labelTextClass = css`
  font-weight: 700;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #dfdfdf;
`;
const buttonsBoxClass = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
`;
const activeClass = css`
  color: #18171c;
  background: linear-gradient(180deg, #ffaa63 0%, #ffaa63 0.01%, #ea9a4e 100%);
`;
const buttonClass = css`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2a2930;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  font-family: 'Onest';
  font-weight: 700;
  font-size: 13px;
  text-align: center;
  letter-spacing: -0.03em;
  color: #dfdfdf;
`;
