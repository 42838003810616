import { SkinData } from 'domain/skin/definitions';

type Time = number;

export type AnyPromo = BalancePromo | ReplPromo | SkinPromo;

type BasePromo = {
  code: string; // можно и не слать но вообще пусть
  expiresAt: Time;
  activatedAt?: Time;
};

export type BalancePromo = BasePromo & {
  type: PromoType.balance;
  amount: number;
};

export type SkinPromo = BasePromo & {
  type: PromoType.skin;
  skin: SkinData;
  cost: number;
};

export type ReplPromo = ReplPercentPromo | ReplAmountPromo;

type BaseReplPromo = BasePromo & {
  type: PromoType.replenish;
  timer?: number; // timer duration in milliseconds
  timerEnds?: number; // timer ends in milliseconds
  kind: ReplPromoKind;
  value: number;
  minDep?: number; // min dep cash float
};

export enum ReplPromoKind {
  percent = 'percent',
  amount = 'amount',
}

export type ReplPercentPromo = BaseReplPromo & {
  kind: ReplPromoKind.percent;
};

export type ReplAmountPromo = BaseReplPromo & {
  kind: ReplPromoKind.amount;
};

export enum PromoType {
  balance = 'balance',
  replenish = 'replenish',
  skin = 'skin',
}
