import { css } from '@emotion/css';
import { CrushRecent } from 'app/crush/recent/recent';
import { CrushSceneBackground } from 'app/crush/scene/background-animation/background';
import { CrushSceneChick } from 'app/crush/scene/chick/chick';
import { CrushSceneClouds } from 'app/crush/scene/clouds';
import { CrushSceneCounters } from 'app/crush/scene/counters/counters';
import { CrushSceneWinners } from './winners';
import { ContestLiveGameModeBadge } from 'domain/contest/live/units/game-mode-badge';
import { ContestUpon } from 'domain/contest';

export const CrushScene = (): JsxElement => {
  return (
    <div className={mainClass}>
      <CrushRecent />
      <CrushSceneBackground />
      <CrushSceneClouds />
      <div className={contestNameClass}>
        <ContestLiveGameModeBadge upon={ContestUpon.crush} />
      </div>
      <div className={countersBoxClass}>
        <CrushSceneCounters />
      </div>
      <div className={winnersBoxClass}>
        <CrushSceneWinners />
      </div>
      <CrushSceneChick />
    </div>
  );
};

const mainClass = css`
  flex: 1;
  position: relative;
  overflow: hidden;
`;

const winnersBoxClass = css`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 60px;
  display: flex;
  justify-content: center;
`;

const countersBoxClass = css`
  position: absolute;
  top: 40%;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
`;

const contestNameClass = css`
  position: absolute;
  top: 50px;
  left: 4px;
`;
