import { QuestsGlossary } from 'app/quests/glossary';
import { QuestChapterPeriod, QuestCode } from 'domain/quest';
import { coilListen, coilReq } from 'packs/libs/coil';
import { getIntl } from 'packs/libs/intl';
import { showIntlSuccessToast } from 'packs/libs/intl/toasts';
import React, { createContext, createElement, useContext, useEffect } from 'react';
import { MutSpec } from 'support/etc/immutate';
import { useBasicSwissStore } from 'support/react/swiss';
import { PeriodicQuest, QuestChapter, QuestsState, SingleQuest } from './definitions';

const Context = createContext<QuestsState>(null!);
if (__DEBUG__) Context.displayName = 'QuestContext';

export const useQuestsState = () => useContext(Context);

type QuestsOperator = {
  sync(): void;
  single: {
    takeReward(): void;
  };
  chapter: {
    takeReward(period: QuestChapterPeriod, id: string): void;
  };
};

let globalOperator: QuestsOperator;

export const getQuestsOperator = () => globalOperator;

export function QuestsStoreProvider({ children }: Rcp) {
  const [value, effect] = useBasicSwissStore<QuestsState, React.EffectCallback>(() => [
    null!,
    ({ iupState, mutState, setState, getState }) => {
      const notify = (code: string, data?: Rsa) =>
        showIntlSuccessToast(QuestsGlossary, `notify.${code}`, data);
      const notifyQuestCompleted = (title: string) => notify('quest.completed', { title });
      const notifyRewardTook = () => notify('reward-took');

      const checkPeriodicQuestComplete = (quest: PeriodicQuest) => quest.progress >= quest.edge;

      // function reload() {
      //   coilReq({
      //     action: "quest.load",
      //     inf: "e",
      //   }).then(setState);
      // }

      const mutChapter = (period: QuestChapterPeriod, spec: MutSpec<QuestChapter>) =>
        mutState((draft) => spec(draft.chapters[period]));

      const applyPeriodicQuest = (
        period: QuestChapterPeriod,
        questId: string,
        cb: (quest: PeriodicQuest) => PeriodicQuest
      ) =>
        mutChapter(period, (draft) => {
          for (const [i, quest] of draft.quests.entries())
            if (quest.id === questId) draft.quests[i] = cb(quest);
        });

      const updPeriodicQuest = (
        period: QuestChapterPeriod,
        questId: string,
        state: Partial<PeriodicQuest>
      ) => applyPeriodicQuest(period, questId, (quest) => ({ ...quest, ...state }));

      const mutSingleQuest = (code: QuestCode, spec: MutSpec<SingleQuest>) =>
        mutState((draft) => {
          for (const quest of draft.single.list) if (quest.code === code) spec(quest);
        });

      globalOperator = {
        single: {
          takeReward() {
            coilReq({
              action: 'quest.single.take-reward',
            }).then(() => {
              mutState((draft) => {
                draft.single.took = true;
              });
              notifyRewardTook();
            });
          },
        },
        chapter: {
          takeReward(period: QuestChapterPeriod, id: string) {
            coilReq({
              action: 'quest.chapter.take-reward',
              data: id,
            }).then(() => {
              mutChapter(period, (draft) => {
                draft.took = true;
              });
              notifyRewardTook();
            });
          },
        },

        sync() {
          coilReq({
            action: 'quest.stats',
          }).then((chapters) => {
            mutState((draft) => {
              for (const [period, quests] of chapters) {
                const questMap = new Map(quests);
                if (!draft.chapters[period]) return;
                for (const quest of draft.chapters[period].quests) {
                  quest.totalCompleted = questMap.get(quest.id);
                }
              }
            });
          });
        },
      };

      const coilListeners: Rsa = {
        all: (data) => {
          setState(data);
        },

        'chapter.new': (data) => {
          iupState({ chapters: { $merge: { [data.period]: data } } });
        },

        'periodic.progress': ([questId, progress]: [string, number]) => {
          function getQuestAndChapter(): [PeriodicQuest, QuestChapter] {
            for (const chapter of Object.values(getState().chapters)) {
              for (const quest of chapter.quests) {
                if (quest.id === questId) return [quest, chapter];
              }
            }
            throw new Error(`Quest ${questId} not found`);
          }

          const [quest, chapter] = getQuestAndChapter();
          const { edge } = quest;

          updPeriodicQuest(chapter.period, questId, { progress });

          if (progress >= edge) {
            notifyQuestCompleted(getIntl().fork(quest.title));

            // check chapter complete
            for (const quest of getState().chapters[chapter.period].quests)
              if (!checkPeriodicQuestComplete(quest)) return;

            notify('chapter.completed', chapter);
          }
        },

        'single.progress': ([code, progress]: [QuestCode, number]) => {
          let complete = false;
          mutSingleQuest(code, (quest) => {
            quest.progress = progress;
            complete = progress >= quest.edge;
          });
          if (complete) {
            notifyQuestCompleted(
              getIntl().get(QuestsGlossary).sub('single.lists')(`${code}.title`)
            );

            const allComplete = !getState().single.list.some(
              (quest) => quest.progress < quest.edge
            );

            if (allComplete) {
              mutState((draft) => {
                draft.single.complete = true;
              });
            }
          }
        },
      };

      return () =>
        coilListen('quests', ([event, data]) => {
          coilListeners[event](data);
        });
    },
  ]);

  useEffect(effect, []);

  return createElement(Context.Provider, { value }, children);
}

// export function useHasAtLeastOneSingleQuestIncomplete() {
//   return hasAtLeastOneSingleQuestIncomplete(useBonusState());
// }
