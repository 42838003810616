import { css, cx } from '@emotion/css';

type Props = {
  streak: number;
  className?: string;
};

export const UpgradeUnitsStreakBadge = ({ streak, className }: Props): JsxElement => {
  return (
    <img
      className={cx(mainClass, className)}
      src={`/assets/upgrade/streak-badge/${streak}.png`}
      alt={`streak ${streak}`}
    />
  );
};

const mainClass = css`
  filter: drop-shadow(0px 0px 20px #b8ccff);
`;
