import { css } from '@emotion/css';
import { useContestT } from 'app/contest/glossary';
import { ReactComponent as MembersIcon } from 'app/contest/insight/assets/members.svg';
import { ContestsInsightTopTableBody } from 'app/contest/insight/top/body';
import { ContestsInsightTopTableHead } from 'app/contest/insight/top/head';
import { ContestData, ContestTopListItem } from 'domain/contest';

type ContestInsightTableProps = {
  contest: ContestData;
  top: ContestTopListItem[];
};

export const ContestInsightTopTable = ({ contest, top }: ContestInsightTableProps): JsxElement => {
  const t = useContestT().sub('insight.table');

  return (
    <div className={mainClass}>
      <div className={boxClass}>
        <MembersIcon />
        <p className={titleClass}>{t('title')}</p>
      </div>
      <ContestsInsightTopTableHead />
      <ContestsInsightTopTableBody contest={contest} top={top} />
    </div>
  );
};

const mainClass = css`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const boxClass = css`
  background: #1e1c22;
  border-radius: 12px;
  padding: 24px;
  display: flex;
  aling-items: center;
  gap: 6px;
`;

const titleClass = css`
  font-family: 'Commissioner';
  font-weight: 700;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #dfdfdf;
`;
