import { useEffect } from 'react';
import { UpgradeUnitsWheel, UpgradeUnitsWheelTheme } from 'app/upgrade/units/wheel';
import { UPGRADE_LOST_SCENE_DURATION } from 'app/upgrade/definitions';
import { getUpgradeOperator } from 'app/upgrade';
import { useUpgradeT } from 'app/upgrade/glossary';
import { css } from '@emotion/css';

if (__DEV__) {
  window['upgrade_result_lost_freeze'] = !!window['upgrade_result_lost_freeze'];
}

export const UpgradeResultLost = (): JsxElement => {
  useEffect(() => {
    if (__DEV__ && window['upgrade_result_lost_freeze']) return;

    setTimeout(() => {
      getUpgradeOperator().reset();
    }, UPGRADE_LOST_SCENE_DURATION);
  }, []);
  const t = useUpgradeT().sub('upgrade.result.lost');

  return (
    <div>
      <UpgradeUnitsWheel theme={UpgradeUnitsWheelTheme.fail}>
        <g transform="scale(4.1) translate(100 80)">
          <path
            d="M30.525 24.0002L46.65 7.87523C48.4594 6.07523 48.4594 3.1596 46.6687 1.35023C44.8687 -0.459146 41.9531 -0.459146 40.1438 1.33148L40.1344 1.34085L24.0094 17.4659L7.875 1.35023C6.075 -0.449771 3.15 -0.449771 1.35 1.35023C-0.45 3.15023 -0.45 6.07523 1.35 7.87523L17.475 24.0002L1.35 40.1252C-0.45 41.9252 -0.45 44.8502 1.35 46.6502C3.15 48.4502 6.075 48.4502 7.875 46.6502L24 30.5252L40.125 46.6502C41.925 48.4502 44.85 48.4502 46.65 46.6502C48.45 44.8502 48.45 41.9252 46.65 40.1252L30.525 24.0002Z"
            fill="#FF5A5A"
          />
        </g>

        <foreignObject x={200} y={550} width={600} height={100} style={{ textAlign: 'center' }}>
          <span className={textClass}>{t('title')}</span>
        </foreignObject>
      </UpgradeUnitsWheel>
    </div>
  );
};

const textClass = css`
  font-family: 'Commissioner';
  font-weight: 700;
  font-size: 70px;
  line-height: 140%;
  color: #ff5a5a;
`;
