import React from 'react';
import { css } from '@emotion/css';

import { useUpgradeT } from 'app/upgrade/glossary';
import { ReactComponent as ChooseSkinImage } from 'app/upgrade/assets/choose-skin-image.svg';

export const UpgradeNoItemsSelected = (): JsxElement => {
  const t = useUpgradeT().sub('stake');

  return (
    <div className={mainClass}>
      <ChooseSkinImage className={imageClass} />
      <h3 className={titleClass}>{t('choose-skin')}</h3>
    </div>
  );
};

const mainClass = css`
  padding-top: 25px;
  display: flex;
  flex-direction: column;
  gap: 18px;
  justify-content: center;
  align-items: center;
  max-width: 356px;
`;

const titleClass = css`
  width: 175px;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  color: #dfdfdf;
`;
const imageClass = css`
  width: 142px;
  height: 70px;
`;
