import { css } from '@emotion/css';

import { ChatMessageContentProps } from 'app/chat/definitions';

export function TextMessageContent({ message: { id, content } }: ChatMessageContentProps) {
  return <div className={mainClass} id={id} dangerouslySetInnerHTML={{ __html: content }}></div>;
}

const mainClass = css`
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  color: #988c92;

  .censored {
    color: transparent;
    text-shadow: 0 0 5px #988c92;
  }
`;
