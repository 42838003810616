export const HelpIcon = ({ active, current }) => {
  return active || current ? (
    <svg viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 2.0625C4.58274 2.0625 1.8125 4.83274 1.8125 8.25V10.3125H0.6875V8.25C0.6875 4.21142 3.96142 0.9375 8 0.9375C12.0386 0.9375 15.3125 4.21142 15.3125 8.25V10.3125H14.1875V8.25C14.1875 4.83274 11.4173 2.0625 8 2.0625Z"
          fill="url(#paint0_linear_207_1591)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.75 9.9375C15.0607 9.9375 15.3125 10.1893 15.3125 10.5V12C15.3125 14.7959 13.0459 17.0625 10.25 17.0625H8C7.68934 17.0625 7.4375 16.8107 7.4375 16.5C7.4375 16.1893 7.68934 15.9375 8 15.9375H10.25C12.4246 15.9375 14.1875 14.1746 14.1875 12V10.5C14.1875 10.1893 14.4393 9.9375 14.75 9.9375Z"
          fill="url(#paint1_linear_207_1591)"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.6875 9.75C9.6875 8.1967 10.9467 6.9375 12.5 6.9375C14.0533 6.9375 15.3125 8.1967 15.3125 9.75V11.25C15.3125 12.8033 14.0533 14.0625 12.5 14.0625C10.9467 14.0625 9.6875 12.8033 9.6875 11.25V9.75Z"
        fill="url(#paint2_linear_207_1591)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.6875 9.75C0.6875 8.1967 1.9467 6.9375 3.5 6.9375C5.0533 6.9375 6.3125 8.1967 6.3125 9.75V11.25C6.3125 12.8033 5.0533 14.0625 3.5 14.0625C1.9467 14.0625 0.6875 12.8033 0.6875 11.25V9.75Z"
        fill="url(#paint3_linear_207_1591)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_207_1591"
          x1="8"
          y1="0.9375"
          x2="8"
          y2="17.0625"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFAA63" />
          <stop offset="1" stopColor="#EA9A4E" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_207_1591"
          x1="8"
          y1="0.9375"
          x2="8"
          y2="17.0625"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFAA63" />
          <stop offset="1" stopColor="#EA9A4E" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_207_1591"
          x1="8"
          y1="6.9375"
          x2="8"
          y2="14.0625"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFAA63" />
          <stop offset="1" stopColor="#EA9A4E" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_207_1591"
          x1="8"
          y1="6.9375"
          x2="8"
          y2="14.0625"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFAA63" />
          <stop offset="1" stopColor="#EA9A4E" />
        </linearGradient>
      </defs>
    </svg>
  ) : (
    <svg viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.4">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 2.0625C4.58274 2.0625 1.8125 4.83274 1.8125 8.25V10.3125H0.6875V8.25C0.6875 4.21142 3.96142 0.9375 8 0.9375C12.0386 0.9375 15.3125 4.21142 15.3125 8.25V10.3125H14.1875V8.25C14.1875 4.83274 11.4173 2.0625 8 2.0625Z"
          fill="#988C92"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.75 9.9375C15.0607 9.9375 15.3125 10.1893 15.3125 10.5V12C15.3125 14.7959 13.0459 17.0625 10.25 17.0625H8C7.68934 17.0625 7.4375 16.8107 7.4375 16.5C7.4375 16.1893 7.68934 15.9375 8 15.9375H10.25C12.4246 15.9375 14.1875 14.1746 14.1875 12V10.5C14.1875 10.1893 14.4393 9.9375 14.75 9.9375Z"
          fill="#988C92"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.6875 9.75C9.6875 8.1967 10.9467 6.9375 12.5 6.9375C14.0533 6.9375 15.3125 8.1967 15.3125 9.75V11.25C15.3125 12.8033 14.0533 14.0625 12.5 14.0625C10.9467 14.0625 9.6875 12.8033 9.6875 11.25V9.75Z"
        fill="#988C92"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.6875 9.75C0.6875 8.1967 1.9467 6.9375 3.5 6.9375C5.0533 6.9375 6.3125 8.1967 6.3125 9.75V11.25C6.3125 12.8033 5.0533 14.0625 3.5 14.0625C1.9467 14.0625 0.6875 12.8033 0.6875 11.25V9.75Z"
        fill="#988C92"
      />
    </svg>
  );
};
