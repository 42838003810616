import { chronicle } from 'packs/libs/chronicle';
import { parse, stringify } from 'qs';
import { useEffect, useState } from 'react';
import { SimpleEventTarget } from 'support/struct/simple-event-target';

/**
 * Handy lib for working with url search params
 */

const Subscription = new SimpleEventTarget<Rsa>();

function isInt(value: string): boolean {
  return /^-{0,1}\d+$/.test(value);
}

export const queEncode = (query: Rsa) => '?' + stringify(query, {});
export const queDecode = (query: string) =>
  parse(query.slice(1), {
    decoder(val, dec, _charset, type) {
      if (type === 'value' && typeof val === 'string') {
        if (isInt(val)) return parseInt(val, 10);
      }
      return dec(val);
    },
  });

let lastSearch = window.location.search;
let currentQue = queDecode(lastSearch);

export function getQue<Q extends Rsu = Rsa>(): Q {
  return currentQue as Q;
}

// @ts-ignore
chronicle.listen(({ location }) => {
  const search = location.search;
  if (search !== lastSearch) {
    Subscription.emit((currentQue = queDecode(search)));
    lastSearch = search;
  }
});

export function useQue<Q extends Rsu = Rsa>(): Q {
  const store = useState<any>(currentQue);
  useEffect(() => Subscription.sub(store[1]), []);
  return store[0];
}

export function queReplace<T extends Rsu = Rsa>(query: T) {
  chronicle.replace({
    ...chronicle.location,
    search: queEncode(query),
  });
}

export function useQuePropReplace<T>(prop: string): [T | undefined, (value: T) => void] {
  const value = useQue<any>();

  return [
    value[prop],
    (value) => {
      queReplace({ ...currentQue, [prop]: value });
    },
  ];
}
