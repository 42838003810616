import { css } from '@emotion/css';
import { CrushPlaceholderGrabBonusButton } from './grab-bonus-button';
import ARCADE_MACHINE_URL from 'app/crush/assets/arcade-machine.png';
import { useCrushT } from 'app/crush/glossary';
import videoTutorial from '../assets/tutorial.mp4';

export const CrushPlaceholder = (): JsxElement => {
  const t = useCrushT().sub('placeholder');

  return (
    <div className={mainClass}>
      <div className={welcomeClass}>
        <div className={circle2Class}></div>
        <div className={circle3Class}></div>

        <p className={titleClass}>{t('title')}</p>
        <p className={descriptionClass}>{t('description')}</p>
        <CrushPlaceholderGrabBonusButton />
      </div>
      <div className={arcadeClass}>
        <div className={circle1Class}></div>
        <div className={arcadeMachineClass}>
          <img src={ARCADE_MACHINE_URL} alt="" className={arcadeMachineSvgClass} />
          <div className={videoBoxClass}>
            <video
              autoPlay
              loop
              muted
              playsInline
              disablePictureInPicture
              src={videoTutorial}
              className={videoClass}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mainClass = css`
  display: flex;
  justify-content: space-between;
  margin: 0 0 0 32px;
`;

const arcadeMachineSvgClass = css`
  height: 250px;
`;

const videoBoxClass = css`
  position: absolute;
  top: 71px;
  left: 87px;
  width: 134.36px;
  height: 115.33px;
  transform: matrix(1, -0.02, -0.04, 1, 0, 0);
  overflow: hidden;
`;

const videoClass = css`
  width: 140.36px;
  height: 119.33px;
`;

const welcomeClass = css`
  position: relative;
  display: flex;
  flex: 0 0 50%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const titleClass = css`
  position: relative;
  font-family: 'Onest';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 12px;
`;

const descriptionClass = css`
  position: relative;
  font-family: 'Onest';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #988c92;
  margin-bottom: 24px;
  max-width: 75%;
`;

const arcadeClass = css`
  position: relative;
`;

const arcadeMachineClass = css`
  position: relative;
`;

const circle1Class = css`
  position: absolute;
  top: -35px;
  left: -20px;
  width: 195px;
  height: 195px;
  background: linear-gradient(180deg, #ffaa63 0%, #ffaa63 0.01%, #ea9a4e 100%);
  opacity: 0.15;
  filter: blur(50px);
`;

const circle2Class = css`
  position: absolute;
  top: -63px;
  left: -67px;
  width: 195px;
  height: 195px;
  background: #988c92;
  opacity: 0.1;
  filter: blur(50px);
`;

const circle3Class = css`
  position: absolute;
  bottom: -110px;
  left: 80px;
  width: 195px;
  height: 195px;
  background: #988c92;
  opacity: 0.1;
  filter: blur(50px);
`;
