import { createRef, useEffect } from 'react';
import { useSingleton } from 'support/react/use-singleton';
import { SimpleEventTarget } from 'support/struct/simple-event-target';

const BlurSub = new SimpleEventTarget<number>();

export const subLayoutBlur = () => {
  BlurSub.emit(1);
  return () => BlurSub.emit(-1);
};

export function LayoutBlurStack() {
  useEffect(subLayoutBlur, []);
  return null;
}

export const useLayoutBlurRef = () => {
  const [ref, effect] = useSingleton(() => {
    const ref = createRef<HTMLDivElement>();
    let stack = 0;

    function effect() {
      return BlurSub.sub((add) => {
        stack += add;
        const el = ref.current;
        if (!el) return;
        if (stack !== 0) {
          el.style.filter = 'blur(4px)';
        } else {
          el.style.filter = null!;
        }
      });
    }

    return [ref, effect];
  });

  useEffect(effect, []);

  return ref;
};
