import { css } from '@emotion/css';
import { useContestT } from 'app/contest/glossary';
import { ContestInsightGameHistoryStats } from 'app/contest/insight/game-history/stats';
import { ContestInsightGameHistoryTable } from 'app/contest/insight/game-history/table';
import { appSyringe } from 'app/syringe';
import { ContestData, ContestTopListItem, ContestUpon, ContestUponCodeMap } from 'domain/contest';
import { coilReq, coilReqR } from 'packs/libs/coil';
import { StdHarmonicDialogBlock } from 'packs/std/dialog/blocks/harmonic';
import { StdTopDialog } from 'packs/std/dialog/top-dialog';
import { launch } from 'support/react/use-launch';

export const openContestInsightGameHistoryDialog = (
  contest: ContestData,
  item: ContestTopListItem,
  prize: string
) => {
  launch(async () => {
    const r = await coilReqR({
      action: 'contest.member.history',
      data: { contestId: contest.id, playerId: item.player.id },
    });

    const data: {
      upon: ContestUpon;
      stake: number;
      profit: number;
    }[] = [];
    const codeMap = ContestUponCodeMap.rev;
    while (!r.complete) {
      data.push({
        upon: codeMap.get(r.uint8()),
        stake: r.double(),
        profit: r.double(),
      });
    }

    appSyringe(function ContestInsightGameHistoryDialog({ onClose }) {
      const t = useContestT().sub('insight.dialog');
      return (
        <StdTopDialog onClose={onClose}>
          <StdHarmonicDialogBlock title={t('title')} onClose={onClose}>
            <div className={mainClass}>
              <ContestInsightGameHistoryStats item={item} prize={prize} />
              <ContestInsightGameHistoryTable data={data} />
            </div>
          </StdHarmonicDialogBlock>
        </StdTopDialog>
      );
    });
  });
};

const mainClass = css`
  width: 710px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: -2px;
    background: linear-gradient(180deg, rgba(38, 35, 45, 0) 0%, rgba(38, 35, 45, 1) 100%);
    width: 100%;
    height: 53px;
  }
`;
