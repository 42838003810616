import { css } from '@emotion/css';
import { SkinData } from 'domain/skin/definitions';
import { SkinImg } from 'domain/skin/units/image';
import { useUpgradeT } from '../glossary';
import { skinNameViewOnSkin } from 'domain/skin/name-view';
import { UpgradeUnitsStreakBadge } from '../units/streak-badge';
import { stdBreakpoints } from 'packs/std';

type Props = {
  data: {
    streak: number;
    cost: number;
    skin: SkinData;
  };
};

const EXAMPLE_PROPS: Props = {
  data: {
    streak: 0,
    cost: 658.17,
    skin: {
      id: 'StatTrak™ AWP | Lightning Strike (Factory New)',
      color: [235, 75, 75],
      image:
        '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpot621FAZt7P_BdjVW4tW4k7-KgOfLP7LWnn8fu5In27GYod2l21Gx-xU5MGDzddCRdw83Y1DW-VS3wu291JS76Z7PnWwj5Hc0AjJzVA',
    } as any,
  },
};

//блок зі скіном + блюр
export const UpgradeStreak = (): JsxElement => {
  const t = useUpgradeT().sub('streak');

  const {
    data: { skin, streak, cost },
  } = EXAMPLE_PROPS;

  const name = skinNameViewOnSkin(skin);
  return (
    <div className={RightSideContentBoxClass}>
      <div className={rightSideStreakBoxClass}>
        <span className={rightSideStreakTitleClass}>{t('title')}</span>
        <div className={rightSideStreakSkinBoxClass}>
          <SkinImg className={skinImage} code={skin.image} />
        </div>
        <div>
          <span className={rightSideStreakSkinPriceClass}>${cost}</span>
          <div className={rightSideStreakSkinInfoClass}>
            <span className={rightSideStreakSkinNameClass}>{name.brand}</span>
            <span className={rightSideStreakSkinFactoryClass}>{name.model}</span>
          </div>
          <div className={rightSideStreakSkinDescriptionBoxClass}>
            <span className={rightSideStreakSkinDescriptionClass}>{t('description')}</span>
          </div>
        </div>
      </div>
      <Plug />
    </div>
  );
};

export const Plug = (): JsxElement => {
  const t = useUpgradeT().sub('plug');
  return (
    <div>
      <UpgradeUnitsStreakBadge className={streakIconClass} streak={0} />
      <div className={plugBoxClass}>
        <span className={plugTitleClass}>{t('title')}</span>
        <span className={plugDescriptionClass}>{t('description')}</span>
      </div>
    </div>
  );
};

const RightSideContentBoxClass = css`
  padding-top: 10px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  user-select: none;
  ${stdBreakpoints.up(1695)} {
    transform: scaleX(0.9);
  }
  ${stdBreakpoints.up(1820)} {
    transform: scaleX(0.8);
  }
  ${stdBreakpoints.up(1920)} {
    transform: scaleX(0.78);
  }
`;

//блок з текстом soon на заглушці
const plugBoxClass = css`
  position: absolute;
  right: 90px;
  bottom: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 167px;
`;
const plugTitleClass = css`
  font-family: 'Onest';
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  background: linear-gradient(180deg, #ffaa63 0%, #ffaa63 0.01%, #ea9a4e 100%);
  -webkit-text-fill-color: transparent;
  background-clip: text;
`;
const plugDescriptionClass = css`
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  color: #dfdfdf;
  text-align: center;
`;

const rightSideStreakBoxClass = css`
  position: relative;
  width: calc(252px + 31px);
  height: calc(183px + 17px);
  padding: 5px 25px 15px 0;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;

  opacity: 0.5;
  filter: blur(4px);
`;
const rightSideStreakTitleClass = css`
  font-weight: 600;
  font-size: 12px;
  background: linear-gradient(180deg, #ffaa63 0%, #ffaa63 0.01%, #ea9a4e 100%);
  -webkit-text-fill-color: transparent;
  background-clip: text;
`;

// Skin Box
const rightSideStreakSkinBoxClass = css`
  width: 148px;
  height: 110px;
  padding-left: 17px;
  background: radial-gradient(circle, rgba(180, 57, 255, 0.2) -100%, rgba(30, 28, 34, 0) 90%);
`;

const skinImage = css`
  width: 130px;
  height: 80px;
`;

const rightSideStreakSkinPriceClass = css`
  font-family: 'Onest';
  font-weight: 700;
  font-size: 11px;
  color: #b439ff;
  padding-bottom: 2px;
  line-height: 130%;
`;
const rightSideStreakSkinInfoClass = css`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const rightSideStreakSkinNameClass = css`
  font-weight: 600;
  font-size: 12px;
  color: #dfdfdf;
  line-height: 140%;
`;
const rightSideStreakSkinFactoryClass = css`
  font-weight: 600;
  font-size: 11px;
  color: #988c92;
`;
const rightSideStreakSkinDescriptionBoxClass = css`
  padding-right: 20px;
`;
const rightSideStreakSkinDescriptionClass = css`
  font-weight: 400;
  font-size: 11px;
  color: #988c92;
`;
// іконка поверх всіх блоків на макеті
const streakIconClass = css`
  width: 52px;
  height: 48px;
  position: absolute;
  top: 0;
  right: 15px;
`;
