export const UpgradesIcon = () => {
  return (
    <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.2"
        d="M10 0H4C1.79086 0 0 1.79086 0 4V10C0 12.2091 1.79086 14 4 14H10C12.2091 14 14 12.2091 14 10V4C14 1.79086 12.2091 0 10 0Z"
        fill="#696155"
      />
      <path
        d="M3.6261 6.92822L7.12501 2.23801C7.46968 1.77598 8.17659 2.03167 8.17659 2.61837V5.4571C8.17659 5.79795 8.43997 6.07426 8.76488 6.07426H9.91061C10.401 6.07426 10.6761 6.66667 10.3739 7.07178L6.87499 11.762C6.53032 12.224 5.82341 11.9683 5.82341 11.3816V8.5429C5.82341 8.20205 5.56003 7.92574 5.23512 7.92574H4.08939C3.59902 7.92574 3.32389 7.33333 3.6261 6.92822Z"
        fill="url(#paint0_linear_1_23)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1_23"
          x1="7"
          y1="2"
          x2="7"
          y2="12"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFAA63" />
          <stop offset="1" stopColor="#EA9A4E" />
        </linearGradient>
      </defs>
    </svg>
  );
};
