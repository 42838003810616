type CmpFn<T> = (a: T, b: T) => number;
export const fastInsertMany = <T>(cmp: CmpFn<T>, added: T[], iter: Iterator<T>) => {
  const result: T[] = [];
  const set = createSortedSet(added, cmp);

  loop: while (true) {
    const it = iter.next();
    if (it.done) {
      result.push(...set);
      return result;
    }

    for (const item of set) {
      if (cmp(item, it.value) >= 0) {
        result.push(item);
        set.delete(item);
      } else {
        result.push(it.value);
        continue loop;
      }
    }

    result.push(it.value);
    break;
  }

  while (true) {
    const it = iter.next();
    if (it.done) return result;
    result.push(it.value);
  }
};

const createSortedSet = <T>(list: T[], cmp: CmpFn<T>) => {
  const array = [...list];
  array.sort(cmp);
  return new Set(array);
};
