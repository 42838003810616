import { css, cx } from '@emotion/css';
import { useLeaderBoardT } from '../glossary';

export const LeaderBoardTableHead = (): JsxElement => {
  const t = useLeaderBoardT().sub('table-head');

  return (
    <div className={headTableBoxClass}>
      <span className={cx(headTableBoxItemClass, headTableBoxItemPlaceClass)}>{t('place')}</span>
      <span className={cx(headTableBoxItemClass, headTableBoxItemUserClass)}>{t('player')}</span>
      <span className={cx(headTableBoxItemClass, headTableBoxItemGamesClass)}>{t('games')}</span>
      <span className={cx(headTableBoxItemClass, headTableBoxItemProfitClass)}>{t('profit')}</span>
      <span className={cx(headTableBoxItemClass, headTableBoxItemPrizeClass)}>{t('prize')}</span>
    </div>
  );
};

const headTableBoxClass = css`
  min-height: 53px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #1e1c22;
  padding: 18px 96px 18px 24px;
  border-radius: 12px;
  color: #696155;
  font-weight: 700;
  font-size: 12px;
`;
const headTableBoxItemClass = css`
  width: calc(717px / 5);
  display: flex;
`;
const headTableBoxItemPlaceClass = css`
  max-width: 100px;
  justify-content: center;
`;
const headTableBoxItemUserClass = css`
  min-width: 150px;
  justify-content: flex-start;
`;
const headTableBoxItemGamesClass = css`
  justify-content: center;
`;
const headTableBoxItemProfitClass = css`
  justify-content: center;
`;
const headTableBoxItemPrizeClass = css`
  justify-content: center;
`;
