import { css } from '@emotion/css';
import { ReactComponent as PlayersOnlineIcon } from 'app/crush/assets/players-online-icon.svg';
import { ReactComponent as StatsAmountIcon } from 'app/crush/assets/stats-amount-icon.svg';
import { useCrushT } from 'app/crush/glossary';
import { CrushStoreBetsData } from 'app/crush/store/definitions';
import { CrushBetStatus } from 'domain/crush';
import { useMemo } from 'react';
import { useSingleton } from 'support/react/use-singleton';
import { graduator } from 'support/struct/graduator';

type CrushBetsStatsProps = {
  bets: CrushStoreBetsData;
};

export const CrushBetsStats = ({ bets }: CrushBetsStatsProps): JsxElement => {
  const t = useCrushT().sub('bets');
  return useMemo(() => {
    const { players, stake } = calcStats(bets);

    return (
      <div key={t.locale} className={statsBoxClass}>
        <div className={statsClass}>
          <PlayersOnlineIcon />
          <p className={inGameTextClass}>
            {t('in-game')}: <GraduatedValue value={players} decimals={0} />
          </p>
        </div>
        <div className={statsClass}>
          <StatsAmountIcon />
          <p className={textClass}>
            $<GraduatedValue decimals={2} value={stake} />
          </p>
        </div>
      </div>
    );
  }, [bets, t]);
};

type GraduatedValueProps = {
  decimals: number;
  value: number;
};

const GraduatedValue = (props: GraduatedValueProps): JsxElement => {
  const [update, content] = useSingleton(() => {
    let grad;

    function initRef(el: HTMLDivElement) {
      grad = graduator({
        decimals: props.decimals,
        onUpdate(value) {
          if (el) el.innerHTML = value.toFixed(props.decimals);
        },
      });
      grad.reset(props.value);
    }

    let init = true;
    const update = (value) => {
      if (grad) {
        if (init) {
          grad.reset(value);
          init = false;
        } else if (value === 0) {
          grad.reset(value);
        } else {
          grad.update(value);
        }
      }
    };

    const content = <span className={spanClass} ref={initRef} />;
    return [update, content];
  });

  update(props.value);

  return content;
};

const calcStats = (bets: CrushStoreBetsData) => {
  const players = bets.size;
  // let still = 0;
  let stake = 0;
  let won = 0;
  for (const bet of bets.values()) {
    stake += bet.stake.total;
    // if (bet.status === Bet.State.PENDING) {
    //   still += add;
    // } else
    if (bet.status === CrushBetStatus.won) {
      won += bet.prize!.total ?? 0;
    }
  }

  return { players, stake, won };
};

const textClass = css`
  font-size: 14px;
  font-family: 'Onest';
  font-weight: 500;
  letter-spacing: 0.08em;
  color: #dfdfdf;
  display: flex;
  align-items: center;
`;

const inGameTextClass = css`
  font-family: 'Commissioner';
  font-weight: 700;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #dfdfdf;
`;
const statsClass = css`
  display: flex;
  align-items: center;
  gap: 6px;
  svg {
    height: 18px;
  }
`;
const statsBoxClass = css`
  flex-basis: 58px;
  border-top-left-radius: 8px;
  background: #1e1c22;
  width: 328px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
`;

const spanClass = css`
  font-size: 13.5px;
`;
