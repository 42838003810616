import { css } from '@emotion/css';
import { useCaseBattleMonitorT } from '../glossary';
import { CaseBattleMemberData, CaseBattleStage } from '../../definitions';
import { memoize } from 'lodash';
import { PlayerImage } from 'domain/player/image/image';
import { ReactComponent as BotAvatar } from 'app/case/battle/assets/bot-avatar.svg';
import { ReactComponent as UserIcon } from 'app/case/battle/assets/user.svg';

type CaseBattleMonitorAllCaseHeaderProps = {
  stage: CaseBattleStage;
  size: number;
  members: CaseBattleMemberData[];
};
export const CaseBattleMonitorAllCaseHeader = ({
  stage,
  size,
  members,
}: CaseBattleMonitorAllCaseHeaderProps): JsxElement => {
  const t = useCaseBattleMonitorT().sub('all-cases');
  const players = members.map((data) =>
    data.bot ? (
      <BotAvatar className={playerAvatarClass} />
    ) : (
      <PlayerImage key={data.id} className={playerAvatarClass} code={data.image} />
    )
  );
  for (let i = members.length; i < size; i++) players.push(emptyPlayer);
  return (
    <div className={mainClass}>
      <div className={stageClass}>
        <CaseBattleMonitorAllCaseHeaderBadge stage={stage} />
        <span className={stageTitleClass}>{t.sub('stage')(String(stage))}</span>
      </div>
      <div className={membersClass}>
        <div className={userTotalClass}>
          <UserIcon />
          <p>
            <span>{members.length}</span>/{size}
          </p>
        </div>
        <div className={playersClass}>{...players}</div>
      </div>
    </div>
  );
};
type HeaderBadgeProps = {
  stage: CaseBattleStage;
};
export const CaseBattleMonitorAllCaseHeaderBadge = ({ stage }: HeaderBadgeProps): JsxElement => {
  return (
    <div className={badgeClass(stage)}>
      <div className={badgeSecondClass(stage)}>
        <div className={badgeThirdClass(stage)}></div>
      </div>
    </div>
  );
};

const mainClass = css`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 110px;
`;

const membersClass = css`
  display: flex;
  align-items: center;
  gap: 18px;
`;
const userTotalClass = css`
  font-family: 'Onest';
  font-weight: 700;
  font-size: 11px;
  line-height: 140%;
  color: #988c92;

  display: flex;
  align-items: center;

  span {
    color: #dfdfdf;
    font-size: 13px;
  }

  svg {
    width: auto;
    height: 14px;
    margin-right: 4px;
  }
`;
const playersClass = css`
  display: flex;
  gap: 7px;
`;

const playerAvatarClass = css`
  border-radius: 6px;
  width: 32px;
  height: 32px;
`;
const emptyAvatarClass = css`
  ${playerAvatarClass};
  background: #18171c;
`;
const stageClass = css`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

const stageTitleClass = css`
  font-family: 'Onest';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #ffffff;
`;
const backgroundStage = ['#988C92', '#0FAC7F', '#ac0f0f'];
const badgeClass = memoize(
  (stage) => css`
    border-radius: 50%;
    width: 24px;
    height: 24px;
    background: ${backgroundStage[stage]}47;
    display: flex;
    align-items: center;
    justify-content: center;
  `
);
const badgeSecondClass = memoize(
  (stage) => css`
    border-radius: 50%;
    width: 18px;
    height: 18px;
    background: ${backgroundStage[stage]}58;
    display: flex;
    align-items: center;
    justify-content: center;
  `
);
const badgeThirdClass = memoize(
  (stage) => css`
    border-radius: 50%;
    width: 6px;
    height: 6px;
    background: ${backgroundStage[stage]};
    box-shadow: 0px 0px 4px 1px ${backgroundStage[stage]};
    display: flex;
    align-items: center;
    justify-content: center;
  `
);
const emptyPlayer = <div className={emptyAvatarClass} />;
