export const TopIcon = ({ active, current }) => {
  return active || current ? (
    <svg viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.75 6.57576V2C12.75 1.17157 12.0784 0.5 11.25 0.5H6.75C5.92157 0.5 5.25 1.17157 5.25 2V6.57576C6.20289 5.60335 7.53098 5 9 5C10.469 5 11.7971 5.60335 12.75 6.57576Z"
        fill="url(#paint0_linear_920_324)"
      />
      <circle opacity="0.4" cx="9" cy="10.25" r="5.25" fill="url(#paint1_linear_920_324)" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.8977 6.10225C17.1174 6.32192 17.1174 6.67808 16.8977 6.89775L16.1477 7.64775C15.9281 7.86742 15.5719 7.86742 15.3523 7.64775C15.1326 7.42808 15.1326 7.07192 15.3523 6.85225L16.1023 6.10225C16.3219 5.88258 16.6781 5.88258 16.8977 6.10225Z"
        fill="url(#paint2_linear_920_324)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3523 12.8523C15.5719 12.6326 15.9281 12.6326 16.1477 12.8523L16.8977 13.6023C17.1174 13.8219 17.1174 14.1781 16.8977 14.3977C16.6781 14.6174 16.3219 14.6174 16.1023 14.3977L15.3523 13.6477C15.1326 13.4281 15.1326 13.0719 15.3523 12.8523Z"
        fill="url(#paint3_linear_920_324)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.10225 6.10225C0.882583 6.32192 0.882583 6.67808 1.10225 6.89775L1.85225 7.64775C2.07192 7.86742 2.42808 7.86742 2.64775 7.64775C2.86742 7.42808 2.86742 7.07192 2.64775 6.85225L1.89775 6.10225C1.67808 5.88258 1.32192 5.88258 1.10225 6.10225Z"
        fill="url(#paint4_linear_920_324)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.64775 12.8523C2.42808 12.6326 2.07192 12.6326 1.85225 12.8523L1.10225 13.6023C0.882583 13.8219 0.882583 14.1781 1.10225 14.3977C1.32192 14.6174 1.67808 14.6174 1.89775 14.3977L2.64775 13.6477C2.86742 13.4281 2.86742 13.0719 2.64775 12.8523Z"
        fill="url(#paint5_linear_920_324)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.2956 7.52151C9.46144 7.624 9.56238 7.80505 9.56238 8V11.9375H9.74988C10.0605 11.9375 10.3124 12.1893 10.3124 12.5C10.3124 12.8107 10.0605 13.0625 9.74988 13.0625H8.24988C7.93922 13.0625 7.68738 12.8107 7.68738 12.5C7.68738 12.1893 7.93922 11.9375 8.24988 11.9375H8.43738V8.90539C8.17353 8.99904 7.87517 8.88338 7.74676 8.62656C7.60783 8.3487 7.72046 8.01082 7.99832 7.87189L8.74832 7.49689C8.92269 7.4097 9.12977 7.41902 9.2956 7.52151Z"
        fill="url(#paint6_linear_920_324)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_920_324"
          x1="9"
          y1="0.5"
          x2="9"
          y2="6.57576"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFAA63" />
          <stop offset="1" stopColor="#EA9A4E" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_920_324"
          x1="9"
          y1="5"
          x2="9"
          y2="15.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFAA63" />
          <stop offset="1" stopColor="#EA9A4E" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_920_324"
          x1="16.125"
          y1="5.9375"
          x2="16.125"
          y2="7.8125"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFAA63" />
          <stop offset="1" stopColor="#EA9A4E" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_920_324"
          x1="16.125"
          y1="12.6875"
          x2="16.125"
          y2="14.5625"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFAA63" />
          <stop offset="1" stopColor="#EA9A4E" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_920_324"
          x1="1.875"
          y1="5.9375"
          x2="1.875"
          y2="7.8125"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFAA63" />
          <stop offset="1" stopColor="#EA9A4E" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_920_324"
          x1="1.875"
          y1="12.6875"
          x2="1.875"
          y2="14.5625"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFAA63" />
          <stop offset="1" stopColor="#EA9A4E" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_920_324"
          x1="8.99982"
          y1="7.4375"
          x2="8.99982"
          y2="13.0625"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFAA63" />
          <stop offset="1" stopColor="#EA9A4E" />
        </linearGradient>
      </defs>
    </svg>
  ) : (
    <svg viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.75 6.57576V2C12.75 1.17157 12.0784 0.5 11.25 0.5H6.75C5.92157 0.5 5.25 1.17157 5.25 2V6.57576C6.20289 5.60335 7.53098 5 9 5C10.469 5 11.7971 5.60335 12.75 6.57576Z"
        fill="#988C92"
      />
      <circle opacity="0.4" cx="9" cy="10.25" r="5.25" fill="#988C92" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.8977 6.10225C17.1174 6.32192 17.1174 6.67808 16.8977 6.89775L16.1477 7.64775C15.9281 7.86742 15.5719 7.86742 15.3523 7.64775C15.1326 7.42808 15.1326 7.07192 15.3523 6.85225L16.1023 6.10225C16.3219 5.88258 16.6781 5.88258 16.8977 6.10225Z"
        fill="#988C92"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3523 12.8523C15.5719 12.6326 15.9281 12.6326 16.1477 12.8523L16.8977 13.6023C17.1174 13.8219 17.1174 14.1781 16.8977 14.3977C16.6781 14.6174 16.3219 14.6174 16.1023 14.3977L15.3523 13.6477C15.1326 13.4281 15.1326 13.0719 15.3523 12.8523Z"
        fill="#988C92"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.10225 6.10225C0.882583 6.32192 0.882583 6.67808 1.10225 6.89775L1.85225 7.64775C2.07192 7.86742 2.42808 7.86742 2.64775 7.64775C2.86742 7.42808 2.86742 7.07192 2.64775 6.85225L1.89775 6.10225C1.67808 5.88258 1.32192 5.88258 1.10225 6.10225Z"
        fill="#988C92"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.64775 12.8523C2.42808 12.6326 2.07192 12.6326 1.85225 12.8523L1.10225 13.6023C0.882583 13.8219 0.882583 14.1781 1.10225 14.3977C1.32192 14.6174 1.67808 14.6174 1.89775 14.3977L2.64775 13.6477C2.86742 13.4281 2.86742 13.0719 2.64775 12.8523Z"
        fill="#988C92"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.29566 7.52151C9.4615 7.624 9.56244 7.80505 9.56244 8V11.9375H9.74994C10.0606 11.9375 10.3124 12.1893 10.3124 12.5C10.3124 12.8107 10.0606 13.0625 9.74994 13.0625H8.24994C7.93928 13.0625 7.68744 12.8107 7.68744 12.5C7.68744 12.1893 7.93928 11.9375 8.24994 11.9375H8.43744V8.90539C8.17359 8.99904 7.87523 8.88338 7.74682 8.62656C7.60789 8.3487 7.72052 8.01082 7.99838 7.87189L8.74838 7.49689C8.92275 7.4097 9.12983 7.41902 9.29566 7.52151Z"
        fill="#988C92"
      />
    </svg>
  );
};
