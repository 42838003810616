import { kvRepo } from 'packs/libs/db';
import { isObjectEmpty } from 'support/etc/is-object-empty';

const [utmRepoFind, utmRepoSet, utmRepoDel] = kvRepo('utm');

export { utmRepoFind, utmRepoDel };

export const startupUtm = async (query: Rsa) => {
  const params = {};
  for (const [key, value] of Object.entries(query)) {
    if (key.startsWith('utm_')) params[key] = value;
  }
  if (isObjectEmpty(params)) return;
  await utmRepoSet(params);
};
