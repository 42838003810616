import { css } from '@emotion/css';
import { forwardRef, memo, Ref } from "react";

import { CrushBetsCardDetailsPop } from 'app/crush/bets/card/details-pop';
import { CrushBetData } from 'app/crush/store/definitions';
import { CrushBetStatus } from 'domain/crush';
import { PlayerLinkedImage } from 'domain/player/image/linked-image';
import { juxPath } from 'support/etc/juxtapose';

type CrushBetCardProps = {
  bet: CrushBetData;
};

export const CrushBetCard = memo(
  forwardRef(function CrushBetCard({ bet }: CrushBetCardProps, ref: Ref<HTMLDivElement>) {
    const { player, stake, status, prize, x } = bet;

    return (
      <div ref={ref}>
        <CrushBetsCardDetailsPop bet={bet}>
          <div className={playerBoxClass}>
            <PlayerLinkedImage className={imageClass} player={player} />
            <p className={nameClass}>{player.name}</p>
          </div>
          {status !== CrushBetStatus.crashed ? (
            x ? (
              <p className={winnCofClass}>x{x.toFixed(2)}</p>
            ) : (
              <p className={pendingLineClass}>━</p>
            )
          ) : null}

          {status !== CrushBetStatus.crashed ? (
            prize ? (
              <p className={prizeClass}>${prize.total.toFixed(2)}</p>
            ) : (
              <p className={pendingTextClass}>${stake.total.toFixed(2)}</p>
            )
          ) : null}
        </CrushBetsCardDetailsPop>
      </div>
    );
  }),
  juxPath(['bet', 'status'])
);

const playerBoxClass = css`
  display: flex;
  gap: 8px;
  flex-grow: 1;
  align-items: center;
  cursor: pointer;
`;
const textClass = css`
  font-family: 'Onest';
  font-weight: 700;
  font-size: 13px;
  line-height: 140%;
  color: #0fac7f;
`;
const winnCofClass = css`
  ${textClass};
  margin-left: 8px;
`;
const prizeClass = css`
  ${textClass};
  flex-basis: 70px;
  text-align: end;
`;

const pendingTextClass = css`
  ${prizeClass};
  color: #dfdfdf;
`;

const pendingLineClass = css`
  color: #988c92;
`;

const imageClass = css`
  height: 32px;
  border-radius: 6px;
`;
const nameClass = css`
  ${textClass};
  color: #dfdfdf;
  width: 82px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
