import { css } from '@emotion/css';
import { useContestT } from 'app/contest/glossary';
import { ContestBriefData } from 'domain/contest';

type ContestCardPrizeProps = {
  contest: ContestBriefData;
};

export const ContestCardPrize = ({ contest }: ContestCardPrizeProps): JsxElement => {
  const t = useContestT().sub('card');

  const fund = contest.prize.reduce((acc, item) => acc + item, 0);

  return (
    <div className={mainClass}>
      <div className={prizeClass}>
        <div className={fullPrizeBoxClass}>
          <p className={titlePrizeClass}>{t('prize-fund')}</p>
          <p className={fullPrizeClass}>${fund}</p>
        </div>
      </div>
    </div>
  );
};

const mainClass = css`
  background: linear-gradient(150deg, rgba(15, 172, 127, 4%) 40%, rgba(15, 172, 127, 100%) 100%);
  border-radius: 4px;
  width: 100%;
  height: 57px;
`;

const prizeClass = css`
  background: linear-gradient(135deg, rgba(24, 23, 28, 50%) 0%, rgba(24, 23, 28, 75%) 100%);
  border-radius: 4px;
  width: calc(100% - 1px);
  height: 56px;
`;

const titlePrizeClass = css`
  font-family: 'Commissioner';
  font-weight: 600;
  font-size: 10px;
  line-height: 130%;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #dfdfdf;
  white-space: nowrap;
`;

const fullPrizeBoxClass = css`
  padding: 10px 2px 6px 12px;
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

const fullPrizeClass = css`
  font-family: 'Onest';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 140%;
  color: #0fac7f;
`;
