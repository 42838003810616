import { css, cx } from '@emotion/css';
import { ReactComponent as PlusIcon } from './assets/plus.svg';
import { ReactComponent as MinusIcon } from './assets/minus.svg';
import { ListCaseData } from './case-list';
import { CaseImg } from 'domain/case/units/image';
import { CaseBattleCreationClickOnCaseWhileCreatingSound } from 'app/case/battle/creation/sounds';
import { CSSProperties, useState } from 'react';
import { stdBp } from 'packs/std';

type CardProps = {
  data: ListCaseData;
  select(data: ListCaseData | null): void;
  actions: {
    setAmount(id: string, amount: number): void;
    delCase(caseId: string): void;
  };
};
const MIN_COUNT = 1;
export const Card = ({ data, select, actions }: CardProps): JsxElement => {
  const [selected, setSelected] = useState<boolean>(false);
  const handleChangeInput = (event) => {
    actions.setAmount(data.case.id, +event.target.value ?? MIN_COUNT);
  };
  const toggle = () => {
    setSelected(() => !selected);
    if (data.amount > 0) select(data);
    else select(null);
  };
  return (
    <div
      className={cx(cardMainClass, selected && cardMainSelectedClass)}
      style={{ '--l-color': data.case.color?.join(', ') } as CSSProperties}
      onClick={() => {
        toggle();
        CaseBattleCreationClickOnCaseWhileCreatingSound.replay();
      }}
    >
      {data.amount > 0 && (
        <div
          className={cardCaseAmountClass}
          onClick={(event) => {
            event.stopPropagation();
            CaseBattleCreationClickOnCaseWhileCreatingSound.replay();
          }}
        >
          <div
            onClick={() => {
              actions.setAmount(data.case.id, data.amount - 1);
              if (data.amount < 2) {
                select(null);
              }
            }}
            className={cardCaseIconBoxClass}
          >
            <MinusIcon className={minusIconClass} />
          </div>
          <input
            type="number"
            onChange={handleChangeInput}
            value={data.amount}
            onKeyDown={(event) => {
              if (event.key === ',' || event.key === '.') {
                event.preventDefault();
              }
            }}
            className={cx(inputClass, data.amount >= 10 && inputActiveClass)}
          />
          <div
            onClick={() => {
              actions.setAmount(data.case.id, data.amount + 1);
              CaseBattleCreationClickOnCaseWhileCreatingSound.replay();
            }}
            className={cardCaseIconBoxClass}
          >
            <PlusIcon className={plusIconClass} />
          </div>
        </div>
      )}
      <div className={cardHeadClass}>
        <span className={cardPriceClass}>${data.case.price}</span>
      </div>
      <CaseImg className={cardCaseImgClass} id={data.case.id} />
      <div className={cardCaseNameClass}>{data.case.name}</div>
    </div>
  );
};

const plusIconClass = css`
  width: 10px;
  height: 10px;
  ${stdBp.up(2560)} {
    width: calc(10px * 1.3);
    height: calc(10px * 1.3);
  }
`;

const minusIconClass = css`
  width: 10px;
  height: 2px;
  ${stdBp.up(2560)} {
    width: calc(10px * 1.3);
    height: calc(2px * 1.3);
  }
`;

const cardCaseAmountClass = css`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  user-select: none;
  top: 8px;
  left: 0;
  width: 100%;
`;

const cardCaseIconBoxClass = css`
  cursor: pointer;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${stdBp.up(2560)} {
    width: calc(15px * 1.3);
    height: calc(15px * 1.3);
  }
`;
const inputClass = css`
  width: 9px;
  font-family: 'Onest';
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #dfdfdf;
  outline: 0;
  border: none;
  background: none;
  -moz-appearance: textfield;
  ${stdBp.up(2560)} {
    font-size: calc(14px * 1.3);
    width: calc(9px * 1.3);
  }
  &::placeholder {
    font-family: 'Onest';
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #dfdfdf;
    ${stdBp.up(2560)} {
      font-size: calc(14px * 1.3);
    }
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  &:hover,
  &:focus {
    appearance: none;
    -moz-appearance: textfield;
  }
`;

const inputActiveClass = css`
  width: 16px;
  ${stdBp.up(2560)} {
    width: calc(16px * 1.3);
  }
`;

const cardMainClass = css`
  position: relative;
  width: 145px;
  height: 150px;
  background: #18171c;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(180deg, rgb(0, 0, 0, 0) 0%, rgb(var(--l-color), 0.1) 100%);
  padding: 6px;
  &:hover {
    border: 1px solid #ea9a4e;
  }
  ${stdBp.up(2560)} {
    height: calc(145px * 1.3);
    width: calc(147px * 1.3);
  }
`;

const cardMainSelectedClass = css`
  border: 1px solid #ea9a4e;
`;
const cardHeadClass = css`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
const cardPriceClass = css`
  font-family: 'Onest';
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #dfdfdf;
  ${stdBp.up(2560)} {
    font-size: calc(14px * 1.3);
  }
`;
const cardCaseImgClass = css`
  height: 100px;
  width: 135px;
  object-fit: none;
  padding-bottom: 5px;
  ${stdBp.up(2560)} {
    height: calc(100px * 1.3);
    width: calc(135px * 1.3);
    object-fit: cover;
  }
`;
const cardCaseNameClass = css`
  font-weight: 600;
  font-size: 12px;
  line-height: 130%;
  color: #dfdfdf;
  ${stdBp.up(2560)} {
    font-size: calc(12px * 1.3);
  }
`;
