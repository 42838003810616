import { ComponentType, createElement, lazy, Suspense } from 'react';

export const stdLoadable = <T extends ComponentType<any>>(
  factory: () => Promise<{ default: T }>
): T => {
  // @ts-ignore
  // return dynamic(factory, {ssr: false});
  const Component = lazy<T>(factory);

  // @ts-ignore
  return function StdLoader(props) {
    return <Suspense fallback={null}>{createElement(Component, props)}</Suspense>;
  };
};
