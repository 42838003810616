import { css } from '@emotion/css';
import { InventoryItemData } from 'app/inventory/definitions';
import { InventorySkinsActionPanel } from 'app/inventory/skins/action-panel';
import { InventorySkinsItem } from 'app/inventory/skins/item';
import { useInventoryState } from 'app/inventory/store';
import { stdBreakpoints } from 'packs/std';

export const InventorySkins = () => {
  return (
    <div className={mainClass}>
      <List />
      <InventorySkinsActionPanel />
    </div>
  );
};

const List = (): JsxElement => {
  const { items } = useInventoryState();

  return (
    <div className={listClass}>
      {...items.listMapV((item: InventoryItemData) => (
        <>
          <InventorySkinsItem data={item} key={item.id} />
        </>
      ))}
    </div>
  );
};

const listClass = css`
  display: flex;
  flex-wrap: wrap;
  height: 137px;
  overflow: auto;

  ${stdBreakpoints.between(1280, 1440)} {
    height: 100px;
  }
`;
const mainClass = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${stdBreakpoints.between(1280, 1440)} {
    height: calc(100% - 80px);
  }
  height: calc(100% - 68px);
`;
