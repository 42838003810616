import { css } from '@emotion/css';
import { skinColorMemoize } from 'domain/skin/utils/color-memoize';

export const returnGradientFrame = skinColorMemoize((color: number[]) => {
  const mkRP = (rgb: number[], percent: string | number) =>
    `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, 1) ${percent}%`;

  const bgLine = ['rgba(0, 0, 0, 0) 45.02%', mkRP(color, 100)];

  const toGradient = (line) => `linear-gradient(155deg, ${line.join(', ')});`;

  const mainClass = css`
    padding: 0 1px 1px 0;
    border-radius: 8px;
    background-image: ${toGradient(bgLine)};
    width: clamp(70px, 72px, 74px);
    height: clamp(42px, 44px, 46px);
  `;

  const boxClass = css`
    width: clamp(69px, 71px, 73px);
    height: clamp(41px, 43px, 45px);
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(180deg, #1f1d23 0%, rgba(30, 28, 34, 0.6) 100%);
    border-radius: 8px;
  `;

  return function GradientFrame({ children }: Rcp) {
    return (
      <div className={mainClass}>
        <div className={boxClass}>{children}</div>
      </div>
    );
  };
});
