import { css } from '@emotion/css';

import { LayoutSidebarSettingsChangeLanguage } from './change-language';
import { LayoutSidebarSettingsSoundVolumeMixer } from './sound-volume-mixer';
import { LayoutSidebarContestLazy } from 'app/layout/sidebar/contest/lazy';

export const LayoutSidebarSettingsPanel = (): JsxElement => {
  return (
    <div className={settingsBoxClass}>
      <LayoutSidebarContestLazy />
      <LayoutSidebarSettingsSoundVolumeMixer />
      <LayoutSidebarSettingsChangeLanguage />
    </div>
  );
};

const settingsBoxClass = css`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
